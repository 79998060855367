import React, { useContext, useEffect, useState } from "react";
import {
  Route,
  Routes,
  Navigate,
  useLocation,
  useNavigate,
  useSearchParams,
} from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import {
  GA_ACTION,
  GA_CATEGORIES,
  GA_LABEL,
  PAGE_URLS,
  UTM_APP_TYPE_ANDROID,
  hasMovedToNewUrl,
} from "./constants";
import { APIContext } from "../apiLayer";
import ErrorPage from "../components/Fallback/ErrorPage";
import LoadingPage from "../components/Fallback/LoadingPage";
import {
  useLazyCheckAuthStatusDataQuery,
  useLazyGetSessionIdQuery,
  useUpdateAppUtmMutation,
} from "../redux/reduxAPI";
import { useCheckMiniSourcingQuery } from "../redux/miniSourcingAuthAPI";
import { updateAuthCheckStatus, updateOauthParameters } from "../redux/redux";
import { triggerGa } from "./gaUtils";
import { signOutFirebaseAuth } from "./common";
import { useGetCreatorWarningsQuery } from "../HomePageDataLayer/HomePageReduxAPI";
import { useSingular, SINGULAR } from "../hooks/useSingular";

const RenderRoutes = (props) => {
  const { routes } = props;
  const apis = useContext(APIContext);
  const [searchParams, setSearchParams] = useSearchParams();
  const { pageName, screenName, milestones, onGoingOauth } = useSelector(
    (state) => state.appReducer
  );
  const [authCheckStatus, setAuthCheckStatus] = useState(null);
  const [triggerCheckAuthStatusData, resultCheckAuthStatusData] =
    useLazyCheckAuthStatusDataQuery();
  const [triggerGetSessionId, resultGetSessionId] = useLazyGetSessionIdQuery();
  const [triggerUpdateAppUtm] = useUpdateAppUtmMutation();
  const { logSingularEvent } = useSingular();

  const [isSourcingEnabled, setSourcingEnabled] = useState(false);
  const [runningFirebaseAuth, setRunningFirebaseAuth] = useState(true);
  const [isCreatorAdmin, setIsCreatorAdmin] = useState(false);
  const [firebaseAuthComplete, setFirebaseAuthComplete] = useState(null);
  const [creatorPostFound, setCreatorPostFound] = useState(false);
  const [creatorExists, setCreatorExists] = useState(false);
  const [authCheckingComplete, setAuthCheckingComplete] = useState(null);
  const [lastRoute, setLastRoute] = useState(null);
  const [creatorSocialAuthComplete, setCreatorSocialAuthComplete] =
    useState(false);
  const [creatorLinkOnboardingComplete, setCreatorLinkOnboardingComplete] =
    useState(false);
  const [creatorHasStorefront, setCreatorHasStorefront] = useState(true);
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const skipMiniSourcingApiCall = !["brands", "sourcing"].includes(
    location.pathname.split("/")[1]
  );
  const { data: warningData } = useGetCreatorWarningsQuery();
  const { data: checkMiniSourcing, isLoading: checkMiniSourcingisLoading } =
    useCheckMiniSourcingQuery(undefined, { skip: skipMiniSourcingApiCall });
  const isMiniSourcingEnabled = checkMiniSourcing?.mini_sourcing_enabled;
  const { creatorData } = useSelector((state) => state.apiDataReducer);
  const creatorInfo = creatorData?.data?.data || {};

  const getRouteElement = (
    firebaseAuthComplete,
    creatorExists,
    creatorSocialAuthComplete,
    isSourcingEnabled,
    route,
    creatorLinkOnboardingComplete,
    isMiniSourcingEnabled
  ) => {
    let routeElement = route.component;
    if (hasMovedToNewUrl(route.path)) {
      routeElement = (
        <Navigate to={hasMovedToNewUrl(route.path)} replace={true} />
      );
      return routeElement;
    }
    if (
      warningData?.warning?.is_creator_blocked &&
      location.pathname !== PAGE_URLS.USER_BLOCKED &&
      firebaseAuthComplete
    ) {
      navigate(PAGE_URLS.USER_BLOCKED);
    }
    if (route.path === location.pathname) {
      if (
        onGoingOauth &&
        firebaseAuthComplete &&
        creatorExists &&
        route.path !== PAGE_URLS.CREATOR_OAUTH
      ) {
        routeElement = <Navigate to={PAGE_URLS.CREATOR_OAUTH} />;
        return routeElement;
      }
      if (route.path === PAGE_URLS.APPLOGIN) {
        return routeElement;
      }
      if (isCreatorAdmin === false && creatorHasStorefront === false) {
        signOutFirebaseAuth();
        routeElement = <Navigate to={PAGE_URLS.FIREBASELOGIN} />;
        return routeElement;
      }
      if (isCreatorAdmin) {
        if (localStorage?.adminToken && route.path === PAGE_URLS.ADMIN) {
          routeElement = <Navigate to={PAGE_URLS.HOME} />;
          return routeElement;
        }
        if (route.adminNotAllowed) {
          routeElement = <Navigate to={PAGE_URLS.HOME} />;
          return routeElement;
        }
        return routeElement;
      }
      if (
        creatorLinkOnboardingComplete === false &&
        !route.canAccessWithoutOnboarding
      ) {
        routeElement = <Navigate to={PAGE_URLS.HOME} />;
        return routeElement;
      }
      if (route.path === PAGE_URLS.CREATOR_OAUTH) {
        let freshSearchParams = new URLSearchParams(location.search);
        let oauthResponseType = freshSearchParams.get("response_type");
        let oauthClientId = freshSearchParams.get("client_id");
        let oauthRedirectUri = freshSearchParams.get("redirect_uri");
        let oauthCodeChallenge = freshSearchParams.get("code_challenge");
        if (
          oauthClientId &&
          oauthRedirectUri &&
          oauthCodeChallenge &&
          oauthResponseType
        ) {
          dispatch(
            updateOauthParameters({
              oauthClientId: oauthClientId,
              oauthRedirectUri: oauthRedirectUri,
              oauthCodeChallenge: oauthCodeChallenge,
              oauthResponseType: oauthResponseType,
              onGoingOauth: true,
            })
          );
        }
      }
      if (route.needFirebaseAuthentication && !firebaseAuthComplete) {
        routeElement = <Navigate to={PAGE_URLS.FIREBASELOGIN} />;
        return routeElement;
      }
      if (route.needCreatorExists && !creatorExists) {
        routeElement = <Navigate to={PAGE_URLS.SOCIALMEDIALOGIN} />;
        return routeElement;
      }
      if (!localStorage.adminToken && route.path === PAGE_URLS.ANALYTICS) {
        if (creatorInfo?.show_payout_tnc_screen) {
          routeElement = (
            <Navigate to={PAGE_URLS.ANALYTICS_TNC} replace={true} />
          );
          return routeElement;
        }
      }
      if (
        route.needCompleteAuthentication &&
        !(firebaseAuthComplete && creatorSocialAuthComplete && creatorExists)
      ) {
        if (
          route.path === PAGE_URLS.NEWPOST ||
          route.path === PAGE_URLS.NEWCOLLECTION ||
          route.path === PAGE_URLS.AFFILIATEPRODUCTS ||
          route.path === PAGE_URLS.BANKDETAILS
        ) {
          triggerGa({
            category: GA_CATEGORIES.NAVIGATION,
            action: GA_ACTION.REDIRECT,
            label: GA_LABEL.SOCIAL_LOGIN,
            label2: route.path,
            page: pageName,
            screenName: screenName,
          });
        }
        routeElement = <Navigate to={PAGE_URLS.SOCIALMEDIALOGIN} />;
      }
      if (
        route.path === PAGE_URLS.FIREBASELOGIN ||
        route.path === PAGE_URLS.DEFAULT ||
        route.path === PAGE_URLS.WELCOME ||
        route.path === PAGE_URLS.OTP
      ) {
        if (firebaseAuthComplete) {
          if (creatorExists) {
            routeElement = <Navigate to={PAGE_URLS.HOME} />;
          } else {
            routeElement = <Navigate to={PAGE_URLS.SOCIALMEDIALOGIN} />;
          }
        }
      }
      if (route.path === PAGE_URLS.SOCIALMEDIALOGIN) {
        if (
          firebaseAuthComplete &&
          creatorExists &&
          creatorSocialAuthComplete
        ) {
          // console.log(creatorPostFound, creatorSocialLoginMilestoneComplete);
          if (creatorPostFound /* || creatorSocialLoginMilestoneComplete*/) {
            // console.log("herher6");
            routeElement = <Navigate to={PAGE_URLS.HOME} />;
          } else {
            routeElement = (
              <Navigate
                to={
                  PAGE_URLS.SOCIAL_LOGIN_SUCCESS +
                  `${
                    creatorData?.data?.data?.is_connected_insta
                      ? "#instagram"
                      : creatorData?.data?.data?.is_connected_yt
                      ? "#youtube"
                      : "#instagram"
                  }`
                }
              />
            );
          }
        }
      }

      if (route.isSourcingAccessNeeded) {
        if (!isSourcingEnabled) {
          routeElement = <Navigate to={PAGE_URLS.HOME} />;
        }
      }
      if (
        route.isMiniSourcingAccessNeeded &&
        !isMiniSourcingEnabled &&
        !checkMiniSourcingisLoading
      ) {
        routeElement = <Navigate to={PAGE_URLS.HOME} />;
      }
    } else if (
      location.pathname.startsWith("/recommended-collection/") ||
      location.pathname.startsWith("/wishlink-bestseller/")
    ) {
      if (route.needFirebaseAuthentication && !firebaseAuthComplete) {
        routeElement = <Navigate to={PAGE_URLS.FIREBASELOGIN} />;
        return routeElement;
      }
    }
    return routeElement;
  };
  useEffect(() => {
    if (
      milestones?.milestones_found &&
      milestones?.check_first_post_milestone?.achieved
    ) {
      logSingularEvent(SINGULAR.EVENTS.first_post_created);
    }
  }, [milestones]);
  useEffect(() => {
    const redirectFirebaseToken = searchParams?.get("ft");
    const redirectApp = searchParams?.get("app");
    const referee = searchParams?.get("ref");
    if (referee) {
      localStorage.setItem("referee", referee);
      searchParams?.delete("ref");
      setSearchParams(searchParams);
    }
    if (redirectFirebaseToken) {
      localStorage.setItem("firebaseToken", redirectFirebaseToken);
      localStorage.setItem("firebaseTokenExpiry", new Date().getTime() * 2);
      localStorage.setItem("loginApp", redirectApp);
      setSearchParams({});
    }
    setRunningFirebaseAuth(true);
    setAuthCheckStatus(null);
    setAuthCheckingComplete(false);
  }, [location.pathname]);

  useEffect(() => {
    if (runningFirebaseAuth) {
      setRunningFirebaseAuth(false);
      triggerCheckAuthStatusData();
    }
  }, [runningFirebaseAuth]);

  useEffect(() => {
    if (resultCheckAuthStatusData.status === "fulfilled") {
      setAuthCheckStatus(resultCheckAuthStatusData);
      dispatch(updateAuthCheckStatus(resultCheckAuthStatusData));
      setFirebaseAuthComplete(
        resultCheckAuthStatusData?.data?.data?.firebase_auth_complete
      );
      setCreatorSocialAuthComplete(
        resultCheckAuthStatusData?.data?.data?.creator_social_auth_complete
      );
      setSourcingEnabled(
        resultCheckAuthStatusData?.data?.data?.is_sourcing_enabled
      );
      setCreatorHasStorefront(
        resultCheckAuthStatusData?.data?.data?.has_storefront
      );
      setCreatorExists(resultCheckAuthStatusData?.data?.data?.creator_exists);
      setCreatorPostFound(
        resultCheckAuthStatusData?.data?.data?.creator_post_found
      );
      setIsCreatorAdmin(
        resultCheckAuthStatusData?.data?.data?.is_creator_admin
      );
      setCreatorLinkOnboardingComplete(
        milestones?.milestones_found &&
          (milestones?.check_first_link_created?.achieved ||
            milestones?.check_first_link_skipped?.achieved)
      );
      setAuthCheckingComplete(true);
      setLastRoute(location.pathname);
      if (resultCheckAuthStatusData?.data?.data?.creator_exists) {
        apis.getCreatorData();
      }
      if (localStorage?.firebaseToken) {
        triggerGetSessionId({
          sessionId: localStorage.getItem("sessionId") || "",
        });
      }
      let appUtmSource = localStorage.getItem("appUtmSource") || "";
      let appUtmMedium = localStorage.getItem("appUtmMedium") || "";
      let appType =
        window?.cordova?.platformId === "android" ? UTM_APP_TYPE_ANDROID : "";
      if (appUtmSource || appUtmMedium) {
        triggerUpdateAppUtm({
          creatorUtmSource: appUtmSource,
          creatorUtmMedium: appUtmMedium,
          creatorAppType: appType,
        });
        if (localStorage?.firebaseToken) {
          localStorage.removeItem("appUtmSource");
          localStorage.removeItem("appUtmMedium");
        }
      }
    } else if (resultCheckAuthStatusData.status === "rejected") {
      setAuthCheckingComplete(true);
      setFirebaseAuthComplete(false);
      setCreatorSocialAuthComplete(false);
      setCreatorHasStorefront(true);
      setCreatorExists(false);
      setCreatorPostFound(false);
      setCreatorLinkOnboardingComplete(false);
    }
    if (localStorage.firebaseToken || localStorage.adminToken) {
      if (resultCheckAuthStatusData?.data?.data?.creator_exists)
        apis.getCreatorDashboardMilestonesStatus(undefined, true);
    }
  }, [resultCheckAuthStatusData]);

  useEffect(() => {
    if (
      resultGetSessionId.status === "fulfilled" &&
      resultGetSessionId?.data?.success
    ) {
      localStorage.setItem("sessionId", resultGetSessionId?.data?.sessionId);
    }
  }, [resultGetSessionId]);

  const getIsRouteChanged = (lastRoute) => {
    return lastRoute === location.pathname;
  };
  return routes ? (
    <>
      {authCheckingComplete && getIsRouteChanged(lastRoute) ? (
        <Routes>
          {routes.map((route, idx) => {
            return (
              <Route
                key={idx}
                path={route.path}
                element={getRouteElement(
                  firebaseAuthComplete,
                  creatorExists,
                  creatorSocialAuthComplete,
                  isSourcingEnabled,
                  route,
                  creatorLinkOnboardingComplete,
                  isMiniSourcingEnabled
                )}
              />
            );
          })}
        </Routes>
      ) : authCheckStatus?.status === "rejected" ? (
        <ErrorPage />
      ) : (
        <LoadingPage />
      )}
    </>
  ) : null;
};

export default RenderRoutes;
