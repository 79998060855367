import { useDispatch } from "react-redux";
import { useEffect } from "react";
import {
  hideToastNotification,
  showToastNotification,
} from "../../../redux/redux";
import {
  setStatusFilterOptions,
  updateMyOrdersTriggers,
  updateSourcingMyOrders,
  setShowContentLiveDrawer,
} from "./myOrdersRedux";
import {
  useLazyGetMyOrdersQuery,
  useLazyGetMyOrderStatusOptionsQuery,
  usePostPlaceLineItemReturnRequestMutation,
} from "./myOrdersReduxAPI";

const MyOrdersDataLayer = () => {
  const dispatch = useDispatch();

  const [triggerGetMyOrders, resultGetMyOrders] = useLazyGetMyOrdersQuery();
  const [triggerGetStatusOptionsQuery, resultGetStatusOptionsQuery] =
    useLazyGetMyOrderStatusOptionsQuery();
  const [triggerPlaceLineItemReturnRequest, resultPlaceLineItemReturnRequest] =
    usePostPlaceLineItemReturnRequestMutation();

  const trigger = {
    getSourcingOrders: async (apiCallParams) =>
      await triggerGetMyOrders(apiCallParams),
    getSourcingOrdersStatusOptions: async (apiCallParams) =>
      await triggerGetStatusOptionsQuery(apiCallParams),
    postProductReturnRequest: async (apiCallParams) =>
      await triggerPlaceLineItemReturnRequest(apiCallParams),
  };

  function showToast({ variant, message }) {
    dispatch(showToastNotification({ variant, message }));
    setTimeout(() => {
      dispatch(hideToastNotification());
    }, 3000);
  }

  useEffect(() => {
    dispatch(updateMyOrdersTriggers(trigger));
  }, [trigger]);

  useEffect(() => {
    const data = resultGetMyOrders.data;
    if (data) {
      if (data?.success) {
        dispatch(updateSourcingMyOrders(data?.orders || []));
      }
    }
  }, [resultGetMyOrders]);

  useEffect(() => {
    const data = resultGetStatusOptionsQuery.data;
    if (data) {
      if (data?.success) {
        dispatch(setStatusFilterOptions(data?.status_options || []));
        dispatch(setShowContentLiveDrawer(data?.show_content_live_drawer));
      }
    }
  }, [resultGetStatusOptionsQuery]);

  useEffect(() => {
    const data = resultPlaceLineItemReturnRequest.data;
    if (data) {
      if (data.success) {
        showToast({
          variant: "success",
          message: "Return request placed successfully",
        });
      } else {
        showToast({
          variant: "error",
          message: data?.msg,
        });
      }
    }
  }, [resultPlaceLineItemReturnRequest]);

  return <></>;
};

export default MyOrdersDataLayer;
