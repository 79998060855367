import React, { useContext, useEffect, useState } from "react";
import { differenceInDays } from "date-fns";
import { useNavigate } from "react-router-dom";

import { APIContext } from "../../apiLayer";

// components
import EngageComments from "./components/EngageComments";
import ReportCardItem from "./components/ReportCardItem";
import FacebookConnect from "./components/FacebookConnect";
import { BetterHeader } from "../../components/BetterHeader";
import InstagramAccessMessage from "./components/InstagramAccessMessage";
import WishlinkTypography from "../../WishlinkComponents/WishlinkTypography";

// utils
import {
  ENGAGE_UPDATE_TYPES,
  GA_ACTION,
  GA_CATEGORIES,
  GA_LABEL,
  GA_PAGE,
  GA_SCREEN_NAME,
  PAGE_URLS,
} from "../../utils/constants";
import { getAssetUrl } from "../../utils/common";
import { triggerGa } from "../../utils/gaUtils";
import EngagePostChecker from "./components/EngagePostChecker";
import { useEngageDebuggerReportMutation } from "../../redux/reduxAPI";
import PollingAnimation from "../../components/PollingAnimation";

const EngageDiagnosisReport = () => {
  const navigate = useNavigate();
  const apis = useContext(APIContext);

  const [engageEnabled, setEngageEnabled] = useState(false);
  const [commentEnabled, setCommentEnabled] = useState(false);
  const [report, setReport] = useState(null);

  const [getCreatorEngageDebuggerResult, { isLoading }] =
    useEngageDebuggerReportMutation();

  useEffect(() => {
    fetchCreatorEngageDetails();
  }, []);

  const fetchCreatorEngageDetails = async () => {
    const res = await getCreatorEngageDebuggerResult({ postUrl: null });
    setReport(res?.data?.debug_report);
    setEngageEnabled(res?.data?.debug_report?.is_engage_enabled);
    setCommentEnabled(res?.data?.debug_report?.is_ctk_comments_enabled);
  };

  const isMultipleAccountConnected =
    report?.connected_accounts_insta?.length > 1;

  const noOfDays =
    (report?.comment_threshold_resolves_on &&
      differenceInDays(
        new Date(report?.comment_threshold_resolves_on),
        new Date()
      )) ||
    0;

  const isAllStateGood =
    engageEnabled &&
    !report?.is_message_access_disabled &&
    report?.is_facebook_connected &&
    !isMultipleAccountConnected;

  const onClose = () => {
    navigate(-1, { replace: true });
  };

  const onToggleEnagage = async () => {
    const body = {
      updateType: ENGAGE_UPDATE_TYPES.DM,
      status: !engageEnabled,
    };
    setEngageEnabled(!engageEnabled);
    navigate(PAGE_URLS.ENGAGE_DIAGONISIS_REPORT, {
      state: { report: { ...report, is_engage_enabled: !engageEnabled } },
      replace: true,
    });
    await apis.updateCTKsettings(body);
    triggerGa({
      category: GA_CATEGORIES.USER_INTERACTION,
      action: GA_ACTION.TOGGLE,
      label: GA_LABEL.ENGAGE_STATUS,
      label2: !engageEnabled ? "On" : "Off",
      page: GA_PAGE.DEBUG,
      screenName: GA_SCREEN_NAME.DEBUG,
    });
  };

  const onToggleComment = async () => {
    const body = {
      updateType: ENGAGE_UPDATE_TYPES.COMMENT,
      status: !commentEnabled,
    };
    setCommentEnabled(!commentEnabled);
    navigate(PAGE_URLS.ENGAGE_DIAGONISIS_REPORT, {
      state: {
        report: { ...report, is_ctk_comments_enabled: !commentEnabled },
      },
      replace: true,
    });
    await apis.updateCTKsettings(body);
    triggerGa({
      category: GA_CATEGORIES.USER_INTERACTION,
      action: GA_ACTION.TOGGLE,
      label: GA_LABEL.ENGAGE_COMMENT,
      label2: !commentEnabled ? "On" : "Off",
      page: GA_PAGE.DEBUG,
      screenName: GA_SCREEN_NAME.DEBUG,
    });
  };

  const handleDIY = () => {
    triggerGa({
      category: GA_CATEGORIES.CREATE,
      action: GA_ACTION.CREATE_IG_POST,
      label: "postig",
      page: GA_PAGE.DEBUG,
      screenName: GA_SCREEN_NAME.DEBUG,
    });
    navigate(PAGE_URLS.CREATE_DIY);
  };

  const handleSupport = () => {
    triggerGa({
      category: GA_CATEGORIES.NAVIGATION,
      action: GA_ACTION.FRESHDESK_OPEN,
      page: GA_PAGE.DEBUG,
      screenName: GA_SCREEN_NAME.DEBUG,
    });
    navigate(`${PAGE_URLS.HOME}?support=true`);
  };

  const engageCommentUnsortedDivs = [
    <EngageComments
      checked={engageEnabled}
      onChange={onToggleEnagage}
      title={"Engage Comment"}
      subtitle={`You need to enable this to start sending automated DMs on comment`}
      disabled={!report?.is_facebook_connected || localStorage.adminToken}
    />,
    <InstagramAccessMessage
      accessToMessage={!report?.is_message_access_disabled}
    />,
    <FacebookConnect isConnected={report?.is_facebook_connected} />,
    <ReportCardItem
      status={report?.is_dm_limit_reached}
      title={"Maximum DM limit"}
      subtitle={
        "We have reached Instagram limit of sending messages from your account, this will autmatically get resolved in sometime and DMs will start going."
      }
      statusText={report?.is_dm_limit_reached ? "Reached" : "Not Reached"}
    />,
    <ReportCardItem
      status={isMultipleAccountConnected}
      title={"Account Connected"}
      subtitle={
        "You have logged in from multiple account, make sure to unlink Engage from your previous Wishlink accounts"
      }
      statusText={isMultipleAccountConnected ? "Multiple" : "Single"}
    />,
  ];

  const engageCommentSortedConditions = [
    engageEnabled,
    !report?.is_message_access_disabled,
    report?.is_facebook_connected,
    !report?.is_dm_limit_reached,
    !isMultipleAccountConnected,
  ];

  const engageCommentSortedDivs = engageCommentSortedConditions
    .map((condition, index) => ({
      condition,
      element: engageCommentUnsortedDivs[index],
    }))
    .sort((a, b) => a.condition - b.condition) // false (0) comes before true (1)
    .map((item) => item.element);

  if (isLoading) {
    return <PollingAnimation />;
  }

  return (
    <div
      style={{
        background: "#F4F6F5",
        minHeight: "100vh",
        margin: "0 auto",
      }}
    >
      <BetterHeader
        title="Diagnosis Report"
        onClick={onClose}
        bgColor="#F4F6F5"
      />
      <div style={{ padding: "1.25rem" }}>
        <WishlinkTypography
          fontSize="0.875rem"
          color="#000000"
          fontWeight={600}
          textAlign="left"
        >
          Engage DM Health
        </WishlinkTypography>
        <WishlinkTypography
          fontSize="0.75rem"
          color="#7F7F7F"
          fontWeight={400}
          textAlign="left"
          mt="0.75rem"
          mb="1.5rem"
        >
          You need to take some action to improve Engage DM health
        </WishlinkTypography>
        <div
          style={{
            gap: "2rem",
            display: "flex",
            flexDirection: "column",
            marginBottom: "2.88rem",
          }}
        >
          {engageCommentSortedDivs}
          <EngagePostChecker
            isPostMadeOnWishlink={report?.is_post_made_on_wishlink}
            onClick={handleDIY}
            report={report}
          />
        </div>

        <WishlinkTypography
          fontSize="0.875rem"
          color="#000000"
          fontWeight={600}
          textAlign="left"
          mr="2rem"
        >
          Engage Comment Reply Health
        </WishlinkTypography>
        <WishlinkTypography
          fontSize="0.75rem"
          color="#7F7F7F"
          fontWeight={400}
          textAlign="left"
          mt="0.75rem"
          mb="1.5rem"
        >
          You need to take some action to improve the health of your Engage
          replies
        </WishlinkTypography>
        <div style={{ gap: "2rem", display: "flex", flexDirection: "column" }}>
          <EngageComments
            checked={commentEnabled}
            onChange={onToggleComment}
            title={"Engage Comment Reply"}
            disabled={localStorage.adminToken}
            subtitle={
              "You need to enable this to start sending automated replies on comment"
            }
          />
          <ReportCardItem
            status={report?.is_comments_disabled_forcefully}
            title={"Maximum Reply limit"}
            subtitle={`We have reached Instagram limit of sending replies to comment from your account, your Engage Comment Reply will again start working in ${noOfDays} days.`}
            statusText={
              report?.is_comments_disabled_forcefully
                ? "Reached"
                : "Not Reached"
            }
          />
        </div>
        {isAllStateGood && (
          <div
            className="mt-6"
            style={{
              borderRadius: "0.5rem",
              border: ` 1px solid #000`,
              background: "#FFE0C2",
              padding: "0.75rem",
              cursor: "pointer",
            }}
            onClick={handleSupport}
          >
            <WishlinkTypography
              fontSize="0.75rem"
              color="#000000"
              fontWeight={400}
              textAlign="left"
              mb="0.75rem"
            >
              Still have queries unsolved? Reach to our support
            </WishlinkTypography>
            <div className="flex align-center">
              <img src={getAssetUrl("engage-chat-icon.svg")} alt="icon" />
              <WishlinkTypography
                fontSize="0.875rem"
                color="#000000"
                fontWeight={600}
                textAlign="left"
                ml="0.4rem"
              >
                Contact Support
              </WishlinkTypography>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default EngageDiagnosisReport;
