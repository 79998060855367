import React, { useState } from "react";
import { Divider, useTheme } from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";

// theme
import { tokens } from "../../theme";
import { getAssetUrl } from "../../utils/common";

// components
import PollingAnimation from "../../components/PollingAnimation";
import WishlinkButton from "../../WishlinkComponents/WishlinkButton";
import WishlinkTextField from "../../WishlinkComponents/WishlinkTextField";
import WishlinkTypography from "../../WishlinkComponents/WishlinkTypography";

// redux
import { useEngageDebuggerReportMutation } from "../../redux/reduxAPI";
import {
  GA_ACTION,
  GA_CATEGORIES,
  GA_PAGE,
  GA_SCREEN_NAME,
  PAGE_URLS,
  URL_REGEX,
} from "../../utils/constants";
import { triggerGa } from "../../utils/gaUtils";

const EnagageDubugger = () => {
  const theme = useTheme();
  const naviagte = useNavigate();
  const colors = tokens(theme.palette.mode);
  const location = useLocation();
  const hideEngageHealth = location.state?.hideEngageHealth;

  const [postUrl, setPostUrl] = useState("");
  const [linkInvalidMessage, setLinkInvalidMessage] = useState(null);

  const [getCreatorEngageDebuggerResult, { isLoading }] =
    useEngageDebuggerReportMutation();

  const onClose = () => {
    naviagte(-1, { replace: true });
  };

  const onClickIcon = (link) => {
    if (link === "") {
      window.customPaste((clipText) => {
        setPostUrl(clipText);
      }, navigator);
    } else setPostUrl("");
    setLinkInvalidMessage(null);
  };

  const handleContinue = () => {
    const pattern = new RegExp(URL_REGEX);
    if (!pattern.test(postUrl)) {
      setLinkInvalidMessage("Invalid Post URL");
      return;
    }
    triggerGa({
      category: GA_CATEGORIES.NAVIGATION,
      action: GA_ACTION.DEBUG_START,
      label: "Withlink",
      page: GA_PAGE.DEBUG_START,
      screenName: GA_SCREEN_NAME.DEBUG_START,
    });
    getCreatorEngageDebuggerResult({ postUrl })
      .unwrap()
      .then((response) => {
        const report = response?.debug_report;
        naviagte(PAGE_URLS.ENGAGE_DIAGONISIS_REPORT, {
          state: {
            report: {
              ...report,
              showPostMadeOnWishlink: true,
            },
            hideEngageHealth: true,
          },
        });
      });
  };

  const handleContinueWithoutLink = () => {
    triggerGa({
      category: GA_CATEGORIES.NAVIGATION,
      action: GA_ACTION.DEBUG_START,
      label: "Withoutlink",
      page: GA_PAGE.DEBUG_START,
      screenName: GA_SCREEN_NAME.DEBUG_START,
    });
    getCreatorEngageDebuggerResult({ postUrl: null })
      .unwrap()
      .then((response) => {
        const report = response?.debug_report;
        naviagte(PAGE_URLS.ENGAGE_DIAGONISIS_REPORT, {
          state: {
            report: {
              ...report,
              showPostMadeOnWishlink: false,
            },
            hideEngageHealth: false,
          },
        });
      });
  };

  return (
    <>
      {isLoading ? (
        <PollingAnimation />
      ) : (
        <div
          style={{
            background: "#F4F6F5",
            minHeight: "100vh",
            padding: "1.25rem",
          }}
        >
          <div className="flex justify-between align-center mt-4">
            <WishlinkTypography
              fontSize="1rem"
              color={"#333333"}
              fontWeight={500}
              textAlign={"left"}
            >
              Engage Debug Center
            </WishlinkTypography>
            <img
              src={getAssetUrl("engage-debug-close-icon.svg")}
              alt="closeicon"
              onClick={onClose}
            />
          </div>
          <div className="flex">
            <img
              src={getAssetUrl("engage-debug-image.webp")}
              alt="engagedebug"
              style={{
                width: "5.97844rem",
                height: "5rem",
                marginTop: "3.31rem",
                marginBottom: "2rem",
              }}
            />
          </div>
          <WishlinkTypography
            fontSize="0.875rem"
            color={"#000000"}
            fontWeight={400}
            textAlign={"left"}
            mb="1rem"
          >
            <b>Engage</b> Debug is an application that detects common problem
            with your Engage Connection. It can also monitor your Instagram
            connection for intermittent connectivity failures and suggest
            relevant actions to solve it.
          </WishlinkTypography>
          {hideEngageHealth ? (
            <>
              <WishlinkTypography
                fontSize="0.875rem"
                color="#000000"
                fontWeight={400}
                textAlign="left"
                mb="1.5rem"
              >
                Please enter url of the Instagram post for which Engage
                automation is not working.
              </WishlinkTypography>
              <WishlinkTextField
                value={postUrl}
                fullWidth={true}
                placeholder="Paste your Instagram post URL here"
                onInputValueChange={(val) => {
                  setPostUrl(val);
                  setLinkInvalidMessage(null);
                }}
                error={Boolean(linkInvalidMessage) && postUrl?.length !== 0}
                showIcon={true}
                icon={postUrl === "" ? "clipboard.svg" : "close2.svg"}
                onClickIcon={() => onClickIcon(postUrl)}
                helperText={linkInvalidMessage}
                border={colors.red[500]}
              />

              <WishlinkButton
                background={"#FFF"}
                style={{
                  marginTop: "1.5rem",
                  marginBottom: "2rem",
                  ":hover": {
                    bgcolor: "#FFF",
                  },
                  ":disabled": {
                    bgcolor: colors.grey[800],
                  },
                }}
                borderRadius="0.5rem"
                width="100%"
                height="3rem"
                onClick={handleContinue}
              >
                <WishlinkTypography
                  fontSize="1rem"
                  color="#6E717D"
                  fontWeight={600}
                >
                  Continue
                </WishlinkTypography>
              </WishlinkButton>

              <div style={{ position: "relative" }}>
                <Divider />
                <div
                  style={{
                    position: "absolute",
                    left: 0,
                    right: 0,
                    bottom: -10,
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  <div
                    style={{
                      background: "#F4F6F5",
                      width: "4rem",
                    }}
                  >
                    <WishlinkTypography
                      fontSize="0.875rem"
                      color="#000"
                      fontWeight={400}
                    >
                      OR
                    </WishlinkTypography>
                  </div>
                </div>
              </div>

              <WishlinkTypography
                fontSize="0.875rem"
                color="#000000"
                fontWeight={400}
                textAlign="left"
                mt="2rem"
                mb="1rem"
              >
                You can proceed with an automated debug, if you do not have a
                post link.
              </WishlinkTypography>
            </>
          ) : (
            <></>
          )}
          <WishlinkButton
            background={colors.red[500]}
            style={{
              marginBottom: "2rem",
              ":hover": {
                bgcolor: colors.red[500],
              },
              ":disabled": {
                bgcolor: colors.grey[800],
              },
            }}
            borderRadius="0.5rem"
            width="100%"
            height="3rem"
            onClick={handleContinueWithoutLink}
            disabled={postUrl?.length > 0}
          >
            <WishlinkTypography fontSize="1rem" color="#FFF" fontWeight={600}>
              {hideEngageHealth ? "Continue without Link" : "Continue"}
            </WishlinkTypography>
          </WishlinkButton>
        </div>
      )}
    </>
  );
};

export default EnagageDubugger;
