import { createContext, useState, useMemo } from "react";
import { createTheme } from "@mui/material/styles";

export const tokens = (mode) => ({
  ...(mode === "dark"
    ? {
        grey: {
          0: "#ffffff",
          50: "#aaaaaa",
          100: "#f5f5f5",
          200: "#c2c2c2",
          300: "#a3a3a3",
          400: "#1B1B1B",
          500: "#666666",
          600: "#E5E5E5",
          700: "#333333",
          800: "#c4c4c4",
          900: "#141414",
          1000: "#f2f0f0",
          1100: "#999999",
          1200: "#000000",
          1300: "#EFEFEF",
          1400: "#6E717D",
        },
        primary: {
          100: "#d0d1d5",
          200: "#a1a4ab",
          300: "#727681",
          400: "#f2f0f0",
          500: "#f1f7ff",
          600: "#101624",
          700: "#0c101b",
          800: "#080b12",
          900: "#040509",
        },
        green: {
          100: "#dbf5ee",
          200: "#b7ebde",
          300: "#94e2cd",
          400: "#38B782",
          500: "#18a716",
          600: "#3da58a",
          700: "#e1ffdc",
          800: "#45D145",
          900: "#ebffe3",
        },
        red: {
          0: "#E78021",
          100: "#f8dcdb",
          200: "#B52254",
          300: "#E79549",
          400: "#fee8e8",
          500: "#E91E63",
          600: "#e79548",
          700: "#ff0000",
          800: "#e38125",
          900: "#ffe8e8",
          1000: "#DD5F2D",
          1100: "#FC9055",
          1200: "#E99649",
          1300: "#CD071E",
          1400: "#EDA5BF",
        },
        indigo: {
          100: "#F2F5FF",
          200: "#05003D",
          300: "#4e90ff",
          400: "#868dfb",
          500: "#F1F4F9",
          600: "#613CEA",
          700: "#204590",
          800: "#f8ecff",
          900: "#F8F4FF",
          1000: "#4031E9",
          1100: "#8998E4",
          1200: "#6E82E5",
          1300: "#4E90FF",
        },
        yellow: {
          300: "#FFEEDA",
          400: "#E89649",
          500: "#e28025",
          600: "#E58B37",
          700: "#FFFDD2",
        },
        misc: {
          0: "#000",
          100: "#11318F",
          200: "#8A0096",
          300: "#FFF3F1",
          400: "#FAA73F",
          500: "#CCE2FB",
        },
      }
    : {
        grey: {
          0: "#ffffff",
          50: "#aaaaaa",
          100: "#141414",
          200: "#c4c4c4",
          300: "#3d3d3d",
          400: "#E5E5E5",
          500: "#666666",
          600: "#1B1B1B",
          700: "#333333",
          800: "#c2c2c2",
          900: "#f5f5f5",
          1000: "#f2f0f0",
          1100: "#999999",
          1200: "#000000",
          1300: "#EFEFEF",
          1400: "#6E717D",
          1500: "#f9f9f9",
        },
        primary: {
          100: "#040509",
          200: "#080b12",
          300: "#0c101b",
          400: "#101624",
          500: "#f1f7ff",
          600: "#434957",
          700: "#727681",
          800: "#a1a4ab",
          900: "#d0d1d5",
        },
        green: {
          100: "#ebffe3",
          200: "#45D145",
          300: "#e1ffdc",
          400: "#3da58a",
          500: "#18a716",
          600: "#38B782",
          700: "#94e2cd",
          800: "#b7ebde",
          900: "#dbf5ee",
          1000: "#C2DEBD",
        },
        red: {
          0: "#E78021",
          100: "#ffe8e8",
          200: "#e38125",
          300: "#ff0000",
          400: "#e79548",
          500: "#E91E63",
          600: "#fee8e8",
          700: "#E79549",
          800: "#B52254",
          900: "#f8dcdb",
          1000: "#DD5F2D",
          1100: "#FC9055",
          1200: "#E99649",
          1300: "#CD071E",
          1400: "#EDA5BF",
        },
        indigo: {
          100: "#F8F4FF",
          200: "#f8ecff",
          300: "#204590",
          400: "#613CEA",
          500: "#F1F4F9",
          600: "#7B78FD",
          700: "#4e90ff",
          800: "#05003D",
          900: "#F2F5FF",
          1000: "#4031E9",
          1100: "#8998E4",
          1200: "#6E82E5",
          1300: "#4E90FF",
          1400: "#2B73E9",
        },
        yellow: {
          200: "#FFFDD2",
          300: "#FFEEDA",
          400: "#E89649",
          500: "#e28025",
          600: "#E58B37",
        },
        misc: {
          0: "#000",
          100: "#11318F",
          200: "#8A0096",
          300: "#FFF3F1",
          400: "#FAA73F",
          500: "#CCE2FB",
        },
      }),
});

export const themeSettings = (mode) => {
  const colors = tokens(mode);
  return {
    palette: {
      mode: mode,
      ...(mode === "dark"
        ? {
            primary: {
              main: colors.primary[500],
            },
            secondary: {
              main: colors.green[500],
            },
            neutral: {
              dark: colors.grey[700],
              main: colors.grey[500],
              light: colors.grey[100],
            },
            background: {
              default: colors.primary[500],
            },
          }
        : {
            primary: {
              main: colors.primary[100],
            },
            secondary: {
              main: colors.green[500],
            },
            neutral: {
              dark: colors.grey[700],
              main: colors.grey[500],
              light: colors.grey[100],
            },
            background: {
              default: "#fff",
            },
          }),
    },
    typography: {
      fontFamily: ["Poppins", "sans-serif"].join(","),
    },
  };
};

export const ColorModeContext = createContext({
  toggleColorMode: () => {},
});

export const useMode = () => {
  const [mode, setMode] = useState("light");

  const colorMode = useMemo(
    () => ({
      toggleColorMode: () =>
        setMode((prev) => (prev === "dark" ? "light" : "dark")),
    }),
    []
  );
  const theme = useMemo(() => createTheme(themeSettings(mode)), [mode]);

  return [theme, colorMode];
};
