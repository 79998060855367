import { createSlice } from "@reduxjs/toolkit";
// All the data related to the Home page as well as miscellaneous data that can be used anywhere will be stored here
const initialState = {
  cartTriggers: null,
  // Products should come sorted
  products: [],
  productsLoading: true,
  selectedAddress: null,
  placeOrderRequestLoading: false,
  placeOrderRequestSuccessfulId: null,
  miniSourcingSelectedProductPageUrl: null,
  isNavigated: false,
};

export const sourcingCartSlice = createSlice({
  name: "Cart",
  initialState: initialState,
  reducers: {
    updateCartTriggers(state, action) {
      state.cartTriggers = action.payload;
    },
    updateSourcingCartProducts(state, action) {
      state.products = action.payload;
    },
    updateSourcingCartProductsLoading(state, action) {
      state.productsLoading = action.payload;
    },
    updateSelectedAddress(state, action) {
      state.selectedAddress = action.payload;
    },
    updatePlaceOrderRequestLoading(state, action) {
      state.placeOrderRequestLoading = action.payload;
    },
    updatePlaceOrderRequestSuccessfulId(state, action) {
      state.placeOrderRequestSuccessfulId = action.payload;
    },
    setIsNavigated(state, action) {
      state.isNavigated = action.payload;
    },
    setMiniSourcingSelectedProductForBrandPage(state, action) {
      state.miniSourcingSelectedProductPageUrl = action.payload;
    },
  },
});

export const {
  updateCartTriggers,
  updateSourcingCartProducts,
  updateSourcingCartProductsLoading,
  updateSelectedAddress,
  updatePlaceOrderRequestLoading,
  updatePlaceOrderRequestSuccessfulId,
  setIsNavigated,
  setMiniSourcingSelectedProductForBrandPage,
} = sourcingCartSlice.actions;
