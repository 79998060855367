import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { IMAGE_PREFIX } from "../../utils/constants";
import { useTheme } from "@mui/material";

import { tokens } from "../../theme";
import WishlinkTypography from "../WishlinkTypography";
import "./index.css";

const WishlinkNotification = () => {
  const { showToast, variant, message } = useSelector(
    (state) => state.notificationReducer
  );
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  const [toastColor, setToastColor] = useState("");
  const [toastIcon, setToastIcon] = useState("");
  const [borderColor, setBorderColor] = useState("");

  useEffect(() => {
    if (variant === "success") {
      setToastColor("#E7F9ED");
      setBorderColor(colors.green[400]);
      setToastIcon("check-circle-filled.svg");
    }
    if (variant === "error") {
      setToastColor("#FDEBEA");
      setBorderColor(colors.red[500]);
      setToastIcon("error-icon.svg");
    }
    if (variant === "info") {
      setToastColor(colors.green[400]);
      setBorderColor(colors.green[400]);
      setToastIcon("success-toast.json");
    }
    if (variant === "add-to-cart") {
      setToastColor(colors.grey[0]);
      setBorderColor(colors.grey[400]);
      setToastIcon("add-to-cart-rectangle.svg");
    }
  }, [variant]);

  return (
    <>
      {showToast && (
        <div
          className="flex align-center pt-1 pb-1 pl-3 pr-2 bs-2"
          style={{
            position: "fixed",
            left: "50%",
            transform: "translateX(-50%)",
            background: toastColor,
            animation: "toastAnimation 3s ease-in-out forwards",
            zIndex: 10000000000,
            height: "auto",
            width: "max-content",
            // maxWidth: "24rem",
            maxWidth: "80%",
            border: `0.06rem solid ${borderColor}`,
            borderRadius: "0.25rem",
          }}
        >
          <img src={`${IMAGE_PREFIX}${toastIcon}`} alt="" />
          <div>
            <WishlinkTypography
              color={colors.grey[100]}
              fontSize="0.812rem"
              fontWeight={400}
              className="pl-2 text-left pt-1 pb-1 pr-1"
            >
              {message}
            </WishlinkTypography>
          </div>
        </div>
      )}
    </>
  );
};

export default WishlinkNotification;
