import { useTheme } from "@mui/material";

import { tokens } from "../../theme";
import WishlinkButton from "../../WishlinkComponents/WishlinkButton";
import { useNavigate } from "react-router-dom";
import WishlinkTypography from "../../WishlinkComponents/WishlinkTypography";
import { triggerGa } from "../../utils/gaUtils";
import { GA_ACTION, GA_CATEGORIES, GA_LABEL } from "../../utils/constants";
import { useSelector } from "react-redux";
import SocialMediaDrawer from "../Profile/SocialMediaDrawer";

const ConnectEngageAccount = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const navigate = useNavigate();
  const { pageName, screenName } = useSelector((state) => state.appReducer);

  const { milestones } = useSelector((state) => state.appReducer);
  const { creatorData } = useSelector((state) => state.apiDataReducer);

  const navigateLocked = Boolean(
    !milestones?.check_creator_social_login_complete?.achieved ||
      !milestones?.check_first_post_milestone?.achieved ||
      !creatorData?.data?.data?.is_connected_insta
  );
  const ctkNotConnected = Boolean(
    !navigateLocked && !creatorData?.data?.data?.is_connected_ctk
  );

  function onClickEnableEngage() {
    if (navigateLocked) {
      navigate("#accesslocked");
    } else if (ctkNotConnected) {
      triggerGa({
        category: GA_CATEGORIES.USER_INTERACTION,
        action: GA_ACTION.BUTTON_CLICK,
        label: GA_LABEL.ENGAGE_DRAWER,
        page: pageName,
        screenName: screenName,
      });
      navigate("#connect-engage");
    } else {
      triggerGa({
        category: GA_CATEGORIES.NAVIGATION,
        action: GA_ACTION.BUTTON_CLICK,
        label: GA_LABEL.ENGAGE_SETTINGS,
        page: pageName,
        screenName: screenName,
      });
      navigate(`/engage`);
    }
  }

  return (
    <div
      className="fixed footer p-4"
      style={{
        bottom: 0,
        zIndex: 10,
        background: "#fff",
        boxShadow: `0px -2px 10px 0px rgba(0, 0, 0, 0.25)`,
      }}
    >
      <WishlinkButton
        background={colors.red[500]}
        style={{
          ":hover": {
            bgcolor: colors.red[500],
          },
          marginBottom: "1rem",
        }}
        borderRadius="2rem"
        width="100%"
        height="3rem"
        onClick={() => onClickEnableEngage()}
      >
        <WishlinkTypography
          color={colors.grey[900]}
          fontWeight={600}
          fontSize="1rem"
          className="p-1"
        >
          Activate Engage
        </WishlinkTypography>
      </WishlinkButton>
      <SocialMediaDrawer />
    </div>
  );
};

export default ConnectEngageAccount;
