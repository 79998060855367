import { useNavigate } from "react-router-dom";
import WishlinkTypography from "../../WishlinkComponents/WishlinkTypography";
import {
  GA_ACTION,
  GA_CATEGORIES,
  GA_LABEL,
  GA_PAGE,
  GA_SCREEN_NAME,
  IMAGE_PREFIX,
  PAGE_URLS,
} from "../../utils/constants";
import { triggerGa } from "../../utils/gaUtils";

const ExploreEngageSTK = () => {
  const navigate = useNavigate();

  const onClickEngageComment = () => {
    triggerGa({
      category: GA_CATEGORIES.NAVIGATION,
      action: GA_ACTION.CARD_CLICK,
      label: GA_LABEL.ENGAGE_COMMENT,
      page: GA_PAGE.ENGAGE,
      screenName: GA_SCREEN_NAME.ENGAGE,
    });
    navigate(PAGE_URLS.ENGAGE_COMMENT);
  };

  const onClickEngageShare = () => {
    triggerGa({
      category: GA_CATEGORIES.NAVIGATION,
      action: GA_ACTION.CARD_CLICK,
      label: GA_LABEL.ENGAGE_SHARE,
      page: GA_PAGE.ENGAGE,
      screenName: GA_SCREEN_NAME.ENGAGE,
    });
    navigate(PAGE_URLS.ENGAGE_SHARE);
  };

  return (
    <div className="p-4 mt-5">
      <WishlinkTypography
        fontSize="0.875rem"
        color="#000"
        fontWeight={600}
        lineHeight="0.1875rem"
        className="text-left"
      >
        Explore
      </WishlinkTypography>
      <WishlinkTypography
        fontSize="1.25rem"
        color="#000"
        fontWeight={600}
        className="text-left mt-1"
      >
        Engage
      </WishlinkTypography>
      <div className="flex justify-between align-stretch mt-4">
        <div
          style={{ border: "1px solid #000", background: "#F6D2D7", flex: 1 }}
          className="w-100 mr-1 border-radius p-4"
          onClick={() => onClickEngageComment()}
        >
          <img
            src={`${IMAGE_PREFIX}engage-share-comment-icon.png`}
            style={{ width: "4rem" }}
            alt=""
          />
          <WishlinkTypography
            fontSize="0.875rem"
            fontWeight={600}
            color="#000"
            lineHeight="0.9375rem"
          >
            Engage Comment
          </WishlinkTypography>
          <WishlinkTypography
            fontSize="0.625rem"
            fontWeight={500}
            color="#000"
            lineHeight="0.9375rem"
            fontStyle="italic"
            className="mt-3"
          >
            Automatically send product links in DM who <b>comment on</b> your
            post.
          </WishlinkTypography>
        </div>
        <div
          style={{ border: "1px solid #000", background: "#FCB594", flex: 1 }}
          className="w-100 ml-1 border-radius p-4 relative"
          onClick={() => onClickEngageShare()}
        >
          <img
            src={`${IMAGE_PREFIX}new-ribbon.svg`}
            className="absolute"
            style={{ top: "0.25rem", right: 0 }}
            alt=""
          />
          <img
            src={`${IMAGE_PREFIX}engage-share-entry-icon.png`}
            style={{ width: "4rem" }}
            alt=""
          />
          <WishlinkTypography
            fontSize="0.875rem"
            fontWeight={600}
            color="#000"
            lineHeight="0.9375rem"
          >
            Engage Share
          </WishlinkTypography>
          <WishlinkTypography
            fontSize="0.625rem"
            fontWeight={500}
            color="#000"
            lineHeight="0.9375rem"
            fontStyle="italic"
            className="mt-3"
          >
            Automatically send product links in DM who <b>share post</b> with
            you on DM.
          </WishlinkTypography>
        </div>
      </div>
      <div className="mt-5 pt-1">
        <WishlinkTypography
          fontSize="0.75rem"
          fontWeight={700}
          color="#000"
          className="mt-3"
        >
          Safe and Secure
        </WishlinkTypography>
        <div className="flex justify-center align-center mt-3">
          <WishlinkTypography fontSize="0.75rem" fontWeight={400} color="#000">
            Developed in-house with
          </WishlinkTypography>
          <img
            src={`${IMAGE_PREFIX}meta-icon.png`}
            style={{ height: "1rem" }}
            alt=""
          />
        </div>
      </div>
    </div>
  );
};

export default ExploreEngageSTK;
