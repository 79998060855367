import EngageCommentShareHeader from "./EngageCommentShareHeader";

const EngageCommentShare = (props) => {
  const {
    headerBgColor,
    starImage,
    titleText,
    engageHeaderDetails,
    engageHeaderGif,
  } = props;
  return (
    <EngageCommentShareHeader
      headerBgColor={headerBgColor}
      starImage={starImage}
      titleText={titleText}
      engageHeaderDetails={engageHeaderDetails}
      engageHeaderGif={engageHeaderGif}
    />
  );
};

export default EngageCommentShare;
