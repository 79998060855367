import { useEffect } from "react";
import { loadGoogleScript } from "../../utils/common";

const LoadScripts = () => {
  const handleScroll = () => {
    removeListeners();
    loadGoogleScript();
    if (document.getElementById("facebook-pixel")) return;
    // Load Facebook Pixel script
    const fbScript = document.createElement("script");
    fbScript.id = "facebook-pixel";
    fbScript.defer = true;
    fbScript.innerHTML = `
      !(function (f, b, e, v, n, t, s) {
        if (f.fbq) return;
        n = f.fbq = function () {
          n.callMethod
            ? n.callMethod.apply(n, arguments)
            : n.queue.push(arguments);
        };
        if (!f._fbq) f._fbq = n;
        n.push = n;
        n.loaded = !0;
        n.version = "2.0";
        n.queue = [];
        t = b.createElement(e);
        t.async = !0;
        t.src = v;
        s = b.getElementsByTagName(e)[0];
        s.parentNode.insertBefore(t, s);
      })(
        window,
        document,
        "script",
        "https://connect.facebook.net/en_US/fbevents.js"
      );
      fbq("init", "496989819025278");
      fbq("track", "PageView");
    `;
    document.head.appendChild(fbScript);
  };

  const addListeners = () => {
    window.addEventListener("scroll", handleScroll);
    window.addEventListener("click", handleScroll);
    window.addEventListener("touchstart", handleScroll);
  };

  const removeListeners = () => {
    window.removeEventListener("scroll", handleScroll);
    window.removeEventListener("click", handleScroll);
    window.removeEventListener("touchstart", handleScroll);
  };

  useEffect(() => {
    addListeners();
    return () => {
      removeListeners();
    };
  }, []);

  return null;
};

export default LoadScripts;
