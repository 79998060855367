import { triggerGa } from "./gaUtils";

export const AUTHENTICATION_STATES = {
  AUTHENTICATED: "AUTHENTICATED",
  ONLY_FIREBASE_AUTHENTICATED: "ONLY_FIREBASE_AUTHENTICATED",
  NOT_AUTHENTICATED: "NOT_AUTHENTICATED",
};

export const SENTRY = {
  DSN: "https://e97a9c02eb5f1dedc92298d731841c2e@o4506868845182976.ingest.us.sentry.io/4507305112109056",
  SAMPLE_RATE: 0.2,
};

export const GA_CATEGORIES = {
  SUPPORT: "support",
  HELP: "help",
  FIRST_POST: "firstpost",
  NAVIGATION: "navigation",
  LOGIN: "login",
  ERROR: "error",
  USER_EXIT: "user_exit",
  USER_ENGAGEMENT: "user_engagement",
  USER_INTERACTION: "user_interaction",
  CHART_INTERACTION: "chart_interaction",
  CREATE: "create",
  USER_EDUCATION: "usereducation",
  REWARDS: "rewards",
  LOADING: "loading",
  WEB_VITALS: "WEB_VITALS",
  STORY_INTERACTION: "story_interaction",
  VIDEO_INTERACTION: "Videointeraction",
  DELETE: "delete",
  IMPRESSION: "impression",
  CHECK: "check",
};

export const GA_ACTION = {
  CREATE_IG_POST: "createigpost",
  DONE_CLICK: "Doneclick",
  DEBUG_START: "DebugStart",
  DEBUG_RESTART: "DebugRestart",
  FAB_CLICK: "fabclick",
  MONTHLY_FILTERS: "monthlyfilter",
  NUDGE_CLICK: "NudgeClick",
  ORDER_DETAILS_PENDING: "orderdetailspending",
  ORDER_DETAILS_CONFIRMED: "orderdetailsconfirmed",
  PAUSE_VIDEO: "pausevideo",
  FLIPKART_CLICK: "flipkartclick",
  ENGAGE_GTM_DRAWER_CLOSE: "engagegtmdrawerclose",
  LOADER_TIMEOUT: "loadertimeout",
  SEE_ALL_PRODUCT_FINDS: "SeeAllProductFinds",
  MEESHO_CLICK: "meeshoclick",
  DOB_UPDATE: "dobupdate",
  ADDRESS_UPDATE: "addressupdate",
  PAGE_NAVIGATION: "pagenavigation",
  ACCORDION_OPEN: "accordionopen",
  ACCORDION_CLOSE: "accordionclose",
  TOGGLE_CLICK: "toggleclick",
  MILESTONE_SCREEN: "milestonescreen",
  FOOTER_CLICK: "footerclick",
  SKIP_JOURNEY: "skipjourney",
  BACK: "back",
  CONTENT_FILTER_SELECTION: "contentfilterselection",
  COPY_CONTENT_LINK: "copycontentlink",
  CONFIRM_SHOP_NAME: "confirmshopname",
  CREDIT_CLICK: "creditclick",
  PRO_TIP_CLICK: "protipclick",
  SKIP_POST_LINK: "skippostlink",
  TAGS_SELECTION: "tagsselection",
  SELECT_CHANNEL: "selectchannel",
  CONTENT_FILTER_CLEAR: "contentfilterclear",
  CONTENT_FILTER_OPEN: "contentfilteropen",
  CONTENT_SORT_OPEN: "contentsortopen",
  SORT: "sort",
  HIDE_CONTENT: "hidecontent",
  COPY_LINK: "copylink",
  EDIT_CONTENT: "editcontent",
  WATCH_VIDEO: "watchvideo",
  EDIT_INFO_CLICK: "editinfoclick",
  UPDATE_DETAILS: "updatedetails",
  VIDEO_INTERACTION: "videointeraction",
  NEXT_STEPS_CLICK: "nextstepsclick",
  EDIT_KEYWORDS: "editkeywords",
  EDIT_REPLIES: "editreplies",
  ADD_KEYWORD: "addkeyword",
  UNLINK_INSTA_CONFIRMATION: "unlinkinstaconfirmation",
  UNLINK_INSTA: "unlinkinsta",
  TOGGLE: "toggle",
  REFRESH_PRODUCTS: "refreshproducts",
  IMAGE_UPLOAD: "imageupload",
  PUBLISH_CONTENT: "publishcontent",
  PRODUCT_STATUS: "productstatus",
  REDIRECT: "redirect",
  ADD_PRODUCT_LINKS: "addproductlinks",
  ADD_ANOTHER_PRODUCT: "addanotherproduct",
  SAVE_DRAFT: "savedraft",
  PASTE_LINK: "pastelink",
  EDIT_POST_LINK: "editpostlink",
  TAGS_OPEN: "tagsopen",
  ADD_CONTENT: "addcontent",
  CLEAR_SEARCH: "clearsearch",
  SEARCH_BRAND: "searchbrand",
  BRAND_FILTER_CHECKBOX: "brandfiltercheckbox",
  BRAND_FILTER_CLEAR: "brandfilterclear",
  BRAND_FILTER_OPEN: "brandfilteropen",
  BRAND_FILTER_SELECTION: "brandfilterselection",
  BRAND_CARD_CLICK: "brandcardclick",
  PREVIEW_CONTENT: "previewcontent",
  BUTTON_CLICK: "buttonclick",
  SUBMIT_FORM: "submitform",
  SOCIAL_LOGIN: "sociallogin",
  CONTACT_US: "contactus",
  OVERVIEW_CLICK: "overviewclick",
  BANNER_CLICK: "bannerclick",
  REWARDS_CLICK: "rewardsclick",
  BRAND_CLICK: "brandclick",
  BOTTOM_NAV_CLICK: "bottomnavclick",
  HAMBURGER_CLICK: "hamburgerclick",
  SHOT_CUT_CLICK: "shortcutclick",
  GRAPH_SCROLL: "graphscroll",
  GRAPH_PERIOD: "graphperiod",
  DATE_SELECTION: "dateselection",
  PILL_SELECTION: "pillselection",
  LOAD_MORE_POSTS: "loadmoreposts",
  TOOLTIP_CLICK: "tooltipclick",
  ACCORDION_CLICK: "accordionclick",
  TARGET_CLICK: "targetclick",
  CREATOR_CLICK: "creatorclick",
  CARD_CLICK: "cardclick",
  CTA_CLICK: "ctaclick",
  PIE_CLICK: "pieclick",
  CONTENT_DETAILS: "contentdetails",
  COPY_ALL_LINKS: "copyalllinks",
  NOTIFICATION_ICON_CLICK: "notification_icon_click",
  NOTIFICATION_CLICK: "notification_click",
  SHARE_REFERRAL: "Share_referral",
  COLLECTION_OPEN: "collection_open",
  CATEGORY_FILTER: "category_filter",
  SPECIAL_FILTER: "special_filter",
  ADD_COLLECTION: "add_collection",
  PRODUCT_CLICK: "product_click",
  ICON_CLICK: "icon_click",
  STORY_INTERACTION: "story_interaction",
  WEB_VITALS: "WEB_VITALS",
  BRAND_SELECTION: "brand_selection",
  STORY_OPEN: "open",
  STORY_HOLD: "hold",
  STORY_TAP: "tap",
  STORY_END: "end",
  PLAY: "play",
  PAUSE: "pause",
  SCROLL_H: "scroll_h",
  CLOSE: "close",
  SEE_ALL_CLICK: "see_all_click",
  OVERLAY_CLICK: "overlay_click",
  TAB_CLICK: "tabclick",
  COPY_POST_WISHLINK: "copy_post_wishlink",
  SUPPORT_ICON_CLICK: "support_icon_click",
  FRESHDESK_OPEN: "FRESHDESK_OPEN",
  GTM_DRAWER_IMPRESSION: "gtm_drawer_impression",
  HORIZONTAL_SWIPE: "horizontalswipe",
  HEADER_CLICK: "HeaderClick",
  WIDGET_CLICK: "WidgetClick",
  CATEGORY_CLICK: "CategoryClick",
  VIEW_MORE_CATEGORY: "ViewMoreCategory",
  CATALOG_OPEN: "CatalogOpen",
  COPY_PRODUCT_LINK: "CopyProductLink",
  DELETE: "Delete",
  CONFIRM_PRODUCTS: "ConfirmProducts",
  CONFIRM_ADDRESS: "ConfirmAddress",
  PLACE_ORDER_REQUEST: "PlaceOrderRequest",
  APPLY_FILTER: "ApplyFilter",
  VIDEO_CLICK: "VideoClick",
  FAQ_CLICK: "FAQClick",
  GOT_IT_CLICK: "GotItClick",
  REMOVE: "Remove",
  ADD_NEW_ADDRESS: "AddNewAddress",
  LEARN_CLICK: "LearnClick",
  SEARCH: "Search",
  PRODUCT_CARD_CLICK: "ProductCardClick",
  SORT_OPEN: "SortOpen",
  SORT_SELECTION: "SortSelection",
  FILTER_OPEN: "FilterOpen",
  FILTER_CLEAR_ALL: "FilterClearAll",
  SIZE_CLICK: "SizeClick",
  SIZE_CHART_CLICK: "SizeChartClick",
  ADD_TO_CART: "AddToCart",
  CART_ICON: "CartIcon",
  BANNER_IMPRESSION: "BannerImpression",
  FILTER_SELECTION: "FilterSelection",
  PRODUCT_IMPRESSION: "ProductImpression",
  COPY_COUPON_CODE: "CopyCouponCode",
  MULTIPLE_FILTER_SELECTION: "MultipleFilterSelection",
  OKAY_CLICK: "OkayClick",
  MILESTONE_CLICK: "MilestoneClick",
  FRESHDESK_USER_CREATED: "FreshdeskUserCreated",
  SHOPSY_CLICK: "ShopsyClick",
  QUICK_ACTION_CLICKED: "QuickAccessClicked",
  OPTIN_CLICK: "OptinClick",
  TRACK_PROGRESS: "TrackProgress",
  VIEW_DETAILS: "ViewDetails",
  VISIT_BRAND_SITE: "VisitBrandSite",
  SOURCING: "Sourcing",
  REEL_PLAYER: "ReelPlayer",
  TNC_CLICK: "TnCClick",
  ACTIVATE_ENGAGE: "ActivateEngage",
  VIEW_LEADERBOARD_REWARDS: "ViewLeaderboardRewards",
  CREATOR_CLICK_LEADERBOARD: "CreatorClickLeaderboard",
  CONTENT_LINKS: "contentlinks",
  API_CALL_STARTED: "ApiCallStarted",
  API_CALL_END: "ApiCallEnd",
};

export const GA_LABEL = {
  ENGAGE_DEBUG: "EngageDebug",
  WHATSAPP: "whatsapp",
  MONTHLY_ORDER_STATUS: "monthly_order_status",
  REQUEST_REPORT: "Requestreport",
  CONFIRMED_BRAND: "ConfirmedBrand",
  PENDING_BRAND: "PendingBrand",
  POTENTIAL_RETURNS: "potentialreturns",
  PAST_PAYOUTS: "pastpayouts",
  SINGLE_PAYOUT: "singlepayout",
  TDS: "TDS",
  PENDING_COMMISSIONS: "pendingcommissions",
  CONFIRMED_COMMISSIONS: "confirmedcommissions",
  REWARDS_REFERRAL: "rewardsreferral",
  LANGUAGE_CHANGE: "languagechange",
  PAYOUTS_PRELAUNCH: "payoutsprelaunch",
  LIBAS_REWARDS_SOURCING_NUDGE_CLICK: "libasrewardssourcingnudgeclick",
  LIBAS_REWARDS_BANNER: "libasrewardsbanner",
  VIEW_COMMISSIONS: "viewcommissions",
  ENGAGE_GTM_DRAWER_CLOSE: "engagegtmdrawerclose",
  ENGAGE_COMMENTS_TOGGLE: "engagecommentstoggle",
  APP_HIGHLIGHT_BANNER: "apphighlightbanner",
  WRAPPED_VIEWED: "wrappedviewed",
  WRAPPED_CLICKED: "wrappedclicked",
  WRAPPED_HOME: "wrappedhome",
  LOADER_TIMEOUT_TEXT_SHOWN: "loadertimeouttextshown",
  LOADER_TIMEOUT_CLICKED: "loadertimeoutclicked",
  BPC_MEET: "bpcmeet",
  WINTER_MEET: "wintermeet",
  CREATOR_DEMO: "creatordemo",
  SUBMIT_REFERRAL: "submitreferral",
  SKIP_REFERRAL: "skipreferral",
  TIRA: "tira",
  LEADERBOARD: "leaderboard",
  REWARDS_CALCULATION: "rewardscalculation",
  EXPLORE_PRODUCT_FINDS: "ExploreProductFinds",
  PRODUCT_FINDS_BUTTON: "ProductFindsButton",
  MUST_READ: "mustread",
  MEESHO: "meesho",
  BRANDFEST: "brandfest",
  EDIT_SAVE: "editsave",
  NAME: "name",
  ADDRESS: "address",
  EMAIL: "email",
  DOB: "dob",
  CANCEL: "cancel",
  CONFIRM: "confirm",
  REMOVE_CANCEL: "removecancel",
  REMOVE_CONFIRM: "removeconfirm",
  REMOVE_CLICK: "removeclick",
  CHANGE_DEFAULT: "changedefault",
  EDIT_CLICK: "editclick",
  PAGE_CHANGE: "pagechange",
  ORDER_DATE: "orderdate",
  DATEWISE_ORDERS: "datewiseorders",
  FILTER_LIST: "filterlist",
  ORDER_LEDGER: "orderledger",
  ENGAGE_ACTIVATION: "engageactivation",
  ENGAGE_SETTINGS: "engagesettings",
  THRESHOLD_EARNINGS: "thresholdearnings",
  FIRST_SALE: "firstsale",
  ENGAGE: "engage",
  IN_FOCUS: "infocus",
  OUT_FOCUS: "outfocus",
  FEEDBACK_SUBMIT: "feedbacksubmit",
  FEEDBACK_OPEN: "feedbackopen",
  WISHLINK: "wishlink",
  BOTH: "both",
  SHARE_WISHLINKS: "sharewishlinks",
  ENGAGE_DRAWER: "engagedrawer",
  POST_CREATION: "postcreation",
  BRANDS_CATEGORY: "brandscategory",
  MUTE: "mute",
  UNMUTE: "unmute",
  NEXT: "next",
  BACK: "back",
  IMAGE_SIZE: "imagesize",
  DISPLAY: "display",
  COVER: "cover",
  BIO: "bio",
  GENDER: "gender",
  BANK_ACCOUNT_DETAILS: "bankaccountdetails",
  PAYOUT_FAILURE: "payoutfailure",
  OPEN: "open",
  CLOSE: "close",
  PLAY: "play",
  PAUSE: "pause",
  KEYWORDS_UPDATED: "keywordsupdated",
  KEYWORDS_SELECTION: "keywordsselection",
  EDIT_EXISTING_CLICK: "editexistingclick",
  EDIT_EXISTING_SAVE: "editexistingsave",
  DELETE_EXISTING_CLICK: "deleteexistingclick",
  DELETE_EXISTING_CONFIRM: "deleteexistingconfirm",
  NEW_TEMPLATE_CLICK: "newtemplateclick",
  NEW_KEYWORD_ADD: "newkeywordadd",
  NEW_TEMPLATE_ADD: "newtemplateadd",
  ENGAGE_STATUS: "engagestatus",
  SALE: "sale",
  LOGIN: "login",
  ENTER_MOBILE: "entermobile",
  ENTER_OTP: "enterotp",
  RESEND_OTP: "resendotp",
  SIGNUP: "signup",
  HOME: "home",
  SOCIAL_LOGIN: "sociallogin",
  SKIP_FOR_NOW: "skipfornow",
  ENTER_DETAILS: "enterdetails",
  SIGNUP_FAIL: "signupfail",
  POST: "post",
  COLLECTIONS: "collections",
  ANALYTICS: "analytics",
  PAYOUTS: "payouts",
  REWARDS: "rewards",
  ENGAGE_PROPS: "engageprops",
  SHOP_PROPS: "shopprops",
  PLAYBOOK: "playbook",
  BRANDS: "brands",
  BRAND_WEBSITE: "brandwebsite",
  COPY_SHOP_LINK: "copyshoplink",
  SHOP: "shop",
  CREATE: "create",
  PROFILE: "profile",
  HELP: "help",
  LOGOUT: "logout",
  DETAILEDANALYTICS: "detailedanalytics",
  DAY: "day",
  WEEK: "week",
  PRESET: "preset",
  CHANNEL: "channel",
  AMAZON_CARD: "amazoncard",
  POSTS: "posts",
  PRODUCTS: "products",
  MANUAL: "manual",
  SELLING_PLAYBOOK: "sellingplaybook",
  RETURNS_TRACKING: "returnstracking",
  SOURCING: "sourcing",
  IG: "ig",
  YT: "yt",
  POST_IG: "postig",
  POST_YT: "postyt",
  CURRENT: "current",
  PREVIOUS: "previous",
  UPCOMING: "upcoming",
  CREATOR_SHOP: "creatorshop",
  EARNINGS: "earnings",
  MY_SHOP: "myshop",
  DRAFTS: "drafts",
  TAG: "tag",
  setShowTooltip: "setShowTooltip",
  DEFAULT: "default",
  UNSEEN: "unseen",
  UNCLICKED: "unclicked",
  CTA: "cta",
  BANNER: "banner",
  NONE: "none",
  HOWREFERRALWORKS: "Howreferralworks",
  OKREFERRAL: "Okayreferral",
  REFERRALTERMS: "ReferralTerms",
  REFERRALPAGE: "ReferralPage",
  SHAREWHATSAPP: "Sharewhatsapp",
  COPYCODE: "Copycode",
  SHAREANY: "ShareAny",
  REFERRAL: "Referral",
  IMAGE: "Image",
  BUTTON: "Button",
  BEST_SELLERS: "BestSellers",
  PLP: "PLP",
  RECOMMENDED_COLLECTIONS: "RECOMMENDED_COLLECTIONS",
  VIEWDETAILSYTSHOPPING: "ViewDetailsYTShopping",
  OPT_OUT_YT_SHOPPING: "OptoutYTShopping",
  LOGIN_CLOSE: "LoginClose",
  AUTO: "auto",
  HOLD: "hold",
  BRAND_SELECTION: "brand_selection",
  SELECT: "select",
  DESELECT: "Deselect",
  CREATE_FIRST_LINK: "CreateFirstLink",
  EXPLORE_BRANDS: "ExploreBrands",
  COLLECTION: "Collection",
  SINGLE_PRODUCT: "SingleProduct",
  CREATOR_NAME: "Creator name",
  CREATOR_EARNINGS: "CreatorEarnings",
  DATE_SELECTION: "dateselection",
  CONVERSIOTN_RATE: "conversionrate",
  PAYOUTS_CHANGED: "payoutschanged",
  VERIFY: "verify",
  NPS_SURVEY: "npssurvey",
  DEACTIVATE: "deactivate",
  DELETE: "delete",
  REWARDS_BANNER: "rewards_banner",
  TOOLTIP_OPEN: "tooltip_open",
  TOOLTIP_CLOSE: "tooltip_close",
  NYKAA_REWARDS_BANNER: "nykaa_rewards_banner",
  NYKAA_REWARDS_SOURCING_NUDGE_CLICK: "nykaa_rewards_sourcing_nudge_click",
  FRESHDESK_CHAT: "freshdesk_chat",
  HAS_SOURCING: "HasSourcing",
  MINI_SOURCING: "MiniSourcing",
  CART: "Cart",
  MY_ORDERS: "MyOrders",
  SEARCH_ICON_CLICK: "searchIconClick",
  BRAND_SEARCH: "BrandSearch",
  CATEGORY_SUBCATEGORY: "CategorySubcategory",
  BRAND_SUBCATEGORY: "BrandSubcategory",
  SOURCING_LOCKED: "SourcingLocked",
  BRAND: "Brand",
  SUBCATEGORY: "Subcategory",
  CUSTOM: "Custom",
  CLEAR_ALL: "ClearAll",
  STATUS: "Status",
  TIME: "Time",
  EDUCATION: "Education",
  BARTER: "Barter",
  OKAY_CLICK: "OkayCLick",
  SIZE_CLICK: "SizeClick",
  SIZE_CHART_CLICK: "SizeChartClick",
  STICKY_BUTTON: "stickybutton",
  AJIO_REWARDS_BANNER: "ajio_rewards_banner_comingsoon",
  AJIO_NUDGE: "ajio_rewards_sourcing_nudge_click",
  SIZE_CHART_DRAWER: "SizeChartDrawer",
  MYNTRA_REWARDS_BANNER: "myntra_rewards_banner",
  FRESHDESK_USER_CREATED_BUT_RESTORED_ID_NOT_FOUND:
    "FreshdeskUserCreatedButRestoredIDNotFound",
  FLIPKART_LEADERBOARD_EXTERNAL: "flipkart_leaderboard_external",
  ENGAGE_COMMENT: "engage_comment",
  ENGAGE_SHARE: "engage_share",
  MEESHO_LEADERBOARD_EXTERNAL: "meesho_leaderboard_external",
  MYNTRA_LEADERBOARD_EXTERNAL: "myntra_bpc",
  API_CALL: "api_call",
};

export const GA_LABEL2 = {
  LOCKED: "locked",
  RETURNED: "returned",
  ALL: "all",
  PAGE_NOT_CONNECTED: "pagenotconnected",
  LOCKED_MILESTONE: "lockedmilestone",
  FOLLOWERS: "followers",
  ACCOUNT_TYPE: "accounttype",
  ACCOUNT_MAPPING: "accountmapping",
  SUBSCRIBERS: "subscribers",
  MEDIA_PERMISSIONS: "mediapermissions",
  POSTS: "posts",
  LEADERBOARD_RANKS: "leaderboardranks",
  POST_YT: "postyt",
  POST_IG: "postig",
  MEN: "men",
  WOMEN: "women",
  WA_ALLOWED: "waallowed",
  WA_DISALLOWED: "wadisallowed",
  IG_POST: "igpost",
  IG_REEL: "igreel",
  YT_SHORTS: "ytshorts",
  YT_VIDEO: "ytvideo",
  COMPLETE: "complete",
  MIN_EARNINGS: "minearnings",
  EXISTING_CONTENT: "existingcontent",
  UNLINK_INSTA: "unlinkinsta",
  ON: "on",
  OFF: "off",
  HIDE: "hide",
  UNHIDE: "unhide",
  MANUAL: "manual",
  AUTO: "auto",
  NO_OTP: "nootp",
  IG: "ig",
  YT: "yt",
  BOTH: "both",
  REWARDS_Y: "rewardsy",
  REWARDS_N: "rewardsn",
  NEXT: "next",
  PREVIOUS: "previous",
  VIEW_LESS: "viewless",
  VIEW_MORE: "viewmore",
  POST: "post",
  COLLECTION: "collection",
  PRODUCT: "product",
  CHART: "chart",
  LEGEND: "legend",
  BUTTON: "button",
  TEXT: "text",
  ANIMATION: "animation",
  COMPONENT: "Component",
  HEADER: "Header",
  TAP: "Tap",
  PRE_SALE: "presale",
  POST_SALE: "postsale",
  SWITCH_HANDLE: "switch",
  SKIPFORNOW: "skipfornow",
  NO: "no",
  YES: "yes",
  COMMISSION_ATTRIBUTION: "commission_&_attribution",
  SOCIALMEDIABLOCKED: "socialmediablocked",
  USERBLOCKED: "userblocked",
};

export const GA_PAGE = {
  DEBUG_START: "/debugstart",
  DEBUG: "/debug",
  META_BOI: "meta-boi",
  PAYOUTS_PRELAUNCH: "payoutsprelaunch",
  LIBAS_REWARDS: "libasrewards",
  LOADING: "loading",
  ORDER_LEDGER: "orderledger",
  HELP: "help",
  SHOP: "shop",
  LOGIN: "login",
  HOME: "home",
  HAMBURGER: "hamburger",
  ANALYTICS: "analytics",
  BRANDS: "brands",
  CREATE: "create",
  PAYOUTS: "payouts",
  PROFILE: "profile",
  REWARDS: "rewards",
  SELLING_PLAYBOOK: "sellingplaybook",
  ENGAGE: "engage",
  NOTIFICATION: "notification",
  REFERRAL: "referral",
  REFERRALTERMS: "ReferralTerms",
  FESTIVEBONANZA: "Festivebonanza",
  BEST_SELLERS: "bestsellers",
  PUBLIC_BRANDS: "PublicBrands",
  ONBOARDING_HOME: "onboardinghome",
  CREATOR_EARNINGS: "CreatorEarnings",
  PAYOUTS_LAUNCH: "payoutslaunch",
  BRANDS_MEN: "brands/men",
  BRANDS_WOMEN: "brands/women",
  NYKAA_REWARDS: "nykaa-rewards",
  SOURCING: "sourcingHome",
  SEARCH: "search",
  PRODUCT: "product",
  SOURCING_ORDER: "sourcing/my-orders",
  MINI_SOURCING_ORDER: "mini-sourcing-active-orders",
  FILTER: "filter",
  SOURCING_CREDIT: "SourcingCredits",
  SOURCING_CREDIT_FAQ: "SourcingCredits/FAQ",
  MINI_SOURCING_FAQ: "MiniSourcing/FAQ",
  SOURCING_LOCKED_FAQ: "SourcingLocked/FAQ",
  MINI_SOURCING_PAGE_OPEN_BANNER: "Banner/MiniSourcing",
  MINI_SOURCING: "sourcing/mini",
  MINI_SOURCING_CART: "mini-sourcing-cart",
  CATALOG: "Catalog",
  AJIO_REWARDS: "rewards_ajio",
  MYNTRA_REWARDS: "rewards_myntra",
  ENGAGE_COMMENT: "engage_comment",
  ENGAGE_SHARE: "engage_share",
  WISHLINK_REWARDS: "wishlink-rewards",
  CONTENTLINKS: "contentlinks",
};

export const GA_SCREEN_NAME = {
  DEBUG_START: "/debugstart",
  DEBUG: "/debug",
  META_BOI: "meta-boi",
  MONTHLY_ORDER_STATUS: "monthly_order_status",
  INSIGHTS: "Insights",
  PAYOUTS_PRELAUNCH: "payoutsprelaunch",
  LIBAS_REWARDS: "libasrewards",
  LOADING: "loading",
  ORDER_LEDGER: "orderledger",
  DETAILED_HELP: "detailedhelp",
  HELP: "help",
  MILESTONE_SCREEN: "milestonescreen",
  DRAWER: "drawer",
  BRANDS_SEARCH_M: "brandssearchm",
  BRANDS_SEARCH_W: "brandssearchw",
  BRANDS_CATEGORY_M: "brandscategorym",
  BRANDS_CATEGORY_W: "brandscategoryw",
  ENGAGE: "engage",
  CONTENT_ANALYTICS: "contentanalytics",
  SELLING_PLAYBOOK_EARNINGS: "sellingplaybookearnings",
  SELLING_PLAYBOOK_POSTS: "sellingplaybookposts",
  POPUP: "popup",
  PREVIEW_CONTENT: "previewcontent",
  PRODUCT_DETAILS: "productdetails",
  SHOP: "shop",
  SHOP_PROPS: "shopprops",
  LOGIN: "login",
  SIGNUP: "signup",
  ENTER_DETAILS: "enterdetails",
  SIGNUP_FAIL: "signupfail",
  HOME: "home",
  ANALYTICS: "analytics",
  ANALYTICS_OVERLAY: "analyticsoverlay",
  ANALYTICS_DETAIL: "analytics",
  BRANDS: "brands",
  POSTDETAILS: "postdetails",
  PAYOUTS: "payouts",
  PROFILE: "profile",
  REWARDS: "rewards",
  LEADERBOARD: "leaderboard",
  SELLING_PLAYBOOK: "sellingplaybook",
  CONTENT_DETAILS: "contentdetails",
  PREVIEW_STOREFRONT: "previewstorefront",
  ENGAGE_SETTING: "engagesetting",
  NOTIFICATION: "notification",
  REFERRAL: "referral",
  REFERRALTERMS: "ReferralTerms",
  FESTIVEBONANZA: "FestiveBonanza",
  BEST_SELLERS: "bestsellers",
  BEST_SELLERS_COLLECTION: "bestsellerscollection",
  YT_SHOPPING_NOTIFICATION: "YTShoppingNotification",
  BRAND_SELECTION: "brand_selection",
  ONBOARDING_HOME: "onboardinghome",
  CENTRAL_WIDGET: "CentralWidget",
  CREATE_LINK_DRAWER: "CreateLinkDrawer",
  EXPLORE_BRANDS: "ExploreBrands",
  CREATOR_EARNINGS: "CreatorEarnings",
  CREATOR_NAME: "CreatorName",
  SALE_DRAWER: "saledrawer",
  PAYOUTS_LAUNCH: "payoutslaunch",
  PAYOUTS_PENDING: "payoutsPending",
  PAYOUTS_CONFIRMED: "payoutsConfirmed",
  PAYOUTS_PENDING_BRAND: "payoutsPendingBrand",
  PAYOUTS_CONFIRMED_BRAND: "payoutsConfirmedBrand",
  PAST_PAYOUTS: "pastpayouts",
  REQUEST_REPORT: "Requestreport",
  PAYOUTS_PAID: "payoutsPaid",
  NYKAA_REWARDS: "nykaa-rewards",
  PAYOUT_DETAILS: "payoutdetails",
  SEARCH: "search",
  PRODUCT: "product",
  SOURCING_CART: "SourcingCart",
  MY_ORDER: "MyOrder",
  SOURCING_CREDIT: "SourcingCredits",
  MINI_SOURCING: "MiniSourcing",
  MINI_SOURCING_CART: "MiniSourcingCart",
  BRAND_PLP: "BrandPLP",
  COLLECTION_PLP: "CollectionPLP",
  SEARCH_PLP: "SearchPLP",
  CUSTOM_PLP: "CustomPLP",
  CATEGORY_PLP: "CategoryPLP",
  PRODUCT_CARD: "ProductCard",
  FAQ: "Faq",
  AJIO_REWARDS: "rewards_ajio_static_page",
  AJIO_REWARDS_TRACKING: "rewards_ajio_tracking",
  WISHLINK_REWARDS: "wishlink-rewards",
  REWARD_DETAILS: "reward-details",
  REWARD_TRACKING: "reward-tracking",
  CONTENTLINKS: "contentlinks",
};

export const bkp = () => {
  triggerGa({
    category: GA_CATEGORIES.USER_INTERACTION,
    action: GA_ACTION.EDIT_INFO_CLICK,
    label: GA_LABEL.NEW_TEMPLATE_CLICK,
    // page: pageName,
    // screenName: screenName,
  });
};

export const PAGE_URLS = {
  META_LANDING: "/meta-landing",
  FIRST_LINK_SELECT_BRANDS: "/first-link-select-brands",
  DEFAULT: "/",
  INDEX: "/index.html",
  APPLOGIN: "/app-login",
  SHOP: "/shop",
  NEWPOST: "/new-post",
  EDITPOST: "/edit-post/:postId",
  ADDPRODUCTS: "/post/:postId/add-products",
  PREVIEWLINKS: "/post/:postId/preview-links",
  NEWCOLLECTION: "/new-collection",
  EDITCOLLECTION: "/edit-collection/:collectionId",
  ADDPRODUCTSCOLLECTION: "/collection/:collectionId/add-products",
  PREVIEWLINKSCOLLECTION: "collection/:collectionId/preview-links",
  PREVIEWPOST: "/post/:postId/preview",
  PREVIEWCOLLECTION: "/collection/:collectionId/preview",
  SOCIALMEDIALOGIN: "/social-login",
  FIREBASELOGIN: "/login",
  INSTALOGINSUCCESS: "/auth/code",
  YTLOGINSUCCESS: "/youtube-auth",
  PINTERESTLOGINSUCCESS: "/pinterest-callback",
  AFFILIATEPRODUCTS: "/new-product",
  BRANDS: "/brands",
  CLOTHING: "/brands/:gender",
  PROFILE: "/profile",
  CROSSPOST: "/cross-post",
  BANKDETAILS: "/bank-details",
  ANALYTICSHOME: "/analytics-home",
  ANALYTICSDETAIL: "/analytics-detail",
  POSTANALYTICS: "/post/:postId/analytics",
  COLLECTIONANALYTICS: "/collection/:collectionId/analytics",
  PRODUCTANALYTICS: "/product/:productId/analytics",
  HOME: "/home",
  ENGAGE: "/engage",
  PAYOUTS: "/payout",
  ADMIN: "/creatoradmin",
  WHY: "/why",
  ENABLEENGAGE: "/enable-wishlink-engage",
  AUTHSTATUS: "/auth/status?/:token",
  FAQ: "/faq",
  FAQ_ITEM: "/faq/:id",
  SOCIAL_LOGIN_SUCCESS: "/social-login-success",
  ERROR_PAGE: "/validation-error",
  REWARDS: "/rewards/:rewardCampaignId",
  WISHLINK_REWARDS: "/wishlink-rewards",
  REWARD_DETAILS: "/wishlink-rewards/:rewardId",
  REWARD_HISTORY: "/reward-history",
  LIBAS_REWARDS: "/libas-rewards",
  PLAYBOOK: "/playbook",
  PLAYBOOK_MONETIZE: "/playbook-monetize",
  PLAYBOOK_CONTENT: "/playbook-content",
  ORDER_HISTORY: "/order-history",
  ADDRESS: "/address/:id",
  NOTIFICATION: "/notification",
  TNC: "/tnc",
  REFERRAL: "/referral",
  REFER: "/refer/:code",
  WELCOME: "/welcome",
  OTP: "/welcome/otp",
  FAVOURITE_BRANDS: "/favourite-brands",
  REFERRALINPUT: "/referral-input",
  PENDING_COMMISSION: "/pending-commission",
  CONFIRMED_COMMISSION: "/confirmed-commission",
  PAYOUT_ORDER_LEDGER: "/payout-order-ledger",
  CREATOR_EARNINGS: "/creator-earnings",
  CREATOR_OAUTH: "/wishlink-creator-oauth",
  PAYOUT_PRELAUNCH: "/payout-prelaunch",
  NYKAA_REWARDS: "/nykaa-rewards",
  AJIO_REWARDS: "/ajio-rewards",
  MYNTRA_REWARDS: "/myntra-rewards",
  DISCOVER_CONTENT: "/discover-content/:rewardId",
  REWARD_LEADERBOARD: "/leaderboard/:rewardId",
  MONTHLY_ORDER_STATUS: "/monthly-order-status",

  USER_BLOCKED: "/user-blocked",

  ANALYTICS: "/analytics",
  ANALYTICS_TNC: "/analytics_tnc",
  PAST_PAYOUTS: "/past-payouts",
  PAST_PAYOUTS_DETAILS: "/past-payouts/:id",
  LEVIED: "/levied",
  ENGAGE_COMMENT: "/engage-comment",
  ENGAGE_SHARE: "/engage-share",
  COPY_LINKS: "/copy-links/:postType/:id",
  // Sourcing
  SOURCING: "/sourcing",
  SOURCING_COLLECTION: "/sourcing/collection",
  SOURCING_PRODUCT: "/sourcing/product/",
  SOURCING_PRODUCT_URL: "/sourcing/product/:productId",
  SOURCING_CART: "/sourcing/cart",
  SOURCING_WISHLIST: "/sourcing/wishlist",
  SOURCING_MY_ORDERS: "/sourcing/my-orders",
  SOURCING_CREDIT: "/sourcing/credit",
  RECOMMENDED_COLLECTIONS: "/recommended-collections",
  WISHLINK_BESTSELLERS: "/wishlink-bestsellers",
  PREVIEWRECOMMENDEDCOLLECTION: "/recommended-collection/:id",
  PREVIEWWISHLINKBESTSELLER: "/wishlink-bestseller/:id",
  HOW_IT_WORKS: "/sourcing/how_it_works",
  SOURCING_NEW_PDP: "/sourcing/product/:productId/c/:collectionId",
  SOURCING_NEW_BARTER_PDP: "/sourcing/product/:productId/b/:collectionId",
  SOURCING_MINI: "/sourcing/mini",
  POST_DETAILS: "/shop-details/:postType/:id",
  SOURCING_LOCKED: "/sourcing-locked",
  SOURCING_CREDITS: "/sourcing-credits",
  MINI_SOURCING_ACTIVE_ORDERS: "/mini-sourcing-active-orders",
  MINI_SOURCING_CART: "/mini-sourcing-cart",
  ENGAGEDEBUGGER: "/engage-debugger",
  ENGAGE_DIAGONISIS_REPORT: "/engage-diagnosis-report",
  CREATE_DIY: "/create-diy",
};

export const MOVED_PAGES_NEW_URLS = {
  [PAGE_URLS.ANALYTICSHOME]: PAGE_URLS.ANALYTICS,
  [PAGE_URLS.ANALYTICSDETAIL]: PAGE_URLS.ANALYTICS,
  [PAGE_URLS.PAYOUTS]: PAGE_URLS.ANALYTICS + "#payouts",
  // [PAGE_URLS.REWARDS]: PAGE_URLS.HOME,
};

export const hasMovedToNewUrl = (url) => MOVED_PAGES_NEW_URLS[url];

export const LOGIN_DRAWER_STAGES = {
  CLOSED: "CLOSED",
  ENTER_NUMBER: "ENTER_NUMBER",
  ENTER_OTP: "ENTER_OTP",
  ENTER_REFERRAL_CODE: "ENTER_REFERRAL_CODE",
};

export const SOCIAL_LOGIN_STATES = {
  CONNECT_ACCOUNT: "CONNECT_ACCOUNT",
  ENTER_DETAILS: "ENTER_DETAILS",
};

export const SOCIAL_LOGIN_TYPES = {
  YOUTUBE: "Youtube",
  INSTAGRAM: "Instagram",
  PINTEREST: "Pinterest",
};

export const CONNECT_SOCIAL_MEDIA_VALUE_PROPS = [
  "Seamlessly link products with any of your content",
  "Provide a personalized shopping experience to your audience",
];

export const ASSET_COMPONENTS = {
  POLLING_ANIMATION_1: "polling-animation-1.gif",
  POLLING_ANIMATION_2: "polling-animation-2.gif",
  POLLING_ANIMATION_3: "polling-animation-3.gif",
  POLLING_ANIMATION_4: "polling-animation-4.gif",
  POLLING_ANIMATION_5: "polling-animation-5.gif",
  POLLING_ANIMATION_6: "polling-animation-6.gif",
};

export const IMAGE_PREFIX =
  "https://d3g01po1nkka75.cloudfront.net/creator-master/";

export const IMAGE_PREFIX_AZURE =
  "https://prodcdn.wishlink.com/creator-master-assets/";

export const IMAGE_PREFIX_SOURCING_ASSETS =
  "https://d3g01po1nkka75.cloudfront.net/sourcing_assets/";

export const pollingAnimations = {
  0: {
    animationUrl: ASSET_COMPONENTS.POLLING_ANIMATION_1,
    title:
      "Provide details on 'how to find links' to your audience in your post caption",
  },
  1: {
    animationUrl: ASSET_COMPONENTS.POLLING_ANIMATION_2,
    title:
      "Share regular stories with product wishlinks to drive maximum sales",
  },
  2: {
    animationUrl: ASSET_COMPONENTS.POLLING_ANIMATION_3,
    title: "Know your audience and what products & outfits they want to see",
  },
  3: {
    animationUrl: ASSET_COMPONENTS.POLLING_ANIMATION_4,
    title: "Run a series of content on products your audience is interested in",
  },
  4: {
    animationUrl: ASSET_COMPONENTS.POLLING_ANIMATION_5,
    title:
      "Frequently share collections in your stories to help your audience shop during sale periods",
  },
  5: {
    animationUrl: ASSET_COMPONENTS.POLLING_ANIMATION_6,
    title: "Follow connect.wishlink for regular updates, tips and tricks",
  },
};

export const USERNAME_STATES = {
  DEFAULT: "default",
  EDITED: "edited",
  FAILED: "failed",
};

export const SOCIAL_POST_TYPES = {
  REEL: "reel",
  SHORTS: "shorts",
  YOUTUBE_VIDEO: "youtube_video",
  INSTAGRAM_POST: "instagram_post",
};

export const gender_values = [
  {
    id: 1,
    label: "Male",
    value: "MALE",
  },
  {
    id: 2,
    label: "Female",
    value: "FEMALE",
  },
  {
    id: 3,
    label: "Prefer not to say",
    value: "OTHERS",
  },
];

export const cover_pic_crop = {
  height: 28.88888888889,
  unit: "px",
  width: 100,
  x: 50,
  y: 50,
};

export const profile_pic_crop = {
  height: 100,
  unit: "px",
  width: 100,
  x: 50,
  y: 50,
};

export const DEFAULT_KEYWORDS_CTK = ["wishlink", "link", "pp", "price"];

export const MONTHS = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

export const DATE_PRESETS = {
  THIS_MONTH: "This Month",
  LAST_2_MONTHS: "Last 2 Months",
  LAST_3_MONTHS: "Last 3 Months",
  ALL_TIME: "All Time",
  TODAY: "Today",
  YESTERDAY: "Yesterday",
  LAST_7_DAYS: "Last 7 days",
  LAST_MONTH: "Last month",
  LAST_TO_LAST_MONTH: MONTHS[new Date().getMonth() - 2],
};

export const CALENDAR_ALLOWED_PRESETS = [
  DATE_PRESETS.TODAY,
  DATE_PRESETS.YESTERDAY,
  DATE_PRESETS.LAST_7_DAYS,
  DATE_PRESETS.THIS_MONTH,
  DATE_PRESETS.LAST_MONTH,
  DATE_PRESETS.ALL_TIME,
];

export const DROPDOWN_ALLOWED_PRESETS = [
  DATE_PRESETS.THIS_MONTH,
  DATE_PRESETS.LAST_MONTH,
  DATE_PRESETS.LAST_3_MONTHS,
  DATE_PRESETS.ALL_TIME,
  DATE_PRESETS.LAST_TO_LAST_MONTH,
];

export const BENEFITS = [
  {
    icon: "personal-shop.png",
    title: "Your personalized",
    subtitle: "shop",
    subtext: "Link products to every",
    subtext2: "post and video",
  },
  {
    icon: "recommendations.png",
    title: "Curate your favv",
    subtitle: "products",
    subtext: "Recommend products you",
    subtext2: "love via your shop",
  },
  {
    icon: "organic-content.png",
    title: "Monetize 100% of",
    subtitle: "your content",
    subtext: "Earn from your",
    subtext2: "favorite brands",
  },
  {
    icon: "realtime-view.png",
    title: "Real time data",
    subtitle: "and analytics",
    subtext: "Track your earnings",
    subtext2: "and gain insights",
  },
];

export const commissions = [
  {
    icon: "camera.svg",
    text: "Curate products from any Wishlink partner brand",
  },
  {
    icon: "thumbsup.svg",
    text: "Link the products on your Wishlink Shop",
  },
  {
    icon: "forward.svg",
    text: "Share Wishlinks with your audience",
  },
  {
    icon: "money.svg",
    text: "Earn commissions on every purchase they make",
  },
];

export const ENABLE_ENGAGE = [
  {
    icon: "clock.svg",
    text: `Save hours replying to "link" comments`,
    color: "#9E71D0",
  },
  {
    icon: "boost.svg",
    text: "Boost engagement rates",
    color: "#FF2F32",
  },
  {
    icon: "delight.svg",
    text: "Delight your followers",
    color: "#EEB665",
  },
  {
    icon: "channel.svg",
    text: "New channel for earnings",
    color: "#5345FD",
  },
];

export const ENGAGE_WORKING = [
  "Your audience asks for the product links in comments e.g. pp, link please",
  "Engage will automatically send DMs with the exact product links",
  "Your followers can easily click and shop their favourite products",
  "You earn commissions on every purchase",
];

export const hamburgerItems = [
  {
    title: "Profile",
    description: "Build your Wishlink profile",
    redirectUrl: "/profile",
    gaLabel: GA_LABEL.PROFILE,
    showNewIcon: false,
  },
  {
    title: "Refer & Win",
    description: "Win upto ₹10,000 on each referral",
    redirectUrl: "/referral",
    showNewIcon: true,
    gaLabel: GA_LABEL.REFERRAL,
    showAfterFirstSale: true,
  },
  {
    title: "Wishlink Engage",
    description: "Boost your instagram engagement",
    redirectUrl: "/enable-wishlink-engage",
    gaLabel: GA_LABEL.ENGAGE_PROPS,
    showNewIcon: true,
  },
  {
    title: "Payout",
    description: "Manage your Wishlink earnings",
    redirectUrl: "/analytics#Payouts",
    gaLabel: GA_LABEL.ANALYTICS + "#Payouts",
    showNewIcon: false,
    showAfterFirstSale: true,
  },
  {
    title: "Help & Support",
    description: "Get your doubts resolved",
    redirectUrl: "/faq",
    gaLabel: GA_LABEL.HELP,
    showNewIcon: false,
  },
];

export const DEFAULTMILESTONES = [
  [
    "check_creator_social_login_complete",
    {
      id: 10,
      rank: 1,
      type: "PRIMARY",
      achieved: false,
      presented_on_dashboard: false,
    },
  ],
  [
    "check_first_post_milestone",
    {
      id: 6,
      rank: 2,
      type: "PRIMARY",
      achieved: false,
      presented_on_dashboard: false,
    },
  ],
  [
    "check_first_sale_milestone",
    {
      id: 4,
      rank: 3,
      type: "PRIMARY",
      achieved: false,
      presented_on_dashboard: false,
    },
  ],
  [
    "check_earnings_cross_2k_milestone",
    {
      id: 9,
      rank: 4,
      type: "PRIMARY",
      achieved: false,
      presented_on_dashboard: false,
    },
  ],
  [
    "check_first_collection_milestone",
    {
      id: 7,
      rank: 1,
      type: "SECONDARY",
      achieved: false,
      presented_on_dashboard: false,
    },
  ],
  [
    "check_creator_profile_milestone",
    {
      id: 8,
      rank: 2,
      type: "SECONDARY",
      achieved: false,
      presented_on_dashboard: false,
    },
  ],
  [
    "check_gmv_cross_milestone",
    {
      id: 3,
      rank: 1,
      type: null,
      achieved: false,
      presented_on_dashboard: false,
    },
  ],
  [
    "check_redirects_cross_milestone",
    {
      id: 1,
      rank: 3,
      type: null,
      achieved: false,
      presented_on_dashboard: false,
    },
  ],
];

export const HOME_LINKS = [
  {
    icon: `${IMAGE_PREFIX}profile-milestone.png`,
    text: `Social Login`,
    description: `Connect your Instagram or YouTube channel for a personalized experience`,
    redirect_url: `/social-login`,
    flag: "check_creator_social_login_complete",
    showPulse: true,
  },
  {
    icon: `${IMAGE_PREFIX}posts-icon.svg`,
    text: `Create your first Wishlink Post`,
    description: `Tag your product links with your content and start earning`,
    redirect_url: `/new-post#instagram`,
    flag: "check_first_post_milestone",
    showPulse: true,
  },
  {
    icon: `${IMAGE_PREFIX}myntra-logo-2-small.png`,
    text: `Earn from existing content`,
    description: `Share Wishlinks of top brands like H&M, Urbanic, Myntra, Meesho and more!`,
    redirect_url: `/shop`,
    flag: "check_first_sale_milestone",
    showPulse: false,
  },
  {
    icon: `${IMAGE_PREFIX}rupee-black.svg`,
    text: `Earn Rs. 2,000 via Wishlink`,
    description: `To unlock Rewards and Leaderboard`,
    redirect_url: `/analytics`,
    flag: "check_earnings_cross_2k_milestone",
    showProgressBar: true,
    status: null,
    showPulse: false,
  },
  {
    icon: `${IMAGE_PREFIX}shopping-black-icon.svg`,
    text: `Create a collection`,
    description: `Curate collections of your favourite products for your audience`,
    redirect_url: `/new-collection`,
    flag: "check_first_collection_milestone",
    showPulse: false,
  },
  {
    icon: `${IMAGE_PREFIX}profile-completion-todo.svg`,
    text: `Add your gender, email and bank details for monthly payouts`,
    description: `Please provide your bank details for smooth payouts`,
    redirect_url: `/profile`,
    flag: "check_creator_profile_milestone",
    showPulse: false,
  },
];

export const HOME_PAGE_BANNERS = [
  {
    banner_header_icon: `${IMAGE_PREFIX}wl-logo-white.svg`,
    banner_header_text: `Maximize earnings`,
    banner_image_url: `${IMAGE_PREFIX}earnings-max.png`,
    banner_redirect_url: PAGE_URLS.PLAYBOOK,
    banner_text: `Want to make more money from the products you recommend?`,
    banner_button_text: `Learn How`,
    open_in_same_page: true,
    ga_label: GA_LABEL.PLAYBOOK,
  },
  {
    banner_header_icon: `${IMAGE_PREFIX}instagram-icon-white.svg`,
    banner_header_text: `Wishlink Engage`,
    banner_image_url: `${IMAGE_PREFIX}engage.png`,
    banner_redirect_url: PAGE_URLS.ENABLEENGAGE,
    banner_text: `Automatically respond to comments asking for product links. Boost your Instagram Engagement & Earnings!`,
    banner_button_text: `Know more`,
    open_in_same_page: true,
    ga_label: GA_LABEL.ENGAGE_PROPS,
  },
  {
    banner_header_icon: `${IMAGE_PREFIX}shop-white.svg`,
    banner_header_text: `My Shop`,
    banner_image_url: `${IMAGE_PREFIX}shop.png`,
    banner_redirect_url: PAGE_URLS.WHY,
    banner_text: `Learn the benefits of having your own Wishlink Shop and how it works!`,
    banner_button_text: `Know more`,
    open_in_same_page: true,
    ga_label: GA_LABEL.SHOP_PROPS,
  },
];

export const POST_TYPES = {
  INSTA_POST: "insta-post",
  INSTA_REEL: "insta-reel",
  YT_VIDEO: "yt_video",
  YT_SHORTS: "yt_shorts",
};

export const TDS_PERCENTAGE_OLD = 10;
export const TDS_PERCENTAGE_NEW = 5;
export const NEW_TDS_START_DATE = new Date("2024-07-01");

export const FAQS = [
  {
    id: 1,
    gaLabel: "workingwithwishlink",
    title: "Working with Wishlink",
    icon: "faq/working-with-wishlink.png",
    text: "General guidelines, eligibility criteria",
    showOnSourcingHowToWork: false,
    children: [
      {
        question: "What is Wishlink, and how can I earn with Wishlink?",
        answer: `<div>Wishlink allows you to partner with your favourite brands and share their product links</div><br /><div>You earn a commission for every successful order through your shared wishlinks. Monetize all your content and make money while doing what you love!</div><br /><div>Learn more about commissions <a href="/faq/2">here</a></div>`,
      },
      {
        question: "Who can use Wishlink? Any eligibility criteria?",
        answer:
          "<div><div>Wishlink is open to all content creators promoting products they genuinely love</div><br /><div>Nevertheless, there are some requirements:</div><ul className='faq'><li>Instagram: Minimum 2,000 followers and Creator / Business Account</li><li>YouTube: At least 500 subscribers</li></ul></div>",
      },
      {
        question: "How can my audience find product links on my WIshlink?",
        answer:
          "<div>Instagram:</div><ul className='faq'><li>Share wishlinks in stories and highlights</li><li>Automatically respond to comments requesting product links via Wishlink Engage. Learn more <a href='/enable-wishlink-engage'>here</a></li><li>Add your Wishlink shop link in bio</li><li>Create a channel and share wishlinks</li></ul><br /><div>YouTube:</div><ul className='faq'><li>Add Wishlinks in Video Descriptions</li><li>Use Pinned comments for Shorts</li><li>Share Wishlinks with community posts</li></ul>",
      },
      {
        question: "What brands can I work with via Wishlink?",
        answer:
          "<div>On Wishlink, you have the freedom to recommend products from any brand.</div><br /><div>We have partnered with the most popular fashion and beauty brands to offer you a diverse selection to earn from</div><br /><div>Explore our partner brands <a href='/brands'>here</a></div>",
      },
      {
        question: "Does Wishlink charge a commission or fee for its services?",
        answer:
          "<div> No, Wishlink does not charge anything. The commissions are passed on to you directly from the brands. Our mission is to make content creation more sustainable for creators.</div>",
      },
    ],
  },
  {
    id: 2,
    gaLabel: "commissions",
    title: "Commissions",
    icon: "faq/creating-wishlinks.svg",
    text: "Learn how commissions work",
    showOnSourcingHowToWork: false,
    children: [
      {
        question: "How do I earn commissions via Wishlink?",
        answer:
          "<div>If a user clicks on your wishlink (from anywhere on Instagram, YouTube, WhatsApp, Telegram, etc.) and successfully orders anything from that website - you get a commission. Simple!</div>",
      },
      {
        question: "How much commission do I get on each brand?",
        answer:
          "<div>The commission percentages vary from brand to brand</div><br /><div>Explore our partner brands & commissions <a href='/brands'>here</a></div>",
      },
      {
        question: "Do I earn commissions on every purchase?",
        answer:
          "<div>You get commissions on 98% of your successful orders. The rare misses include:</div><ul className='faq'><li>Purchases made post 1 day of clicking your links. This is upto 14 days for some brands like H&M, Snitch, Urbanic, Libas, Aurelia etc</li><li>The user clicked on some ad from the same brand</li><li>The user has privacy settings or ad blockers enabled</li></ul>",
      },
      {
        question:
          "What are ordered, returned and net commissions and sales value?",
        answer:
          "<div>Ordered commissions are real-time commissions which get tracked at the time the user makes the purchase</div><br /><div>Returned commissions are deductions in ordered commissions when a user cancels or returns the order</div><br /><div>Net commissions are the total commissions you earn on your successful orders</div><br />Net Commissions = Ordered Commissions - Returned Commissions",
      },
      {
        question: "When are returned orders updated on your dashboard?",
        answer:
          "<div>Wishlink receives returns/cancellations data within 30-45 days of order date from our partner brands</div><br /><div>The data is updated immediately and visible on your dashboard on the date the data is received from the brands</div>",
      },
      {
        question: "Are sales updated in my dashboard real-time?",
        answer:
          "<div>The sales are instantly reflected for most of our partner brands. However, there are some exceptions:</div><br /><div>H&M, Urbanic - 3-6 hours</div><br /><div>Amazon - Once the product is shipped (24-72 hours usually)</div>",
      },
      {
        question: "Why are Amazon earnings displayed separately?",
        answer:
          "<div>We don't get earnings data on individual content for amazon products. You can contact us to get product level data for Amazon</div><br /><div>Moreover, you can view your Amazon commissions for any given time period in Detailed Analytics</div>",
      },
    ],
  },
  {
    id: 3,
    gaLabel: "partnerbrands",
    title: "Partner Brands",
    icon: "faq/partner-brands.png",
    text: "Discover brands, recommend products",
    showOnSourcingHowToWork: false,
    children: [
      {
        question:
          "What all brands has Wishlink partnered with? Are all brands open to all creators?",
        answer:
          "<div>We have partnered with the most popular fashion and beauty brands and you can share wishlinks of any of our partner brands to earn commissions</div><br /><div>Explore our partner brands <a href='/brands'>here</a></div>",
      },
      {
        question: "How much commission do I get on each brand?",
        answer:
          "<div>The commission percentages vary from brand to brand</div><br /><div>Explore our partner brands and commissions <a href='/brands'>here</a></div>",
      },
      {
        question:
          "Does Wishlink help me identify what brands will work for me?",
        answer:
          "<div>Absolutely yes. Wishlink gathers a lot of data through creator as well as brand insights and then our exclusive AI algorithm recommends you the brands most suited for your growth</div>",
      },
      {
        question:
          "Can I create links for brands that are not partnered with Wishlinks?",
        answer:
          "<div>Yes, your audience can click on your non-partner brand wislinks to visit their website. However, you won't earn any commissions</div><br /><div>If you want to earn commissions from those posts as well, feature similar products from partner brands</div>",
      },
      {
        question: "Can I know what brands are runnling sales in advance?",
        answer:
          '<div>Yes, go to "Brands by Category" page and apply the "On Sale" filter</div><br /><div>Wishlink also promotes popular sales on your brands page and also through WhatsApp for your convenience</div>',
      },
    ],
  },
  {
    id: 4,
    gaLabel: "myshop",
    title: "My Shop",
    icon: "faq/my-shop.svg",
    text: "Add a new post, create Wishlinks, etc",
    showOnSourcingHowToWork: false,
    children: [
      {
        question: "How do I set up my shop on Wishlink?",
        answer:
          "<div>To set up your shop, simply log in with your Instagram or YouTube account.</div><br /><div>Make sure to add some posts to your shop before sharing it with your audience</div>",
      },
      {
        question:
          "What is the difference between a post, collection and a single product link?",
        answer:
          "<div>A post is used to tag products with your social media content, allowing users to easily discover and purchase those products.</div><br /><div>A collection is a curated set of recommendations that you can share with your audience, even without creating specific content.</div><br /><div>A single product link is a quick way to share a specific product recommendation when you're short on time or don't have a dedicated post or collection</div>",
      },
      {
        question:
          "What is the difference between a Post Wishlink and a Product Wishlink?",
        answer:
          "<div>Post Wishlink: sends the user to your Wishlink Shop, where they can see all the products tagged and looks like: wishlink.com/username/post/123</div><br /><div>Product Wishlink: sends the user directly to the brand's website and looks like: wishlink.com/share/ABC</div><br /><div>By sharing more product wishlinks, you can drive more users to the brand's website, increasing your earning potential</div>",
      },
      {
        question: "How can I add, hide, or edit a post/collection on my shop?",
        answer:
          "<div>To add a post or collection, simply click on the create icon and select the desired option. Then, follow two easy steps:</div><ul className='faq'><li>Add post/collection details</li><li>Add product links to be tagged</li></ul><br /><div>For editing or hiding, click on the respective post or collection in your Shop, click on the three dots at the top right corner and choose the relevant option</div>",
      },
      {
        question:
          "How long will my wishlinks work for? Is there a limit to number of wishlinks I can create?",
        answer:
          "<div>Your Wishlinks will continue to work indefinitely unless the product goes out of stock or the brand removes our tracking without prior notice</div><br /><div>There is no limit to the number of Wishlinks you can create, allowing you to earn passively from your shared wishlinks</div>",
      },
      {
        question:
          "Can I create Wishlinks for products without linking them to any post?",
        answer:
          "<div>Yes, you can do this by:</div><ul className='faq'><li>Creating a single product Wishlink</li><li>Curating a collection of your favorite products</li></ul>",
      },
      {
        question: "Why does it show 0% commission on some brands?",
        answer:
          "<div>You earn commissions exclusively through our partner brands. Check them <a href='/brands'>here</a></div><br /><div>Want us to partner with your favorite brand? Submit <a href='#feedback'>here</a></div>",
      },
      {
        question: "Why do I see some products as faded in the preview page?",
        answer:
          "<div>Those products may not have been tagged correctly to your Wishlink. Please retry after checking the link.</div><br /><div>If you continue to face such issues, let us know!</div>",
      },
    ],
  },
  {
    id: 5,
    gaLabel: "analyticspayouts",
    title: "Analytics / Payouts",
    icon: "faq/analytics-earnings.svg",
    text: "Track your earnings and payouts",
    showOnSourcingHowToWork: false,
    children: [
      {
        question:
          "Insights: What are ordered commissions, amount, link clicks, orders placed and average order value?",
        answer:
          "<div>Ordered amount: Sum total amount of orders placed via your Wishlinks.</div><br /><div>Ordered Commissions: Commissions at the time orders are placed. Final commissions may differ once order status is confirmed by brands</div><br /><div>Link Clicks: Number of clicks on your Wishlinks to visit the brand's website</div><br /><div>Orders placed: Number of orders placed after clicking on your Wishlinks</div><br /><div>Average order value: Average amount of order placed after clicking your wishlinks. Each brand will have a different A.O.V.</div>",
      },
      {
        question: "What are Pending Commissions and Confirmed Commissions?",
        answer:
          "<div>Pending Commissions: Commissions on orders placed but not confirmed by the brand. These are pending because users can still cancel or return the product(s)</div><br /><div>Confirmed commissions: Commissions on orders confirmed by the brand, after deducting cancellations and returns</div>",
        showOnPayouts: true,
      },
      {
        question: "How is my payout calculated?",
        answer:
          "<div>Your payout in a month will include all the commissions which get confirmed by the brand in that month and any earnings via rewards and referral. <br/> <br/> The confirmed commissions are commissions on final order amount once cancellations and returns have been deducted</div>",
        showOnPayouts: true,
      },
      {
        question: "When will my pending orders be confirmed for payout?",
        answer:
          "<div>Each brand has a different confirmation time ranging from minimum one month to a few months.<br/> <br/>For example, if a brand has confirmation time of 45-60 days, its orders placed in current month will get confirmed between 15th to 30th of the next month. <br/> <br/>  You can see confirmation time of your brands by clicking on the Pending Commissions card in Payouts. The actual confirmation timeof a brand may vary</div>",
        showOnPayouts: true,
      },
      {
        question: "What is Wishlink's payouts policy?",
        answer: `<div>Payouts are processed before 10th of the following month into the bank account linked to your profile.</div><br /><div>A ${TDS_PERCENTAGE_NEW}% TDS is deducted on your total earnings and this will be reflected on your dashboard.</div><br /><div>If you want to claim GST, let us know <a href='https://forms.gle/1MjL3Ri3cKDKCjUX8?wishlink_external=true' target='__blank'>here</a></div>`,
      },
      {
        question: "What are potential returns and actual returns?",
        answer:
          "<div>Potential returns: Typical return percentage of the brand basis historical data</div><br/><div>Actual returns: Actual cancellations & return percentage on the orders placed via your Wishlinks</div>",
        showOnPayouts: true,
      },
      {
        question: "How can I maximize my earnings with Wishlink?",
        answer:
          "<div>Share your Wishlinks everywhere!</div><br /><div>Learn the best tips and triks to maximize your Wishlink earnings <a href='/playbook'>here</a></div>",
      },
      {
        question: "How can I report a payment-related issue?",
        answer:
          "<div>Please connect with your Wishlink manager or contact us on WhatsApp to report any payment related issue</div>",
      },
    ],
  },
  {
    id: 6,
    gaLabel: "sourcing",
    title: "Sourcing",
    icon: "faq/sourcing.png",
    showOnSourcingHowToWork: false,
    text: "Source products for your content",
    children: [
      {
        question: "What is Wishlink Sourcing?",
        answer:
          "<div>Wishlink Sourcing is a personalized platform where you can source products hassle-free for creating your content on a returnable basis.</div>",
      },
      {
        question: "What are Wishlink Sourcing guidelines?",
        answer:
          "<div>Ordering with Wishlink Sourcing is a breeze! Simply source products from our website, create your content and return the items within return deadlines. Don't forget to share links of sourced product in your content.</div><br/><div>Connect with your Wishlink Manager for more details.</div>",
      },
      {
        question: "How does Wishlink source products?",
        answer:
          "<div>With Wishlink you can place your order requests and then our team will communicate those orders with the respective brands. Usually takes 2-3 days to place orders with brands once we receive your requests.</div>",
      },
      {
        question: "How do sourcings credit work?",
        answer:
          "<div>Our exclusive AI algorithm sets a monthly sourcing limit for all creators.</div><br/><div>This limit is determined based on two main factors: your past Wishlink earnings and the percentage of sourced products that you incorporate into your content.</div> <br/><div>Reach out to your Wishlink manager for more details.</div>",
      },
      {
        question: "When am I eligible for sourcing?",
        answer:
          "<div>Sourcing is exclusive to our top performing creators. Our team will reach out to you once you become eligible.</div>",
      },
      {
        question: "Why am I seeing only limited brands?",
        answer:
          "<div>Wishlink sourcing is a personalised platform. Our AI recommends brands that your audience loves and buys from. See any brand you think your audience will love? Please fill the feedback form here.</div>",
      },
      {
        question: "Can I source products outside Wishlink platform?",
        answer:
          "<div>Wishlink can only source products for you available on your sourcing platform.</div>",
      },
      {
        question: "Why don't I see all the products from my favourite brand?",
        answer:
          "<div>The listed products are trending products (or) products with high stock with the brand. This is to ensure that the items featured in your content don't go out of stock when your content is published and also gets maximum sales</div>",
      },
      {
        question: "Why can't I source anymore? ",
        answer:
          "<div>Unfortunately, your sourcing access has been revoked.</div>",
      },
      {
        question: "When will I get sourcing back?",
        answer:
          "<div>Wishlink Sourcing access gets revoked if you don't post content from the sourced products or drive no/minimum sales from them. Or you haven't returned products in the past.<div><br/><div>To get your Sourcing access back; make sure you post all the content from Sourced products as well as try to sell and earn more</div>",
      },
      {
        question: "When will my order be delivered?",
        answer:
          "<div>You can check the estimated delivery date of your orders on your order page.</div> <br/><div>In case your items haven't been delivered 2-3 days after your estimated delivery date, please contact your Wishlink Manager.</div>",
      },
      {
        question: "Where can I track my orders?",
        answer:
          "<div>You can check the status of your orders on your order page. Unfortunately, currently you can't track your orders with Wishlink</div>",
      },
      {
        question: "Why was my order cancelled?",
        answer:
          "<div>Your order might be cancelled for one of these two reasons- either the product in your size is now out of stock or the brand removed the product from their catalogue.</div>",
      },
      {
        question: "What is the return policy of products?",
        answer:
          "<div>You have 15 days to create content and return the items sourced</div>",
      },
      {
        question: "Can I keep products on barter?",
        answer:
          "<div>Products sourced through Wishlink cannot be kept on barter. You can only keep products on barter which are eligible for barter. We appreciate your cooperation.</div>",
      },
    ],
  },
  {
    id: 7,
    gaLabel: "engage",
    title: "Wishlink Engage",
    icon: "faq/engage-faq.svg",
    showOnSourcingHowToWork: false,
    text: "Improve engagement rate and earnings",
    children: [
      {
        question: "What is Wishlink Engage?",
        answer:
          "<div>Engage, developed in collab with Meta, helps you improve your instagram engagement & earnings by automatically responding to comments asking for product links via DM.</div><br /><div>It allows you to build an amazing loyalty with your audience, because you are giving them what they want - LINKs!</div>",
      },
      {
        question:
          "How do I activate Wishlink Engage? (What are the pre requisites to enable engage?)",
        answer:
          "<div>Engage can be set up in 4 easy steps:</div><ul className='faq'><li>Make sure your Instagram account is connected to a Facebook page. <a href='https://help.instagram.com/570895513091465/'>Learn How</a></li><li>In your Instagram app, go to Privacy > Messages and allow access to messages</li><li>Click on Enable Wishlink Engage and provide us the required permissions</li><li>Set up multiple reply templates and keywords on which the DMs are sent</li></ul><br /><div>Don't forget to add #wishlinked to every post and add all your posts on your Shop with the recommended products</div>",
      },
      {
        question:
          "What will be sent in the DM? What will be the reply in comments?",
        answer:
          "<div>DMs - all products tagged to that post/reel in a carousel format</div><br /><div>Comments - randomly selected template from all the customised templates you set</div>",
      },
      {
        question: "What are the limitations of Wishlink Engage by Instagram?",
        answer:
          "<div>Engage works within Meta's limits: 40 comments and 750 DMs per hour. DMs are sent instantly, while comments are sent slowly and sequentially.</div><br /><div>If the limit is reached, Engage enters a 15-minute cooldown and queues incoming comments</div>",
      },
      {
        question:
          "Will Wishlink have access to all of my direct messages, and how will I know which comments have been replied to?",
        answer:
          "<div>No, Wishlink does not have access to any of your messages.</div><br /><div>Engage will only respond to comments that contain the trigger keywords set by you, like link please, details please, order, etc.</div>",
      },
      {
        question: "What if I don't have an active facebook account?",
        answer:
          "<div>it is mandatory to have a Facebook page linked to your Instagram account as per Meta's guidelines</div><br /><div>You can instantly create and link a facebook page in your Instagram under 'Edit Profile'</div>",
      },
      {
        question:
          "I don't want my followers to randomly call/message me? Can that be prevented?",
        answer:
          "<div>Yes, you can change your Instagram settings on who can DM/call you under Settings > Calls & Messages</div>",
      },
    ],
  },
  {
    id: 8,
    gaLabel: "profile",
    title: "Profile",
    icon: "faq/profile-faq.svg",
    showOnSourcingHowToWork: false,
    text: "Edit details, social login, bank account",
    children: [
      {
        question: "Can I edit my shop username?",
        answer:
          "<div>It is not possible to change your username once it has been confirmed</div>",
      },
      {
        question:
          "How do I change my Shop's profile pic, cover pic, display name and bio?",
        answer:
          "<div>How do I update my Shop's profile picture, cover picture and bio?</div><br /><div>Go to your profile and click on the respective edit icon to update these details</div>",
      },
      {
        question: "Can I use multiple social media accounts?",
        answer:
          "<div>You can link only one Instagram and one Youtube account to a single mobile number</div><br /><div>If you need to change your social media account, login via a different number or contact us on WhatsApp</div>",
      },
      {
        question: "How do I change phone number for login?",
        answer:
          "<div>Please contact us on WhatsApp to change the mobile number linked to your Shop</div>",
      },
      {
        question: "Can I change my bank account details?",
        answer:
          "<div>Please contact us on WhatsApp to change your bank account details for payouts</div>",
      },
      {
        question: "What is the relevance of updating additional info details?",
        answer:
          "<div>Wishlink would love to know more details about you so that we can provide you all the relevant updates and ensure timely deliveries</div><br /><div>Your additional info is private to you and is not shared anywhere outside of Wishlink</div>",
      },
    ],
  },
  {
    id: 9,
    gaLabel: "sourcingaccess",
    title: "Sourcing access",
    text: "Track orders, see delivery and return timelines",
    showOnSourcingHowToWork: true,
    thumbnail: "sourcingaccess.png",
    children: [
      {
        question: "What is Wishlink Sourcing?",
        answer:
          "Wishlink Sourcing is a personalized platform where you can source products hassle-free for creating your content on a returnable basis",
      },
      {
        question: "When am I eligible for sourcing?",
        answer:
          "Sourcing is exclusive to our top performing creators. Our team will reach out to you once you become eligible",
      },
      {
        question:
          "How does Wishlink source products? Specifically around placed order status",
        answer:
          "With Wishlink you can place your order requests and our team will place those orders for you. You don't even have to worry about returns since our team will take care of the whole order management process.",
      },
      {
        question: "Why am I seeing only limited brands?",
        answer:
          "Even though a wide variety of brands are available on sourcing, our exclusive AI algorithm recommends you brands most suitable to you basis your audience, gender and past Wishlink performance. See a brand you think your audinece will love? Please add your suggestion here.",
      },
      {
        question: "Why don't I see all the products from my favourite brand?",
        answer:
          "We prioritize listing treding products or products with high inventory to ensure that the items featured in your content don't go out of stock when your content is published",
      },
      {
        question:
          "Why can't I source anymore? Where are the products I shortlisted?",
        answer:
          "Unfortunately, your sourcing access has been revoked. You can find your shortlisted products in the sourcing portal for next 15 days. ",
      },
      {
        question: "When will I get sourcing back?",
        answer:
          "Since Wishlink Sourcing access is based upon your past Wishlink performance and adherence of Wishlink sourcing guidelines, you can improve your performance by selling more and earning more. ",
      },
    ],
  },
  {
    id: 10,
    gaLabel: "sourcingMyOrders",
    title: "My orders",
    text: "Check eligibility for sourcing products",
    showOnSourcingHowToWork: true,
    thumbnail: "Rectangle+39642.png",
    children: [
      {
        question: "When will my order be delivered?",
        answer:
          "You can check the estimated delivery date of your orders on your order page. In case your items haven't been delivered 2-3 days after your estimated delivery date, please contact your Wishlink Manager. ",
      },
      {
        question: "Where can I track my orders?",
        answer:
          "You can check the status of your orders on your order page. Unfortunately, currently you can't track your orders with Wishlink",
      },
      {
        question: "When do I have to return the product?",
        answer:
          "You have 15 days to create content and return the items sourced",
      },
      {
        question: "Why was my order cancelled?",
        answer:
          "Your order might be cancelled for one of these two reasons- either the product in your size is now out of stock or the brand removed the product from their catalogue.",
      },
    ],
  },
  {
    id: 11,
    gaLabel: "sourcingCredits",
    title: "Credits",
    text: "Learn more about credits",
    showOnSourcingHowToWork: true,
    thumbnail: "credits.png",
    children: [
      {
        question: "What are credits?",
        answer:
          "Sourcing credits are platform currency through which you can source products. ",
      },
      {
        question: "What are barter credits?",
        answer:
          "Barter credits are exclusively available for some creators. Through barter credits you can source items from barter section and keep the items with you after you have created the content.",
      },
      {
        question: "What is brand limit?",
        answer:
          "You can only source using the allocated amount of credits on certain brands. Check your brand limits in the bottom of your screen while sourcing. ",
      },
      {
        question: "When am I assigned new credits?",
        answer:
          "Our exclusive AI assigns credits to sourcing eligible creators on every 25th of the month.",
      },
      {
        question: "Where can I see my sourcing credits?",
        answer:
          "You can see your allocated sourcing credits on the top right corner of your sourcing home screen. ",
      },
      {
        question:
          "Why are my credits so less? Who decides my sourcing limit? How can I increase my credits?",
        answer:
          "Our exclusive AI algorithm sets a monthly sourcing limit for all creators. This limit is determined based on two main factors: your past Wishlink earnings and the percentage of sourced products that you incorporate into your content",
      },
      {
        question: "My credits are exhausted, how can I still source products?",
        answer:
          "You can't source products outside your allocated sourcing credits. Please wait for 25th to source products again.",
      },
    ],
  },
  {
    id: 12,
    gaLabel: "SourcingGuidelines",
    title: "Sourcing guidelines",
    text: "Guidelines for sourcing products",
    showOnSourcingHowToWork: true,
    thumbnail: "guideline.png",
    children: [
      {
        question: "What are Wishlink Sourcing guidelines?",
        answer:
          "Ordering with Wishlink Sourcing is a breeze! Simply source products from our website, create your content and return the items within return deadlines. Don't forget to share links of sourced product in your content. Connect with your Wishlink Manager for more details ",
      },
      {
        question: "What are barter credits?",
        answer:
          "Barter credits are exclusively available for some creators. Through barter credits you can source items from barter section and keep the items with you after you have created the content.",
      },
      {
        question: "What is brand limit?",
        answer:
          "You can only source using the allocated amount of credits on certain brands. Check your brand limits in the bottom of your screen while sourcing. ",
      },
      {
        question: "When am I assigned new credits?",
        answer:
          "Our exclusive AI assigns credits to sourcing eligible creators on every 25th of the month.",
      },
      {
        question: "Where can I see my sourcing credits?",
        answer:
          "You can see your allocated sourcing credits on the top right corner of your sourcing home screen. ",
      },
      {
        question:
          "Why are my credits so less? Who decides my sourcing limit? How can I increase my credits?",
        answer:
          "Our exclusive AI algorithm sets a monthly sourcing limit for all creators. This limit is determined based on two main factors: your past Wishlink earnings and the percentage of sourced products that you incorporate into your content",
      },
      {
        question: "My credits are exhausted, how can I still source products?",
        answer:
          "You can't source products outside your allocated sourcing credits. Please wait for 25th to source products again.",
      },
    ],
  },
];

export const FAQ_EDUCATION_PAGE = {
  gaLabel: "analyticspayouts",
  faqArray: [
    {
      question: "Will I receive the April '24 payout in May '24?",
      answer:
        "Commissions for orders placed in April won't be paid out in May '24. They will be disbursed once the brand confirms the status of the orders, which typically takes a minimum of one month. Therefore, your April commissions will begin to be confirmed in May and will be paid out in June.<br/><br/>The confirmation period varies from brand to brand. Starting from next month, you will receive monthly payouts.<br/> <br/>Watch the YouTube video above to learn more about the new payout process.",
    },
    {
      question: "When will my pending orders be confirmed for payout",
      answer:
        "Each brand has a different confirmation time ranging from minimum one month to a few months.<br/> <br/>For example, if a brand has confirmation time of 45-60 days, its orders placed in the current month will get confirmed between 15th to 30th of the next month.<br/> <br/>You can see confirmation time of your brands by clicking on the Pending Commissions card in Payouts. The actual confirmation time of a brand may vary.",
    },
    {
      question: "What are pending commissions and confirmed commissions?",
      answer:
        "Pending Commissions: Commissions on orders placed but not confirmed by the brand. These are pending because users can still cancel or return the product(s).<br/><br/>Confirmed commissions: Commissions on orders confirmed by the brand, after deducting cancellations and returns.",
    },
    {
      question: "How is my payout calculated?",
      answer:
        "Your payout in a month will include all the commissions which get confirmed by the brand in that month and any earnings via rewards and referral.<br/><br/>The confirmed commissions are commissions on the final order amount once cancellations and returns have been deducted.",
    },
    {
      question: "What are potential returns and actual returns?",
      answer:
        "Potential returns: Typical return percentage of the brand basis historical data.<br/><br/>Actual returns: Actual cancellations & return percentage on the orders placed via your Wishlinks.",
    },
    {
      question: "What is Wishlink’s payout policy?",
      answer: `Payouts are processed before 10th of the following month into the bank account linked to your profile.<br/><br/>Note: ${TDS_PERCENTAGE_NEW}% TDS will be deducted on your total earnings and this will be reflected on your dashboard.`,
    },
    {
      question: "How can I maximize my earnings with Wishlink?",
      answer:
        "Share your Wishlinks everywhere!<br><br/>Learn the best tips and tricks to maximize your Wishlink earnings <a href='https://creator.wishlink.com/playbook'>here</a><br/><br/>If you want to claim GST, let us know <a href='https://forms.gle/1MjL3Ri3cKDKCjUX8'>here</a>.",
    },
    {
      question: "What payment methods are available for receiving payouts?",
      answer:
        "We process payouts monthly directly to your designated bank account. To ensure seamless transactions, kindly ensure your bank account details are updated in your profile settings.",
    },
    {
      question: "Is there a minimum threshold for payout?",
      answer:
        "There is no minimum threshold for payouts.<br/><br/>Simply start creating content, add product wishlinks, and begin earning commissions. It’s that easy!",
    },
    {
      question: "Can I track my earnings and payout status?",
      answer:
        " Absolutely! You can easily monitor your earnings and payout status through your Wishlink creator dashboard.",
    },
  ],
};

export const RESPONSIVE = [
  {
    breakpoint: 430,
    settings: {
      centerPadding: "90px",
    },
  },
  {
    breakpoint: 400,
    settings: {
      centerPadding: "80px",
    },
  },
  {
    breakpoint: 370,
    settings: {
      centerPadding: "70px",
    },
  },
];

export const GET_STARTED_OBJECT = [
  "login-monetize.png",
  "login-brandsv2.png",
  "login-sourcing.png",
];

export const REC_BRAND_LOGOS = [
  "h&m.svg",
  "urbanic1.png",
  "meesho_new_small.png",
  "myntra-logo-2-small.png",
];

export const FILTERS_SHOW_STATES = {
  SHOW_MORE: "Show More",
  SHOW_LESS: "Show Less",
};

export const POST_CATEGORIES = {
  MG: "MIN. GUARANTEE",
  HIDDEN: "HIDDEN",
  BRAND_COLLAB: "BRAND COLLAB",
  CONTRACT: "CONTRACT",
  AMAZON: "AMAZON",
  NON_PARTNERED_BRAND: "NON-PARTNER BRAND",
};

export const POST_CATEGORIES_IMG_SRC = {
  HIDDEN: "hidden-tag.svg",
  AMAZON: "amazon-tag.svg",
  NON_PARTNER_BRAND: "non-partner-tag.svg",
};

export const EARNING_TYPES = {
  ORGANIC: "ORGANIC",
  MG: "MG",
  CONTRACT: "CONTRACT_MG",
  BRAND_COLLAB: "FIXED",
};

export const REWARDS_EXPLAINER_CARDS = {
  YOUR_TERMS: {
    imageUrl: "reward-helper-lock-main.svg",
    text: "See milestone for reward in each week's card",
  },
  LINK_PRODUCTS: {
    imageUrl: "reward-rupee.svg",
    text: "Click on weekly milestone to know your rewards",
  },
  EXCLUDE_MEESHO: {
    imageUrl: "reward-gift.svg",
    text: "Meesho earnings will not be included in rewards",
  },
};

export const REWARDS_EXPLAINER_CARDS_SALES = {
  ANY_BRAND: {
    imageUrl: "reward-gift.svg",
    text: "We have exciting WEEKLY and MONTHLY rewards for you!",
  },
  YOUR_TERMS: {
    imageUrl: "reward-helper-lock-main.svg",
    text: "You will know your 2 weekly MILESTONES in each week's tab",
  },
  LINK_PRODUCTS: {
    imageUrl: "reward-rupee.svg",
    text: "Click on each milestones to know corresponding reward AMOUNT!",
  },
};

export const TELEGRAM_REWARDS_EXPLAINER_CARDS_SALES = {
  ANY_BRAND: {
    imageUrl: "reward-gift.svg",
    text: "See the reward amount with your earning target!",
  },
  LINK_PRODUCTS: {
    imageUrl: "reward-rupee.svg",
    text: "All of your earnings would be included!",
  },
};

export const REWARDS_CAMPAIGN_STATUSES = {
  PAST: "past",
  ACTIVE: "active",
  UPCOMING: "upcoming",
};

export const REWARDS_CAMPAIGN_COMPLETION_STATUS = {
  ALL_LEVELS_COMPLETED: "all-levels",
  TWO_LEVELS_COMPLETED: "two-levels",
  ONE_LEVELS_COMPLETED: "one-levels",
  NO_LEVELS_COMPLETED: "no-levels",
};

export const CAMPAIGN_REWARDS_STATES = [
  "no-levels",
  "one-levels",
  "two-levels",
  "all-levels",
];

export const SLAB_BACKGROUNDS = ["#D6FFDC", "#E4D6FF", "#FFE1C5"];

export const CAMPAIGN_PROGRESS_BAR_STATES = [
  "campaign-state-1.png",
  "campaign-state-2.png",
  "campaign-state-3.png",
];

export const rewardParentStatuses = {
  PAST: "past",
  CURRENT: "current",
  UPCOMING: "upcoming",
};

export const REWARDS_ACHIEVING_METRICS = {
  SALES: "sales",
  EARNINGS: "earnings",
  EARNINGS_WITH_PRODUCTS: "earnings_with_products",
  EARNINGS_WITH_PRODUCTS_ECOMM_NON_MEESHO:
    "earnings_with_product_ecomm_non_meesho",
  ALL_EARNINGS: "all_earnings",
};

export const REWARDS_DETAILS_TYPES = {
  LEADERBOARD: "leaderboard",
  WEEKLY_DETAILS: "weekly-details",
};

export const PERFORMANCE_METRIC_TYPES = {
  REWARDS: "rewards",
  PAYOUT: "payout",
};

export const PRO_TIP_TYPES = {
  BRANDS: "brands",
  DIY: "diy",
  LEADERBOARD: "leaderboard",
  REWARDS: "rewards",
};

export const CONTACT_WISHLINK_NUMBER = "919319007907";

export const USERNAME_REGEX = /^[a-zA-Z0-9.]*$/;

export const MEESHO_PILOT_3_BRAND_ID = 251;

export const AJIO_BRAND_ID = 301;

export const FLIPKART_BRAND_ID = 303;

export const AMAZON_BRAND_ID = 7;

export const FLIPKART_COMMISSION = 9;

export const PURE_ORIGIN_BRAND_ID = 305;

export const SHOPSY_BRAND_ID = 315;

export const SHOPSY_COMMISSION = 11;

export const AMAZON_COMMISSION_AIP_PERCENT = 7;

export const AMAZON_COMMISSION_FIP_PERCENT = 10;

export const EMAIL_REGEX =
  /^[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+(?:.[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-zA-Z0-9](?:[a-zA-z0-9-]*[a-zA-Z0-9])?.)+[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?$/;

export const ADDRESS_REGEX = /[`!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?~]/;

export const AMAZON_TAG_REGEX = /.*-\d{2}$/;

export const NON_PRODUCT_LINK_IDENTIFIERS = [
  "instagram.com/",
  "youtube.com/",
  "youtu.be/",
  "telegram.me/",
  "linktr.ee/",
];

export const IMAGE_FILE_EXTENSIONS = [".jpg", ".jpeg", ".png", ".webp"];

export const LINK_REGEX =
  /^(https?:\/\/)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,16}\b([-a-zA-Z0-9()@!:%_\+.~#?&\/=]*)/i;

export const PRODUCT_SECTION_TYPES = {
  TAGGED: "TAGGED",
  FAILED: "FAILED",
};

export const CITY_REGEX = /[`!@#$%^&*_+\-=[\]{};':"\\|,.<>/?~]/;

export const ADDITIONAL_ELEMENT = {
  CTA_BUTTON: "CTA_BUTTON",
  BANNER: "BANNER",
};

export const LINK_TYPE = {
  LINK_EXTERNAL: "LINK_EXTERNAL",
  LINK_INTERNAL: "LINK_INTERNAL",
  COPY_TEXT: "COPY_TEXT",
};

export const NOTIFICATION_TYPE = {
  NEW: "new",
  EARLIER: "earlier",
};

export const ADD_CREATOR_TO_GROUP = "add_creator_to_group";

export const NOTIF_TYPE = {
  NOTIFICATION_LEDGER: "NOTIFICATION_LEDGER",
  BELL_ICON: "BELL_ICON",
};

export const NOTIFICATION_GIFS = [
  `${IMAGE_PREFIX}321.gif`,
  `${IMAGE_PREFIX}Heart.gif`,
  `${IMAGE_PREFIX}Quote.gif`,
];

export const STOREFRONT_HOST_NAME = "https://wishlink.com/refer/";
export const tncSheet = `https://www.wishlink.com/w/terms-of-service`;

export const TIRA_BEAUTY_BANNER = `https://www.wishlink.com/share/45rgs`;

export const invitedCreatorCount =
  20 +
  5 *
    Math.floor((new Date().getTime() - 1695197731582) / (1000 * 60 * 60 * 24));

export const REFERRAL_NUDGES = [
  {
    icon_url: `${IMAGE_PREFIX}ref-topbrandsv2.png`,
    title:
      "Help your besties work with top brands like Myntra, Meesho, H&M & more",
  },
  {
    icon_url: `${IMAGE_PREFIX}timeflies.png`,
    title:
      "Simplify their life by automating replies to comments asking for links",
  },
  {
    icon_url: `${IMAGE_PREFIX}ref-earningsboost.png`,
    title: "Boost their earnings and celebrate together!",
  },
  {
    icon_url: `${IMAGE_PREFIX}ref-high-five.png`,
    title: `${invitedCreatorCount}+ creators have already invited their besties to Wishlink`,
  },
];

export const URL_REGEX =
  // eslint-disable-next-line no-useless-escape
  /[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,16}\b([-a-zA-Z0-9()@!:%_\+.~#?&\/=]*)/g;

export const WISHLINK_CREATION_NUDGE = [
  "Pssst! Wanna know the secret sauce? More Product Wishlinks shared = more cha-ching! 💰",
  "Here's the scoop: Keep the Product Wishlinks flowing, and your bank balance growing! 💸",
  "Time to level up your earnings: Share those Product  Wishlinks like a pro! 💼💰",
  "In the world of wishlinks, sharing is caring, and earning! 🌟💸",
  "Show those Product Wishlinks some love, and they'll show your wallet some love back! 💖💰",
  "Ready to turn Wishlinks into a WishFUL wallet? Share away! 🚀💰",
  "Your Wishlink army is ready to conquer the earnings realm! Share away, General! ⚔️💰",
  "Wishlink warriors assemble! The more you share, the richer you get! 💎💸",
  "Eureka! Your genius formula: More Product Wishlinks = More moolah! 🧪💸",
  "Wishlink sorcery: The more you cast them, the more gold you amass! ✨💰",
];

export const brandWiseChartColors = [
  "#E38125",
  "#D37FA2",
  "#7FD397",
  "#8262BF",
  "#E91E63",
  "#D4D4D4",
];

export const ChannelWiseChartColors = [
  "#4e90ff",
  "#E38125",
  "#D37FA2",
  "#7FD397",
  "#8262BF",
  "#E91E63",
  "#D4D4D4",
];

export const FRONTEND_ASSETS_BASE_URL =
  "https://d3g01po1nkka75.cloudfront.net/allthingsgood-frontend-assets/";
export const OPEN_MEESHO_DRAWER = "openMeeshoDrawer";

export const RETURN_YT_VIDEO_LINK =
  "https://youtu.be/f1GxPCY19l0?feature=shared";

export const HOW_DOES_ITS_WORK = [
  "Source from highly personalised and commissonable brands",
  "Use credits to source products",
  "Create Content and Share Your Wishlinks",
  "Return your sourced products in 14 days",
];

export const PLAY_STORE_URL =
  "https://play.google.com/store/apps/details?id=com.wishlink.creator";
export const APP_STORE_URL =
  "https://apps.apple.com/in/app/wishlink-creator/id6458532575";

export const ENGAGE_UPDATE_TYPES = {
  COMMENT: "comment",
  DM: "dm",
  STK: "stk",
};

export const UTM_APP_TYPE_ANDROID = "ANDROID";

export const LEARN_HOW_DOCUMENT = "https://help.instagram.com/2358103564437429";

export const SCREEN_HALVES = {
  TOP: "top",
  BOTTOM: "bottom",
};

//These names need to be same as the names in the backend
export const OVERLAY_NAMES = {
  SHOP_INTRO: "SHOP_OVERLAY_1",
  SHOP_CONTENT_ITEM_INTRO: "SHOP_OVERLAY_2",
  SHOP_CONTENT_ITEM_INTRO_2: "SHOP_OVERLAY_3",
  SHOP_OVERLAY_NON_PARTNERED: "SHOP_OVERLAY_NON_PARTNERED",
  SHOP_OVERLAY_AMAZON: "SHOP_OVERLAY_AMAZON",
  DIY_OVERLAY_BASIC: "DIY_OVERLAY_BASIC",
  DIY_OVERLAY_COUPON: "DIY_OVERLAY_COUPON",
  PAYOUTS_HOME_COMMS: "PAYOUTS_HOME_COMMS",
  PAYOUTS_HOME_PAST_PAYOUTS: "PAYOUTS_HOME_PAST_PAYOUTS",
  PAYOUTS_PENDING_COMMS: "PAYOUTS_PENDING_COMMS",
  PAYOUTS_PENDING_BRANDS: "PAYOUTS_PENDING_BRANDS",
  PAYOUTS_CONFIRMED_COMMS: "PAYOUTS_CONFIRMED_COMMS",
  PAYOUTS_CONFIRMED_BRANDS: "PAYOUTS_CONFIRMED_BRANDS",
  FRESHDESK_CHAT_COMMS: "FRESHDESK_CHAT_COMMS",
  ENGAGE_OVERLAY: "ENGAGE_OVERLAY",
};

export const DRAWER_ALLOWED_OVERLAYS = [
  OVERLAY_NAMES.SHOP_OVERLAY_AMAZON,
  OVERLAY_NAMES.SHOP_OVERLAY_NON_PARTNERED,
];

export const OVERLAY_ALLOWED_HASHES = ["#Payouts", "#Insights"];

export const SUPPORT_DRAWER_ONBOARDING_HASH = "#sourcing-support";
export const SUPPORT_DRAWER_HASH = "#sourcing-support-drawer";

export const SOURCING_SUPPORT_NUMBER = "9319007902";

export const SOURCING_SUPPORT_POINTS = [
  "OTP for receiving/returning any product",
  "Rescheduling any delivery or Return",
  "Help related to which Items to be returned",
  "Report wrong order status",
];
export const FLIPKART_COMMISSION_URL = "https://www.wishlink.com/share/67n29";

export const SHOPSY_COMMISSION_URL =
  "https://docs.google.com/document/d/1pdiv0GXv6zC8Pu_5UvU-a_3c8gBWA6skNwaUP9bWEA4/edit";

export const APPEAL_FORM_URL =
  "https://docs.google.com/forms/d/19k3jwihKupc_cMD1ReWtaJFZpdfFki7z8eUsEQ-L28U/edit#responses";

export const TESTIMONIAL_SUBTITLES = [
  "Affordable ethnic to look amazing everyday",
  "Beauty and lifestyle creator helping you with a bit of everything",
  "Fashion deals too good to refuse",
  "Men's fashion, grooming and lifestyle expert",
  "Desi fashion enthusiast showcasing their favorite outfitsg",
  "Discover fresh drips and best fits from our top emerging creator",
];

export const SOCIAL_LOGIN_DATE_CONSTANT = "2024-03-18T18:30:00.0000Z";
export const PAYOUT_STATUS = {
  PENDING: "pending",
  CONFIRMED: "confirmed",
  PAID: "paid",
  PROCESSING: "processing",
  SETTLED: "settled",
  REJECTED: "rejected",
  TO_BE_PAID: "to_be_paid",
};

export const PAYOUT_STATUS_CAPTALISE = {
  PENDING: "Pending",
  CONFIRMED: "Confirmed",
  PAID: "Paid",
  PROCESSING: "Processing",
  SETTLED: "Settled",
  REJECTED: "Rejected",
  TO_BE_PAID: "To be Paid",
};

export const YT_SHOPPING_PILLS = [
  {
    id: 1,
    name: "Wishlinks",
    value: "wishlinks",
    collapsible: false,
    clickable: true,
  },
  {
    id: 2,
    name: "YT Shopping",
    value: "youtube",
    collapsible: false,
    clickable: true,
  },
];
export const NYKAA_WISHLINK_URL = "https://www.wishlink.com/share/b8dg8";

export const MYNTRA_BRAND_ID = 227;

export const FAQ_ICON_URL = "help-support.svg";

export const ANDRIOD_URL =
  "https://play.google.com/store/apps/details?id=com.wishlink.creator";

export const IOS_URL =
  "https://apps.apple.com/in/app/wishlink-creator/id6458532575";

export const PROGRESSION = {
  ADJUSTMENT: "adjustment",
  RETURN: "return",
};
export const BRAND_IDS_TO_SHOW_CATEGORY_COMMISSION_SPLIT_FOR = [227];

export const CREATOR_EXPERIMENT_TYPE = {
  CONTROL: "control",
  TEST: "test",
};

export const REWARDS_CAMPAIGN = {
  AJIO: "ajio_reward",
  MYNTRA: "myntra_reward",
};

export const FORCE_UPDATE_BUILD_NUMBER = 9;

export const firebaseAuthPhoneNumbers = [
  "9812569663",
  "9971751599",
  "9569394880",
  "6377092692",
];

export const WHY_ENGAGE_STK = [
  {
    id: 1,
    icon: "save-hour-star.png",
    title: "Save hours by sending automatic links on comments and shares.",
  },
  {
    id: 2,
    icon: "boost-reach-star.png",
    title: "Boost your reach by getting more engagement on your content.",
  },
  {
    id: 3,
    icon: "delight-followers-star.png",
    title:
      "Delight your followers by sending products they like on your content.",
  },
  {
    id: 4,
    icon: "channel-earnings-star.png",
    title: "New channel to boost your earnings.",
  },
];

// milliseconds
export const FRESHDESK_TIMEOUT = 120000;

export const FRESHDESK_RETRY_INTERVAL = 300;

export const FRESHDESK_RETRIES = FRESHDESK_TIMEOUT / FRESHDESK_RETRY_INTERVAL;

export const ENGAGE_CAROUSEL = [
  "engage-carousel-1.png",
  "engage-carousel-2.png",
  "engage-carousel-3.png",
  "engage-carousel-4.png",
];

export const FLIPKART_BANNER_REDIRECT_URL =
  "https://www.wishlink.com/share/crsn8";

export const MEESHO_BANNER_REDIRECT_URL =
  "https://metabase.wishlink.com/public/dashboard/865ef918-c53f-4cfc-bb1a-b346a7730287?wishlink_username=";

export const AMAZON_AIP_COMMISSION_DOC = `https://www.wishlink.com/share/ygc24`;
export const AMAZON_FIP_COMMISSION_DOC = `https://www.wishlink.com/share/y5wg8`;
