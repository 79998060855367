import { useDispatch } from "react-redux";
import { useEffect } from "react";
import {
  updateCartTriggers,
  updatePlaceOrderRequestLoading,
  updatePlaceOrderRequestSuccessfulId,
  updateSourcingCartProducts,
  updateSourcingCartProductsLoading,
} from "./cartRedux";
import {
  hideToastNotification,
  showToastNotification,
} from "../../../redux/redux";
import {
  useLazyGetCartProductsQuery,
  usePostCartActionMutation,
  usePostPlaceOrderRequestMutation,
} from "./cartReduxAPI";
import { useNavigate } from "react-router-dom";
import { PAGE_URLS } from "../../../utils/constants";

const CartDataLayer = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [triggerAddSourcingCartAction, resultAddSourcingCartAction] =
    usePostCartActionMutation();
  const [triggerGetCartProducts, resultGetCartProducts] =
    useLazyGetCartProductsQuery();
  const [triggerPlaceOrderRequest, resultPlaceOrderRequest] =
    usePostPlaceOrderRequestMutation();

  const trigger = {
    getSourcingCartProducts: async (apiCallParams) =>
      await triggerGetCartProducts(apiCallParams),
    postSourcingCartAction: async (apiCallParams) =>
      await triggerAddSourcingCartAction(apiCallParams),
    postSourcingPlaceOrderRequest: async (apiCallParams) =>
      await triggerPlaceOrderRequest(apiCallParams),
  };

  function showToast({ variant, message }) {
    dispatch(showToastNotification({ variant, message }));
    setTimeout(() => {
      dispatch(hideToastNotification());
    }, 3000);
  }

  useEffect(() => {
    dispatch(updateCartTriggers(trigger));
  }, [trigger]);

  useEffect(() => {
    const data = resultAddSourcingCartAction.data;
    if (data?.success === false) {
      showToast({
        variant: "error",
        message: data?.msg,
      });
      return;
    }
    if (data?.success === true) {
      const message = data?.message || "Success";
      let variant = data?.variant || "success";
      if (variant === "success") {
        variant = "add-to-cart";
      }

      showToast({
        variant: variant,
        message: message,
      });
    }
  }, [resultAddSourcingCartAction]);

  useEffect(() => {
    const data = resultGetCartProducts.data;
    if (data) {
      if (data?.success) {
        dispatch(updateSourcingCartProducts(data.products));
      }
      dispatch(updateSourcingCartProductsLoading(false));
    }
  }, [resultGetCartProducts]);

  useEffect(() => {
    const data = resultPlaceOrderRequest.data;
    if (data) {
      if (data.success) {
        dispatch(updatePlaceOrderRequestSuccessfulId(data?.orderId || ""));
        setTimeout(() => {
          navigate(`${PAGE_URLS.SOURCING_MY_ORDERS}#feedback`, {
            replace: true,
            state: {
              takeBackToWhereStarted: true,
            },
          });
          dispatch(updatePlaceOrderRequestSuccessfulId(null));
        }, 3000);
      } else {
        showToast({
          variant: "error",
          message: data?.message,
        });
      }
      dispatch(updatePlaceOrderRequestLoading(false));
    }
  }, [resultPlaceOrderRequest]);

  return <></>;
};

export default CartDataLayer;
