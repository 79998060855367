import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { getDataPointLoadingValue } from "../../utils/common";

export const useDataUpdate = (triggerResult, dispatchAction) => {
  const dispatch = useDispatch();
  useEffect(() => {
    const data = triggerResult.data;
    if (data?.success) {
      dispatch(dispatchAction(data.data));
    }
  }, [triggerResult, dispatchAction]);
};

export const useDataUpdateWithLoading = (
  triggerResult,
  dispatchAction,
  setLoading
) => {
  const dispatch = useDispatch();
  useEffect(() => {
    const data = triggerResult.data;
    if (data?.success) {
      dispatch(dispatchAction(data.data));
    }
    dispatch(setLoading(getDataPointLoadingValue(triggerResult)));
  }, [triggerResult, dispatchAction, setLoading]);
};

export default useDataUpdate;
