import WishlinkTypography from "../../WishlinkComponents/WishlinkTypography";
import { IMAGE_PREFIX, WHY_ENGAGE_STK } from "../../utils/constants";

const WhyConnectEngageSTK = () => {
  return (
    <div className="p-4">
      <WishlinkTypography
        fontSize="0.875rem"
        fontWeight={600}
        color="#000"
        lineHeight="0.1875rem"
        className="text-left"
      >
        Why you should
      </WishlinkTypography>
      <WishlinkTypography
        fontSize="1.25rem"
        fontWeight={600}
        color="#000"
        className="text-left mt-1"
      >
        Engage
      </WishlinkTypography>
      {WHY_ENGAGE_STK.map((item, index) => (
        <div
          key={index}
          style={{ background: "#C5D1E3", border: "0.5px solid #000" }}
          className="w-100 border-radius p-4 mt-2 flex align-center"
        >
          <div
            style={{ width: "4rem", height: "3rem", background: "#fff" }}
            className="border-radius"
          >
            <img
              src={`${IMAGE_PREFIX}${item.icon}`}
              style={{ width: "4rem", height: "3rem" }}
              alt=""
            />
          </div>
          <WishlinkTypography
            fontSize="0.75rem"
            fontWeight={400}
            color="#000"
            lineHeight="0.9375rem"
            className="text-left ml-4"
          >
            {item.title}
          </WishlinkTypography>
        </div>
      ))}
    </div>
  );
};

export default WhyConnectEngageSTK;
