import { lazy } from "react";
import { PAGE_URLS } from "./utils/constants";
import CreatorEarnings from "./views/CreatorEarnings";
import Playbook from "./views/Playbook";
import PlaybookContent from "./views/PlaybookContent";
import PlaybookMonetize from "./views/PlaybookMonetize";
import ReferralInput from "./views/ReferralInput";
import WishlinkCreatorOauth from "./views/WishlinkCreatorOauth";
import PayoutExplainerVideo from "./views/PayoutExplainerVideo/index.jsx";
import EnableEngageSTK from "./views/EnableEngageSTK/index.jsx";
import EngageComment from "./views/EngageComment/index.jsx";
import EngageShare from "./views/EngageShare/index.jsx";
import MonthlyOrderStatus from "./views/MonthlyOrderStatus/index.jsx";
import EngageDebugger from "./views/EngageDebugger/index.jsx";
import EngageDiagnosisReport from "./views/EngageDiagnosisReport/index.jsx";

const PastPayouts = lazy(() =>
  import(/* webpackChunkName: "PastPayouts" */ "./views/PastPayouts")
);
const PastPayoutDetails = lazy(() =>
  import(
    /* webpackChunkName: "PastPayoutDetails" */ "./views/PastPayoutDetails"
  )
);

const SourcingCollection = lazy(() =>
  import(
    /* webpackChunkName: "SourcingCollection" */ "./sourcing/views/Collections"
  )
);
const SourcingCart = lazy(() =>
  import(/* webpackChunkName: "SourcingCart" */ "./sourcing/views/Cart")
);
const SourcingWishlist = lazy(() =>
  import(/* webpackChunkName: "SourcingWishlist" */ "./sourcing/views/Wishlist")
);
const SourcingMyOrders = lazy(() =>
  import(/* webpackChunkName: "SourcingMyOrders" */ "./sourcing/views/MyOrders")
);
const SourcingCredit = lazy(() =>
  import(/* webpackChunkName: "SourcingCredit" */ "./sourcing/views/Credits")
);
const SourcingProduct = lazy(() =>
  import(/* webpackChunkName: "SourcingProduct" */ "./sourcing/views/Product")
);

// const Shop = lazy(() => import(/* webpackChunkName: "Shop" */ "./views/Shop"));
const CreatorShop = lazy(() =>
  import(/* webpackChunkName: "Shop" */ "./views/CreatorShop")
);
const NewPost = lazy(() =>
  import(/* webpackChunkName: "NewPost" */ "./views/NewPost")
);
const AddProducts = lazy(() =>
  import(/* webpackChunkName: "AddProducts" */ "./views/AddProducts")
);
const PreviewLinks = lazy(() =>
  import(/* webpackChunkName: "PreviewLinks" */ "./views/PreviewLinks")
);
const NewCollection = lazy(() =>
  import(/* webpackChunkName: "NewCollection" */ "./views/NewCollection")
);
const AddProductsCollection = lazy(() =>
  import(
    /* webpackChunkName: "AddProductsCollection" */ "./views/AddProductsCollection"
  )
);
const PreviewPost = lazy(() =>
  import(/* webpackChunkName: "PreviewPost" */ "./views/PreviewPost")
);
const FirebaseLogin = lazy(() =>
  import(/* webpackChunkName: "FirebaseLogin" */ "./views/FirebaseLogin")
);
const SocialMediaLogin = lazy(() =>
  import(/* webpackChunkName: "SocialMediaLogin" */ "./views/SocialMediaLogin")
);
const InstaLoginSuccess = lazy(() =>
  import(
    /* webpackChunkName: "InstaLoginSuccess" */ "./views/InstaLoginSuccess"
  )
);
const PinterestLoginSuccess = lazy(() =>
  import(
    /* webpackChunkName: "PinterestLoginSuccess" */ "./views/PinterestLoginSuccess"
  )
);
const YoutubeLoginSuccess = lazy(() =>
  import(
    /* webpackChunkName: "YoutubeLoginSuccess" */ "./views/YoutubeLoginSuccess"
  )
);
const Brands = lazy(() =>
  import(/* webpackChunkName: "Brands" */ "./views/Brands")
);
const Clothing = lazy(() =>
  import(/* webpackChunkName: "Clothing" */ "./views/Clothing")
);
const AffiliateProducts = lazy(() =>
  import(
    /* webpackChunkName: "AffiliateProducts" */ "./views/AffiliateProducts"
  )
);
const Profile = lazy(() =>
  import(/* webpackChunkName: "Profile" */ "./views/Profile")
);
const CrossPosting = lazy(() =>
  import(/* webpackChunkName: "CrossPosting" */ "./views/CrossPosting")
);
const BankDetails = lazy(() =>
  import(/* webpackChunkName: "BankDetails" */ "./views/BankDetails")
);
const PostAnalytics = lazy(() =>
  import(/* webpackChunkName: "PostAnalytics" */ "./views/PostAnalytics")
);
const CollectionAnalytics = lazy(() =>
  import(
    /* webpackChunkName: "CollectionAnalytics" */ "./views/CollectionAnalytics"
  )
);
const Home = lazy(() =>
  import(/* webpackChunkName: "Home" */ "./views/CreatorHome")
);
const Engage = lazy(() =>
  import(/* webpackChunkName: "Engage" */ "./views/Engage")
);
const Payouts = lazy(() =>
  import(/* webpackChunkName: "Payouts" */ "./views/Payouts")
);
const CreatorAdmin = lazy(() =>
  import(/* webpackChunkName: "CreatorAdmin" */ "./views/CreatorAdmin")
);
const Why = lazy(() => import(/* webpackChunkName: "Why" */ "./views/Why"));
const AuthStatus = lazy(() =>
  import(/* webpackChunkName: "AuthStatus" */ "./views/AuthStatus")
);
const Faq = lazy(() =>
  import(/* webpackChunkName: "Faq" */ "./views/Faq/index.jsx")
);
const FaqItem = lazy(() =>
  import(/* webpackChunkName: "FaqItem" */ "./views/Faq/FaqItem.jsx")
);
const SocialLoginSuccess = lazy(() =>
  import(
    /* webpackChunkName: "SocialLoginSuccess" */ "./views/SocialLoginSuccess"
  )
);
const ErrorPage = lazy(() =>
  import(/* webpackChunkName: "ErrorPage" */ "./components/Fallback/ErrorPage")
);
const ProductAnalytics = lazy(() =>
  import(/* webpackChunkName: "ProductAnalytics" */ "./views/ProductAnalytics")
);
const Rewards = lazy(() =>
  import(/* webpackChunkName: "Rewards" */ "./views/TelegramRewards")
);
const WishlinkRewards = lazy(() =>
  import(/* webpackChunkName: "WishlinkRewards" */ "./views/WishlinkRewards")
);
const RewardHistory = lazy(() =>
  import(/* webpackChunkName: "RewardHistory" */ "./views/RewardHistory")
);
const RewardFullLeaderboard = lazy(() =>
  import(
    /* webpackChunkName: "RewardLeaderboard" */ "./views/RewardDetails/RewardFullLeaderboard"
  )
);
const OrderHistory = lazy(() =>
  import(/* webpackChunkName: "OrderHistory" */ "./views/OrderHistory")
);
const Address = lazy(() =>
  import(/* webpackChunkName: "Address" */ "./views/Address")
);
const AppLoginTrigger = lazy(() =>
  import(/* webpackChunkName: "AppLoginTrigger" */ "./views/AppLoginTriggers")
);
const Notification = lazy(() =>
  import(/* webpackChunkName: "Notification" */ "./views/Notification")
);
const Tnc = lazy(() => import(/* webpackChunkName: "Tnc" */ "./views/Tnc"));
const Referral = lazy(() =>
  import(/* webpackChunkName: "Referral" */ "./views/Referral")
);
const RecommendedCollections = lazy(() =>
  import(
    /* webpackChunkName: "RecommendedCollections" */ "./views/RecommendedCollections"
  )
);
const FirstLinkSelectBrands = lazy(() =>
  import(
    /* webpackChunkName: "FirstLinkSelectBrands" */ "./views/FirstLinkSelectBrands"
  )
);
const Welcome = lazy(() =>
  import(/* webpackChunkName: "Welcome" */ "./views/Welcome")
);
const Otp = lazy(() => import(/* webpackChunkName: "Otp" */ "./views/Otp"));
const FavouriteBrands = lazy(() =>
  import(/* webpackChunkName: "FavouriteBrands" */ "./views/FavouriteBrands")
);
const LibasRewards = lazy(() =>
  import(/* webpackChunkName: "LibasRewards" */ "./views/LibasRewards")
);
const AjioRewards = lazy(() =>
  import(/* webpackChunkName: "AjioRewards" */ "./views/AjioRewards")
);
const MyntraRewards = lazy(() =>
  import(/* webpackChunkName: "MyntraRewards" */ "./views/MyntraRewards")
);

const SourcingHome = lazy(() =>
  import(/* webpackChunkName: "SourcingHome" */ "./sourcing/views/Home")
);
const HowItWorks = lazy(() =>
  import(/* webpackChunkName: "HowItWorks" */ "./sourcing/views/HowItWorks")
);
const SourcingMini = lazy(() =>
  import(
    /* webpackChunkName: "SourcingMini" */ "./sourcing/views/SourcingMini/index.jsx"
  )
);
const AnalyticsV2 = lazy(() =>
  import(/* webpackChunkName: "AnalyticsV2" */ "./views/AnalyticsV2")
);
const PendingCommission = lazy(() =>
  import(
    /* webpackChunkName: "PendingCommission" */ "./views/PayoutPendingCommission"
  )
);
const ConfirmedCommission = lazy(() =>
  import(
    /* webpackChunkName: "ConfirmedCommission" */ "./views/PayoutConfirmedCommission"
  )
);
const PayoutOrderLedger = lazy(() =>
  import(
    /* webpackChunkName: "PayoutOrderLedger" */ "./views/PayoutOrderLedger"
  )
);
const Levied = lazy(() =>
  import(/* webpackChunkName: "Levied" */ "./views/Levied")
);
const AnalyticsTNC = lazy(() =>
  import(/* webpackChunkName: "AnalyticsTNC" */ "./views/AnalyticsTNC")
);
const PostDetails = lazy(() =>
  import(/* webpackChunkName: "PostDetails" */ "./views/PostDetails")
);
const NykaaRewards = lazy(() =>
  import(/* webpackChunkName: "NykaaRewards" */ "./views/NykaaRewards")
);
const SourcingLocked = lazy(() =>
  import(
    /* webpackChunkName: "SourcingLocked" */ "./sourcing/views/SourcingLocked"
  )
);
const SourcingCredits = lazy(() =>
  import(
    /* webpackChunkName: "SourcingCredits" */ "./sourcing/views/SourcingCredits"
  )
);
const MiniSourcingActiveOrders = lazy(() =>
  import(
    /* webpackChunkName: "MiniSourcingActiveOrders" */ "./sourcing/views/MiniSourcingActiveOrders"
  )
);
const MiniSourcingCart = lazy(() =>
  import(
    /* webpackChunkName: "MiniSourcingCart" */ "./sourcing/views/MiniSourcingCart"
  )
);

const UserBlocked = lazy(() =>
  import(/* webpackChunkName: "UserBlocked" */ "./views/UserBlocked")
);

const RewardDetails = lazy(() =>
  import(/* webpackChunkName: "RewardDetails" */ "./views/RewardDetails")
);

const DiscoverContent = lazy(() =>
  import(/* webpackChunkName: "DiscoverContent" */ "./views/DiscoverContent")
);

const CopyLinks = lazy(() =>
  import(/* webpackChunkName: "CopyLinks" */ "./views/CopyLinks")
);

const CreateDiy = lazy(() =>
  import(/* webpackChunkName: "CreateDiy" */ "./views/CreateDiy")
);
const MetaLanding = lazy(() =>
  import(/* webpackChunkName: "MetaLanding" */ "./views/MetaLanding")
);
const appRoutes = [
  {
    path: PAGE_URLS.DEFAULT,
    exact: true,
    needFirebaseAuthentication: false,
    needCreatorExists: false,
    needCompleteAuthentication: false,
    component: <FirebaseLogin />,
    showFooter: false,
    canAccessWithoutOnboarding: true,
  },
  {
    path: PAGE_URLS.META_LANDING,
    exact: true,
    needFirebaseAuthentication: false,
    needCreatorExists: false,
    needCompleteAuthentication: false,
    component: <MetaLanding />,
    showFooter: false,
    canAccessWithoutOnboarding: true,
  },
  {
    path: PAGE_URLS.FIRST_LINK_SELECT_BRANDS,
    exact: true,
    needFirebaseAuthentication: true,
    needCreatorExists: true,
    needCompleteAuthentication: false,
    component: <FirstLinkSelectBrands />,
    showFooter: false,
    canAccessWithoutOnboarding: true,
  },
  {
    path: PAGE_URLS.HOW_IT_WORKS,
    exact: true,
    needFirebaseAuthentication: false,
    needCreatorExists: false,
    needCompleteAuthentication: false,
    component: <HowItWorks />,
    showFooter: false,
  },
  {
    path: PAGE_URLS.INDEX,
    exact: true,
    needFirebaseAuthentication: true,
    needCreatorExists: true,
    needCompleteAuthentication: false,
    component: <Home />,
    showFooter: true,
    canAccessWithoutOnboarding: true,
  },
  {
    path: PAGE_URLS.APPLOGIN,
    exact: true,
    needFirebaseAuthentication: true,
    needCreatorExists: true,
    needCompleteAuthentication: false,
    component: <AppLoginTrigger />,
    showFooter: false,
    canAccessWithoutOnboarding: true,
  },
  {
    path: PAGE_URLS.FIREBASELOGIN,
    exact: true,
    needFirebaseAuthentication: false,
    needCreatorExists: false,
    needCompleteAuthentication: false,
    component: <FirebaseLogin />,
    showFooter: false,
    canAccessWithoutOnboarding: true,
  },
  {
    path: PAGE_URLS.REFERRALINPUT,
    exact: true,
    needFirebaseAuthentication: false,
    needCreatorExists: false,
    needCompleteAuthentication: false,
    component: <ReferralInput />,
    showFooter: false,
    canAccessWithoutOnboarding: true,
  },
  {
    path: PAGE_URLS.SHOP,
    exact: true,
    needFirebaseAuthentication: true,
    needCreatorExists: true,
    needCompleteAuthentication: false,
    component: <CreatorShop />,
    showFooter: true,
    canAccessWithoutOnboarding: true,
  },
  {
    path: PAGE_URLS.NEWPOST,
    exact: true,
    needFirebaseAuthentication: true,
    needCreatorExists: true,
    needCompleteAuthentication: true,
    component: <NewPost />,
    showFooter: false,
    adminNotAllowed: true,
    canAccessWithoutOnboarding: true,
  },
  {
    path: PAGE_URLS.EDITPOST,
    exact: true,
    needFirebaseAuthentication: true,
    needCreatorExists: true,
    needCompleteAuthentication: true,
    component: <NewPost edit={true} />,
    showFooter: false,
    adminNotAllowed: true,
    canAccessWithoutOnboarding: true,
  },
  {
    path: PAGE_URLS.ADDPRODUCTS,
    exact: true,
    needFirebaseAuthentication: true,
    needCreatorExists: true,
    needCompleteAuthentication: true,
    component: <AddProducts />,
    showFooter: false,
    adminNotAllowed: true,
    canAccessWithoutOnboarding: true,
  },
  {
    path: PAGE_URLS.PREVIEWLINKS,
    exact: true,
    needFirebaseAuthentication: true,
    needCreatorExists: true,
    needCompleteAuthentication: true,
    component: <PreviewLinks postType={"post"} />,
    showFooter: false,
    adminNotAllowed: true,
    canAccessWithoutOnboarding: true,
  },
  {
    path: PAGE_URLS.NEWCOLLECTION,
    exact: true,
    needFirebaseAuthentication: true,
    needCreatorExists: true,
    needCompleteAuthentication: true,
    component: <NewCollection />,
    showFooter: false,
    adminNotAllowed: true,
    canAccessWithoutOnboarding: true,
  },
  {
    path: PAGE_URLS.EDITCOLLECTION,
    exact: true,
    needFirebaseAuthentication: true,
    needCreatorExists: true,
    needCompleteAuthentication: true,
    component: <NewCollection edit={true} />,
    showFooter: false,
    adminNotAllowed: true,
    canAccessWithoutOnboarding: true,
  },
  {
    path: PAGE_URLS.ADDPRODUCTSCOLLECTION,
    exact: true,
    needFirebaseAuthentication: true,
    needCreatorExists: true,
    needCompleteAuthentication: true,
    component: <AddProductsCollection />,
    showFooter: false,
    adminNotAllowed: true,
    canAccessWithoutOnboarding: true,
  },
  {
    path: PAGE_URLS.PREVIEWLINKSCOLLECTION,
    exact: true,
    needFirebaseAuthentication: true,
    needCreatorExists: true,
    needCompleteAuthentication: true,
    component: <PreviewLinks postType={"collection"} />,
    showFooter: false,
    adminNotAllowed: true,
    canAccessWithoutOnboarding: true,
  },
  {
    path: PAGE_URLS.PREVIEWPOST,
    exact: true,
    needFirebaseAuthentication: true,
    needCreatorExists: true,
    needCompleteAuthentication: true,
    component: (
      <PreviewPost postType={"post"} isRecommendedCollectionPreview={false} />
    ),
    showFooter: false,
    adminNotAllowed: true,
    canAccessWithoutOnboarding: true,
  },
  {
    path: PAGE_URLS.PREVIEWCOLLECTION,
    exact: true,
    needFirebaseAuthentication: true,
    needCreatorExists: true,
    needCompleteAuthentication: true,
    component: (
      <PreviewPost
        postType={"collection"}
        isRecommendedCollectionPreview={false}
      />
    ),
    showFooter: false,
    adminNotAllowed: true,
    canAccessWithoutOnboarding: true,
  },
  {
    path: PAGE_URLS.SOCIALMEDIALOGIN,
    exact: true,
    needFirebaseAuthentication: true,
    needCreatorExists: false,
    needCompleteAuthentication: false,
    component: <SocialMediaLogin />,
    showFooter: false,
    adminNotAllowed: true,
    canAccessWithoutOnboarding: true,
  },
  {
    path: PAGE_URLS.INSTALOGINSUCCESS,
    exact: true,
    needFirebaseAuthentication: true,
    needCreatorExists: false,
    needCompleteAuthentication: false,
    component: <InstaLoginSuccess />,
    canAccessWithoutOnboarding: true,
  },
  {
    path: PAGE_URLS.CROSSPOST,
    exact: true,
    needFirebaseAuthentication: true,
    needCreatorExists: true,
    needCompleteAuthentication: false,
    component: <CrossPosting />,
    showFooter: true,
    adminNotAllowed: true,
    canAccessWithoutOnboarding: false,
  },
  {
    path: PAGE_URLS.PINTERESTLOGINSUCCESS,
    exact: true,
    needFirebaseAuthentication: true,
    needCreatorExists: true,
    needCompleteAuthentication: false,
    showFooter: false,
    component: <PinterestLoginSuccess />,
    canAccessWithoutOnboarding: false,
  },
  {
    path: PAGE_URLS.YTLOGINSUCCESS,
    exact: true,
    needFirebaseAuthentication: true,
    needCreatorExists: false,
    needCompleteAuthentication: false,
    component: <YoutubeLoginSuccess />,
    canAccessWithoutOnboarding: true,
  },
  {
    path: PAGE_URLS.AFFILIATEPRODUCTS,
    exact: true,
    needFirebaseAuthentication: true,
    needCreatorExists: true,
    needCompleteAuthentication: true,
    component: <AffiliateProducts />,
    showFooter: false,
    adminNotAllowed: true,
    canAccessWithoutOnboarding: true,
  },
  {
    path: PAGE_URLS.BRANDS,
    exact: true,
    needFirebaseAuthentication: false,
    needCreatorExists: false,
    needCompleteAuthentication: false,
    component: <Brands />,
    showFooter: true,
    canAccessWithoutOnboarding: true,
  },
  {
    path: PAGE_URLS.CLOTHING,
    exact: true,
    needFirebaseAuthentication: false,
    needCreatorExists: false,
    needCompleteAuthentication: false,
    component: <Clothing />,
    showFooter: true,
  },
  {
    path: PAGE_URLS.PROFILE,
    exact: true,
    needFirebaseAuthentication: true,
    needCreatorExists: true,
    needCompleteAuthentication: false,
    component: <Profile />,
    showFooter: true,
    adminNotAllowed: true,
    canAccessWithoutOnboarding: true,
  },
  {
    path: PAGE_URLS.BANKDETAILS,
    exact: true,
    needFirebaseAuthentication: true,
    needCreatorExists: true,
    needCompleteAuthentication: true,
    component: <BankDetails />,
    showFooter: false,
    canAccessWithoutOnboarding: true,
  },
  {
    path: PAGE_URLS.ANALYTICSHOME,
    exact: true,
    needFirebaseAuthentication: true,
    needCreatorExists: true,
    needCompleteAuthentication: false,
    component: <></>,
    showFooter: true,
    canAccessWithoutOnboarding: true,
  },
  {
    path: PAGE_URLS.ANALYTICSDETAIL,
    exact: true,
    needFirebaseAuthentication: true,
    needCreatorExists: true,
    needCompleteAuthentication: false,
    component: <></>,
    showFooter: true,
    canAccessWithoutOnboarding: true,
  },
  {
    path: PAGE_URLS.POSTANALYTICS,
    exact: true,
    needFirebaseAuthentication: true,
    needCreatorExists: true,
    needCompleteAuthentication: false,
    component: <PostAnalytics />,
    showFooter: false,
  },
  {
    path: PAGE_URLS.COLLECTIONANALYTICS,
    exact: true,
    needFirebaseAuthentication: true,
    needCreatorExists: true,
    needCompleteAuthentication: false,
    component: <CollectionAnalytics />,
    showFooter: false,
  },
  {
    path: PAGE_URLS.HOME,
    exact: true,
    needFirebaseAuthentication: true,
    needCreatorExists: true,
    needCompleteAuthentication: false,
    component: <Home />,
    showFooter: true,
    canAccessWithoutOnboarding: true,
  },
  {
    path: PAGE_URLS.ENGAGE,
    exact: true,
    needFirebaseAuthentication: true,
    needCompleteAuthentication: false,
    component: <Engage />,
    showFooter: false,
    adminNotAllowed: true,
    canAccessWithoutOnboarding: true,
  },
  {
    path: PAGE_URLS.PAYOUTS,
    exact: true,
    needFirebaseAuthentication: true,
    needCompleteAuthentication: false,
    component: <Payouts />,
    showFooter: false,
  },
  {
    path: PAGE_URLS.ADMIN,
    exact: true,
    needFirebaseAuthentication: false,
    needCompleteAuthentication: false,
    component: <CreatorAdmin />,
    showFooter: false,
    canAccessWithoutOnboarding: true,
  },
  {
    path: PAGE_URLS.WHY,
    exact: true,
    needFirebaseAuthentication: true,
    needCompleteAuthentication: false,
    component: <Why />,
    showFooter: false,
    canAccessWithoutOnboarding: true,
  },
  {
    path: PAGE_URLS.ENABLEENGAGE,
    exact: true,
    needFirebaseAuthentication: true,
    needCompleteAuthentication: false,
    component: <EnableEngageSTK />,
    showFooter: false,
    canAccessWithoutOnboarding: true,
  },
  {
    path: PAGE_URLS.AUTHSTATUS,
    exact: false,
    needFirebaseAuthentication: true,
    needCompleteAuthentication: false,
    component: <AuthStatus />,
    showFooter: false,
    canAccessWithoutOnboarding: true,
  },
  {
    path: PAGE_URLS.FAQ,
    exact: false,
    needFirebaseAuthentication: true,
    needCompleteAuthentication: false,
    component: <Faq />,
    showFooter: false,
    canAccessWithoutOnboarding: true,
  },
  {
    path: PAGE_URLS.FAQ_ITEM,
    exact: false,
    needFirebaseAuthentication: true,
    needCompleteAuthentication: false,
    component: <FaqItem />,
    showFooter: false,
    canAccessWithoutOnboarding: true,
  },
  {
    path: PAGE_URLS.SOCIAL_LOGIN_SUCCESS,
    exact: false,
    needFirebaseAuthentication: true,
    needCompleteAuthentication: false,
    component: <SocialLoginSuccess />,
    showFooter: false,
    canAccessWithoutOnboarding: true,
  },
  {
    path: PAGE_URLS.ERROR_PAGE,
    exact: false,
    needFirebaseAuthentication: true,
    needCompleteAuthentication: false,
    component: <ErrorPage />,
    showFooter: false,
    canAccessWithoutOnboarding: true,
  },
  {
    path: PAGE_URLS.PRODUCTANALYTICS,
    exact: false,
    needFirebaseAuthentication: true,
    needCreatorExists: true,
    needCompleteAuthentication: false,
    component: <ProductAnalytics />,
    showFooter: false,
  },
  {
    path: PAGE_URLS.REWARDS,
    exact: false,
    needFirebaseAuthentication: true,
    needCreatorExists: true,
    needCompleteAuthentication: false,
    component: <Rewards />,
    showFooter: false,
  },
  {
    path: PAGE_URLS.LIBAS_REWARDS,
    exact: false,
    needFirebaseAuthentication: true,
    needCreatorExists: true,
    needCompleteAuthentication: false,
    component: <LibasRewards />,
    showFooter: false,
  },
  {
    path: PAGE_URLS.PLAYBOOK,
    exact: false,
    needFirebaseAuthentication: false,
    needCreatorExists: false,
    needCompleteAuthentication: false,
    component: <Playbook />,
    showFooter: false,
  },
  {
    path: PAGE_URLS.PLAYBOOK_CONTENT,
    exact: false,
    needFirebaseAuthentication: false,
    needCreatorExists: false,
    needCompleteAuthentication: false,
    component: <PlaybookContent />,
    showFooter: false,
  },
  {
    path: PAGE_URLS.PLAYBOOK_MONETIZE,
    exact: false,
    needFirebaseAuthentication: false,
    needCreatorExists: false,
    needCompleteAuthentication: false,
    component: <PlaybookMonetize />,
    showFooter: false,
  },
  {
    path: PAGE_URLS.ORDER_HISTORY,
    exact: false,
    needFirebaseAuthentication: true,
    needCreatorExists: true,
    needCompleteAuthentication: false,
    component: <OrderHistory />,
    showFooter: false,
  },
  {
    path: PAGE_URLS.ADDRESS,
    exact: false,
    needFirebaseAuthentication: true,
    needCreatorExists: true,
    needCompleteAuthentication: false,
    component: <Address />,
    showFooter: false,
  },
  // Sourcing URLS
  {
    path: PAGE_URLS.SOURCING,
    exact: false,
    needFirebaseAuthentication: true,
    needCreatorExists: true,
    needCompleteAuthentication: false,
    isSourcingAccessNeeded: true,
    component: <SourcingHome />,
    showFooter: false,
    canAccessWithoutOnboarding: true,
  },
  {
    path: PAGE_URLS.SOURCING_COLLECTION,
    exact: false,
    needFirebaseAuthentication: true,
    needCreatorExists: true,
    needCompleteAuthentication: false,
    isSourcingAccessNeeded: true,
    component: <SourcingCollection />,
    showFooter: false,
  },
  {
    path: PAGE_URLS.SOURCING_PRODUCT_URL,
    exact: false,
    needFirebaseAuthentication: true,
    needCreatorExists: true,
    needCompleteAuthentication: false,
    isSourcingAccessNeeded: true,
    component: <SourcingProduct />,
    showFooter: false,
  },
  {
    path: PAGE_URLS.SOURCING_CART,
    exact: false,
    needFirebaseAuthentication: true,
    needCreatorExists: true,
    needCompleteAuthentication: false,
    isSourcingAccessNeeded: true,
    component: <SourcingCart />,
    showFooter: false,
  },
  {
    path: PAGE_URLS.SOURCING_WISHLIST,
    exact: false,
    needFirebaseAuthentication: true,
    needCreatorExists: true,
    needCompleteAuthentication: false,
    isSourcingAccessNeeded: true,
    component: <SourcingWishlist />,
    showFooter: false,
  },
  {
    path: PAGE_URLS.SOURCING_MY_ORDERS,
    exact: false,
    needFirebaseAuthentication: true,
    needCreatorExists: true,
    needCompleteAuthentication: false,
    isSourcingAccessNeeded: true,
    component: <SourcingMyOrders />,
    showFooter: false,
  },
  {
    path: PAGE_URLS.SOURCING_CREDIT,
    exact: false,
    needFirebaseAuthentication: true,
    needCreatorExists: true,
    needCompleteAuthentication: false,
    isSourcingAccessNeeded: true,
    component: <SourcingCredit />,
    showFooter: false,
  },
  // Sourcing END
  {
    path: PAGE_URLS.NOTIFICATION,
    exact: false,
    needFirebaseAuthentication: true,
    needCreatorExists: true,
    needCompleteAuthentication: false,
    component: <Notification />,
    showFooter: false,
    canAccessWithoutOnboarding: true,
  },
  {
    path: PAGE_URLS.TNC,
    exact: true,
    needFirebaseAuthentication: true,
    needCreatorExists: true,
    needCompleteAuthentication: false,
    component: <Tnc />,
    showFooter: false,
  },
  {
    path: PAGE_URLS.REFERRAL,
    exact: true,
    needFirebaseAuthentication: true,
    needCreatorExists: true,
    needCompleteAuthentication: false,
    component: <Referral />,
    showFooter: false,
  },
  {
    path: PAGE_URLS.RECOMMENDED_COLLECTIONS,
    exact: true,
    needFirebaseAuthentication: true,
    needCreatorExists: true,
    needCompleteAuthentication: false,
    component: <RecommendedCollections />,
    showFooter: true,
    canAccessWithoutOnboarding: true,
  },
  {
    path: PAGE_URLS.WISHLINK_BESTSELLERS,
    exact: true,
    needFirebaseAuthentication: true,
    needCreatorExists: true,
    needCompleteAuthentication: false,
    component: <RecommendedCollections />,
    showFooter: true,
    canAccessWithoutOnboarding: true,
  },
  {
    path: PAGE_URLS.PREVIEWRECOMMENDEDCOLLECTION,
    exact: true,
    needFirebaseAuthentication: true,
    needCreatorExists: true,
    needCompleteAuthentication: false,
    component: (
      <PreviewPost
        postType={"collection"}
        isRecommendedCollectionPreview={true}
      />
    ),
    showFooter: false,
    adminNotAllowed: true,
    canAccessWithoutOnboarding: true,
  },
  {
    path: PAGE_URLS.PREVIEWWISHLINKBESTSELLER,
    exact: true,
    needFirebaseAuthentication: true,
    needCreatorExists: true,
    needCompleteAuthentication: false,
    component: (
      <PreviewPost
        postType={"collection"}
        isRecommendedCollectionPreview={true}
      />
    ),
    showFooter: false,
    adminNotAllowed: true,
    canAccessWithoutOnboarding: true,
  },
  {
    path: PAGE_URLS.SOURCING_NEW_PDP,
    exact: true,
    needFirebaseAuthentication: true,
    needCreatorExists: true,
    needCompleteAuthentication: false,
    component: <SourcingProduct />,
    showFooter: false,
    adminNotAllowed: false,
  },
  {
    path: PAGE_URLS.SOURCING_NEW_BARTER_PDP,
    exact: true,
    needFirebaseAuthentication: true,
    needCreatorExists: true,
    needCompleteAuthentication: false,
    component: <SourcingProduct />,
    showFooter: false,
    adminNotAllowed: false,
  },
  {
    path: PAGE_URLS.SOURCING_MINI,
    exact: true,
    needFirebaseAuthentication: true,
    needCreatorExists: true,
    needCompleteAuthentication: true,
    isMiniSourcingAccessNeeded: true,
    component: <SourcingMini />,
    showFooter: false,
    adminNotAllowed: false,
    canAccessWithoutOnboarding: true,
  },
  {
    path: PAGE_URLS.WELCOME,
    exact: true,
    needFirebaseAuthentication: false,
    needCreatorExists: false,
    needCompleteAuthentication: false,
    component: <Welcome />,
    showFooter: false,
    canAccessWithoutOnboarding: true,
  },
  {
    path: PAGE_URLS.OTP,
    exact: true,
    needFirebaseAuthentication: false,
    needCreatorExists: false,
    needCompleteAuthentication: false,
    component: <Otp />,
    showFooter: false,
    canAccessWithoutOnboarding: true,
  },
  {
    path: PAGE_URLS.FAVOURITE_BRANDS,
    exact: true,
    needFirebaseAuthentication: true,
    needCreatorExists: true,
    needCompleteAuthentication: false,
    component: <FavouriteBrands />,
    showFooter: false,
    canAccessWithoutOnboarding: true,
  },
  {
    path: PAGE_URLS.CREATOR_EARNINGS,
    exact: true,
    needFirebaseAuthentication: true,
    needCreatorExists: true,
    needCompleteAuthentication: false,
    component: <CreatorEarnings />,
    showFooter: false,
    adminNotAllowed: false,
    canAccessWithoutOnboarding: true,
  },
  {
    path: PAGE_URLS.CREATOR_OAUTH,
    exact: true,
    needFirebaseAuthentication: true,
    needCreatorExists: true,
    needCompleteAuthentication: false,
    component: <WishlinkCreatorOauth />,
    showFooter: false,
    adminNotAllowed: true,
    canAccessWithoutOnboarding: true,
  },
  {
    path: PAGE_URLS.PAYOUT_PRELAUNCH,
    exact: true,
    needFirebaseAuthentication: true,
    needCreatorExists: true,
    needCompleteAuthentication: false,
    component: <PayoutExplainerVideo />,
    showFooter: false,
    canAccessWithoutOnboarding: true,
  },
  {
    path: PAGE_URLS.ANALYTICS,
    exact: false,
    needFirebaseAuthentication: true,
    needCreatorExists: true,
    needCompleteAuthentication: false,
    component: <AnalyticsV2 />,
    showFooter: true,
    canAccessWithoutOnboarding: true,
  },
  {
    path: PAGE_URLS.PENDING_COMMISSION,
    exact: true,
    needFirebaseAuthentication: true,
    needCreatorExists: true,
    needCompleteAuthentication: false,
    component: <PendingCommission />,
    showFooter: false,
  },
  {
    path: PAGE_URLS.CONFIRMED_COMMISSION,
    exact: true,
    needFirebaseAuthentication: true,
    needCreatorExists: true,
    needCompleteAuthentication: false,
    component: <ConfirmedCommission />,
    showFooter: false,
  },
  {
    path: PAGE_URLS.PAYOUT_ORDER_LEDGER,
    exact: true,
    needFirebaseAuthentication: true,
    needCreatorExists: true,
    needCompleteAuthentication: false,
    component: <PayoutOrderLedger />,
    showFooter: false,
  },
  {
    path: PAGE_URLS.PAST_PAYOUTS,
    exact: true,
    needFirebaseAuthentication: true,
    needCreatorExists: true,
    needCompleteAuthentication: false,
    component: <PastPayouts />,
    showFooter: false,
  },
  {
    path: PAGE_URLS.PAST_PAYOUTS_DETAILS,
    exact: true,
    needFirebaseAuthentication: true,
    needCreatorExists: true,
    needCompleteAuthentication: false,
    component: <PastPayoutDetails />,
    showFooter: false,
  },
  {
    path: PAGE_URLS.LEVIED,
    exact: true,
    needFirebaseAuthentication: true,
    needCreatorExists: true,
    needCompleteAuthentication: false,
    component: <Levied />,
    showFooter: false,
  },
  {
    path: PAGE_URLS.ANALYTICS_TNC,
    exact: true,
    needFirebaseAuthentication: true,
    needCreatorExists: true,
    needCompleteAuthentication: false,
    component: <AnalyticsTNC />,
    showFooter: false,
  },
  {
    path: PAGE_URLS.POST_DETAILS,
    exact: true,
    needFirebaseAuthentication: true,
    needCreatorExists: true,
    needCompleteAuthentication: false,
    component: <PostDetails />,
    showFooter: false,
    canAccessWithoutOnboarding: true,
  },
  {
    path: PAGE_URLS.NYKAA_REWARDS,
    exact: false,
    needFirebaseAuthentication: true,
    needCreatorExists: true,
    needCompleteAuthentication: false,
    component: <NykaaRewards />,
    showFooter: false,
  },
  {
    path: PAGE_URLS.SOURCING_LOCKED,
    exact: false,
    needFirebaseAuthentication: true,
    needCreatorExists: true,
    needCompleteAuthentication: false,
    component: <SourcingLocked />,
    showFooter: false,
    canAccessWithoutOnboarding: true,
  },
  {
    path: PAGE_URLS.SOURCING_CREDITS,
    exact: false,
    needFirebaseAuthentication: true,
    needCreatorExists: true,
    needCompleteAuthentication: false,
    component: <SourcingCredits />,
    showFooter: false,
    canAccessWithoutOnboarding: false,
  },
  {
    path: PAGE_URLS.MINI_SOURCING_ACTIVE_ORDERS,
    exact: false,
    needFirebaseAuthentication: true,
    needCreatorExists: true,
    needCompleteAuthentication: false,
    component: <MiniSourcingActiveOrders />,
    showFooter: false,
    canAccessWithoutOnboarding: false,
  },
  {
    path: PAGE_URLS.MINI_SOURCING_CART,
    exact: false,
    needFirebaseAuthentication: true,
    needCreatorExists: true,
    needCompleteAuthentication: false,
    component: <MiniSourcingCart />,
    showFooter: false,
    canAccessWithoutOnboarding: false,
  },
  {
    path: PAGE_URLS.USER_BLOCKED,
    exact: false,
    needFirebaseAuthentication: false,
    needCreatorExists: false,
    needCompleteAuthentication: false,
    component: <UserBlocked />,
    showFooter: false,
    canAccessWithoutOnboarding: true,
  },
  {
    path: PAGE_URLS.AJIO_REWARDS,
    exact: false,
    needFirebaseAuthentication: true,
    needCreatorExists: true,
    needCompleteAuthentication: false,
    component: <AjioRewards />,
    showFooter: false,
  },
  {
    path: PAGE_URLS.MYNTRA_REWARDS,
    exact: false,
    needFirebaseAuthentication: true,
    needCreatorExists: true,
    needCompleteAuthentication: false,
    component: <MyntraRewards />,
    showFooter: false,
  },
  {
    path: PAGE_URLS.ENGAGE_COMMENT,
    exact: false,
    needFirebaseAuthentication: true,
    needCreatorExists: true,
    needCompleteAuthentication: false,
    component: <EngageComment />,
    showFooter: false,
    canAccessWithoutOnboarding: true,
  },
  {
    path: PAGE_URLS.ENGAGE_SHARE,
    exact: false,
    needFirebaseAuthentication: true,
    needCreatorExists: true,
    needCompleteAuthentication: false,
    component: <EngageShare />,
    showFooter: false,
    canAccessWithoutOnboarding: true,
  },
  {
    path: PAGE_URLS.WISHLINK_REWARDS,
    exact: false,
    needFirebaseAuthentication: true,
    needCreatorExists: true,
    needCompleteAuthentication: false,
    component: <WishlinkRewards />,
    showFooter: false,
    canAccessWithoutOnboarding: true,
  },
  {
    path: PAGE_URLS.REWARD_DETAILS,
    exact: false,
    needFirebaseAuthentication: true,
    needCreatorExists: true,
    needCompleteAuthentication: false,
    component: <RewardDetails />,
    showFooter: false,
    canAccessWithoutOnboarding: true,
  },
  {
    path: PAGE_URLS.REWARD_HISTORY,
    exact: false,
    needFirebaseAuthentication: true,
    needCreatorExists: true,
    needCompleteAuthentication: false,
    component: <RewardHistory />,
    showFooter: false,
  },
  {
    path: PAGE_URLS.DISCOVER_CONTENT,
    exact: false,
    needFirebaseAuthentication: true,
    needCreatorExists: true,
    needCompleteAuthentication: false,
    component: <DiscoverContent />,
    showFooter: false,
  },
  {
    path: PAGE_URLS.REWARD_LEADERBOARD,
    exact: false,
    needFirebaseAuthentication: true,
    needCreatorExists: true,
    needCompleteAuthentication: false,
    component: <RewardFullLeaderboard />,
    showFooter: false,
  },
  {
    path: PAGE_URLS.MONTHLY_ORDER_STATUS,
    exact: false,
    needFirebaseAuthentication: true,
    needCreatorExists: true,
    needCompleteAuthentication: false,
    component: <MonthlyOrderStatus />,
    showFooter: false,
  },
  {
    path: PAGE_URLS.COPY_LINKS,
    exact: true,
    needFirebaseAuthentication: true,
    needCreatorExists: true,
    needCompleteAuthentication: false,
    component: <CopyLinks />,
    showFooter: false,
    canAccessWithoutOnboarding: true,
  },
  {
    path: PAGE_URLS.ENGAGEDEBUGGER,
    exact: true,
    needFirebaseAuthentication: true,
    needCompleteAuthentication: false,
    component: <EngageDebugger />,
    showFooter: false,
    canAccessWithoutOnboarding: true,
  },
  {
    path: PAGE_URLS.ENGAGE_DIAGONISIS_REPORT,
    exact: true,
    needFirebaseAuthentication: true,
    needCompleteAuthentication: false,
    component: <EngageDiagnosisReport />,
    showFooter: false,
    canAccessWithoutOnboarding: true,
  },
  {
    path: PAGE_URLS.CREATE_DIY,
    exact: false,
    needFirebaseAuthentication: true,
    needCreatorExists: true,
    needCompleteAuthentication: false,
    component: <CreateDiy />,
    showFooter: true,
    canAccessWithoutOnboarding: true,
  },
  {
    path: "/*",
    component: <ErrorPage errorPage={true} />,
    showFooter: false,
  },
];
export default appRoutes;
