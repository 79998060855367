import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import { Provider } from "react-redux";
import * as Sentry from "@sentry/react";
import "./wdyr";
import "./index.css";
import App from "./App";
import { store } from "./redux/redux";
import { ParallaxProvider } from "react-scroll-parallax";
import { SENTRY } from "./utils/constants";

if (process.env.NODE_ENV === "production") {
  Sentry.init({
    dsn: SENTRY.DSN,
    tracesSampleRate: SENTRY.SAMPLE_RATE,
    release: process.env.NODE_ENV,
    replaysOnErrorSampleRate: 1,
    replaysSessionSampleRate: 0.3,
  });
}

const root = ReactDOM.createRoot(document.getElementById("root"), {
  // Callback called when React catches an error in an ErrorBoundary.
  onCaughtError: Sentry.reactErrorHandler(),
  // Callback called when React automatically recovers from errors.
  onRecoverableError: Sentry.reactErrorHandler(),
});
const render = () => {
  root.render(
    <Provider store={store}>
      <ParallaxProvider>
        <BrowserRouter>
          <App />
        </BrowserRouter>
      </ParallaxProvider>
    </Provider>
  );
};

window.customCopy = (text, navigator) => {
  if (window.ReactNativeWebView) {
    const obj = JSON.parse(window?.ReactNativeWebView?.injectedObjectJson());
    if (obj?.appBuild > 5) {
      // only release this feature for new app release for both platform
      window?.ReactNativeWebView?.postMessage(
        JSON.stringify({ type: "copy", payload: text })
      );
    } else if (obj?.platform === "android") {
      window?.ReactNativeWebView?.postMessage(
        JSON.stringify({ type: "copy", payload: text })
      );
    }
  }
  navigator?.clipboard?.writeText(text);
};
window.customPaste = (pasteFunction, navigator) => {
  if (window?.ReactNativeWebView) {
    const obj = JSON.parse(window?.ReactNativeWebView?.injectedObjectJson());
    if (obj?.appBuild > 5) {
      // only release this feature for new app release for both platform
      window?.ReactNativeWebView?.postMessage(
        JSON.stringify({ type: "paste" })
      );
      if (obj?.platform === "android") {
        document.addEventListener("message", (e) => {
          try {
            const response = JSON.parse(e.data);
            pasteFunction(response?.payload || "");
          } catch (error) {
            // eslint-disable-next-line
          }
        });
      } else {
        window.addEventListener("message", (e) => {
          try {
            const response = JSON.parse(e.data);
            pasteFunction(response?.payload || "");
          } catch (error) {
            // eslint-disable-next-line
          }
        });
      }
    } else if (obj?.platform === "android" && obj?.clipboard) {
      pasteFunction(obj?.clipboard);
    }
  } else {
    navigator?.clipboard?.readText()?.then(pasteFunction);
  }
};
window.customShare = (text, navigator) => navigator?.share({ text: text });

if (
  window._cordovaNative ||
  (window.CDV_ASSETS_URL &&
    /app:/.test(window.CDV_ASSETS_URL) &&
    /creator.wishlink.com/.test(window.CDV_ASSETS_URL))
) {
  document.addEventListener(
    "deviceready",
    () => {
      try {
        window.installReferrer.getReferrer(
          (result) => {
            //  Adding this logger temporarily for a while to keep logging ki kya results aate hain yahan pe nikalke, just to monitor ki chal kya raha hai yahan
            fetch("https://api.wishlink.com/api/frontEndLogger", {
              method: "POST",
              headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
              },
              body: JSON.stringify({
                log: `Result received for app UTM is this ${JSON.stringify(
                  result
                )}`,
              }),
            })
              .then()
              .catch();
            let appUtmSource = result.utm_source;
            let appUtmMedium = result.utm_medium;
            localStorage.setItem("appUtmSource", appUtmSource);
            localStorage.setItem("appUtmMedium", appUtmMedium);
          },
          () => {
            // console.log("app utm extraction errored");
          }
        );
      } catch (e) {
        // This api call is only here for the experimentation phase
        fetch("https://api.wishlink.com/api/frontEndLogger", {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            log: `App UTM extraction failed with exception ${JSON.stringify(
              e
            )}`,
          }),
        })
          .then()
          .catch();
        // console.log("app utm extraction failed");
      }
      if (window.cordova?.platformId === "ios") {
        window?.WkWebView?.allowsBackForwardNavigationGestures(true);
      } else if (window.cordova?.platformId === "android") {
        window.customCopy = (text, navigator) =>
          window.cordova.plugins.clipboard.copy(text);
        window.customPaste = (pasteFunction, navigator) =>
          window.cordova.plugins.clipboard.paste(pasteFunction);
        window.customShare = (text, navigator) => navigator.share(text);
      }
      document.addEventListener(
        "offline",
        () => {
          alert("No internet connection found");
        },
        false
      );
      render();
    },
    false
  );
} else {
  render();
}

export { getDisplayTextFromDatesAndPresets } from "./utils/common";
