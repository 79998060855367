import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  profileTriggers: {},
};

export const socialMediaProfileSlice = createSlice({
  name: "socialMediaProfile",
  initialState: initialState,
  reducers: {
    updateProfileTriggers(state, action) {
      state.profileTriggers = action.payload;
    },
  },
});

export const { updateProfileTriggers } = socialMediaProfileSlice.actions;
