import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  youtubeShoppingData: [],
  youtubeShoppingDataLoading: false,
  brandWiseYoutubeEarningsData: {},
  brandWiseYoutubeEarningsDataLoading: false,
  ytEarningsStatus: false,
  ytEarningsStatusLoading: false,
  youtubeShoppingTriggers: {},
};

export const YoutubeShoppingSlice = createSlice({
  name: "youtubeShopping",
  initialState: initialState,
  reducers: {
    updateYoutubeShoppingData(state, action) {
      if (
        action?.payload?.data?.data !== undefined &&
        action.payload.status === "fulfilled"
      ) {
        state.youtubeShoppingData = action.payload.data.data;
      }
    },
    updateYoutubeShoppingDataLoading(state, action) {
      state.youtubeShoppingDataLoading = action.payload;
    },
    updateBrandWiseYoutubeEarningsData(state, action) {
      if (
        action?.payload?.data?.data !== undefined &&
        action.payload.status === "fulfilled"
      ) {
        state.brandWiseYoutubeEarningsData =
          action.payload.data.data.brand_wise_youtube_data_result;
      }
    },
    updateBrandWiseYoutubeEarningsDataLoading(state, action) {
      state.brandWiseYoutubeEarningsDataLoading = action.payload;
    },
    updateYoutubeShoppingTriggers(state, action) {
      state.youtubeShoppingTriggers = action.payload;
    },
    updateYtEarningsStatus(state, action) {
      if (
        action?.payload?.data?.data !== undefined &&
        action.payload.status === "fulfilled"
      )
        state.ytEarningsStatus = action.payload.data.data;
    },
    updateYtEarningsStatusLoading(state, action) {
      state.ytEarningsStatusLoading = action.payload;
    },
  },
});

export const {
  updateYoutubeShoppingData,
  updateBrandWiseYoutubeEarningsData,
  updateYoutubeShoppingDataLoading,
  updateBrandWiseYoutubeEarningsDataLoading,
  updateYtEarningsStatus,
  updateYtEarningsStatusLoading,
  updateYoutubeShoppingTriggers,
} = YoutubeShoppingSlice.actions;
