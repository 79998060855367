import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  meeshoBrandDetails: null,
  homePageTriggers: {},
  testimonials: null,
  showWaitlistWidget: false,
  failedLoginId: "",
  highlights: null,
  highlightsRead: null,
  highlightsUnread: null,
  goToSelectBrands: false,
  creatorAllTimeEarnings: 0,
  creatorActiveGtms: null,
  creatorActiveGtmsLoading: false,
  creatorWarningData: null,
};

export const homePageSlice = createSlice({
  name: "HomePage",
  initialState: initialState,
  reducers: {
    updateHomePageTriggers(state, action) {
      state.homePageTriggers = action.payload;
    },
    updateMeeshoBrandDetails(state, action) {
      if (
        action?.payload?.data?.data !== undefined &&
        action.payload.status === "fulfilled"
      ) {
        state.meeshoBrandDetails = action.payload.data.data;
      }
    },
    updateTestimonials(state, action) {
      if (
        action?.payload?.data?.data !== undefined &&
        action.payload.status === "fulfilled"
      ) {
        state.testimonials = action.payload.data.data;
      }
    },
    updateToggleWaitlistWidget(state, action) {
      state.showWaitlistWidget = action.payload;
    },
    updateFailedLoginId(state, action) {
      state.failedLoginId = action.payload;
    },
    updateStoryHighlights(state, action) {
      if (
        action?.payload?.data?.highlight !== undefined &&
        action.payload.status === "fulfilled"
      ) {
        const highlight = action.payload.data.highlight;
        const highlightsRead = [];
        const highlightsUnread = [];
        highlight.map((story) => {
          story.mark_as_read
            ? highlightsRead.push(story)
            : highlightsUnread.push(story);
          return story;
        });
        state.highlights = highlight;
        state.highlightsRead = highlightsRead;
        state.highlightsUnread = highlightsUnread;
      }
    },
    updateMarkAsRead(state, action) {
      const id = action.payload;
      const highlightsUnread = state.highlightsUnread;
      highlightsUnread.map((item) => {
        if (item.id === id) item.mark_as_read = true;
        return item;
      });
      state.highlightsUnread = highlightsUnread;
    },
    updateMarkItemAsRead(state, action) {
      const id = action.payload;
      const highlightsUnread = state.highlightsUnread;
      highlightsUnread[0].highlight_items.map((highlightItem) => {
        if (highlightItem.id === id) highlightItem.mark_item_as_read = true;
        return highlightItem;
      });
    },
    updateGoToSelectBrands(state, action) {
      state.goToSelectBrands = action.payload;
    },
    updateCreatorAllTimeEarnings(state, action) {
      if (
        action?.payload?.data?.data !== undefined &&
        action.payload.status === "fulfilled"
      ) {
        state.creatorAllTimeEarnings = action.payload.data.data;
      }
    },
    updateCreatorActiveGtms(state, action) {
      state.creatorActiveGtms = action.payload;
    },
    updateCreatorActiveGtmsLoading(state, action) {
      state.creatorActiveGtmsLoading = action.payload;
    },
    updateCreatorWarningData(state, action) {
      state.creatorWarningData = action.payload.data.data;
    },
  },
});

export const {
  updateHomePageTriggers,
  updateMeeshoBrandDetails,
  updateTestimonials,
  updateToggleWaitlistWidget,
  updateFailedLoginId,
  updateStoryHighlights,
  updateMarkAsRead,
  updateGoToSelectBrands,
  updateCreatorAllTimeEarnings,
  updateMarkItemAsRead,
  updateCreatorActiveGtms,
  updateCreatorActiveGtmsLoading,
  updateCreatorWarningData,
} = homePageSlice.actions;
