// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.slick-next {
  margin-right: 1rem;
  z-index: 1000;
}

.slick-prev {
  margin-left: 1rem;
  z-index: 1000;
}`, "",{"version":3,"sources":["webpack://./src/WishlinkComponents/WishlinkSlider/index.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB,aAAa;AACf;;AAEA;EACE,iBAAiB;EACjB,aAAa;AACf","sourcesContent":[".slick-next {\n  margin-right: 1rem;\n  z-index: 1000;\n}\n\n.slick-prev {\n  margin-left: 1rem;\n  z-index: 1000;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
