// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.wishlink-loader path {
    stroke-dashoffset: 210;
    stroke-dasharray: 210;
    animation: draw 0.9s forwards infinite;
}

@keyframes draw {
    0% {
        stroke-dashoffset: 210;
        fill-opacity: 0;
    }

    50% {
        fill-opacity: 0.1;
    }

    100% {
        stroke-dashoffset: 0;
        fill-opacity: 1;
    }
}

@keyframes fill-in {
    to {
        fill-opacity: 1;
    }
}
`, "",{"version":3,"sources":["webpack://./src/components/Fallback/LoadingPage.css"],"names":[],"mappings":"AAAA;IACI,sBAAsB;IACtB,qBAAqB;IACrB,sCAAsC;AAC1C;;AAEA;IACI;QACI,sBAAsB;QACtB,eAAe;IACnB;;IAEA;QACI,iBAAiB;IACrB;;IAEA;QACI,oBAAoB;QACpB,eAAe;IACnB;AACJ;;AAEA;IACI;QACI,eAAe;IACnB;AACJ","sourcesContent":[".wishlink-loader path {\n    stroke-dashoffset: 210;\n    stroke-dasharray: 210;\n    animation: draw 0.9s forwards infinite;\n}\n\n@keyframes draw {\n    0% {\n        stroke-dashoffset: 210;\n        fill-opacity: 0;\n    }\n\n    50% {\n        fill-opacity: 0.1;\n    }\n\n    100% {\n        stroke-dashoffset: 0;\n        fill-opacity: 1;\n    }\n}\n\n@keyframes fill-in {\n    to {\n        fill-opacity: 1;\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
