import {
  IMAGE_PREFIX,
  PAYOUT_STATUS,
  PAYOUT_STATUS_CAPTALISE,
} from "../../../../utils/constants";
import WishlinkTypography from "../../../../WishlinkComponents/WishlinkTypography";
import { Card, Row } from "../../../../components/UtilityComponents";

const getStatusImageAndString = (status) => {
  switch (status) {
    case PAYOUT_STATUS.PENDING:
      return {
        statusIcon: "payout-info-icon-blue.svg",
        statusText: PAYOUT_STATUS_CAPTALISE.TO_BE_PAID, // pending is now to_be_paid
        background: "#C2D0F2",
      };
    case PAYOUT_STATUS.PAID:
      return {
        statusIcon: "confirmed-check.svg",
        statusText: PAYOUT_STATUS_CAPTALISE.PAID,
        background: "#CDFFE8",
      };
    case PAYOUT_STATUS.SETTLED:
      return {
        statusIcon: "settledpayout_icon.svg",
        statusText: PAYOUT_STATUS_CAPTALISE.SETTLED,
        background: "#F9F9F9",
      };
    case PAYOUT_STATUS.PROCESSING:
      return {
        statusIcon: "pending-clock.svg",
        statusText: PAYOUT_STATUS_CAPTALISE.PROCESSING,
        background: "#D7E6FF",
      };
    case PAYOUT_STATUS.REJECTED:
      return {
        statusIcon: "payout-info-icon-red.svg",
        statusText: PAYOUT_STATUS_CAPTALISE.REJECTED,
        background: "#F9F9F9",
      };
    case PAYOUT_STATUS.TO_BE_PAID:
      return {
        statusIcon: "payout-info-icon-blue.svg",
        statusText: PAYOUT_STATUS_CAPTALISE.TO_BE_PAID,
        background: "#C2D0F2",
      };
    default:
      return {
        statusIcon: "",
        statusText: "",
        background: "",
      };
  }
};

export const StatusPill = ({
  type,
  height = "14px",
  fontSize = "0.75rem",
  mt = "0.75",
}) => {
  const { statusIcon, statusText, background } = getStatusImageAndString(type);
  return (
    <>
      {!!statusText && !!statusIcon && (
        <Card width="auto" bgColor={background} p={0.12} pr={0.4} mt={mt}>
          <Row>
            <img
              src={IMAGE_PREFIX + statusIcon}
              height={height}
              style={{ marginRight: "0.25rem" }}
              alt=""
            />
            <WishlinkTypography
              fontSize={fontSize}
              lineHeight={0}
              color={"#000000"}
              ml="0.1rem"
            >
              {statusText}
            </WishlinkTypography>
          </Row>
        </Card>
      )}
    </>
  );
};
