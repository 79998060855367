import { useTheme } from "@mui/material";

import { tokens } from "../../theme";
import WishlinkButton from "../../WishlinkComponents/WishlinkButton";
import WishlinkTypography from "../../WishlinkComponents/WishlinkTypography";
import {
  GA_ACTION,
  GA_CATEGORIES,
  GA_PAGE,
  GA_SCREEN_NAME,
  IMAGE_PREFIX,
} from "../../utils/constants";
import { triggerGa } from "../../utils/gaUtils";

const CreatorTestimonialCard = (props) => {
  const { testimonial, setShowModal, setTestimonialLink, testimonialSubtitle } =
    props;
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const handleTestimonialClick = (testimonial) => {
    triggerGa({
      category: GA_CATEGORIES.NAVIGATION,
      action: GA_ACTION.CREATOR_CLICK,
      label: testimonial.name,
      label2: testimonial?.earnings_range,
      page: GA_PAGE.CREATOR_EARNINGS,
      screenName: GA_SCREEN_NAME.CREATOR_EARNINGS,
      // eventValue: "Order of testimonial", // TODO
    });
    setShowModal(true);
    setTestimonialLink(testimonial.testimonial_link);
  };
  return (
    <div
      style={{
        backgroundImage: `url(${testimonial.img_url})`,
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
        height: "20.5rem",
      }}
      className="mb-3 flex flex-column justify-between"
    >
      <div></div>
      <div
        style={{
          background: `linear-gradient(0deg, rgba(0, 0, 0, 0.7) 70%, rgba(0, 0, 0, 0))`,
        }}
      >
        <div className="flex justify-between p-4">
          <div>
            <WishlinkTypography
              fontSize="0.875rem"
              fontWeight={700}
              color={colors.grey[0]}
            >
              {testimonial.name}
            </WishlinkTypography>
          </div>
          <div className="flex justify-between">
            <div>
              {testimonial.yt_subscribers ? (
                <div className="flex align-start">
                  <img
                    src={`${IMAGE_PREFIX}youtube-icon.svg`}
                    className="mt-1"
                    alt=""
                  />
                  <div>
                    <WishlinkTypography
                      fontSize="0.875rem"
                      fontWeight={600}
                      color={colors.grey[0]}
                    >
                      {testimonial.yt_subscribers}
                    </WishlinkTypography>
                    <WishlinkTypography
                      fontSize="0.625rem"
                      color={colors.grey[0]}
                    >
                      Subscribers
                    </WishlinkTypography>
                  </div>
                </div>
              ) : (
                <></>
              )}
            </div>
            <div>
              {testimonial.insta_followers ? (
                <div className="flex align-start ml-3">
                  <img
                    src={`${IMAGE_PREFIX}instagram-icon.svg`}
                    className="mt-1"
                    alt=""
                  />
                  <div>
                    <WishlinkTypography
                      fontSize="0.875rem"
                      fontWeight={600}
                      color={colors.grey[0]}
                    >
                      {testimonial.insta_followers}
                    </WishlinkTypography>
                    <WishlinkTypography
                      fontSize="0.625rem"
                      color={colors.grey[0]}
                    >
                      Followers
                    </WishlinkTypography>
                  </div>
                </div>
              ) : (
                <></>
              )}
            </div>
          </div>
        </div>
        <div className="flex justify-between p-4 pt-0">
          <div>
            <WishlinkTypography
              fontSize="1rem"
              fontWeight={700}
              color={colors.grey[0]}
              className="text-left"
            >
              {testimonial.earnings_range}
            </WishlinkTypography>
            <WishlinkTypography
              fontSize="0.625rem"
              color={colors.grey[0]}
              className="text-left"
            >
              Monthly Earnings
            </WishlinkTypography>
          </div>
          <WishlinkButton
            background={colors.misc[500]}
            style={{
              ":hover": {
                bgcolor: colors.misc[500],
              },
              ":disabled": {
                bgcolor: colors.grey[800],
              },
            }}
            onClick={() => {
              handleTestimonialClick(testimonial);
            }}
            disabled={false}
          >
            <WishlinkTypography
              fontSize="0.75rem"
              fontWeight={400}
              color="#006EED"
            >
              Testimonials
            </WishlinkTypography>
            <img
              src={`${IMAGE_PREFIX}arrow-right-blue.svg`}
              style={{ borderRadius: "1rem" }}
              className="ml-1"
              alt="left"
            />
          </WishlinkButton>
        </div>
        <WishlinkTypography
          className="text-left pb-4 pl-4"
          fontSize="0.75rem"
          color={colors.grey[0]}
        >
          {testimonialSubtitle}
        </WishlinkTypography>
      </div>
    </div>
  );
};

export default CreatorTestimonialCard;
