import { useDispatch } from "react-redux";
import { useEffect } from "react";
import {
  updateBarterCreditsForCollection,
  updateBarterLimitForAllCollections,
  updateCreditLimits,
  updateCreditTriggers,
  updateSourcingBrandLimit,
  updateSourcingLimitForAllBrands,
} from "./creditsRedux";
import {
  useLazyGetBarterCreditLimitsForAllCollectionsQuery,
  useLazyGetBarterCreditsForCollectionQuery,
  useLazyGetBrandCreditLimitQuery,
  useLazyGetCreditLimitsForAllBrandsQuery,
  useLazyGetCreditLimitsQuery,
} from "./creditsReduxAPI";
import useDataUpdate from "../../utils/useDataUpdate";

const CreditsDataLayer = () => {
  const dispatch = useDispatch();

  const [triggerGetCreditLimits, resultGetCreditLimits] =
    useLazyGetCreditLimitsQuery();
  const [triggerGetBrandLimit, resultGetBrandLimit] =
    useLazyGetBrandCreditLimitQuery();
  const [
    triggerGetCreditLimitsForAllBrands,
    resultGetCreditLimitsForAllBrands,
  ] = useLazyGetCreditLimitsForAllBrandsQuery();
  const [
    triggerGetBarterCreditLimitsForAllCollections,
    resultGetBarterCreditLimitsForAllCollections,
  ] = useLazyGetBarterCreditLimitsForAllCollectionsQuery();
  const [
    triggerGetBarterCreditsForCollection,
    resultGetBarterCreditsForCollection,
  ] = useLazyGetBarterCreditsForCollectionQuery();

  const trigger = {
    getSourcingCreditLimits: async (apiCallParams) =>
      await triggerGetCreditLimits(apiCallParams),
    getSourcingBrandLimit: async (apiCallParams) =>
      await triggerGetBrandLimit(apiCallParams),
    getBarterCreditsForCollection: async (apiCallParams) =>
      await triggerGetBarterCreditsForCollection(apiCallParams),
    getSourcingCreditLimitsForAllBrands: async (apiCallParams) =>
      await triggerGetCreditLimitsForAllBrands(apiCallParams),
    getBarterCreditLimitsForAllCollections: async (apiCallParams) =>
      await triggerGetBarterCreditLimitsForAllCollections(apiCallParams),
  };

  useEffect(() => {
    dispatch(updateCreditTriggers(trigger));
  }, [trigger]);

  useDataUpdate(resultGetBrandLimit, updateSourcingBrandLimit);
  useDataUpdate(
    resultGetCreditLimitsForAllBrands,
    updateSourcingLimitForAllBrands
  );
  useDataUpdate(
    resultGetBarterCreditsForCollection,
    updateBarterCreditsForCollection
  );
  useDataUpdate(
    resultGetBarterCreditLimitsForAllCollections,
    updateBarterLimitForAllCollections
  );

  useEffect(() => {
    const data = resultGetCreditLimits.data;
    if (data?.success) {
      dispatch(updateCreditLimits(data));
    }
  }, [resultGetCreditLimits]);

  return <></>;
};

export default CreditsDataLayer;
