import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useTheme } from "@mui/material";

import { tokens } from "../../theme";
import WishlinkDrawer from "../../WishlinkComponents/WishlinkDrawer";
import WishlinkTypography from "../../WishlinkComponents/WishlinkTypography";
import { triggerGa } from "../../utils/gaUtils";
import { getDIYGaBasedOnFirstPost } from "../../utils/common";
import {
  GA_ACTION,
  GA_LABEL2,
  GA_SCREEN_NAME,
  IMAGE_PREFIX,
} from "../../utils/constants";
import { showNewPostDrawer, updateIsDiyAllowed } from "../../redux/redux";
import { useEffect } from "react";
import { useWebengageEventTriggerMutation } from "../../redux/reduxAPI.js";

const NewPostDrawer = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const { newPostDrawer } = useSelector((state) => state.apiDataReducer);
  const { authCheckStatus } = useSelector((state) => state.loginReducer);
  const creatorPostFound = authCheckStatus?.data?.data?.creator_post_found;

  useEffect(() => {
    if (location.hash === "#create") {
      dispatch(showNewPostDrawer(true));
    } else {
      dispatch(showNewPostDrawer(false));
    }
  }, [location]);

  function onClickType(type) {
    dispatch(showNewPostDrawer(false));
    dispatch(updateIsDiyAllowed(true));
    navigate(type, { replace: true });
  }
  const [triggerWebengage] = useWebengageEventTriggerMutation();

  return (
    <>
      <WishlinkDrawer
        drawerOpen={newPostDrawer}
        onClose={() => navigate(-1, { replace: true })}
        onOpen={() => {}}
      >
        <div className="p-5">
          <WishlinkTypography
            className="text-center pt-3"
            color={colors.grey[700]}
            fontSize="1rem"
            fontWeight={600}
          >
            What do you want to create today?
          </WishlinkTypography>
          <div
            className="grid text-center mt-5"
            style={{ gap: "0.1rem", gridTemplateColumns: "1fr 1fr" }}
          >
            <div
              style={{
                height: "8.625rem",
                background: colors.indigo[200],
              }}
              className="flex flex-column align-center justify-between border-radius m-1 p-4 pl-7 pr-7"
              onClick={() => {
                triggerGa({
                  category: getDIYGaBasedOnFirstPost(creatorPostFound),
                  action: GA_ACTION.SELECT_CHANNEL,
                  label2: GA_LABEL2.POST_IG,
                  page: getDIYGaBasedOnFirstPost(creatorPostFound),
                  screenName: GA_SCREEN_NAME.DRAWER,
                });
                triggerWebengage({
                  event_source: "create_banner",
                  event_value: "Instagram Post",
                });
                onClickType(`/new-post#instagram`);
              }}
            >
              <img
                style={{ width: "50%" }}
                src={`${IMAGE_PREFIX}instagram.png`}
                alt=""
              />
              <WishlinkTypography
                fontSize="0.875rem"
                fontWeight={500}
                lineHeight="1.312rem"
              >
                Instagram Post Wishlink
              </WishlinkTypography>
            </div>
            <div
              style={{
                height: "8.625rem",
                background: colors.red[600],
              }}
              className="flex flex-column align-center justify-between border-radius m-1 p-4 pl-7 pr-7"
              onClick={() => {
                triggerGa({
                  category: getDIYGaBasedOnFirstPost(creatorPostFound),
                  action: GA_ACTION.SELECT_CHANNEL,
                  label: GA_LABEL2.POST_YT,
                  page: getDIYGaBasedOnFirstPost(creatorPostFound),
                  screenName: GA_SCREEN_NAME.DRAWER,
                });
                triggerWebengage({
                  event_source: "create_banner",
                  event_value: "Youtube Post",
                });
                onClickType(`/new-post#youtube`);
              }}
            >
              <img
                style={{ width: "50%" }}
                src={`${IMAGE_PREFIX}youtube.png`}
                alt=""
              />
              <WishlinkTypography
                fontSize="0.875rem"
                fontWeight={500}
                lineHeight="1.312rem"
              >
                Youtube Post Wishlink
              </WishlinkTypography>
            </div>
            <div
              style={{
                height: "8.625rem",
                background: colors.primary[500],
              }}
              className="flex flex-column align-center justify-between border-radius m-1 p-4 pl-7 pr-7"
              onClick={() => {
                triggerGa({
                  category: getDIYGaBasedOnFirstPost(creatorPostFound),
                  action: GA_ACTION.SELECT_CHANNEL,
                  label: GA_LABEL2.COLLECTION,
                  page: getDIYGaBasedOnFirstPost(creatorPostFound),
                  screenName: GA_SCREEN_NAME.DRAWER,
                });
                triggerWebengage({
                  event_source: "create_banner",
                  event_value: "Product Collection",
                });
                onClickType(`/new-collection`);
              }}
            >
              <img
                style={{ width: "50%" }}
                src={`${IMAGE_PREFIX}collections.png`}
                alt=""
              />
              <WishlinkTypography
                fontSize="0.875rem"
                fontWeight={500}
                lineHeight="1.312rem"
              >
                Products Collection
              </WishlinkTypography>
            </div>
            <div
              style={{
                height: "8.625rem",
                background: colors.green[100],
              }}
              className="flex flex-column align-center justify-between border-radius m-1 p-4 pl-7 pr-7"
              onClick={() => {
                triggerGa({
                  category: getDIYGaBasedOnFirstPost(creatorPostFound),
                  action: GA_ACTION.SELECT_CHANNEL,
                  label: GA_LABEL2.PRODUCT,
                  page: getDIYGaBasedOnFirstPost(creatorPostFound),
                  screenName: GA_SCREEN_NAME.DRAWER,
                });
                triggerWebengage({
                  event_source: "create_banner",
                  event_value: "Single Product",
                });
                onClickType(`/new-product`);
              }}
            >
              <img
                style={{ width: "50%" }}
                src={`${IMAGE_PREFIX}affiliate-products.png`}
                alt=""
              />
              <WishlinkTypography
                fontSize="0.875rem"
                fontWeight={500}
                lineHeight="1.312rem"
              >
                Single Product Wishlink
              </WishlinkTypography>
            </div>
          </div>
        </div>
      </WishlinkDrawer>
    </>
  );
};

export default NewPostDrawer;
