import blitz from "blitzllama-js";
import { useSelector } from "react-redux";

export const useBlitzllama = () => {
  const { milestones } = useSelector((state) => state.appReducer);
  const { authCheckStatus } = useSelector((state) => state.loginReducer);
  const { is_boi_flipkart } = authCheckStatus?.data?.data || {};

  const blitzllamaEnabled =
    milestones?.check_creator_social_login_complete?.achieved &&
    !localStorage.adminToken;

  function initializeBlitzllama() {
    const api_key_staging = "key_BzfHHOqbKfCZufc";
    blitz.init(api_key_staging);
  }
  function setBlitzllamaUserId(user_id) {
    if (blitzllamaEnabled) {
      blitz.createUser(user_id);
    }
  }
  function setCommmonUserProperties(has_sourcing_access, username, name) {
    if (blitzllamaEnabled) {
      const {
        check_app_download_milestone,
        check_creator_profile_milestone,
        check_creator_social_login_complete,
        check_earnings_cross_2k_milestone,
        check_engage_connected,
        check_first_collection_milestone,
        check_first_link_created,
        check_first_link_skipped,
        check_first_post_milestone,
        check_first_sale_milestone,
        check_onboarding_brands_selection_complete,
        milestones_found,
      } = milestones;

      if (milestones_found) {
        const app_download_milestone = check_app_download_milestone?.achieved;
        const creator_profile_milestone =
          check_creator_profile_milestone?.achieved;
        const creator_social_login_complete =
          check_creator_social_login_complete?.achieved;
        const earnings_cross_2k_milestone =
          check_earnings_cross_2k_milestone?.achieved;
        const engage_connected = check_engage_connected?.achieved;
        const first_collection_milestone =
          check_first_collection_milestone?.achieved;
        const first_link_created = check_first_link_created?.achieved;
        const first_link_skipped = check_first_link_skipped?.achieved;
        const first_post_milestone = check_first_post_milestone?.achieved;
        const first_sale_milestone = check_first_sale_milestone?.achieved;
        const onboarding_brands_selection_complete =
          check_onboarding_brands_selection_complete?.achieved;
        blitz.setUserProperties({
          app_download_milestone,
          creator_profile_milestone,
          creator_social_login_complete,
          earnings_cross_2k_milestone,
          engage_connected,
          first_collection_milestone,
          first_link_created,
          first_link_skipped,
          first_post_milestone,
          first_sale_milestone,
          onboarding_brands_selection_complete,
        });
      }
      blitz.setUserProperties({
        has_sourcing_access,
        is_boi_flipkart,
        username,
        name,
      });
    }
  }
  function setUserProperties(properties) {
    if (blitzllamaEnabled) {
      blitz.setUserProperties(properties);
    }
  }
  function triggerEvent(trigger, properties, skip = false) {
    if (blitzllamaEnabled || skip) {
      blitz.triggerEvent(trigger, properties);
    }
  }
  function logout() {
    blitz.logout();
  }

  return {
    initializeBlitzllama,
    setBlitzllamaUserId,
    setCommmonUserProperties,
    setUserProperties,
    triggerEvent,
    logout,
  };
};
