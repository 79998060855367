import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import CreatorEarningsHeader from "../../components/CreatorEarnings/CreatorEarningsHeader";
import CreatorTestimonialsList from "../../components/CreatorEarnings/CreatorTestimonialsList";
import CreatorTestimonialVideo from "../../components/CreatorEarnings/CreatorTestimonialVideo";
import WishlinkModal from "../../WishlinkComponents/WishlinkModal";
import {
  GA_ACTION,
  GA_CATEGORIES,
  GA_SCREEN_NAME,
  GA_PAGE,
  IMAGE_PREFIX,
} from "../../utils/constants";
import { triggerGa } from "../../utils/gaUtils";

const CreatorEarnings = () => {
  const { homePageTriggers } = useSelector((state) => state.homePageReducer);
  const [showModal, setShowModal] = useState(false);
  const [testimonialLink, setTestimonialLink] = useState("");

  useEffect(() => {
    homePageTriggers.fetchCreatorTestimonials();
  }, []);
  const handleClose = () => {
    triggerGa({
      category: GA_CATEGORIES.VIDEO_INTERACTION,
      action: GA_ACTION.CLOSE,
      page: GA_PAGE.CREATOR_EARNINGS,
      screenName: GA_SCREEN_NAME.CREATOR_NAME,
    });
    setTestimonialLink("");
    setShowModal(false);
  };
  return (
    <>
      <CreatorEarningsHeader />
      <CreatorTestimonialsList
        setShowModal={setShowModal}
        setTestimonialLink={setTestimonialLink}
      />
      <WishlinkModal
        showModal={showModal}
        setShowModal={setShowModal}
        showCloseIcon={false}
        width="100%"
      >
        <div
          className="close-stories float-right"
          onClick={() => handleClose()}
        >
          <img src={`${IMAGE_PREFIX}close.png`} alt="" />
        </div>
        <CreatorTestimonialVideo media_url={testimonialLink} />
      </WishlinkModal>
    </>
  );
};

export default CreatorEarnings;
