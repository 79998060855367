import WishlinkTypography from "../../WishlinkComponents/WishlinkTypography";
import CreatorTestimonialVideo from "../../components/CreatorEarnings/CreatorTestimonialVideo";
import PoweredBy from "../../components/Poweredby";
import EngageCommentShare from "../../components/EngageCommentShare/EngageCommentShare";
import { GA_PAGE, IMAGE_PREFIX } from "../../utils/constants";
import HowToConnectSTK from "../../components/EnableEngageSTK/HowToConnectSTK";
import EngageShareToggle from "../../components/Engage/EngageShareToggle";
import { useContext, useEffect } from "react";
import { APIContext } from "../../apiLayer";
import { useSelector } from "react-redux";
import ConnectEngageAccount from "../../components/EnableEngageSTK/ConnectEngageAccount";

const EngageShare = () => {
  const apis = useContext(APIContext);
  const { milestones, milestonesLoading } = useSelector(
    (state) => state.appReducer
  );
  const { creatorData, creatorDataIsLoading } = useSelector(
    (state) => state.apiDataReducer
  );

  const navigateLocked = Boolean(
    !milestones?.check_creator_social_login_complete?.achieved ||
      !milestones?.check_first_post_milestone?.achieved ||
      !creatorData?.data?.data?.is_connected_insta
  );

  const ctkNotConnected = Boolean(
    !navigateLocked && !creatorData?.data?.data?.is_connected_ctk
  );

  useEffect(() => {
    apis.getInstaBasicProfileData(undefined, false);
    apis.getCreatorData(undefined, false);
    apis.getCreatorDashboardMilestonesStatus();
  }, []);

  const engageHeaderDetails = () => {
    return (
      <div
        className="ml-4 mr-4 border-radius"
        style={{ background: "#FFF9FF", border: "1px solid #000" }}
      >
        <ul className="pr-4">
          <li>
            <WishlinkTypography
              fontSize="0.75rem"
              fontWeight={400}
              color="#000"
              className="text-left"
            >
              Get more <b>Shares, Engagement</b> on your content
            </WishlinkTypography>
          </li>
          <li>
            <WishlinkTypography
              fontSize="0.75rem"
              fontWeight={400}
              color="#000"
              className="text-left"
            >
              <b>Automatically sends links</b> to users who share reels to you
            </WishlinkTypography>
          </li>
          <li>
            <WishlinkTypography
              fontSize="0.75rem"
              fontWeight={400}
              color="#000"
              className="text-left"
            >
              Links directly go to <b>primary/general DM</b>
            </WishlinkTypography>
          </li>
        </ul>
      </div>
    );
  };

  return (
    <>
      <EngageCommentShare
        headerBgColor="#FCB594"
        starImage="engage-share-star.png"
        titleText="Send links to users who share your reels in the DM."
        engageHeaderDetails={engageHeaderDetails}
        engageHeaderGif="engage-share.gif"
        page={GA_PAGE.ENGAGE_SHARE}
      />
      {!creatorDataIsLoading &&
      !milestonesLoading &&
      !ctkNotConnected &&
      !navigateLocked ? (
        <div className="pt-3">
          <EngageShareToggle />
        </div>
      ) : (
        <></>
      )}
      <HowToConnectSTK />
      <div className="p-4 border-radius flex justify-center mt-5">
        <div
          style={{ border: "0.5px solid #000", width: "80%" }}
          className="text-center block"
        >
          <CreatorTestimonialVideo
            media_url={`${IMAGE_PREFIX}stk-video.mp4`}
            playing={false}
          />
        </div>
      </div>
      <div style={{ paddingBottom: ctkNotConnected ? "7rem" : "" }}>
        <PoweredBy />
      </div>
      {ctkNotConnected || navigateLocked ? <ConnectEngageAccount /> : <></>}
    </>
  );
};

export default EngageShare;
