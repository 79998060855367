import { Chip } from "@mui/material";

const WishlinkChip = (props) => {
  const { label, onClick, style, className, variant, color, type, id } = props;
  return (
    <>
      <Chip
        label={label}
        onClick={() => onClick(label, type)}
        sx={style}
        className={className}
        variant={variant}
        color={color}
        id={id}
      />
    </>
  );
};

export default WishlinkChip;
