import { useContext, useEffect, useState } from "react";
import { APIContext } from "../../apiLayer";
import WishlinkTypography from "../../WishlinkComponents/WishlinkTypography";
import WishlinkButton from "../../WishlinkComponents/WishlinkButton";
import { useSelector } from "react-redux";

const WishlinkCreatorOauth = () => {
  const apis = useContext(APIContext);
  const [oauthClient, setOauthClient] = useState("");
  const [disableOauthFlow, setDisableOauthFlow] = useState(true);

  const {
    onGoingOauth,
    oauthResponseType,
    oauthRedirectUri,
    oauthClientId,
    oauthCodeChallenge,
    oauthClientName,
    oauthClientNameIsLoading,
  } = useSelector((state) => state.appReducer);

  useEffect(() => {
    if (!onGoingOauth) {
      setDisableOauthFlow(true);
    }
  }, [onGoingOauth]);

  useEffect(() => {
    if (!oauthClientNameIsLoading && oauthClientName) {
      setOauthClient(oauthClientName);
      setDisableOauthFlow(false);
    } else {
      setDisableOauthFlow(true);
    }
  }, [oauthClientNameIsLoading, oauthClientName]);

  useEffect(() => {
    if (oauthClientId) {
      apis.getOauthClientNameData({ clientId: oauthClientId });
    }
  }, [oauthClientId]);

  const handleClose = () => {
    window.location.replace(oauthRedirectUri + "?status=cancelled");
  };

  const handleProceedWithOauth = async () => {
    fetch(
      `https://api.wishlink.com/api/oauth/authorize?response_type=${oauthResponseType}&code_challenge=${oauthCodeChallenge}&code_challenge_method=S256&client_id=${oauthClientId}&redirect_uri=${oauthRedirectUri}`,
      {
        method: "GET",
        headers: {
          Authorization: `Token ${localStorage?.firebaseToken}`,
        },
      }
    )
      .then((res) => {
        res.json().then((decodedRes) => {
          if (decodedRes?.success) {
            window.location.replace(decodedRes?.data?.redirect_url);
          } else {
            window.location.replace(oauthRedirectUri + "?status=failed");
          }
        });
      })
      .catch();
  };

  return (
    <div style={{ height: window.innerHeight }}>
      {disableOauthFlow ? (
        <></>
      ) : (
        <div
          className="flex flex-column align-center w-100 h-100 p-4"
          style={{ paddingTop: "7rem" }}
        >
          <img
            src={"logo192.png"}
            style={{ height: "4rem", marginTop: "1rem" }}
            alt=""
          />
          <div className="flex flex-column mt-4 w-100">
            <WishlinkTypography fontSize="1.25rem" fontWeight={600}>
              {oauthClient} wants to access your <br /> Wishlink account
              <br />
              Make sure you trust them
            </WishlinkTypography>
            <WishlinkTypography
              fontSize="0.9375rem"
              fontWeight={400}
              mt={"1rem"}
              textAlign={"left"}
            >
              Proceeding here allows {oauthClient} to:
            </WishlinkTypography>
            <WishlinkTypography
              fontSize="0.9375rem"
              fontWeight={400}
              textAlign={"left"}
            >
              • See basic wishlink account information
            </WishlinkTypography>
            <WishlinkTypography
              fontSize="0.9375rem"
              fontWeight={400}
              textAlign={"left"}
            >
              • Create wishlinks on your account
            </WishlinkTypography>
          </div>
          <div className="flex w-100 justify-between mt-6">
            <div
              style={{
                width: "50%",
                borderRadius: "0.5rem",
                margin: "0.5rem",
              }}
            >
              <WishlinkButton
                onClick={() => handleClose()}
                border="0.0625rem solid #E38125"
              >
                <WishlinkTypography
                  fontSize={"0.875rem"}
                  fontWeight={500}
                  color="#E38125"
                >
                  Cancel
                </WishlinkTypography>
              </WishlinkButton>
            </div>
            <div
              style={{
                width: "50%",
                borderRadius: "0.5rem",
                margin: "0.5rem",
              }}
            >
              <WishlinkButton
                background={"#E38125"}
                onClick={handleProceedWithOauth}
              >
                <WishlinkTypography
                  fontSize={"0.875rem"}
                  fontWeight={500}
                  color="#FFFFFF"
                >
                  Agree
                </WishlinkTypography>
              </WishlinkButton>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default WishlinkCreatorOauth;
