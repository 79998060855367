import React, { useEffect, useState } from "react";
import { useTheme } from "@mui/material";
import { useNavigate, useLocation } from "react-router-dom";

import { tokens } from "../../theme";
import "./Overlay.css";
import {
  FRONTEND_ASSETS_BASE_URL,
  GA_ACTION,
  GA_CATEGORIES,
  GA_LABEL,
  GA_PAGE,
  PAGE_URLS,
} from "../../utils/constants";
import WishlinkTypography from "../../WishlinkComponents/WishlinkTypography";
import WishlinkButton from "../../WishlinkComponents/WishlinkButton";
import { triggerGa } from "../../utils/gaUtils";

function BrandPageOverlay() {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const navigate = useNavigate();
  const location = useLocation();
  const [isOverlayOpen, setIsOverlayOpen] = useState(false);

  useEffect(() => {
    setTimeout(() => {
      let loginOverlayCount =
        parseInt(localStorage.getItem("loginOverlayCount")) || 0;
      if (loginOverlayCount < 3) setIsOverlayOpen(true);
      localStorage.setItem("loginOverlayCount", loginOverlayCount + 1);
    }, 5000);
  }, []);

  const onClickCloseOverlay = () => {
    setIsOverlayOpen(false);
    triggerGa({
      category: GA_CATEGORIES.USER_INTERACTION,
      action: GA_ACTION.ICON_CLICK,
      label: GA_LABEL.LOGIN_CLOSE,
      page: GA_PAGE.PUBLIC_BRANDS,
      screenName: location.pathname,
    });
  };

  const onClickLoginBtn = () => {
    navigate(PAGE_URLS.FIREBASELOGIN + `?utm_source=publicbrands`);
    triggerGa({
      category: GA_CATEGORIES.NAVIGATION,
      action: GA_ACTION.BUTTON_CLICK,
      label: GA_LABEL.LOGIN,
      page: GA_PAGE.PUBLIC_BRANDS,
      screenName: location.pathname,
    });
  };

  return (
    <div className="main-content">
      {isOverlayOpen && (
        <div className="overlay-content">
          <div className="close-btn" onClick={onClickCloseOverlay}>
            &times;
          </div>
          <WishlinkButton
            background={colors.grey[0]}
            style={{
              ":hover": {
                bgcolor: colors.grey[0],
              },
              ":disabled": {
                bgcolor: colors.grey[800],
              },
            }}
            borderRadius="3rem"
            width="70%"
            height="2.3rem"
            className="pt-3 mt-3 mb-3"
            onClick={() => onClickLoginBtn()}
          >
            <WishlinkTypography fontSize="0.75rem">
              wishlink.com/
            </WishlinkTypography>
            <WishlinkTypography
              color="rgba(227, 129, 37, 0.6)"
              fontSize="0.75rem"
            >
              yourname
            </WishlinkTypography>
            <div className="rectangle-wrapper ml-2">
              <img
                style={{ width: "1.5rem" }}
                alt="Rectangle"
                src={FRONTEND_ASSETS_BASE_URL + "creator-signup-animation.gif"}
              />
            </div>
          </WishlinkButton>
          <WishlinkTypography
            color={colors.grey[0]}
            fontSize="0.875rem"
            className="mt-2"
          >
            Are you a creator?
          </WishlinkTypography>
          <WishlinkTypography
            color={colors.grey[0]}
            fontSize="0.75rem"
            className="mt-2"
          >
            Monitize your content with Wishlink
          </WishlinkTypography>
        </div>
      )}
    </div>
  );
}

export default BrandPageOverlay;
