import { Switch } from "@mui/material";

const WishlinkSwitch = (props) => {
  const { checked, onChange, disabled, color = "warning" } = props;
  return (
    <>
      <Switch
        checked={checked}
        onChange={onChange}
        disabled={disabled}
        inputProps={{ "aria-label": "controlled" }}
        color={color}
      />
    </>
  );
};

export default WishlinkSwitch;
