import React, { useEffect, useState } from "react";
import {
  GA_ACTION,
  GA_CATEGORIES,
  GA_LABEL,
  GA_PAGE,
  GA_SCREEN_NAME,
} from "../../utils/constants";
import {
  getAssetUrl,
  getResponsiveWidth,
  triggerGoBackGa,
} from "../../utils/common";
import { useDispatch, useSelector } from "react-redux";
import { Box, Grid, Typography } from "@mui/material";
import ReactPlayer from "react-player";
import { useNavigate } from "react-router-dom";
import { triggerGa } from "../../utils/gaUtils";
import { updatePageNameAndScreenName } from "../../redux/redux";
import "../../animation.css";

const checkBackground = "check-grid-background.png";
const swipeVector = "swipe-vector.svg";
const maximiseEarningIcon = "maximize-earning-4x.png";
const ytVideosIcon = "yt-video-icon-white.svg";
const infoIcon = "info-icon-white.svg";
const instagramWhiteIconUrl = "instagram-white-icon.svg";
const instaCard1 = "monetize-insta-card-1.svg";
const instaCard2 = "monetize-insta-card-2.svg";
const instaCard3 = "monetize-insta-card-3.svg";
const instaCard4 = "monetize-insta-card-4.svg";
const ytCard1 = "monetize-yt-card-1.svg";
const ytCard2 = "monetize-yt-card-2.svg";
const leftArrow = "arrow-left-light.svg";

const instagramExplainerCards = [
  {
    iconUrl: instaCard1,
    iconBgColor: "#7E5AA6",
    titleText: "Share product Wishlinks on Stories",
    subTitleText: "",
    bgColor: "#9F71D1",
  },
  {
    iconUrl: instaCard2,
    iconBgColor: "#9C2022",
    titleText: "Organize your",
    subTitleText: "Highlights",
    bgColor: "#B4623F",
  },
  {
    iconUrl: instaCard3,
    iconBgColor: "#CB8E34",
    titleText: "Activate Wishlink",
    subTitleText: "Engage",
    bgColor: "#FEAA66",
  },
  {
    iconUrl: instaCard4,
    iconBgColor: "#423D7E",
    titleText: "Add your Wishlink Shop in Bio",
    subTitleText: "",
    bgColor: "#6049FC",
  },
];

const youtubeExplainerCards = [
  {
    iconUrl: ytCard1,
    iconBgColor: "#B96137",
    titleText: "Add Wishlinks in pinned comments",
    bgColor: "#EE9E78",
  },
  {
    iconUrl: ytCard2,
    iconBgColor: "#AD0000",
    titleText: "Provide detailed description",
    bgColor: "#FF0000",
  },
];

const HeadingTypography = (props) => {
  return (
    <Typography
      sx={{
        color: "#FFFFFF",
        zIndex: 100,
        fontWeight: 500,
        fontSize: "1rem",
        lineHeight: "1.5rem",
        marginBottom: "-0.5rem",
      }}
      className="right-transition"
    >
      {props.children}
    </Typography>
  );
};

const Banner = (props) => {
  return (
    <div style={{ background: "#000000" }}>
      <div
        style={{
          display: "flex",
          opacity: "1 !important",
          zIndex: 100,
          justifyContent: "center",
          alignItems: "center",
          marginTop: "2rem",
          background:
            "linear-gradient(148.05deg, rgba(255, 255, 255, 0.4) 0%, rgba(255, 255, 255, 0) 100%, rgba(255, 255, 255, 0) 100%)",
          filter: "drop-shadow(0px 2px 4px rgba(0, 0, 0, 0.2))",
          backdropFilter: "blur(20px)",
          borderRadius: "0.75rem",
          padding: "0.75rem",
        }}
      >
        <img
          src={getAssetUrl(props.iconUrl)}
          alt="bulb"
          style={{ height: "1.375rem" }}
        />
        <Typography
          sx={{
            color: "#F2F2F2",
            fontWeight: 500,
            fontSize: "0.8125rem",
            lineHeight: "1.25rem",
            marginLeft: "0.5rem",
          }}
        >
          {props.children}
        </Typography>
      </div>
    </div>
  );
};

const AttentionTypography = (props) => {
  return (
    <Typography
      sx={{
        fontWeight: 400,
        fontSize: "0.8125rem",
        lineHeight: "1.25rem",
        color: "#F2F2F2",
        textAlign: "left",
        marginLeft: "0.5rem",
      }}
    >
      {props.children}
    </Typography>
  );
};

const Attention = (props) => {
  return (
    <div style={{ display: "flex", alignItems: "center", marginTop: "2rem" }}>
      <img src={getAssetUrl(infoIcon)} alt="info-icon" />
      <AttentionTypography>{props.children}</AttentionTypography>
    </div>
  );
};

const CategoryHeadingTypography = (props) => {
  return (
    <Typography
      sx={{
        fontWeight: 500,
        fontSize: "1rem",
        lineHeight: "1.5rem",
        color: "#FFFFFF",
        marginTop: "1.5rem",
      }}
      className="right-transition"
    >
      {props.children}
    </Typography>
  );
};

const ExplainerCard = (props) => {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        gap: "0.625rem",
        padding: "1rem",
        alignItems: "center",
        justifyContent: "space-between",
        background: props.bgColor,
        boxShadow: "0rem 0.25rem 0.75rem rgba(0, 0, 0, 0.24)",
        borderRadius: "0.5rem",
      }}
    >
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          background: props.iconBgColor,
          height: "3rem",
          width: "3rem",
          borderRadius: "1.5rem",
        }}
      >
        <img src={getAssetUrl(props.iconUrl)} alt="icon" />
      </div>
      <Typography
        sx={{
          color: "#FFFFFF",
          fontWeight: 500,
          fontSize: "0.75rem",
          lineHeight: "1.3125rem",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        {props.titleText}
        <br />
        {props.subTitleText}
      </Typography>
    </div>
  );
};

const CardRenderer = (props) => {
  const cards = props.cards;
  return (
    <Grid container sx={{ zIndex: 100, marginTop: "0.01rem" }} spacing={"1rem"}>
      {cards.map((card, idx) => (
        <Grid
          item
          xs={6}
          key={idx}
          className={idx % 2 === 0 ? "right-transition" : "left-transition"}
        >
          <ExplainerCard
            titleText={card.titleText}
            iconUrl={card.iconUrl}
            bgColor={card.bgColor}
            iconBgColor={card.iconBgColor}
            subTitleText={card.subTitleText}
          />
        </Grid>
      ))}
    </Grid>
  );
};

const PlaybookMonetize = React.memo(function PlaybookMonetize(props) {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [responsiveWidth, setResponsiveWidth] = useState(getResponsiveWidth);
  const { pageName, screenName } = useSelector((state) => state.appReducer);

  useEffect(() => {
    const handleScreenResize = () => {
      setResponsiveWidth(getResponsiveWidth());
    };

    window.addEventListener("resize", handleScreenResize);
    return () => window.removeEventListener("resize", handleScreenResize);
  }, []);

  let isInstaPlaying = false;
  let isYTPlaying = false;

  const [height, setHeight] = useState(window.innerHeight);

  useEffect(() => {
    setHeight(window.innerHeight);
  }, [window.innerHeight]);

  useEffect(() => {
    dispatch(
      updatePageNameAndScreenName({
        pageName: GA_PAGE.SELLING_PLAYBOOK,
        screenName: GA_SCREEN_NAME.SELLING_PLAYBOOK_EARNINGS,
      })
    );
  }, []);

  return (
    <div
      style={{
        backgroundColor: "#000000",
        width: responsiveWidth,
        margin: "auto",
        minHeight: height,
        paddingBottom: "0.5rem",
      }}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          width: responsiveWidth,
          alignItems: "center",
          minHeight: height,
        }}
      >
        <div
          style={{
            position: "absolute",
            width: responsiveWidth,
            zIndex: 0,
          }}
        >
          <img
            src={getAssetUrl(checkBackground)}
            style={{ width: "100%" }}
            alt="check-background"
          />
        </div>
        <div
          style={{
            padding: "1rem",
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-start",
            boxSizing: "border-box",
            width: "100%",
            zIndex: 100,
          }}
        >
          <img
            src={getAssetUrl(leftArrow)}
            alt="left"
            onClick={() => {
              triggerGoBackGa(pageName, screenName);
              navigate(-1);
            }}
          />
        </div>
        <div
          style={{
            padding: "1.5rem",
            zIndex: 10000000000,
            width: "100%",
            boxSizing: "border-box",
          }}
        >
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              zIndex: 100,
            }}
          >
            <img
              src={getAssetUrl(maximiseEarningIcon)}
              alt="icon"
              style={{
                width: "4rem",
                marginBottom: "0.5rem",
              }}
            />
          </div>
          <HeadingTypography>Maximize your monetization</HeadingTypography>
          <img src={getAssetUrl(swipeVector)} alt="vector" />
          <Banner iconUrl={instagramWhiteIconUrl}>On Instagram</Banner>
          <CategoryHeadingTypography>
            Watch this video to learn & earn more
          </CategoryHeadingTypography>
          <Box
            sx={{
              width: "100% !important",
              boxSizing: "border-box",
              objectFit: "contain !important",
              borderRadius: "0.5rem",
              margin: "auto",
              position: "relative",
              top: 0,
              marginTop: "1rem",
              maxHeight: "100%",
              height: "9.75rem",
            }}
          >
            <ReactPlayer
              url={"https://www.youtube.com/embed/q6JN_CRC8SE"}
              playing={isInstaPlaying}
              className="video-player"
              onPlay={() => {
                triggerGa({
                  category: GA_CATEGORIES.USER_EDUCATION,
                  action: GA_ACTION.WATCH_VIDEO,
                  label: GA_LABEL.IG,
                  page: GA_PAGE.SELLING_PLAYBOOK,
                  screenName: GA_SCREEN_NAME.SELLING_PLAYBOOK_EARNINGS,
                });
              }}
              style={{ width: "100%" }}
              controls
              loop={true}
            />
          </Box>
          <Attention>Things to do to boost your earnings</Attention>
          <CardRenderer cards={instagramExplainerCards} />
          <img
            src={getAssetUrl(swipeVector)}
            alt="vector"
            style={{ marginTop: "2rem" }}
          />
          <Banner iconUrl={ytVideosIcon}>On YouTube</Banner>
          <CategoryHeadingTypography>
            Watch this video to learn & earn more
          </CategoryHeadingTypography>
          <Box
            sx={{
              width: "100% !important",
              boxSizing: "border-box",
              objectFit: "contain !important",
              borderRadius: "0.5rem",
              margin: "auto",
              position: "relative",
              top: 0,
              marginTop: "1rem",
              maxHeight: "100%",
            }}
          >
            <ReactPlayer
              url={"https://www.youtube.com/embed/XanFDif-Quo"}
              playing={isYTPlaying}
              className="video-player"
              style={{ width: "100%" }}
              controls
              onPlay={() => {
                triggerGa({
                  category: GA_CATEGORIES.USER_EDUCATION,
                  action: GA_ACTION.WATCH_VIDEO,
                  label: GA_LABEL.YT,
                  page: GA_PAGE.SELLING_PLAYBOOK,
                  screenName: GA_SCREEN_NAME.SELLING_PLAYBOOK_EARNINGS,
                });
              }}
              loop={true}
            />
          </Box>
          <Attention>Things to do to boost your earnings</Attention>
          <CardRenderer cards={youtubeExplainerCards} />
        </div>
      </div>
    </div>
  );
});

export default PlaybookMonetize;
