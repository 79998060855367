export const EVENTS = {
  session_start: "session_start",
  engage_activation: "engage_activation",
  engage_exit: "engage_exit",
  engage_exit_after_one_month: "engage_exit_after_one_month",
  post_detail_page_exit: "post_detail_page_exit",
  mom_page_exit: "mom_page_exit",
  past_payout_detail_page_exit: "past_payout_detail_page_exit",
  pending_commission_page_exit: "pending_commission_page_exit",
  link_copied: "link_copied",
  place_order_request_complete: "place_order_request_complete",
  link_creation: "link_creation",
  banner_click: "banner_click",
  analytics_post_list_seen: "analytics_post_list_seen",
  payouts_page_view: "payouts_page_view",
};
