import { useSelector } from "react-redux";
import FooterPreOnboarding from "../FooterPreOnboarding";
import {
  CREATOR_EXPERIMENT_TYPE,
  SOCIAL_LOGIN_DATE_CONSTANT,
} from "../../utils/constants";
import { Suspense, lazy, useEffect, useState } from "react";
const Footer = lazy(() => /* webpackChunkName: "Footer" */ import("../Footer"));

const FooterContainer = () => {
  const { milestones } = useSelector((state) => state.appReducer);
  const { creatorData } = useSelector((state) => state.apiDataReducer);
  const [showOnboardingFooter, setShowOnboardingFooter] = useState(false);
  const [showFullFledgedFooter, setShowFullFledgedFooter] = useState(false);

  const firstSaleAchieved =
    milestones.milestones_found &&
    milestones.check_first_sale_milestone?.achieved &&
    milestones.check_first_sale_milestone?.presented_on_dashboard;

  const hasUserCreatedPostButNoSaleDone =
    milestones.check_creator_social_login_complete?.achieved === true &&
    milestones.check_creator_social_login_complete?.achieved_on <
      SOCIAL_LOGIN_DATE_CONSTANT &&
    milestones.check_first_post_milestone?.achieved === true &&
    !milestones.check_first_sale_milestone?.achieved;

  useEffect(() => {
    if (
      firstSaleAchieved ||
      hasUserCreatedPostButNoSaleDone ||
      creatorData?.data?.data?.experiment_type === CREATOR_EXPERIMENT_TYPE.TEST
    ) {
      setShowFullFledgedFooter(true);
      setShowOnboardingFooter(false);
    } else if (
      milestones?.milestones_found &&
      (milestones?.check_first_link_created?.achieved ||
        milestones?.check_first_link_skipped?.achieved)
    ) {
      setShowFullFledgedFooter(false);
      setShowOnboardingFooter(true);
    }
  }, [milestones, creatorData]);
  return (
    <>
      {showOnboardingFooter ? <FooterPreOnboarding /> : <></>}
      {!!showFullFledgedFooter && (
        <Suspense fallback={<></>}>
          <Footer />
        </Suspense>
      )}
    </>
  );
};

export default FooterContainer;
