import { useContext, useEffect, useState } from "react";
import { useSelector } from "react-redux";

import WishlinkSwitch from "../../WishlinkComponents/WishlinkSwitch";
import WishlinkTypography from "../../WishlinkComponents/WishlinkTypography";
import { triggerGa } from "../../utils/gaUtils";
import {
  ENGAGE_UPDATE_TYPES,
  GA_ACTION,
  GA_CATEGORIES,
  GA_LABEL,
  GA_LABEL2,
  GA_PAGE,
  GA_SCREEN_NAME,
} from "../../utils/constants";
import { APIContext } from "../../apiLayer";

const EngageShareToggle = () => {
  const apis = useContext(APIContext);
  const { instaBasicProfileData, instaBasicProfileLoading } = useSelector(
    (state) => state.engageReducer
  );
  const [toggleDisabledByThreshold, setToggleDisabledByThreshold] =
    useState(true);
  const [checked, setChecked] = useState(false);

  useEffect(() => {
    setChecked(instaBasicProfileData.stkStatus);
    setToggleDisabledByThreshold(!instaBasicProfileData.allowCtkToggle);
  }, [instaBasicProfileData]);

  async function onClickUpdateSTKToggle() {
    triggerGa({
      category: GA_CATEGORIES.USER_INTERACTION,
      action: GA_ACTION.TOGGLE,
      label: GA_LABEL.ENGAGE_COMMENTS_TOGGLE,
      label2: checked ? GA_LABEL2.OFF : GA_LABEL2.ON,
      page: GA_PAGE.ENGAGE,
      screenName: GA_SCREEN_NAME.ENGAGE_SETTING,
    });
    const body = {
      updateType: ENGAGE_UPDATE_TYPES.STK,
      status: !checked,
    };
    await apis.updateCTKsettings(body);
  }

  return (
    <div className="pl-4 pr-4">
      <div className="flex justify-between align-center">
        <WishlinkTypography fontSize="1rem" fontWeight={500} color="#333">
          Engage Share
        </WishlinkTypography>
        <WishlinkSwitch
          checked={checked}
          disabled={instaBasicProfileLoading || toggleDisabledByThreshold}
          onChange={() => onClickUpdateSTKToggle()}
        />
      </div>
      <WishlinkTypography
        className="text-left pt-2"
        fontSize="0.75rem"
        fontWeight={400}
        color="#666"
      >
        Send automatic DMs with exact product links to everyone who share your
        reel in DM with you.
      </WishlinkTypography>
    </div>
  );
};

export default EngageShareToggle;
