import { useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import { showNewPostDrawer } from "../../redux/redux";
import WishlinkTypography from "../../WishlinkComponents/WishlinkTypography";
import {
  GA_ACTION,
  GA_CATEGORIES,
  GA_LABEL,
  IMAGE_PREFIX,
  PAGE_URLS,
} from "../../utils/constants";
import { triggerGa } from "../../utils/gaUtils";

const FooterPreOnboarding = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const { pageName, screenName } = useSelector((state) => state.appReducer);

  useEffect(() => {
    if (location.hash === "#create") {
      dispatch(showNewPostDrawer(true));
    } else {
      dispatch(showNewPostDrawer(false));
    }
  }, [location]);

  const navigationItems = [
    {
      url: PAGE_URLS.HOME,
      gaLabel: GA_LABEL.HOME,
      icon:
        location.pathname === PAGE_URLS.HOME
          ? `${IMAGE_PREFIX}onboarding-2-home-selected.png`
          : `${IMAGE_PREFIX}onboarding-2-home.png`,
      fontColor: location.pathname === PAGE_URLS.HOME ? "#DF472C" : "#808080",
      isVisible: true,
      title: "HOME",
      navigateRoute: PAGE_URLS.HOME,
    },
    {
      url: PAGE_URLS.SHOP,
      gaLabel: GA_LABEL.SHOP,
      icon:
        location.pathname === PAGE_URLS.SHOP
          ? `${IMAGE_PREFIX}onboarding-2-shop-selected.png`
          : `${IMAGE_PREFIX}onboarding-2-shop.png`,
      fontColor: location.pathname === PAGE_URLS.SHOP ? "#DF472C" : "#808080",
      isVisible: true,
      title: "MY SHOP",
      navigateRoute: PAGE_URLS.SHOP,
    },
    {
      url: PAGE_URLS.ANALYTICS,
      gaLabel: GA_LABEL.ANALYTICS,
      icon:
        location.pathname === PAGE_URLS.ANALYTICS
          ? `${IMAGE_PREFIX}onboarding-2-analytics-selected.png`
          : `${IMAGE_PREFIX}onboarding-2-analytics.png`,
      fontColor:
        location.pathname === PAGE_URLS.ANALYTICS ? "#DF472C" : "#808080",
      isVisible: true,
      title: "ANALYTICS",
    },
  ];
  const handleNavigation = (url, gaLabel) => {
    triggerGa({
      category: GA_CATEGORIES.NAVIGATION,
      action: GA_ACTION.BOTTOM_NAV_CLICK,
      label: gaLabel,
      page: pageName,
      screenName: screenName,
    });
    navigate(url);
  };
  return (
    <div
      className="fixed footer"
      style={{
        bottom: "0rem",
        background: "#FFFFFF",
        height: "5rem",
        zIndex: 1000,
      }}
    >
      <div
        className="flex justify-around align-center h-100"
        style={{ boxShadow: "0px -1px 4px 0px rgba(0, 0, 0, 0.10)" }}
      >
        {navigationItems.map((item, idx) => {
          return item.isVisible ? (
            <div
              className="flex flex-column align-center justify-center w-100 h-100"
              onClick={() => handleNavigation(item.url, item.gaLabel)}
            >
              <img
                className="mb-1"
                src={item.icon}
                style={{ height: "1rem" }}
                alt=""
              />
              <WishlinkTypography
                fontSize="0.625rem"
                fontWeight={500}
                lineHeight="0.9375rem"
                color={item.fontColor}
              >
                {item.title}
              </WishlinkTypography>
            </div>
          ) : (
            <></>
          );
        })}
      </div>
    </div>
  );
};

export default FooterPreOnboarding;
