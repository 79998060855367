import { useDispatch } from "react-redux";
import { useEffect } from "react";
import {
  useLazyFetchBrandDetailsByIdQuery,
  useLazyFetchCreatorTestimonialsQuery,
  useLazyFetchCreatorHghlightsQuery,
  useUpdateCreatorStoryHighlightsMutation,
  useUpdateCreatorEventTrackingLogsMutation,
  useLazyFetchCreatorAllTimeSalesQuery,
  useLazyGetCreatorActiveGtmsQuery,
  useLazyGetCreatorWarningsQuery,
} from "./HomePageReduxAPI";
import {
  updateHomePageTriggers,
  updateMeeshoBrandDetails,
  updateTestimonials,
  updateStoryHighlights,
  updateCreatorAllTimeEarnings,
  updateCreatorActiveGtms,
  updateCreatorActiveGtmsLoading,
  updateCreatorWarningData,
} from "./HomePageRedux";

import { getDataPointLoadingValue } from "../utils/common";

const HomePageDataLayer = () => {
  const dispatch = useDispatch();

  const [triggerFetchDetailsById, resultFetchDetailsById] =
    useLazyFetchBrandDetailsByIdQuery();
  const [triggerFetchCreatorTestimonials, resultFetchCreatorTestimonials] =
    useLazyFetchCreatorTestimonialsQuery();
  const [triggerFetchCreatorHighlights, resultFetchCreatorHighlights] =
    useLazyFetchCreatorHghlightsQuery();
  const [triggerUpdateCreatorStoryHighlights] =
    useUpdateCreatorStoryHighlightsMutation();
  const [triggerUpdateCreatorEventTrackingLogs] =
    useUpdateCreatorEventTrackingLogsMutation();
  const [triggerFetchCreatorAllTimeSales, resultFetchCreatorAllTimeSales] =
    useLazyFetchCreatorAllTimeSalesQuery();
  const [triggerGetCreatorActiveGtms, resultGetCreatorActiveGtms] =
    useLazyGetCreatorActiveGtmsQuery();
  const [triggerGetCreatorWarnings, resultGetCreatorWarnings] =
    useLazyGetCreatorWarningsQuery();

  const trigger = {
    fetchBrandDetailsById: async (apiCallParams) =>
      await triggerFetchDetailsById(apiCallParams),
    fetchCreatorTestimonials: async (apiCallParams) =>
      await triggerFetchCreatorTestimonials(apiCallParams),
    fetchCreatorHighlights: async (apiCallParams) =>
      await triggerFetchCreatorHighlights(apiCallParams),
    updateCreatorStoryDetails: async (apiCallParams) =>
      await triggerUpdateCreatorStoryHighlights(apiCallParams),
    updateCreatorEventTrackingLogs: async (apiCallParams) =>
      await triggerUpdateCreatorEventTrackingLogs(apiCallParams),
    fetchCreatorAllTimeSales: async (apiCallParams) =>
      await triggerFetchCreatorAllTimeSales(apiCallParams),
    getCreatorActiveGtms: async (apiCallParams) =>
      await triggerGetCreatorActiveGtms(apiCallParams, false),
    getCreatorWarnings: async (apiCallParams) =>
      await triggerGetCreatorWarnings(apiCallParams, true),
  };

  useEffect(() => {
    dispatch(updateHomePageTriggers(trigger));
  }, []);

  useEffect(() => {
    if (resultFetchDetailsById?.data?.success === true) {
      dispatch(updateMeeshoBrandDetails(resultFetchDetailsById));
    }
  }, [resultFetchDetailsById]);

  useEffect(() => {
    if (resultFetchCreatorTestimonials?.data?.success === true) {
      dispatch(updateTestimonials(resultFetchCreatorTestimonials));
    }
  }, [resultFetchCreatorTestimonials]);

  useEffect(() => {
    if (resultFetchCreatorHighlights?.data?.success === true) {
      dispatch(updateStoryHighlights(resultFetchCreatorHighlights));
    }
  }, [resultFetchCreatorHighlights]);

  useEffect(() => {
    if (resultFetchCreatorAllTimeSales?.data?.success === true) {
      dispatch(updateCreatorAllTimeEarnings(resultFetchCreatorAllTimeSales));
    }
  }, [resultFetchCreatorAllTimeSales]);

  useEffect(() => {
    dispatch(
      updateCreatorActiveGtmsLoading(
        getDataPointLoadingValue(resultGetCreatorActiveGtms)
      )
    );
    if (resultGetCreatorActiveGtms?.data?.success === true) {
      dispatch(updateCreatorActiveGtms(resultGetCreatorActiveGtms.data.data));
    }
  }, [resultGetCreatorActiveGtms]);

  useEffect(() => {
    if (resultGetCreatorWarnings?.data?.success === true) {
      dispatch(updateCreatorWarningData(resultGetCreatorWarnings));
    }
  }, [resultGetCreatorWarnings]);

  return <></>;
};

export default HomePageDataLayer;
