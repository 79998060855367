import { useDispatch } from "react-redux";
import { useEffect } from "react";
import {
  updateBrandsPageTriggers,
  updateOnboardingBrands,
  updateAmazonEarningsCard,
  updateAmazonEarningsLoading,
} from "./BrandsPageRedux";
import {
  useLazyFetchOnboardingBrandsQuery,
  useUpdateCreatorOnboardingBrandsMutation,
  useLazyFetchAmazonEarningsQuery,
} from "./BrandsPageReduxAPI";
import { getDataPointLoadingValue } from "../utils/common";

const BrandsPageDataLayer = () => {
  const dispatch = useDispatch();

  const [triggerFetchOnboardedBrands, resultFetchOnboardedBrands] =
    useLazyFetchOnboardingBrandsQuery();
  const [triggerUpdateCreatorOnboardingBrands] =
    useUpdateCreatorOnboardingBrandsMutation();
  const [triggerFetchAmazonEarningsData, resultFetchAmazonEarningsData] =
    useLazyFetchAmazonEarningsQuery();

  const trigger = {
    fetchOnboardedBrands: async (apiCallParams) =>
      await triggerFetchOnboardedBrands(apiCallParams),
    updateCreatorOnboardingBrands: async (apiCallParams) =>
      await triggerUpdateCreatorOnboardingBrands(apiCallParams),
    fetchAmazonEarningsData: async (apiCallParams) =>
      await triggerFetchAmazonEarningsData(apiCallParams),
  };

  useEffect(() => {
    dispatch(updateBrandsPageTriggers(trigger));
  }, []);

  useEffect(() => {
    if (resultFetchOnboardedBrands?.data?.success === true) {
      dispatch(updateOnboardingBrands(resultFetchOnboardedBrands));
    }
  }, [resultFetchOnboardedBrands]);

  useEffect(() => {
    dispatch(
      updateAmazonEarningsLoading(
        getDataPointLoadingValue(resultFetchAmazonEarningsData)
      )
    );
    if (resultFetchAmazonEarningsData?.data?.success === true) {
      dispatch(
        updateAmazonEarningsCard(resultFetchAmazonEarningsData?.data?.data)
      );
    }
  }, [resultFetchAmazonEarningsData]);

  return <></>;
};

export default BrandsPageDataLayer;
