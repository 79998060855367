import { useEffect, useState, useContext } from "react";
import { useSelector } from "react-redux";
import { useTheme } from "@mui/material";

import { tokens } from "../../theme";
import { APIContext } from "../../apiLayer";
import WishlinkSwitch from "../../WishlinkComponents/WishlinkSwitch";
import WishlinkTypography from "../../WishlinkComponents/WishlinkTypography";
import { triggerGa } from "../../utils/gaUtils";
import {
  ENGAGE_UPDATE_TYPES,
  GA_ACTION,
  GA_CATEGORIES,
  GA_LABEL,
  GA_LABEL2,
  GA_PAGE,
  GA_SCREEN_NAME,
} from "../../utils/constants";

const EngageCommentToggle = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const apis = useContext(APIContext);
  const [checked, setChecked] = useState(false);
  const [toggleDisabledByThreshold, setToggleDisabledByThreshold] =
    useState(true);
  const [disabledForTime, setDisabledForTime] = useState("");
  const [disabledForReason, setDisabledForReason] = useState("");

  const { instaBasicProfileData, instaBasicProfileLoading } = useSelector(
    (state) => state.engageReducer
  );

  useEffect(() => {
    apis.getInstaBasicProfileData(undefined, false);
  }, []);

  useEffect(() => {
    setChecked(instaBasicProfileData.commentsStatus);
    setToggleDisabledByThreshold(!instaBasicProfileData.allowCommentsToggle);
    setDisabledForTime(instaBasicProfileData.comments_paused_for);
    setDisabledForReason(instaBasicProfileData.comments_paused_reason);
  }, [instaBasicProfileData]);

  async function onClickUpdateCTKCommentsToggle() {
    triggerGa({
      category: GA_CATEGORIES.USER_INTERACTION,
      action: GA_ACTION.TOGGLE,
      label: GA_LABEL.ENGAGE_COMMENTS_TOGGLE,
      label2: checked ? GA_LABEL2.OFF : GA_LABEL2.ON,
      page: GA_PAGE.ENGAGE,
      screenName: GA_SCREEN_NAME.ENGAGE_SETTING,
    });
    const body = {
      updateType: ENGAGE_UPDATE_TYPES.COMMENT,
      status: !checked,
    };
    await apis.updateCTKsettings(body);
  }

  return (
    <div className="pl-4 pr-4 pb-4">
      <div className="flex align-center justify-between">
        <WishlinkTypography
          fontSize="1rem"
          fontWeight={500}
          color={colors.grey[100]}
          lineHeight="1.5rem"
        >
          Engage Comment Replies
        </WishlinkTypography>
        <WishlinkSwitch
          checked={checked}
          disabled={instaBasicProfileLoading || toggleDisabledByThreshold}
          onChange={() => onClickUpdateCTKCommentsToggle()}
        />
      </div>
      <WishlinkTypography
        fontSize="0.75rem"
        fontWeight={400}
        color={colors.grey[500]}
        lineHeight="1.125rem"
        className="text-left"
      >
        Send automatic DMs with exact product links to everyone who share your
        reel in DM with you.
      </WishlinkTypography>
      {!instaBasicProfileLoading &&
        toggleDisabledByThreshold &&
        disabledForReason &&
        disabledForTime && (
          <>
            <WishlinkTypography
              mt="0.75rem"
              fontSize="0.75rem"
              fontWeight={500}
              color={colors.red[500]}
              lineHeight="1.25rem"
              className="text-left"
            >
              Your comment replies will resume in: {disabledForTime}
            </WishlinkTypography>
            <WishlinkTypography
              mt="0.25rem"
              fontSize="0.625rem"
              fontWeight={400}
              color={"rgba(233, 30, 99, 0.60)"}
              lineHeight="0.925rem"
              className="text-left"
            >
              Reason : {disabledForReason}
            </WishlinkTypography>
          </>
        )}
    </div>
  );
};

export default EngageCommentToggle;
