export const SINGULAR = {
  EVENTS: {
    otp_success: "otp_success",
    social_login_attempt: "social_login_attempt",
    first_post_created: "first_post_created",
    third_post_created: "third_post_created",
    first_link_copy: "first_link_copy",
    first_link_share: "first_link_share",
    first_sale_recorded: "first_sale_recorded",
    onboarded: "onboarded",
  },
  LABEL: {
    IG: "ig",
    YT: "yt",
    SKIP_FOR_NOW: "skip_for_now",
  },
};
