import { useTheme } from "@mui/material";
import { useSelector } from "react-redux";

import { tokens } from "../../theme";
import { IMAGE_PREFIX } from "../../utils/constants";
import WishlinkTypography from "../../WishlinkComponents/WishlinkTypography";

const EngageActivationMilestones = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  const { creatorSocialLoginStatus } = useSelector(
    (state) => state.apiDataReducer
  );
  const { milestones } = useSelector((state) => state.appReducer);
  const isInstagramLoggedIn =
    creatorSocialLoginStatus?.data?.data?.instagram_logged_in;
  const isFirstPostCreated =
    milestones?.milestones_found &&
    milestones?.check_first_post_milestone?.achieved;

  return (
    <>
      <WishlinkTypography
        fontSize="0.875rem"
        fontWeight={400}
        color={colors.grey[500]}
        className="text-center pt-2 pb-2"
      >
        {isInstagramLoggedIn ? (
          <div className="flex align-center">
            <img
              src={`${IMAGE_PREFIX}check-circle-full.svg`}
              className="mr-2"
              alt=""
            />
            Instagram is connected
          </div>
        ) : (
          <div className="flex align-start justify-start text-left">
            <img
              src={`${IMAGE_PREFIX}x-circle-filled.svg`}
              className="mr-2"
              alt=""
            />
            Instagram account not connected. Log in Now
          </div>
        )}
      </WishlinkTypography>
      <WishlinkTypography
        fontSize="0.875rem"
        fontWeight={400}
        color={colors.grey[500]}
        className="text-center pt-2 pb-2"
      >
        {isFirstPostCreated ? (
          <div className="flex align-center">
            <img
              src={`${IMAGE_PREFIX}check-circle-full.svg`}
              className="mr-2"
              alt=""
            />
            Post added to your Wishlink Shop
          </div>
        ) : (
          <div className="flex align-start justify-start text-left">
            <img
              src={`${IMAGE_PREFIX}x-circle-filled.svg`}
              className="mr-2"
              alt=""
            />
            Create first Post on Wishlink to enable Engage
          </div>
        )}
      </WishlinkTypography>
    </>
  );
};

export default EngageActivationMilestones;
