import { auth } from "./firebase";
import { saveState } from "./localStorage";
import {
  DATE_PRESETS,
  GA_ACTION,
  GA_CATEGORIES,
  IMAGE_FILE_EXTENSIONS,
  IMAGE_PREFIX,
  LINK_REGEX,
  NON_PRODUCT_LINK_IDENTIFIERS,
  IMAGE_PREFIX_SOURCING_ASSETS,
  FLIPKART_BRAND_ID,
  MYNTRA_BRAND_ID,
  IMAGE_PREFIX_AZURE,
  FLIPKART_COMMISSION,
  SHOPSY_BRAND_ID,
  SHOPSY_COMMISSION,
  PURE_ORIGIN_BRAND_ID,
  AMAZON_BRAND_ID,
  AMAZON_COMMISSION_AIP_PERCENT,
  AMAZON_COMMISSION_FIP_PERCENT,
  TDS_PERCENTAGE_NEW,
} from "./constants";
import { triggerGa, setUserPropertiesGa } from "./gaUtils";
import { getAnalytics, setUserId } from "firebase/analytics";
import { getPlatform } from "../utils/config";

export const addHours = (date, hours) => {
  const dateCopy = new Date(date);
  dateCopy.setTime(dateCopy.getTime() + hours * 60 * 60 * 1000);
  return dateCopy;
};

export const refreshFirebaseAuthStatusOrSignOut = async () => {
  const currentUser = auth.currentUser;
  if (currentUser) {
    const token = await currentUser.getIdToken();
    saveState("tokenExpiry", addHours(new Date(), 1));
    saveState("firebaseToken", token);
  } else {
    await signOutFirebaseAuth();
  }
};

export const signOutFirebaseAuth = async () => {
  localStorage.clear();
  await auth.signOut();
};

export const getResponsiveWidth = (widthFactor = 100) => {
  return window.innerWidth > 448
    ? `${(28 * widthFactor) / 100}rem`
    : window.innerWidth < 360
    ? `${(22.5 * widthFactor) / 100}rem`
    : `${(window.innerWidth * widthFactor) / 100 / 16}rem`;
};

export const copyToClipboard = async (textToCopy) => {
  await window.customCopy(textToCopy, navigator);
};

const openAndroidShare = ({ textToShare }) => {
  window?.ReactNativeWebView?.postMessage(
    JSON.stringify({ type: "share", payload: textToShare })
  );
};

export const openShareDrawer = async (textToShare) => {
  openAndroidShare({ textToShare });
  try {
    await window.customShare(textToShare, navigator);
  } catch (e) {
    // console.log("error opening share drawer", e);
  }
};

export const copyToClipboardAndOpenShareDrawer = async (textToCopyAndShare) => {
  openAndroidShare({ textToShare: textToCopyAndShare });
  try {
    await window.customCopy(textToCopyAndShare, navigator);
    await window.customShare(textToCopyAndShare, navigator);
  } catch (e) {
    // console.log("error opening share drawer", e);
  }
};

export const openLinkInNewTab = (linkToOpen) => {
  openExternalLinkInNewTab({ redirectUrl: linkToOpen });
};

export const getFormattedDate = (date) => {
  return new Date(date).toLocaleDateString("en-us", {
    year: "numeric",
    month: "short",
    day: "numeric",
  });
};

export const getFormattedDateWithoutYear = (date) => {
  return new Date(date).toLocaleDateString("en-us", {
    month: "short",
    day: "numeric",
  });
};

export const getCommissionRangeOfProducts = (
  products,
  myntraCategoryWiseCommissions,
  amazonType = ""
) => {
  if (!products || products?.length === 0) {
    return "0";
  }
  let flipkartProductExists = products?.some(
    (product) => product?.brand_id === FLIPKART_BRAND_ID
  );
  let shopsyProductExists = products?.some(
    (product) => product?.brand_id === SHOPSY_BRAND_ID
  );
  const myntraProductExists = products?.some(
    (product) => product?.brand_id === MYNTRA_BRAND_ID
  );
  const amazonProductExists = products?.some(
    (product) => product?.brand_id === AMAZON_BRAND_ID
  );
  let minCommission = 100;
  let maxCommission = 0;
  if (flipkartProductExists) {
    maxCommission = FLIPKART_COMMISSION;
  }
  if (shopsyProductExists && maxCommission < SHOPSY_COMMISSION) {
    maxCommission = SHOPSY_COMMISSION;
  }
  for (let i = 0; i < products?.length; i++) {
    let productCommission = products[i]?.commission
      ? parseFloat(products[i]?.commission)
      : 0;
    if (products[i] && products[i]?.brand_id === AMAZON_BRAND_ID) {
      productCommission =
        amazonType === "AIP"
          ? AMAZON_COMMISSION_AIP_PERCENT
          : amazonType === "FIP"
          ? AMAZON_COMMISSION_FIP_PERCENT
          : 0;
    }
    if (productCommission > maxCommission) {
      maxCommission = productCommission;
    }
    if (productCommission < minCommission) {
      minCommission = productCommission;
    }
  }
  if (myntraProductExists) {
    if (myntraCategoryWiseCommissions === undefined) {
      return "";
    }
    if (
      myntraCategoryWiseCommissions?.creator_specific_commission_exists !== true
    ) {
      const categoryWiseMax = maxCommssionFromCategory(
        myntraCategoryWiseCommissions
      );
      if (maxCommission < categoryWiseMax) {
        maxCommission = categoryWiseMax;
      }
    }
  }
  if (
    flipkartProductExists ||
    myntraProductExists ||
    shopsyProductExists ||
    amazonProductExists
  ) {
    return `Upto ${maxCommission}`;
  } else if (minCommission === maxCommission) {
    return minCommission;
  } else {
    return `${minCommission} - ${maxCommission}`;
  }
};

export const getMyntraCategoryWiseMaxCommission = (commissions) => {
  if (
    commissions === undefined ||
    commissions?.creator_specific_commission_exists === true
  )
    return "";
  const range = commissions.reduce(
    (acc, commission) => {
      if (acc.max <= commission.creator_commission_pct) {
        acc.max = commission.creator_commission_pct;
      } else if (acc.min > commission.creator_commission_pct) {
        acc.min = commission.creator_commission_pct;
      }
      return acc;
    },
    { min: 100, max: 0 }
  );
  return ` ${range.min} - ${range.max}`;
};

export const getDataPointLoadingValue = (dataPoint) => {
  return dataPoint?.status === "pending";
};

export const getAssetUrl = (assetUrl) => {
  return IMAGE_PREFIX + assetUrl;
};

export const getAssetUrlAzure = (assetUrl) => {
  return IMAGE_PREFIX_AZURE + assetUrl;
};

export const getSourcingAssetUrl = (assetUrl) => {
  return IMAGE_PREFIX_SOURCING_ASSETS + assetUrl;
};

export const getImageUrlFromCategoryNamePng = (categoryName) => {
  if (categoryName === "") return "";
  return IMAGE_PREFIX_SOURCING_ASSETS + categoryName + ".png";
};

export const getImageUrlFromCategoryNameJpeg = (categoryName) => {
  return IMAGE_PREFIX_SOURCING_ASSETS + categoryName + ".jpeg";
};

export const getOpacityForDisabledValue = (disabled) => {
  return disabled ? "0.4" : "1";
};

export function getCroppedImg(image, crop) {
  const canvas = document.createElement("canvas");
  const scaleX = image.naturalWidth / image.width;
  const scaleY = image.naturalHeight / image.height;
  canvas.width = crop.width * 4;
  canvas.height = crop.height * 4;
  const ctx = canvas.getContext("2d");

  ctx.drawImage(
    image,
    crop.x * scaleX,
    crop.y * scaleY,
    crop.width * scaleX,
    crop.height * scaleY,
    0,
    0,
    crop.width * 4,
    crop.height * 4
  );
  // As a blob
  return new Promise((resolve, reject) => {
    canvas.toBlob(
      (blob) => {
        blob.name = "croppedImage";
        resolve(blob);
      },
      "image/jpeg",
      1
    );
  });
}

export const getDisplayDateRange = (startDate, endDate) => {
  const months = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];
  // let startDateString = `${startDate?.getDate()} ${
  //   months[startDate?.getMonth()]
  // }`;
  // let endDateString = `${endDate?.getDate()} ${months[endDate?.getMonth()]}`;
  //
  // return startDateString === endDateString
  //   ? startDateString
  //   : `${startDateString} - ${endDateString}`;
  return `${startDate?.getDate()} ${
    months[startDate?.getMonth()]
  } - ${endDate?.getDate()} ${months[endDate?.getMonth()]}`;
};

export const getStartAndEndDateFromPreset = (preset) => {
  let today = new Date();
  let startDate = new Date();
  let endDate = new Date();
  if (preset === DATE_PRESETS.LAST_MONTH) {
    startDate = new Date(today.getFullYear(), today.getMonth() - 1, 1);
    endDate = new Date(today.getFullYear(), today.getMonth(), 0);
  } else if (preset === DATE_PRESETS.YESTERDAY) {
    endDate = new Date(today.setDate(today.getDate() - 1));
    startDate = endDate;
  } else if (preset === DATE_PRESETS.TODAY) {
    startDate = today;
  } else if (preset === DATE_PRESETS.ALL_TIME) {
    startDate = new Date(2020, 1, 1, 0, 0, 0, 0);
  } else if (preset === DATE_PRESETS.THIS_MONTH) {
    startDate = new Date(today.getFullYear(), today.getMonth(), 1);
  } else if (preset === DATE_PRESETS.LAST_2_MONTHS) {
    startDate = new Date(today.getFullYear(), today.getMonth() - 2, 1);
    endDate = new Date(today.getFullYear(), today.getMonth(), 0);
  } else if (preset === DATE_PRESETS.LAST_3_MONTHS) {
    startDate = new Date(today.getFullYear(), today.getMonth() - 3, 1);
    endDate = new Date(today.getFullYear(), today.getMonth(), 0);
  } else if (preset === DATE_PRESETS.LAST_7_DAYS) {
    startDate = new Date(today.setDate(today.getDate() - 7));
  } else if (preset === DATE_PRESETS.LAST_TO_LAST_MONTH) {
    startDate = new Date(today.getFullYear(), today.getMonth() - 2, 1);
    endDate = new Date(today.getFullYear(), today.getMonth() - 1, 0);
  }
  return [startDate, endDate];
};

export const formatDate = (date) => {
  let year = date.toLocaleString("default", { year: "numeric" });
  let month = date.toLocaleString("default", { month: "2-digit" });
  let day = date.toLocaleString("default", { day: "2-digit" });
  return year + "-" + month + "-" + day;
};

export function getDateObjectFromDateString(date) {
  let dateArray = date?.split("-");
  let dateObject = null;
  if (dateArray?.length === 3)
    dateObject = new Date(
      parseInt(dateArray[0]),
      parseInt(dateArray[1]) - 1,
      parseInt(dateArray[2])
    );
  return dateObject;
}

export const getDisplayTextFromDatesAndPresets = (
  startDate,
  endDate,
  preset,
  allowedPresets
) => {
  if (preset && allowedPresets?.includes(preset)) {
    return preset;
  } else {
    let startDateToDate = new Date(startDate);
    let endDateToDate = new Date(endDate);
    return getDisplayDateRange(startDateToDate, endDateToDate);
  }
};

export const getShareAllLinksText = (
  creatorUsername,
  postId,
  type,
  products,
  postLink = ""
) => {
  let text = "";
  if (type === "collection") {
    text = `*Collection Link:* ${getPostURL(
      creatorUsername,
      postId,
      type
    )}\n\n*Short link for stories:* \n\n${getAllProductShortUrls(products)}`;
  } else {
    if (postLink?.includes("instagram.com")) {
      text = `*Post Link:* ${getPostURL(
        creatorUsername,
        postId,
        type
      )}\n\n*Short link for stories:* \n\n${getAllProductShortUrls(products)}`;
    } else {
      text = `*All Product Links:* ${getPostURL(
        creatorUsername,
        postId,
        type
      )}\n\n*Individual Product Links:* \n\n${getAllProductShortUrls(
        products
      )}`;
    }
  }
  return text;
};

export const getAllProductShortUrls = (products) => {
  return products
    .filter((prod) => prod.is_alive)
    .map(
      (product, i) =>
        `${i + 1}. ${product?.name}: ${
          product?.shorturl || product?.short_url
        }\n`
    )
    .join("\n");
};

export const getPostURL = (creatorUsername, postId, kind) => {
  return `https://wishlink.com/${creatorUsername}/${kind}/${postId}`;
};

export const formatAmountK = (amount) => {
  let formattedAmount = "";
  let isNegative = false;
  let magnitude = 0;
  if (typeof amount != "number") {
    return formattedAmount;
  }
  if (amount < 0) {
    isNegative = true;
  }
  amount = Math.abs(Math.ceil(amount));
  if (amount >= 1000) {
    magnitude += 1;
    amount = amount / 1000;
    while (amount >= 100 && magnitude < 3) {
      magnitude += 1;
      amount = amount / 100;
    }
  }
  amount = Number(amount.toPrecision(3));
  return (
    (isNegative ? "-" : "") +
    amount?.toString() +
    ["", "K", "L", "Cr"][magnitude]
  );
};

export const createDIYPostObj = (inputObj, type) => {
  const diyPost = {};
  if (type === "instagram") {
    diyPost.post_url = inputObj?.permalink;
    diyPost.media_type = inputObj?.media_type;
    diyPost.thumbnail_url = inputObj?.thumbnail_url || inputObj?.media_url;
    diyPost.media_url = inputObj?.media_url;
    diyPost.post_social_media_id = inputObj?.id;
    diyPost.post_added_on_social_media = inputObj?.timestamp;
  } else if (type === "youtube") {
    diyPost.post_url = inputObj?.post_url;
    diyPost.media_type = "VIDEO";
    diyPost.thumbnail_url = `https://img.youtube.com/vi/${inputObj?.contentDetails?.videoId}/0.jpg`;
    diyPost.media_url = `https://www.youtube.com/embed/${inputObj?.contentDetails?.videoId}`;
    diyPost.post_social_media_id = "";
    diyPost.post_added_on_social_media = inputObj?.snippet?.publishedAt;
  } else {
    // console.log("We shouldn't have been here :(");
  }
  return diyPost;
};

export const triggerGoBackGa = (pageName, screenName) => {
  triggerGa({
    category: GA_CATEGORIES.NAVIGATION,
    action: GA_ACTION.BACK,
    pageName: pageName,
    screenName: screenName,
  });
  return true;
};

export const getDIYGaBasedOnFirstPost = (firstPostExists) => {
  return firstPostExists ? GA_CATEGORIES.CREATE : GA_CATEGORIES.FIRST_POST;
};

export const triggerGAUserId = (creatorId) => {
  let adminToken = localStorage.adminToken;
  if (creatorId && !adminToken) {
    if (window.cordova) {
      window.FirebasePlugin?.setUserId(creatorId?.toString());
    } else {
      const analytics = getAnalytics();
      setUserId(analytics, creatorId);
      window.gtag("config", "G-9K7DV3BSPQ", {
        user_id: creatorId,
      });
    }
  }
};

export const triggerGAPlatform = () => {
  let platform = getPlatform();
  setUserPropertiesGa({ platform: platform });
};

export const triggerInstagramLogin = () => {
  openExternalLinkInNewTab({
    redirectUrl: `https://api.instagram.com/oauth/authorize?wishlink_external=true&client_id=1219800908925647&redirect_uri=${window.location.origin}/auth/code&response_type=code&scope=user_profile,user_media`,
  });
};

// TODO: save client_id in config
// TODO: what is state?
export const triggerPinterestLogin = () => {
  openExternalLinkInNewTab({
    redirectUrl: `https://www.pinterest.com/oauth/?wishlink_external=true&response_type=code&redirect_uri=${window.location.origin}/pinterest-callback&client_id=1501683&scope=boards:read,boards:write,pins:read,pins:write`,
  });
};

export const loadGoogleScript = (callback) => {
  if (document.getElementById("google-youtube-signin")) return;
  const googleScript = document.createElement("script");
  googleScript.src = "https://accounts.google.com/gsi/client";
  googleScript.defer = true;
  googleScript.id = "google-youtube-signin";
  if (callback) {
    googleScript.onload = () => callback();
  }
  document.head.appendChild(googleScript);
};

export const triggerYoutubeLogin = async () => {
  const autoLoginApp = localStorage.getItem("loginApp");
  if (autoLoginApp && autoLoginApp === "yt") {
    await signOutFirebaseAuth();
  }
  /* global google */
  const client = google.accounts.oauth2.initCodeClient({
    client_id:
      "429224986687-r19fp48m6j5g1ri7spb3d659ig0pvknu.apps.googleusercontent.com",
    // eslint-disable-next-line no-useless-escape
    scope: `https://www.googleapis.com/auth/youtube.readonly \ https://www.googleapis.com/auth/youtubepartner`,
    ux_mode: "redirect",
    redirect_uri: "https://creator.wishlink.com/youtube-auth",
    access_type: "offline",
    callback: () => {},
  });
  client.requestCode();
};

export const triggerEngageLogin = () => {
  const client_id = "665365291834413";
  let redirect_uri = window.location.origin + "/auth/success";
  if (window.cordova) {
    redirect_uri = redirect_uri.replace("app://", "https://");
  }
  const response_type = "token";

  const scope = [
    "email",
    "public_profile",
    "pages_show_list",
    "instagram_basic",
    "instagram_manage_insights",
    "instagram_manage_comments",
    "instagram_manage_messages",
    "pages_manage_metadata",
    "pages_messaging",
    "pages_read_engagement",
    "pages_show_list",
    "business_management",
  ];

  const redirectUrl = `https://www.facebook.com/v15.0/dialog/oauth?client_id=${client_id}&redirect_uri=${redirect_uri}&response_type=${response_type}&scope=${scope}`;
  openExternalLinkInNewTab({ redirectUrl });
};

export const preloadImage = (url) => {
  return new Promise((resolve, reject) => {
    const image = new Image();
    image.src = url;
    image.onload = () => resolve(url); // Resolve with URL to identify the image that loaded
    image.onerror = (error) =>
      reject(new Error(`Failed to load image at ${url}: ${error.message}`));
  });
};

export const preloadImages = (urls) => {
  const promises = urls.map((url) => preloadImage(url));
  return Promise.all(promises)
    .then((results) => {
      return results;
    })
    .catch((error) => {
      //console.error("Error loading images:", error);
    });
};

export const checkIfLinkIsValidScrapingLink = (
  link,
  scrapingFailureDomains = [],
  username = ""
) => {
  let returnObject = { success: true, message: "" };
  if (!link) {
    returnObject.message = "Please add a link";
  }
  if (typeof link !== "string") {
    returnObject.message = "Please add a link";
  }
  link = link?.toLowerCase();
  let isUrlType = LINK_REGEX.test(link);
  if (!isUrlType) {
    returnObject.message =
      "Please enter a product link, this does not seem to be a link.";
  }
  if (
    NON_PRODUCT_LINK_IDENTIFIERS.some((str) => link.includes(str.toLowerCase()))
  ) {
    returnObject.message =
      "Please add a product link, this seems like a social media link";
  }
  if (
    link.includes("wishlink.com/") &&
    !(
      link.includes("wishlink.com/share/") || link.includes("wishlink.com/buy/")
    ) &&
    username !== "wishlinksrishti" &&
    username !== "wishlinkconnect"
  ) {
    returnObject.message =
      "Please add a product link, this seems like a wishlink storefront url";
  }
  try {
    let processedUrl = new URL(link);
    let path = processedUrl.pathname;
    for (const str of IMAGE_FILE_EXTENSIONS) {
      if (path.endsWith(str)) {
        returnObject.message =
          "Please add a product link, this seems like an image url";
        break;
      }
    }
  } catch (e) {
    returnObject.message = "Please add a valid product link";
  }
  for (const domain of scrapingFailureDomains) {
    if (link?.includes(domain)) {
      returnObject.message = "Please add a valid product link";
      break;
    }
  }
  if (returnObject.message !== "") {
    returnObject.success = false;
  }
  return returnObject;
};

export const checkInvalidLinkExists = (links, scrapingFailureDomains = []) => {
  let invalidLinkExists = false;
  for (const link of links) {
    let validity = checkIfLinkIsValidScrapingLink(link, scrapingFailureDomains);
    if (!validity.success && link !== "") {
      invalidLinkExists = true;
      break;
    }
  }
  return invalidLinkExists;
};

export const checkValidLinkExists = (links, scrapingFailureDomains = []) => {
  let validLinkExists = false;
  for (const link of links) {
    let validity = checkIfLinkIsValidScrapingLink(link, scrapingFailureDomains);
    if (validity.success) {
      validLinkExists = true;
      break;
    }
  }
  return validLinkExists;
};

export const getTimeAgoTextFromDateAndCurrentTimestamps = (
  dateSent,
  dateToday
) => {
  let dateDelta = dateToday - dateSent;
  let daysDelta = Math.floor(dateDelta / (1000 * 60 * 60 * 24));

  if (daysDelta < 0) {
    return "now";
  } else if (daysDelta === 0) {
    let secondsDelta = Math.floor(dateDelta / 1000);

    if (secondsDelta < 60) {
      if (secondsDelta === 0) {
        return "now";
      }
      return `${secondsDelta}s`;
    } else if (secondsDelta >= 60 && secondsDelta < 3600) {
      let minutesDelta = Math.floor(secondsDelta / 60);
      return `${minutesDelta}m`;
    } else {
      let hoursDelta = Math.floor(secondsDelta / 3600);
      return `${hoursDelta}h`;
    }
  } else if (daysDelta >= 1 && daysDelta < 7) {
    return `${daysDelta}d`;
  } else if (daysDelta >= 7 && daysDelta < 30) {
    let weeksDelta = Math.floor(daysDelta / 7);
    return `${weeksDelta}w`;
  } else if (daysDelta >= 30 && daysDelta < 360) {
    let monthsDelta = Math.floor(daysDelta / 30);
    return `${monthsDelta}M`;
  } else {
    if (daysDelta < 365) {
      return "1y";
    } else {
      let yearsDelta = Math.floor(daysDelta / 365);
      return `${yearsDelta}y`;
    }
  }
};

export const getkFormatting = (int) => {
  if (typeof int === "string") {
    int = parseInt(int);
  }
  if (int >= 100000) {
    int = (int / 100000).toFixed(2);
    int = int + "L";
    return int;
  }
  if (int >= 1000) {
    int = (int / 1000).toFixed(2);
    int = int + "K";
    return int;
  }
  return int.toFixed(2);
};
export const getkFormattingWithoutDecimal = (int) => {
  if (typeof int === "string") {
    int = parseInt(int);
  }
  if (int >= 100000) {
    int = (int / 100000).toFixed(2);
    int = int + "L";
    return int;
  }
  if (int >= 1000) {
    int = (int / 1000).toFixed(1);
    int = int + "K";
    return int;
  }
  if (int < 10) {
    return int.toFixed(1);
  }
  return Math.floor(int);
};
const maxCommssionFromCategory = (category) => {
  if (!category || category?.creator_specific_commission_exists === true)
    return "";
  let maxCommission = 0;
  category?.forEach((brand) => {
    if (brand.creator_commission_pct > maxCommission) {
      maxCommission = brand.creator_commission_pct;
    }
  });
  return maxCommission;
};

export const getMaxCategoryWiseBrandCommission = (category) => {
  if (!category || category?.creator_specific_commission_exists === true)
    return "";
  const maxCommission = maxCommssionFromCategory(category);
  return `upto ${maxCommission}%`;
};

export const redirectUserToExternalLink = (external_link) => {
  openExternalLinkInNewTab({ redirectUrl: external_link });
};

export const openExternalLinkInNewTab = ({ redirectUrl }) => {
  if (window.ReactNativeWebView) {
    redirectUrl = redirectUrl.includes("?")
      ? redirectUrl + "&wishlink_external=true"
      : redirectUrl + "?wishlink_external=true";
  }
  const target = window.cordova ? "_self" : "_blank";
  window.open(redirectUrl, target, "noreferrer");
};

const UPTO_BRAND_IDS = [PURE_ORIGIN_BRAND_ID];

export const getUptoOrFlatCommissionText = (BrandId) => {
  if (UPTO_BRAND_IDS.includes(BrandId)) {
    return "Upto";
  }
  return "Flat";
};

export const getTdsAmount = (amount) => {
  return (amount * TDS_PERCENTAGE_NEW) / 100;
};
