import Modal from "@mui/material/Modal";
import { useTheme } from "@mui/material";

import { tokens } from "../../theme";
import WishlinkTypography from "../WishlinkTypography";
import WishlinkButton from "../WishlinkButton";
import { IMAGE_PREFIX } from "../../utils/constants";

const WishlinkModal = (props) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const {
    showModal,
    title,
    description,
    btnText,
    setShowModal,
    onClick,
    width = "90%",
    height = "auto",
    showCloseIcon = true,
    background = colors.grey[0],
    borderRadius = "0.5rem",
    border = "none",
    persistent,
  } = props;

  return (
    <>
      <Modal
        open={showModal}
        onClose={() => (persistent ? {} : setShowModal(false))}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <div
          style={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width,
            boxShadow: 24,
            maxWidth: "28rem",
            border,
            outline: "none",
            background,
            borderRadius,
            height,
          }}
          className="p-4"
        >
          <div className="flex justify-between align-center">
            <WishlinkTypography
              fontWeight={700}
              fontSize="1.2rem"
              id="modal-modal-title"
            >
              {title}
            </WishlinkTypography>
            {showCloseIcon && (
              <img
                onClick={() => setShowModal(false)}
                src={`${IMAGE_PREFIX}close.png`}
                width="20rem"
                alt=""
              />
            )}
          </div>
          <WishlinkTypography
            className="mt-2"
            fontWeight={400}
            fontSize="0.875rem"
            id="modal-modal-description"
            sx={{ mt: 2 }}
          >
            {description}
          </WishlinkTypography>
          {props.children}
          {btnText && (
            <WishlinkButton
              onClick={() => onClick()}
              background={colors.indigo[400]}
              className="mt-2"
              height="3rem"
              width="100%"
              style={{
                ":hover": {
                  bgcolor: colors.indigo[400],
                },
              }}
            >
              <WishlinkTypography
                fontWeight={400}
                fontSize="0.875rem"
                color={colors.grey[0]}
                id="modal-modal-description"
              >
                {btnText}
              </WishlinkTypography>
            </WishlinkButton>
          )}
        </div>
      </Modal>
    </>
  );
};

export default WishlinkModal;
