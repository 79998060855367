import WishlinkTypography from "../../WishlinkComponents/WishlinkTypography";

const EngageProTip = () => {
  return (
    <div className="mt-4 mb-1">
      <div style={{ background: "var(--Accent-Bg, #FFF3F1)" }}>
        <WishlinkTypography
          fontSize="0.625rem"
          fontStyle="normal"
          fontWeight="300"
          pt="0.75rem"
          pb="0.75rem"
        >
          Pro Tip: Add Multiple Templates for more engagement
        </WishlinkTypography>
      </div>
    </div>
  );
};

export default EngageProTip;
