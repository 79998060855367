import { useNavigate } from "react-router-dom";

import { IMAGE_PREFIX, PAGE_URLS } from "../../utils/constants";
import WishlinkTypography from "../../WishlinkComponents/WishlinkTypography";

const CreatorEarningsHeader = () => {
  const navigate = useNavigate();

  const onClickBackBtn = () => {
    navigate(PAGE_URLS.HOME);
  };

  return (
    <div className="flex justify-start p-4">
      <img
        src={`${IMAGE_PREFIX}arrow-left.svg`}
        alt="back-arrow"
        className="pr-3"
        onClick={() => onClickBackBtn()}
      />
      <WishlinkTypography fontSize="1.125rem" fontWeight={600}>
        Creator Earnings
      </WishlinkTypography>
    </div>
  );
};

export default CreatorEarningsHeader;
