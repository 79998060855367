import ReactPlayer from "react-player";
import {
  GA_ACTION,
  GA_CATEGORIES,
  GA_PAGE,
  GA_SCREEN_NAME,
} from "../../utils/constants";
import { triggerGa } from "../../utils/gaUtils";

const CreatorTestimonialVideo = (props) => {
  const { media_url, playing = true } = props;
  const onVideoPlay = () => {
    triggerGa({
      category: GA_CATEGORIES.VIDEO_INTERACTION,
      action: GA_ACTION.PLAY,
      page: GA_PAGE.CREATOR_EARNINGS,
      screenName: GA_SCREEN_NAME.CREATOR_NAME,
    });
  };
  const onVideoPause = () => {
    triggerGa({
      category: GA_CATEGORIES.VIDEO_INTERACTION,
      action: GA_ACTION.PAUSE,
      page: GA_PAGE.CREATOR_EARNINGS,
      screenName: GA_SCREEN_NAME.CREATOR_NAME,
    });
  };

  return (
    <ReactPlayer
      className={`border-radius react-video-player`}
      loop={true}
      url={media_url}
      style={{
        margin: "auto",
        zIndex: 1,
        display: "block",
      }}
      onPlay={onVideoPlay}
      onPause={onVideoPause}
      controls={true}
      playing={playing}
      muted={false}
      width="100%"
      height="auto"
      config={{
        file: {
          attributes: {
            controlsList: "nodownload noremoteplayback noplaybackrate",
            playsInline: true,
            disablePictureInPicture: false,
          },
        },
      }}
    />
  );
};

export default CreatorTestimonialVideo;
