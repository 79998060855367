import styled from "@emotion/styled";

export const ToolTipRightWarper = styled.div`
  position: relative;
  & .tooltipContainer {
    display: block;
    box-sizing: border-box;
    position: absolute;
    z-index: 1000;
    background-color: #111236;
    color: #fff;
    position: absolute;
    left: 124%;
    top: 50%;
    transform: translate(0, -50%);
    border-radius: 4px;
    padding: 8px;
  }
  & .tooltipContainer::after {
    content: "";
    position: absolute;
    color: #fff;
    width: 11px;
    height: 11px;
    background-color: #111236;
    left: -5px;
    top: 50%;
    transform: translate(0, -50%) rotate(45deg);
  }
`;

export const ToolTipLeftWarper = styled.div`
  position: relative;
  & .tooltipContainer {
    display: block;
    box-sizing: border-box;
    position: absolute;
    z-index: 1000;
    background-color: #111236;
    color: #fff;
    position: absolute;
    right: 124%;
    top: 50%;
    transform: translate(0, -50%);
    border-radius: 4px;
    padding: 8px;
  }
  & .tooltipContainer::after {
    content: "";
    position: absolute;
    color: #fff;
    width: 11px;
    height: 11px;
    background-color: #111236;
    right: -5px;
    top: 50%;
    transform: translate(0, -50%) rotate(45deg);
  }
`;
