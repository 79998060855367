import { useTheme } from "@mui/material";

import { tokens } from "../../theme";

const PillGroup = (props) => {
  const { pills, selectedPill, onClickSelectPill, onClickSuffixIcon } = props;
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  function isPillSelected(pill, selectedPill) {
    let selected = false;
    selectedPill.forEach((p) => {
      if (p.id === pill.id) selected = true;
    });
    return selected;
  }

  return (
    <div className="flex overflow-scroll right-transition">
      {pills.map((pill, index) => {
        return (
          <div
            key={index}
            className="p-2 mr-2 flex justify-center align-center"
            style={{
              border: isPillSelected(pill, selectedPill)
                ? `1px solid ${colors.red[500]}`
                : `1px solid ${colors.grey[400]}`,
              borderRadius: "20px",
              height: "34px",
              background: isPillSelected(pill, selectedPill)
                ? colors.red[500] + "20"
                : "",
            }}
          >
            {!pill.collapsible || isPillSelected(pill, selectedPill) ? (
              <div className="flex align-center">
                {/*{pill.prefixIcon && (*/}
                {/*  <img src={pill.prefixIcon} style={{ width: "1rem" }} alt="" />*/}
                {/*)}*/}
                <div
                  className="cursor-pointer ml-2"
                  style={{
                    whiteSpace: "nowrap",
                    fontSize: "0.75rem",
                    fontWeight: 500,
                    color: colors.grey[500],
                  }}
                  onClick={() => {
                    pill?.clickable && onClickSelectPill(pill);
                  }}
                >
                  {pill.name}
                  {/*{pill?.number !== 0 && ` (${pill.number})`}*/}
                </div>
                <img
                  src={pill.suffixIcon}
                  onClick={() => onClickSuffixIcon(pill)}
                  className="ml-2"
                  alt=""
                />
              </div>
            ) : (
              <img
                src={pill.prefixIcon}
                alt=""
                onClick={() => {
                  pill?.clickable && onClickSelectPill(pill);
                }}
              />
            )}
          </div>
        );
      })}
    </div>
  );
};

export default PillGroup;
