import React, { useState } from "react";
import { Divider, Skeleton } from "@mui/material";

// components
import { ToolTip } from "../../../components/ToolTip";
import ValueSkeleton from "../../../components/ValueSkeleton";
import { StatusPill } from "../../PastPayouts/Components/StatusPill";
import WishlinkTypography from "../../../WishlinkComponents/WishlinkTypography";

// utils
import {
  getAssetUrl,
  getkFormattingWithoutDecimal,
} from "../../../utils/common";

import {
  useGetConfirmedCommissionSplitInPayoutsQuery,
  useGetPendingConfirmedCommissionSplitQuery,
} from "../../../redux/reduxAPI";

import {
  GA_ACTION,
  GA_CATEGORIES,
  GA_PAGE,
  GA_SCREEN_NAME,
} from "../../../utils/constants";
import { triggerGa } from "../../../utils/gaUtils";

const OrderStatusCard = ({ month, item, index }) => {
  const [expand, setExpanded] = useState(false);

  const { data, isLoading, isFetching } =
    useGetPendingConfirmedCommissionSplitQuery({
      month: parseInt(item?.value),
      year: parseInt(item?.year),
    });
  const {
    data: monthSplitData,
    isLoading: monthSplitDataIsLoading,
    isFetching: monthSplitDataIsFetching,
  } = useGetConfirmedCommissionSplitInPayoutsQuery({
    month: parseInt(item?.value),
    year: parseInt(item?.year),
  });
  const loading = isLoading || isFetching;
  const monthSplitDataLoading =
    monthSplitDataIsLoading || monthSplitDataIsFetching;
  const monthWiseCommissions =
    monthSplitData?.confirmed_commission_status_list || [];
  const orderedCommisions = data?.commissions_ordered || 0;
  const pendingCommisions = data?.pending_commissions || 0;
  const returnedCommisions = data?.commissions_returned || 0;
  const actualReturnPercentage = data?.actual_return_percentage || 0;
  const confirmedCommisions = data?.confirmed_commissions || 0;
  const totalCommisions =
    pendingCommisions + Math.abs(returnedCommisions) + confirmedCommisions;

  const pendingPercentage = (pendingCommisions / totalCommisions) * 100;
  const returnPercentage =
    (Math.abs(returnedCommisions) / totalCommisions) * 100;
  const confirmedPercentage = (confirmedCommisions / totalCommisions) * 100;

  const isAllOrdersReturned =
    actualReturnPercentage === 100 && pendingCommisions === 0;

  const handleClick = () => {
    const label2 =
      pendingPercentage === 100
        ? "All_Pending"
        : pendingPercentage <= 0
        ? "No_Pending"
        : "Some_Pending";

    triggerGa({
      category: GA_CATEGORIES.USER_INTERACTION,
      action: GA_ACTION.CARD_CLICK,
      label: month,
      label2,
      label3: monthWiseCommissions?.length,
      page: GA_PAGE.ANALYTICS,
      screenName: GA_SCREEN_NAME.MONTHLY_ORDER_STATUS,
      eventValue: index + 1,
    });
    setExpanded((prev) => !prev);
  };
  if (orderedCommisions <= 0 && !loading) return null;
  return (
    <div
      style={{
        boxShadow: `0px 4px 4px 0px rgba(0, 0, 0, 0.10)`,
        background: "#FFF",
        borderRadius: "0.625rem",
        padding: "0.75rem",
        marginTop: "0.75rem",
      }}
    >
      <WishlinkTypography
        fontSize="0.875rem"
        color={"#000000"}
        fontWeight={500}
        textAlign={"left"}
      >
        Orders placed in {month}
      </WishlinkTypography>
      <div
        className="flex justify-between align-center mb-2 mt-2"
        data-cy={`mom-expand-button-${index}`}
        onClick={handleClick}
      >
        <WishlinkTypography
          fontSize="0.625rem"
          color={"#00000099"}
          fontWeight={500}
          textAlign={"left"}
        >
          Ordered Commissions
        </WishlinkTypography>
        <div className="flex align-center">
          <WishlinkTypography fontSize="1rem" color={"#000"} fontWeight={700}>
            <ValueSkeleton
              isLoading={loading}
              value={`₹ ${getkFormattingWithoutDecimal(orderedCommisions)}`}
            />
          </WishlinkTypography>
          <img
            src={getAssetUrl("right-nudge.svg")}
            alt="icon"
            style={{ transform: `rotate(${expand ? -90 : 90}deg)` }}
          />
        </div>
      </div>
      {loading ? (
        <Skeleton sx={{ height: "4rem", width: "100%" }} />
      ) : (
        <div className="flex" style={{ height: "2rem", width: "100%" }}>
          <div
            style={{
              background: "#F5C042",
              width: `${pendingPercentage}%`,
            }}
          />
          <div
            style={{
              background: "#FF426F",
              width: `${returnPercentage}%`,
            }}
          />
          <div
            style={{
              background: "#06C270",
              width: `${confirmedPercentage}%`,
            }}
          />
        </div>
      )}
      <div className="flex justify-between align-center mb-2 mt-2">
        <div>
          <div className="flex align-center" style={{ gap: "0.5rem" }}>
            <div
              style={{
                background: "#F5C042",
                width: "0.375rem",
                height: "0.375rem",
                borderRadius: 50,
                marginTop: "-0.4rem",
              }}
            />
            <WishlinkTypography
              fontSize="0.625rem"
              color={"#000000"}
              fontWeight={400}
              mt={"-0.4rem"}
            >
              Pending
            </WishlinkTypography>
            <ToolTip
              component={
                <div>
                  <WishlinkTypography
                    fontSize="0.6rem"
                    textAlign={"left"}
                    typographystyle={{
                      width: "10rem",
                      marginBottom: "0.4rem",
                    }}
                  >
                    • As of today, how much commissions are pending status from
                    brand(s)
                  </WishlinkTypography>
                  <WishlinkTypography textAlign={"left"} fontSize="0.6rem">
                    • You can check these brands in Pending Commissions section
                    under Payouts
                  </WishlinkTypography>
                </div>
              }
            >
              <img src={getAssetUrl("info-icon-black.svg")} alt="icon" />
            </ToolTip>
          </div>
          <WishlinkTypography
            fontSize="0.75rem"
            color={"#000"}
            fontWeight={500}
            textAlign={"left"}
            mt={"-0.4rem"}
          >
            <ValueSkeleton
              isLoading={loading}
              value={`₹ ${getkFormattingWithoutDecimal(pendingCommisions)}`}
            />
          </WishlinkTypography>
        </div>

        <div>
          <div
            className="flex align-center justify-center"
            style={{
              gap: "0.5rem",
            }}
          >
            <div
              style={{
                background: "#FF426F",
                width: "0.375rem",
                height: "0.375rem",
                borderRadius: 50,
              }}
            />
            <WishlinkTypography
              fontSize="0.625rem"
              color={"#000000"}
              fontWeight={400}
            >
              Returns
            </WishlinkTypography>
          </div>
          <div className="flex align-end">
            <WishlinkTypography
              fontSize="0.75rem"
              color={"#000"}
              fontWeight={500}
              textAlign={"center"}
            >
              <ValueSkeleton
                isLoading={loading}
                value={`₹ ${getkFormattingWithoutDecimal(
                  Math.abs(returnedCommisions)
                )}`}
              />
            </WishlinkTypography>
            {!loading && (
              <WishlinkTypography
                fontSize="0.6rem"
                color={"#000"}
                fontWeight={500}
                textAlign={"center"}
                ml={"0.2rem"}
              >
                {`(${actualReturnPercentage}%)`}
              </WishlinkTypography>
            )}
          </div>
        </div>

        <div>
          <div className="flex align-center" style={{ gap: "0.5rem" }}>
            <div
              style={{
                background: "#06C270",
                width: "0.375rem",
                height: "0.375rem",
                borderRadius: 50,
              }}
            />
            <WishlinkTypography
              fontSize="0.625rem"
              color={"#000000"}
              fontWeight={400}
            >
              Confirmed
            </WishlinkTypography>
          </div>
          <WishlinkTypography
            fontSize="0.75rem"
            color={"#06C270"}
            fontWeight={500}
            textAlign={"right"}
          >
            <ValueSkeleton
              isLoading={loading}
              value={`₹ ${getkFormattingWithoutDecimal(confirmedCommisions)}`}
            />
          </WishlinkTypography>
        </div>
      </div>
      {expand && (
        <div className="mt-4">
          {!monthSplitDataLoading && !loading && isAllOrdersReturned && (
            <WishlinkTypography
              fontSize="0.75rem"
              color={"#FF426F"}
              textAlign={"left"}
              fontWeight={600}
              mb={"0.25rem"}
            >
              All the orders placed via your Wishlinks were cancelled or
              returned
            </WishlinkTypography>
          )}
          {!monthSplitDataLoading && !loading && !isAllOrdersReturned && (
            <div>
              <WishlinkTypography
                fontSize="0.75rem"
                color={"#000"}
                fontStyle="italic"
                textAlign={"left"}
                fontWeight={600}
                mb={"0.25rem"}
              >
                {confirmedCommisions > 0
                  ? `When were my ${month} orders confirmed?`
                  : `When will my ${month} orders be confirmed?`}
              </WishlinkTypography>
              {confirmedCommisions > 0 ? (
                <WishlinkTypography
                  fontSize="0.625rem"
                  color={"#00000099"}
                  textAlign={"left"}
                  fontWeight={400}
                >
                  {`Status received & payout of your ${month} orders in:`}
                </WishlinkTypography>
              ) : (
                <div>
                  <div className="flex" style={{ gap: "0.5rem" }}>
                    <WishlinkTypography
                      fontSize="0.625rem"
                      color={"#00000099"}
                      textAlign={"left"}
                      fontWeight={400}
                    >
                      •
                    </WishlinkTypography>
                    <WishlinkTypography
                      fontSize="0.625rem"
                      color={"#00000099"}
                      textAlign={"left"}
                      fontWeight={400}
                    >
                      Your orders will get confirmed once the brand confirms the
                      status from their end, which takes atleast 1 month.
                    </WishlinkTypography>
                  </div>
                  <div className="flex" style={{ gap: "0.5rem" }}>
                    <WishlinkTypography
                      fontSize="0.625rem"
                      color={"#00000099"}
                      textAlign={"left"}
                      fontWeight={400}
                    >
                      •
                    </WishlinkTypography>
                    <WishlinkTypography
                      fontSize="0.625rem"
                      color={"#00000099"}
                      textAlign={"left"}
                      fontWeight={400}
                    >
                      Once the brand sends the status, the confirmed commissions
                      will be included in your payouts
                    </WishlinkTypography>
                  </div>
                  <div className="flex" style={{ gap: "0.5rem" }}>
                    <WishlinkTypography
                      fontSize="0.625rem"
                      color={"#00000099"}
                      textAlign={"left"}
                      fontWeight={400}
                    >
                      •
                    </WishlinkTypography>
                    <WishlinkTypography
                      fontSize="0.625rem"
                      color={"#00000099"}
                      textAlign={"left"}
                      fontWeight={400}
                    >
                      Go to 'Pending Commissions' in the Payout Tab to view
                      brand-wise confirmation time
                    </WishlinkTypography>
                  </div>
                </div>
              )}
            </div>
          )}
          {monthSplitDataLoading ? (
            <Skeleton sx={{ height: "4rem", width: "100%" }} />
          ) : (
            monthWiseCommissions?.map((item, index) => (
              <>
                <div className="flex align-center mb-2 mt-2">
                  <div style={{ flex: 4 }}>
                    <WishlinkTypography
                      fontSize="0.75rem"
                      color={"#000"}
                      fontWeight={500}
                      textAlign={"left"}
                    >
                      {item?.label}
                    </WishlinkTypography>
                  </div>

                  <div style={{ flex: 1.2 }}>
                    <WishlinkTypography
                      fontSize="0.75rem"
                      color={"#06C270"}
                      fontWeight={500}
                    >
                      ₹ {getkFormattingWithoutDecimal(item?.commission)}
                    </WishlinkTypography>
                  </div>
                  <div
                    style={{
                      flex: 1.5,
                      justifyContent: "left",
                      display: "flex",
                    }}
                  >
                    <StatusPill
                      type={item?.payout_status}
                      fontSize={"0.5rem"}
                      height={"10px"}
                      mt={"0"}
                    />
                  </div>
                </div>
                {index < monthWiseCommissions?.length - 1 && <Divider />}
              </>
            ))
          )}
        </div>
      )}
    </div>
  );
};

export default OrderStatusCard;
