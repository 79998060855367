export const useSingular = () => {
  function logSingularEvent(eventName, data) {
    if (window?.ReactNativeWebView) {
      window?.ReactNativeWebView?.postMessage(
        JSON.stringify({
          type: "singular_events",
          payload: { eventName, data },
        })
      );
    }
  }
  function setUserIdInSingular(userId) {
    if (window?.ReactNativeWebView) {
      window?.ReactNativeWebView?.postMessage(
        JSON.stringify({
          type: "set_user_id",
          payload: { userId },
        })
      );
    }
  }

  return {
    logSingularEvent,
    setUserIdInSingular,
  };
};
