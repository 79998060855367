import { useTheme } from "@mui/material";

import { tokens } from "../../theme";
import WishlinkTypography from "../../WishlinkComponents/WishlinkTypography";
import {
  GA_ACTION,
  GA_CATEGORIES,
  GA_LABEL,
  IMAGE_PREFIX,
  PAGE_URLS,
} from "../../utils/constants";
import { useNavigate } from "react-router-dom";
import { triggerGa } from "../../utils/gaUtils";
import { useSelector } from "react-redux";

const Poweredby = (props) => {
  const { variant = "white", color } = props;
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const navigate = useNavigate();
  const { pageName, screenName } = useSelector((state) => state.appReducer);
  const redirectToConnectWishlinkInstagram = () => {
    triggerGa({
      category: GA_CATEGORIES.USER_EXIT,
      action: GA_ACTION.FOOTER_CLICK,
      label: GA_LABEL.WISHLINK,
      page: pageName,
      screenName: screenName,
    });
    window.open("https://www.instagram.com/connect.wishlink");
  };

  const redirectToFaqPage = () => {
    triggerGa({
      category: GA_CATEGORIES.NAVIGATION,
      action: GA_ACTION.FOOTER_CLICK,
      label: GA_LABEL.HELP,
      page: pageName,
      screenName: screenName,
    });
    navigate(PAGE_URLS.FAQ);
  };

  const handleSupportClick = () => {
    navigate("/faq");
  };

  return (
    <div
      className="flex justify-center align-center p-5"
      style={{
        background: color
          ? color
          : variant === "white"
          ? colors.grey[0]
          : colors.red[200],
        marginTop: "auto",
      }}
    >
      <WishlinkTypography
        fontSize="0.75rem"
        fontWeight={400}
        color={variant === "white" ? colors.grey[700] : colors.grey[0]}
        onClick={() => redirectToConnectWishlinkInstagram()}
      >
        ⚡ Powered by
      </WishlinkTypography>
      <img
        className="ml-1"
        src={`${IMAGE_PREFIX}${
          variant === "white" ? `wishlink-orange.png` : `wishlink-white.png`
        }`}
        style={{ width: "4rem" }}
        alt=""
        onClick={() => redirectToConnectWishlinkInstagram()}
      />
      <div
        style={{
          background: variant === "white" ? colors.grey[500] : colors.grey[0],
          height: "1rem",
          width: "0.06rem",
        }}
        className="ml-3 mr-3"
      ></div>
      <img
        className="ml-1"
        src={`${IMAGE_PREFIX}${
          variant === "white" ? `support.svg` : `support-white.svg`
        }`}
        alt=""
        onClick={() => redirectToFaqPage()}
      />
      <WishlinkTypography
        className="ml-2"
        fontWeight={400}
        fontSize="0.75rem"
        color={variant === "white" ? colors.indigo[400] : colors.grey[0]}
        onClick={() => handleSupportClick()}
      >
        Help & Support
      </WishlinkTypography>
    </div>
  );
};

export default Poweredby;
