import { useDispatch } from "react-redux";
import { useEffect, useState } from "react";
import {
  useLazyGetBrandsEndlessForHomeQuery,
  useLazyGetBrandsMainForHomeQuery,
  useLazyGetBrandsAndSubcategoriesForHomeQuery,
  useLazyGetCategoriesQuery,
  useLazyGetCollectionsByPriceQuery,
  useLazyGetHomeSearchBrandsQuery,
  useLazyGetHomeSearchProductsQuery,
  useLazyGetHottestDealsForHomeQuery,
  useLazyGetRecommendedCollectionsQuery,
  useLazyGetSubcategoriesForBrandQuery,
  useLazyGetSubcategoriesFromCategoryQuery,
  useLazyGetActiveOrdersQuery,
  useLazyGetBrandsAndCategoriesForHomeQuery,
} from "./homeReduxAPI";
import {
  setBrandsListEndless,
  setBrandsListMain,
  setBrandListWithSubcategories,
  setCategories,
  setHomeLoading,
  setHomeSearchBrands,
  setHomeSearchProducts,
  setHottestDealsList,
  setRecommendedCollections,
  setSourceByPriceCollections,
  setSubcategoriesDrawerListLoading,
  updateHomeTriggers,
  setSubcategoryListInHomeDrawer,
  setHomeMoreLoading,
  setActiveOrders,
} from "./homeRedux";
import useDataUpdate, {
  useDataUpdateWithLoading,
} from "../../utils/useDataUpdate";
import { getDataPointLoadingValue } from "../../../utils/common";

const HomeDataLayer = () => {
  const dispatch = useDispatch();

  const [triggerGetCategories, resultGetCategories] =
    useLazyGetCategoriesQuery();
  const [triggerSourceByPriceCollections, resultSourceByPriceCollections] =
    useLazyGetCollectionsByPriceQuery();
  const [
    triggerGetSubcategoriesFromCategory,
    resultGetSubcategoriesFromCategory,
  ] = useLazyGetSubcategoriesFromCategoryQuery();
  const [triggerGetSubcategoriesForBrand] =
    useLazyGetSubcategoriesForBrandQuery();

  const [triggerGetBrandsMainForHome, resultGetBrandsMainForHome] =
    useLazyGetBrandsMainForHomeQuery();
  const [triggerGetBrandsEndlessForHome, resultGetBrandsEndlessForHome] =
    useLazyGetBrandsEndlessForHomeQuery();
  const [
    triggerGetBrandsAndSubcategoriesForHome,
    resultGetBrandsAndSubcategoriesForHomeQuery,
  ] = useLazyGetBrandsAndSubcategoriesForHomeQuery();
  const [triggerGetHottestDealsForHome, resultGetHottestDealsForHome] =
    useLazyGetHottestDealsForHomeQuery();
  const [triggerGetHomeSearchProducts, resultGetHomeSearchProducts] =
    useLazyGetHomeSearchProductsQuery();
  const [triggerGetHomeSearchBrands, resultGetHomeSearchBrands] =
    useLazyGetHomeSearchBrandsQuery();
  const [triggerGetRecommendedCollections, resultGetRecommendedCollections] =
    useLazyGetRecommendedCollectionsQuery();
  const [triggerGetActiveOrders, resultGetActiveOrders] =
    useLazyGetActiveOrdersQuery();
  const [
    triggerGetBrandsAndCategoriesForHomeQuery,
    resultGetBrandsAndCategoriesForHomeQuery,
  ] = useLazyGetBrandsAndCategoriesForHomeQuery();
  const [lastApiCallParams, setLastApiCallParams] = useState(null);

  const trigger = {
    getSourcingCategories: async (apiCallParams) =>
      await triggerGetCategories(apiCallParams),
    getSourcingCollectionsByPrice: async (apiCallParams) =>
      await triggerSourceByPriceCollections(apiCallParams),
    getSourcingSubcategoriesFromCategory: async (apiCallParams) =>
      await triggerGetSubcategoriesFromCategory(apiCallParams),
    getSourcingSubcategoriesForBrand: async (apiCallParams) =>
      await triggerGetSubcategoriesForBrand(apiCallParams),
    getSourcingRecommendedCollections: async (apiCallParams) =>
      await triggerGetRecommendedCollections(apiCallParams),
    getSourcingBrandsMainForHome: async (apiCallParams) =>
      await triggerGetBrandsMainForHome(apiCallParams),
    getSourcingBrandsEndlessForHome: async (apiCallParams) =>
      await triggerGetBrandsEndlessForHome(apiCallParams),
    getBrandsAndSubcategoriesForHome: async (apiCallParams) =>
      await triggerGetBrandsAndSubcategoriesForHome(apiCallParams),
    getSourcingHottestDealsForHome: async (apiCallParams) =>
      await triggerGetHottestDealsForHome(apiCallParams),
    getSourcingHomeSearchBrands: async (apiCallParams) =>
      await triggerGetHomeSearchBrands(apiCallParams),
    getSourcingActiveOrders: async (apiCallParams) =>
      await triggerGetActiveOrders(apiCallParams),
    getSourcingHomeSearchProducts: async (apiCallParams) => {
      setLastApiCallParams(apiCallParams);
      await triggerGetHomeSearchProducts(apiCallParams);
    },
    getBrandsAndCategoriesForHome: async (apiCallParams) => {
      await triggerGetBrandsAndCategoriesForHomeQuery(apiCallParams);
    },
  };

  useEffect(() => {
    dispatch(updateHomeTriggers(trigger));
  }, [trigger]);

  useDataUpdateWithLoading(resultGetCategories, setCategories, setHomeLoading);
  useDataUpdateWithLoading(
    resultGetBrandsMainForHome,
    setBrandsListMain,
    setHomeLoading
  );
  useDataUpdateWithLoading(
    resultGetBrandsAndCategoriesForHomeQuery,
    setCategories,
    setHomeLoading
  );

  useEffect(() => {
    const page = lastApiCallParams?.page || 1;
    const data = resultGetHomeSearchProducts.data;
    if (data) {
      if (data?.success) {
        dispatch(setHomeSearchProducts(data));
      }
    }
    if (page === 1) {
      dispatch(
        setHomeLoading(getDataPointLoadingValue(resultGetHomeSearchProducts))
      );
    }
    dispatch(
      setHomeMoreLoading(getDataPointLoadingValue(resultGetHomeSearchProducts))
    );
  }, [resultGetHomeSearchProducts]);

  useEffect(() => {
    const data = resultGetBrandsEndlessForHome.data;
    if (data) {
      if (data?.success) {
        dispatch(setBrandsListEndless(data));
      }
    }
  }, [resultGetBrandsEndlessForHome]);

  useEffect(() => {
    const data = resultGetBrandsAndCategoriesForHomeQuery.data;
    if (data) {
      if (data.success) {
        dispatch(setBrandListWithSubcategories(data));
        dispatch(setCategories(data.data.categories));
      }
    }
  }, [resultGetBrandsAndCategoriesForHomeQuery]);

  useEffect(() => {
    const data = resultGetBrandsAndSubcategoriesForHomeQuery.data;
    if (data) {
      if (data?.success) {
        dispatch(setBrandListWithSubcategories(data));
      }
    }
  }, [resultGetBrandsAndSubcategoriesForHomeQuery]);

  useDataUpdate(resultGetHomeSearchBrands, setHomeSearchBrands);
  useDataUpdate(resultSourceByPriceCollections, setSourceByPriceCollections);
  useDataUpdate(resultGetRecommendedCollections, setRecommendedCollections);

  useDataUpdateWithLoading(
    resultGetSubcategoriesFromCategory,
    setSubcategoryListInHomeDrawer,
    setSubcategoriesDrawerListLoading
  );

  useDataUpdate(resultGetActiveOrders, setActiveOrders);
  useDataUpdate(resultGetHottestDealsForHome, setHottestDealsList);

  return <></>;
};

export default HomeDataLayer;
