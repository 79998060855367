import WishlinkSlider from "../../WishlinkComponents/WishlinkSlider";
import WishlinkTypography from "../../WishlinkComponents/WishlinkTypography";
import { getAssetUrl } from "../../utils/common";
import { ENGAGE_CAROUSEL } from "../../utils/constants";

const HowToConnectSTK = () => {
  return (
    <div className="p-4">
      <WishlinkTypography
        fontSize="1.25rem"
        fontWeight={600}
        color="rgba(0, 0, 0, 0.60)"
        className="text-left mb-3"
      >
        How are creators using Engage
      </WishlinkTypography>
      <WishlinkSlider
        autoplay={false}
        centerPadding="50px"
        variableWidth={true}
        showDots={true}
        infinite={false}
      >
        {ENGAGE_CAROUSEL.map((filename) => (
          <img
            key={filename}
            src={getAssetUrl(filename)}
            style={{ width: "9rem", height: "17.75rem" }}
            className="p-1"
            alt=""
          />
        ))}
      </WishlinkSlider>
    </div>
  );
};

export default HowToConnectSTK;
