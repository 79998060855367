import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Poweredby from "../../components/Poweredby";
import WishlinkTypography from "../../WishlinkComponents/WishlinkTypography";
import ReactPlayer from "react-player";
import PillGroup from "../../components/AnalyticsInsights/PillGroup";
import WishlinkButton from "../../WishlinkComponents/WishlinkButton";
import { triggerGa } from "../../utils/gaUtils";
import {
  GA_ACTION,
  GA_CATEGORIES,
  GA_LABEL,
  GA_PAGE,
  GA_SCREEN_NAME,
} from "../../utils/constants";
import { Box } from "@mui/material";
import { updatePageNameAndScreenName } from "../../redux/redux";
import { useDispatch } from "react-redux";

const pills = [
  {
    id: 1,
    name: "English",
    label: "english",
    collapsible: false,
    clickable: true,
  },
  {
    id: 2,
    name: "Hindi",
    label: "hindi",
    collapsible: false,
    clickable: true,
  },
];

const ChangesCard = (props) => {
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "flex-start",
        paddingTop: "0.5rem",
        paddingBottom: "0.5rem",
        paddingLeft: "0.5rem",
      }}
    >
      <div
        style={{
          width: "1.25rem",
          height: "1.25rem",
          borderRadius: "50%",
          backgroundColor: "#FFE5B5",
          display: "flex",
          justifyContent: "center",
          flexShrink: "0",
          alignItems: "center",
          marginRight: "0.62rem",
          fontSize: "0.62rem",
          lineHeight: "0.94rem",
          color: "#0E1226",
          fontWeight: "600",
        }}
      >
        {props.number}
      </div>
      <div>
        <WishlinkTypography
          fontSize="0.875rem"
          fontWeight={400}
          color={"#0E1226"}
          lineHeight="1.25rem"
          textAlign="left"
        >
          {props.text}
        </WishlinkTypography>
      </div>
    </div>
  );
};

const hindiURL = "https://youtu.be/pv3N1gdo-Ys";
const englishURL = "https://youtu.be/sac0LCOfRgM";
const englishTexts = [
  "March payout will be done as usual",
  "New Payout process will be applicable for orders placed 1st April onwards",
  "Commissions and returns will get simpler and easier to understand",
];

const PayoutExplainerVideo = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [selectedPill, setSelectedPill] = useState([pills[0]]);
  const goBack = () => {
    navigate(-1);
  };
  const [videoURL, setVideoURL] = useState(englishURL);

  const onClickSelectPill = (pill) => {
    setSelectedPill([pill]);
  };

  useEffect(() => {
    dispatch(
      updatePageNameAndScreenName({
        pageName: GA_PAGE.PAYOUTS_PRELAUNCH,
        screenName: GA_SCREEN_NAME.PAYOUTS_PRELAUNCH,
      })
    );
  }, []);

  useEffect(() => {
    if (selectedPill[0]?.label === "english") {
      setVideoURL(englishURL);
      triggerGa({
        category: GA_CATEGORIES.USER_INTERACTION,
        action: GA_ACTION.BUTTON_CLICK,
        label: GA_LABEL.LANGUAGE_CHANGE,
        label2: "english",
        page: GA_PAGE.PAYOUTS_PRELAUNCH,
        screenName: GA_SCREEN_NAME.PAYOUTS_PRELAUNCH,
      });
    } else if (selectedPill[0]?.label === "hindi") {
      setVideoURL(hindiURL);
      triggerGa({
        category: GA_CATEGORIES.USER_INTERACTION,
        action: GA_ACTION.BUTTON_CLICK,
        label: GA_LABEL.LANGUAGE_CHANGE,
        label2: "hindi",
        page: GA_PAGE.PAYOUTS_PRELAUNCH,
        screenName: GA_SCREEN_NAME.PAYOUTS_PRELAUNCH,
      });
    }
  }, [selectedPill]);

  return (
    <div
      style={{
        position: "relative",
        minHeight: window.innerHeight,
        background: "#FFF",
        display: "flex",
        flexDirection: "column",
        justifyContent: "flex-start",
        alignContent: "center",
      }}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "column",

          paddingTop: "1rem",
          paddingLeft: "1rem",
          paddingRight: "1rem",
          paddingBottom: "1rem",
        }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "flex-start",
          }}
        >
          <WishlinkButton
            onClick={goBack}
            style={{
              color: "#0E1226",
              textAlign: " center",
              fontSize: "1rem",
              fontStyle: "normal",
              fontWeight: "400",
            }}
          >
            x
          </WishlinkButton>
        </div>
        <div
          style={{
            marginTop: "0.75rem",
            marginBottom: "1.5rem",
          }}
        >
          <WishlinkTypography
            color={"#000"}
            fontWeight={600}
            lineHeight={"normal"}
            fontSize={"1.25rem"}
            textAlign={"left"}
          >
            Watch this video to learn what’s changing in Payouts process
          </WishlinkTypography>
        </div>
        <div
          style={{
            marginBottom: "0.5rem",
          }}
        >
          <PillGroup
            pills={pills}
            selectedPill={selectedPill}
            onClickSelectPill={onClickSelectPill}
          />
        </div>
        <Box
          sx={{
            width: "100% !important",
            boxSizing: "border-box",
            objectFit: "contain !important",
            borderRadius: "0.5rem",
            margin: "auto",
            position: "relative",
            top: 0,
            marginTop: "1rem",
            maxHeight: "100%",
            height: "14.75rem",
          }}
        >
          <ReactPlayer
            url={videoURL}
            className="video-player"
            width={"100%"}
            height={"100%"}
            controls
            onPlay={() => {
              triggerGa({
                category: GA_CATEGORIES.USER_EDUCATION,
                action: GA_ACTION.WATCH_VIDEO,
                label: GA_LABEL.PAYOUTS_PRELAUNCH,
                label2: selectedPill[0]?.label,
                page: GA_PAGE.PAYOUTS_PRELAUNCH,
                screenName: GA_SCREEN_NAME.PAYOUTS_PRELAUNCH,
              });
            }}
            onPause={() => {
              triggerGa({
                category: GA_CATEGORIES.USER_EDUCATION,
                action: GA_ACTION.PAUSE_VIDEO,
                label: GA_LABEL.PAYOUTS_PRELAUNCH,
                label2: selectedPill[0]?.label,
                page: GA_PAGE.PAYOUTS_PRELAUNCH,
                screenName: GA_SCREEN_NAME.PAYOUTS_PRELAUNCH,
              });
            }}
          />
        </Box>
        <div
          style={{
            marginTop: "1.5rem",
          }}
        >
          <div>
            <WishlinkTypography
              color={"#666"}
              fontWeight={400}
              lineHeight={"normal"}
              fontSize={"0.875rem"}
              textAlign={"left"}
              mb={"0.5rem"}
            >
              Things to note:
            </WishlinkTypography>
          </div>
          <div>
            <ChangesCard number={"1"} text={englishTexts[0]} />
            <ChangesCard number={"2"} text={englishTexts[1]} />
            <ChangesCard number={"3"} text={englishTexts[2]} />
          </div>
        </div>
      </div>
      <div
        style={{
          margin: "0 auto",
          width: "28rem",
          height: "auto",
          overflow: "hidden",
          position: "fixed",
          bottom: "0px",
        }}
      >
        <Poweredby />
      </div>
    </div>
  );
};

export default PayoutExplainerVideo;
