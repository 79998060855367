import { getAssetUrl } from "../../utils/common";

export const SORT_BY_CATEGORIES = {
  WHATS_NEW: "recent",
  ON_SALE: "sale",
  PRICE_LOW_TO_HIGH: "price_low",
  PRICE_HIGH_TO_LOW: "price_high",
  COMMISSION: "commission",
};

export const FILTER_BY_CATEGORIES = {
  SIZE: "size",
  COLOR: "color",
  BRAND: "brand",
  CATEGORIES: "categories",
  DISCOUNT: "discount",
};

export const HeaderButtonStyle = {
  backgroundColor: "rgba(255, 255, 255, 0.7)",
  borderRadius: "1.1rem",
};

export const HeaderButtonStyleOpaque = {
  backgroundColor: "#fff",
  borderRadius: "1.1rem",
};

export const initialParameters = {
  collectionId: 0,
  brandId: 0,
  subcategoryId: 0,
};

export const sortByOptionsInitialState = [
  {
    label: "What's New",
    value: SORT_BY_CATEGORIES.WHATS_NEW,
    icon_url: getAssetUrl("star-outline.svg"),
  },
  // {
  //   label: "On Sale",
  //   value: SORT_BY_CATEGORIES.ON_SALE,
  //   icon_url: getAssetUrl("sale_coupon.svg"),
  // },
  {
    label: "Price - low to high",
    value: SORT_BY_CATEGORIES.PRICE_LOW_TO_HIGH,
    icon_url: getAssetUrl("rupee_up.svg"),
  },
  {
    label: "Price - high to low",
    value: SORT_BY_CATEGORIES.PRICE_HIGH_TO_LOW,
    icon_url: getAssetUrl("rupee_down.svg"),
  },
  {
    label: "Commission %",
    value: SORT_BY_CATEGORIES.COMMISSION,
    icon_url: getAssetUrl("sourcing_plain.svg"),
  },
];

export const filterOptionsInitialState = [
  {
    label: "Size",
    value: "size",
    options: [],
  },
  {
    label: "Colour",
    value: "colour",
    options: [],
  },
  {
    label: "Brand",
    value: "brand",
    options: [],
  },
  {
    label: "Categories",
    value: "categories",
    options: [],
  },
  {
    label: "Discount",
    value: "discount",
    options: [],
  },
  {
    label: "Price",
    value: "price",
    options: [],
  },
];

export const filterOptionsSelectedInitialState = [
  {
    value: "size",
    options: [],
  },
  {
    value: "colour",
    options: [],
  },
  {
    value: "brand",
    options: [],
  },
  {
    value: "categories",
    options: [],
  },
  {
    value: "discount",
    options: [],
  },
  {
    value: "price",
    options: ["0-10000"],
  },
];

export const SourcingCartActions = {
  ADD_TO_CART: "ADD_TO_CART",
  REMOVE_FROM_CART: "REMOVE_FROM_CART",
  MOVE_TO_WISHLIST: "MOVE_TO_WISHLIST",
};

export const SourcingCartPages = {
  ADD_PRODUCTS: "Add Products",
  SELECT_ADDRESS: "Select address",
  PLACE_REQUEST: "Place request",
};

export const DeliveryStatus = {
  RECEIVED: "received",
  ORDERED: "ordered",
  CANCELLED: "cancelled",
  SHIPPED: "shipped",
  DELIVERED: "delivered",
  DELIVERY_FAILED: "delivery_failed",
  RETURN_INITIATED: "return_initiated",
  RETURN_PICKED_UP: "return_picked_up",
  RETURN_FAILED_X: "return_failed_x",
  RETURN_FAILED_AND_DEAD: "return_failed_and_dead",
  RETURNED: "returned",
  MANUAL_ORDER_CANCELLED: "manual_order_canceled",
};

export const MyOrdersTimeFilters = {
  THIS_MONTH: "This Month",
  TODAY: "Today",
  YESTERDAY: "Yesterday",
  LAST_7_DAYS: "Last 7 days",
  LAST_MONTH: "Last month",
  LAST_3_MONTHS: "Last 3 months",
  ALL_TIME: "All time",
};

export const MyOrdersTimeFilterDefault = MyOrdersTimeFilters.THIS_MONTH;
export const SIZE_ORDER = ["XXS", "XS", "S", "M", "L", "XL", "XXL"];

export const CREDIT_TYPES = {
  SOURCING: "sourcing",
  BARTER: "barter",
  BRANDS: "brands",
  NONE: "none",
};

export const CREDIT_CONDITIONS = {
  SOURCING: "Sourcing",
  BARTER: "Barter",
  BOTH: "Both",
};

export const videoForBarter = "W8BETqZF-4o";
export const videoForWithoutBarter = "5orkC4jJyvI";

export const MANUAL_ORDER_CANCELLED_REASON = "Credit Limit exceeded";

export const SOURCING_REVOKE_ARRAY = [
  {
    id: 2,
    title: "Due to suspicious activity from your account.",
    subtitle: "Reach out to +91 9319007907 to know more! 📜",
    showSubtitleHeader: false,
  },
  {
    id: 3,
    title:
      "Since Telegram doesn't require content creation. Therefore, there's no need for you to source products.🚫",
    subtitle:
      "✅ Create content on Instagram/YouTube & qualify for Sourcing next month",
    showSubtitleHeader: true,
  },
  {
    id: 4,
    title: "As you are not posting any content on Wishlink since February",
    subtitle:
      "✅  Create more than 3 engaging content on Wishlink & start earning again via Wishlink💰",
    showSubtitleHeader: true,
  },
  {
    id: 5,
    title:
      "🚩Because you have sourced products but not posted content for products delivered till 4th March",
    subtitle: "✅ Post content for all sourced products to get access back",
    showSubtitleHeader: true,
  },
  {
    id: 6,
    title: "🚩Due to low sales generated via Wishlink Sourcing",
    subtitle: `Boost your earnings by:
      ✅ Sharing Wishlinks on stories & broadcast channel
      ✅ Create and post content of your sourced products`,
    showSubtitleHeader: true,
  },
];

export const SOURCING_STEPS = [
  {
    step_number: 1,
    title: "Sourcing access is given to top creators of Wishlink",
    subtitle: "",
    icon: "sourcing-work-1.png",
  },
  {
    step_number: 2,
    title:
      "You get sourcing access if you make content & drive sales on multiple brands.",
    subtitle: "Meesho content & sales are not valid",
    icon: "sourcing-work-2.png",
  },
  {
    step_number: 3,
    title:
      "Sourcing credits are allocated by our Algorithm on every 25th and are valid till 25th of next month",
    subtitle: "",
    icon: "sourcing-work-3.png",
  },
  {
    step_number: 4,
    title: "You will be notified once you get sourcing access",
    subtitle: "",
    icon: "sourcing-work-4.png",
  },
];

export const SOURCING_ACCESS_STEPS = [
  {
    step_number: 1,
    title: "Create content and share your wishlinks",
    subtitle: "",
    icon: "retain-access-1.png",
  },
  {
    step_number: 2,
    title: "Drive high sales on your sourced products",
    subtitle: "Atleast 7X sales of your sourced product amount",
    icon: "retain-access-2.png",
  },
  {
    step_number: 3,
    title: "Return your sourced product in 14 days",
    subtitle: "",
    icon: "retain-access-3.png",
  },
];

export const SOURCING_ANSWERS = [
  {
    icon: "sourcing-faq-1.png",
    title: "Sourcing Access",
    subtitle: "Track orders, delivery and return timeline",
    redirect_url: "/faq/9",
  },
  {
    icon: "sourcing-faq-2.png",
    title: "My Order",
    subtitle: "Check eligibility for sourcing products",
    redirect_url: "/faq/10",
  },
  {
    icon: "sourcing-faq-3.png",
    title: "Credits",
    subtitle: "Learn more about credits",
    redirect_url: "/faq/11",
  },
  {
    icon: "sourcing-faq-4-final.png",
    title: "Sourcing Guidelines",
    subtitle: "Guidelines for sourcing products",
    redirect_url: "/faq/12",
  },
];

export const GET_SOURCING_ACCESS_MINI = [
  {
    step_number: 1,
    title: "Make content on multiple brands",
    subtitle: "Meesho content & sales are not valid",
    icon: "mini-sourcing-access-1.png",
  },
  {
    step_number: 2,
    title: "Drive high sales on your content",
    subtitle: "",
    icon: "mini-sourcing-access-2.png",
  },
  {
    step_number: 3,
    title: "You will be notified once you get sourcing access",
    subtitle: "",
    icon: "mini-sourcing-access-3.png",
  },
];

export const SOURCING_REVOKE_REASONS = [
  {
    step: 1,
    title: "You are not creating content on Wishlink actively",
  },
  {
    step: 2,
    title: "You have not created content on previously sourced products",
  },
  {
    step: 3,
    title: "You have not returned sourced products",
  },
  {
    step: 4,
    title: "You have driven low sales from sourced products",
  },
  {
    step: 5,
    title: "We detected suspicious activity on your account",
  },
];

export const SHOW_CONTENT_STATUS = [
  "Delivered",
  "Return Initiated",
  "Returned",
  "Return Failed",
  "Return Picked up",
];

export const CONTENT_DELIVERY_STATUS = [
  "delivered",
  "return_initiated",
  "return_failed_x",
  "returned",
];

export const TO_BE_RETURNED_STATUS = ["return_initiated"];

export const SOURCING_CART_ENTRYPOINT = {
  BANNER: "BANNER",
  BRAND: "BRAND",
  CATEGORY: "CATEGORY",
  COLLECTION: "COLLECTION",
  SEARCH: "SEARCH",
};
