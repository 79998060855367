import React from "react";
import { Grid, useTheme } from "@mui/material";
import ListSkeletonElement from "../ListSkeletonElement";
import { tokens } from "../../theme";

export const ListSkeletonContainer = (props) => {
  const { numRows = 3, showForValue = true } = props;
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  return (
    <Grid container sx={{ width: "100%" }}>
      <>
        {Array(numRows)
          .fill(0)
          .map((placeholder, idx) => (
            <div key={`list-skeleton${idx}`} className="w-100">
              {!!idx && (
                <div style={{ padding: "0rem 0rem", width: "100%" }}>
                  <hr
                    style={{
                      borderWidth: "0.0625rem",
                      borderStyle: "solid",
                      borderColor: colors.grey[400],
                    }}
                  />
                </div>
              )}
              <Grid item xs={12}>
                <ListSkeletonElement showForValue={showForValue} />
              </Grid>
            </div>
          ))}
      </>
    </Grid>
  );
};

export default ListSkeletonContainer;
