import React, { Suspense, useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import "./App.css";
import { CssBaseline, ThemeProvider } from "@mui/material";
import RenderRoutes from "./utils/router";
import appRoutes from "./Routes";
import DataLayer from "./DataLayer";
import { PostHogProvider } from "posthog-js/react";

import { ColorModeContext, useMode } from "./theme";
import WishlinkNotification from "./WishlinkComponents/WishlinkNotification";
import LoadingPage from "./components/Fallback/LoadingPage";
import CONFIG, { getAppBuildVersion } from "./utils/config";
import PullToRefresh from "pulltorefreshjs";
import {
  CREATOR_EXPERIMENT_TYPE,
  FORCE_UPDATE_BUILD_NUMBER,
  PAGE_URLS,
} from "./utils/constants";
import BrandPageOverlay from "./components/Brands/BrandPageOverlay";

import RecommendedCollectionsDataLayer from "./RecommendedCollectionsStore/RecommendedCollectionsDataLayer";
import CollectionsDataLayer from "./sourcing/views/Collections/collectionsDataLayer";
import CartDataLayer from "./sourcing/views/Cart/cartDataLayer";
import MyOrdersDataLayer from "./sourcing/views/MyOrders/myOrdersDataLayer";
import ProductDataLayer from "./sourcing/views/Product/productDataLayer";
import HomeDataLayer from "./sourcing/views/Home/homeDataLayer";
import CreditsDataLayer from "./sourcing/views/Credits/creditsDataLayer";
import YoutubeShoppingDataLayer from "./YoutubeShoppingStore/YoutubeShoppingDataLayer";
import ProfileDataLayer from "./ProfileDataLayer/ProfileDataLayer";
import HomePageDataLayer from "./HomePageDataLayer/HomePageDataLayer";
import { showNewPostDrawer, updateIsDiyAllowed } from "./redux/redux";
import BrandsPageDataLayer from "./BrandsPageDataLayer/BrandsPageDataLayer";
import WebEngageScript from "./components/WebEngageScript";
import { useMarkNotificationAsReadMutation } from "./redux/reduxAPI";
import { SupportWidget } from "./components/SupportWidget/SupportWidget";
import { AppForceUpdate } from "./components/AppForceUpdate";
import NewPostDrawer from "./components/NewPostDrawer/NewPostDrawer";
import FooterContainer from "./components/FooterContainer";
import LoadScripts from "./components/LoadScripts";
import { useBlitzllama } from "./hooks/Blitzllama/useBlitzllama";
const options = {
  api_host: CONFIG.API.postHogHost,
  enable_recording_console_log: true,
  autocapture: false,
  disable_session_recording: true,
};

function App() {
  const [theme, colorMode] = useMode();
  const [showFooter, setShowFooter] = useState(true);
  const [userAppToken, setUserAppToken] = useState(null);
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [triggerMarkNotificationAsRead] = useMarkNotificationAsReadMutation();
  const { milestones } = useSelector((state) => state.appReducer);
  const { creatorData } = useSelector((state) => state.apiDataReducer);
  const { newPostDrawer } = useSelector((state) => state.apiDataReducer);
  const { initializeBlitzllama } = useBlitzllama();

  const showBrandOverlay =
    location.pathname.includes("brands") &&
    !(localStorage.firebaseToken || localStorage.adminToken);

  useEffect(() => {
    initializeBlitzllama();
  }, []);

  useEffect(() => {
    const hashInURL = window.location.hash;
    PullToRefresh.destroyAll();
    if (!hashInURL && !window.location.pathname.includes("/leaderboard/")) {
      PullToRefresh.init({
        mainElement: "body",
        shouldPullToRefresh: () => !window.scrollY,
        onRefresh() {
          if (document.body.scrollTop === 0 && !hashInURL) {
            window.location.reload();
          }
        },
      });
    }
  }, [location]);

  if (window.cordova) {
    const setupPushNotifications = () => {
      let FirebasePlugin = window.FirebasePlugin;
      try {
        FirebasePlugin.grantPermission((hasPermission) => {
          // console.log(
          //   "Permission was " + (hasPermission ? "granted" : "denied")
          // );
        });

        firebasePNSetup();
      } catch (e) {
        // console.error("FirebasePlugin grantPermission error", e);
      }
    };

    const firebasePNSetup = () => {
      window.FirebasePlugin.onTokenRefresh(
        (fcmToken) => {
          //eslint-disable-next-line
          console.log("Token refreshed: " + fcmToken);
          if (localStorage?.firebaseToken) {
            setUserAppToken(fcmToken);
          }
        },
        (error) => {
          // console.log("Failed to refresh token", error);
        }
      );
      // }

      // iOS device token
      if (window.cordova.platformId === "ios") {
        window.FirebasePlugin.onApnsTokenReceived(
          (apnsToken) => {
            // console.log("APNS token received: " + apnsToken);
          },
          (error) => {
            // console.error("Failed to receive APNS token", error);
          }
        );
      }

      window.FirebasePlugin.onMessageReceived(
        (message) => {
          if (message.tap === "background") {
            dispatch(updateIsDiyAllowed(true));
            if (window.cordova?.platformId === "ios") {
              navigate(message?.aps?.url);
              updateNotificationRead(message?.aps?.notification_id);
            } else if (window.cordova?.platformId === "android") {
              navigate(message.redirect_route);
              updateNotificationRead(message?.notification_id);
            }
          }
        },
        (error) => {
          // console.error(error);
        }
      );
    };

    const updateNotificationRead = (notificationId) => {
      if (
        !localStorage.adminToken &&
        localStorage?.firebaseToken &&
        notificationId
      ) {
        triggerMarkNotificationAsRead({ notificationId: notificationId });
      }
    };

    document.addEventListener("deviceready", () => {
      window?.universalLinks?.subscribe("defaultevent", (eventData) => {
        if (eventData?.path) {
          dispatch(updateIsDiyAllowed(true));
          navigate(eventData?.url?.replace("https://creator.wishlink.com", ""));
        }
      });
      setupPushNotifications();
    });
  }

  const triggerRegisterDeviceFcmToken = (body) => {
    fetch("https://api.wishlink.com/api/c/registerAppToken", {
      method: "POST",
      body: JSON.stringify(body),
      headers: {
        "Content-type": "application/json; charset=UTF-8",
        Authorization: localStorage?.firebaseToken,
      },
    }).catch((e) => {
      // console.error(`Error in registering device token on server. Error: ${e}`);
    });
  };

  useEffect(() => {
    if (userAppToken) {
      window.FirebasePlugin.hasPermission((permission) => {
        triggerRegisterDeviceFcmToken({
          fcm_token: userAppToken,
          device_id: window.device?.uuid,
          platform: window.device?.platform.toLowerCase(),
          model: window.device?.model,
          has_permission: permission,
        });
      });
    }
  }, [userAppToken]);

  useEffect(() => {
    appRoutes.forEach((route) => {
      let isFooterVisible = Boolean(
        route.showFooter &&
          (localStorage.firebaseToken || localStorage.adminToken)
      );
      if (
        route.path.substring(1).split("/")[0] ===
        location.pathname.substring(1).split("/")[0]
      ) {
        setShowFooter(isFooterVisible);
      }
    });
  }, [location]);

  useEffect(() => {
    if (location.hash === "#create") {
      dispatch(showNewPostDrawer(true));
    } else {
      dispatch(showNewPostDrawer(false));
    }
  }, [location]);

  let firstLinkOnboardingComplete =
    (milestones?.milestones_found &&
      (milestones?.check_first_link_created?.achieved ||
        milestones?.check_first_link_skipped?.achieved)) ||
    creatorData?.data?.data?.experiment_type === CREATOR_EXPERIMENT_TYPE.TEST;

  const showFooterContainer =
    (location.pathname !== PAGE_URLS.HOME &&
      location.pathname !== PAGE_URLS.PROFILE &&
      location.pathname !== PAGE_URLS.BRANDS &&
      showFooter) ||
    (location.pathname === PAGE_URLS.HOME && firstLinkOnboardingComplete) ||
    (location.pathname === PAGE_URLS.PROFILE && firstLinkOnboardingComplete) ||
    (location.pathname === PAGE_URLS.BRANDS && firstLinkOnboardingComplete);
  const isAdmin = window?.localStorage.getItem("adminToken");
  const creatorID = creatorData?.data?.data?.id;
  const appBuildVersion = getAppBuildVersion();
  const isForceUpdateAvailable =
    Number.isInteger(appBuildVersion) &&
    appBuildVersion < FORCE_UPDATE_BUILD_NUMBER;

  return (
    <PostHogProvider apiKey={CONFIG.API.postHogKey} options={options}>
      <ColorModeContext.Provider value={colorMode}>
        <ThemeProvider theme={theme}>
          <CssBaseline />
          <div className="App" style={{ minHeight: window.innerHeight }}>
            {isForceUpdateAvailable ? (
              <AppForceUpdate isForceUpdateAvailable={isForceUpdateAvailable} />
            ) : (
              <>
                <DataLayer>
                  <Suspense fallback={<LoadingPage />}>
                    <RenderRoutes routes={appRoutes} />
                  </Suspense>
                </DataLayer>
                {!!showFooterContainer && <FooterContainer />}
                {newPostDrawer && (
                  <Suspense fallback={<></>}>
                    <NewPostDrawer />
                  </Suspense>
                )}
                {showBrandOverlay ? <BrandPageOverlay /> : <></>}
                <WishlinkNotification />
                <CollectionsDataLayer />
                <HomeDataLayer />
                <CartDataLayer />
                <MyOrdersDataLayer />
                <ProductDataLayer />
                <CreditsDataLayer />
                <RecommendedCollectionsDataLayer />
                <YoutubeShoppingDataLayer />
                <ProfileDataLayer />
                <HomePageDataLayer />
                <BrandsPageDataLayer />
                {!isAdmin && creatorID && <SupportWidget />}
                <WebEngageScript />
                <LoadScripts />
              </>
            )}
          </div>
        </ThemeProvider>
      </ColorModeContext.Provider>
    </PostHogProvider>
  );
}

export default App;
