import { useEffect, useState } from "react";
import { useBlitzllama } from "./useBlitzllama";
import { EVENTS } from "./constants";

const usePageExitHandler = () => {
  const { triggerEvent, setUserProperties } = useBlitzllama();
  const [startTime, setStartTime] = useState(0);

  useEffect(() => {
    setStartTime(Date.now());
  }, []);

  const handlePostDetailPageExit = () => {
    const savedVisitCount =
      localStorage.getItem("post_detail_page_visit_count") || 0;
    const newVisitCount = parseInt(savedVisitCount) + 1;
    const timeSpent = Date.now() - startTime;
    triggerEvent(EVENTS.post_detail_page_exit, {
      time_spent: timeSpent,
      post_detail_page_exit_count: newVisitCount,
    });
    setUserProperties({
      post_detail_page_exit_count: newVisitCount,
    });

    localStorage.setItem("post_detail_page_visit_count", newVisitCount);
  };

  const handleMoMPageExit = () => {
    const savedVisitCount = localStorage.getItem("mom_page_visit_count") || 0;
    const newVisitCount = parseInt(savedVisitCount) + 1;
    const timeSpent = Date.now() - startTime;
    triggerEvent(EVENTS.mom_page_exit, {
      time_spent: timeSpent,
      mom_page_exit_count: newVisitCount,
    });
    setUserProperties({
      mom_page_exit_count: newVisitCount,
    });

    localStorage.setItem("mom_page_visit_count", newVisitCount);
  };

  const handlePastPageExit = () => {
    const savedVisitCount =
      localStorage.getItem("past_payout_detail_page_exit_count") || 0;
    const newVisitCount = parseInt(savedVisitCount) + 1;
    triggerEvent(EVENTS.past_payout_detail_page_exit, {
      past_payout_detail_page_exit_count: newVisitCount,
    });
    setUserProperties({
      past_payout_detail_page_exit_count: newVisitCount,
    });

    localStorage.setItem("past_payout_detail_page_exit_count", newVisitCount);
  };

  const handlePendingCommissionPageExit = () => {
    const savedVisitCount =
      localStorage.getItem("pending_commission_page_exit_count") || 0;
    const newVisitCount = parseInt(savedVisitCount) + 1;
    triggerEvent(EVENTS.pending_commission_page_exit, {
      pending_commission_page_exit_count: newVisitCount,
    });
    setUserProperties({
      pending_commission_page_exit_count: newVisitCount,
    });

    localStorage.setItem("pending_commission_page_exit_count", newVisitCount);
  };

  const handleNewPageExit = (pageName) => {
    const savedVisitCount =
      localStorage.getItem(`${pageName}_page_exit_count`) || 0;
    const newVisitCount = parseInt(savedVisitCount) + 1;
    triggerEvent(`${pageName}_page_exit`, {
      [`${pageName}_page_exit_count`]: newVisitCount,
    });
    setUserProperties({
      [`${pageName}_page_exit_count`]: newVisitCount,
    });

    localStorage.setItem(`${pageName}_page_exit_count`, newVisitCount);
  };

  return {
    handlePostDetailPageExit,
    handleMoMPageExit,
    handlePastPageExit,
    handlePendingCommissionPageExit,
    handleNewPageExit,
  };
};

export default usePageExitHandler;
