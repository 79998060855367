import { Suspense, lazy, useEffect, useState } from "react";

const WishlinkLottie = lazy(() =>
  import(/* webpackChunkName: "WishlinkLottie" */ "../WishlinkLottie")
);

const WishlinkLottieLazyLoad = ({ ...props }) => {
  const [isIntersecting, setIsIntersecting] = useState(false);

  const handleIntersection = (entries) => {
    entries.forEach((entry) => {
      if (entry.isIntersecting) {
        setIsIntersecting(true);
      }
    });
  };

  useEffect(() => {
    const observer = new IntersectionObserver(handleIntersection, {
      rootMargin: "0px",
      threshold: 0.1,
    });

    observer.observe(document.querySelector("#lottie"));
    return () => observer.disconnect();
  }, []);

  return (
    <div id="lottie">
      {isIntersecting && (
        <Suspense fallback={<></>}>
          <WishlinkLottie {...props} />
        </Suspense>
      )}
    </div>
  );
};

export default WishlinkLottieLazyLoad;
