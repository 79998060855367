import { useLocation, useNavigate } from "react-router-dom";
import { getAssetUrl } from "../../../utils/common";
import WishlinkTypography from "../../../WishlinkComponents/WishlinkTypography";
import { useContext, useEffect, useRef, useState } from "react";
import { Grid } from "@mui/material";
import WishlinkDrawer from "../../../WishlinkComponents/WishlinkDrawer";
// import { useSelector } from "react-redux";
import { APIContext } from "../../../apiLayer";
import { useDispatch, useSelector } from "react-redux";
import { triggerGa } from "../../../utils/gaUtils";
import {
  GA_ACTION,
  GA_CATEGORIES,
  GA_LABEL,
  GA_PAGE,
  GA_SCREEN_NAME,
} from "../../../utils/constants";
import { updateIsDiyAllowed } from "../../../redux/redux";

const EngagePostChecker = (props) => {
  const { report, hideEngageHealth } = props;
  const navigate = useNavigate();
  const location = useLocation();
  const apis = useContext(APIContext);
  const { instaPostsList } = useSelector((state) => state.apiDataReducer);
  const dispatch = useDispatch();

  const [drawerState, setDrawerState] = useState(false);
  const [itemHeight, setItemHeight] = useState("");
  const [postFound, setPostFound] = useState("");
  const [searchDone, setSearchDone] = useState(false);

  const { postsData } = useSelector((state) => state.apiDataReducer);
  const statusIcon =
    searchDone && postFound ? "confirmed-check.svg" : "engage-post-check.svg";

  useEffect(() => {
    if (location.hash === "#post-check") setDrawerState(true);
    else setDrawerState(false);
  }, [location]);

  useEffect(() => {
    apis.getInstaPostsListData({ nextPageCursor: "" });
    apis.getStorePostsData({
      page: 1,
      sort: "-added_on",
      filter: "{}",
      limit: 20,
    });
  }, []);

  const imgRef = useRef(null);

  useEffect(() => {
    setItemHeight(imgRef?.current?.clientWidth);
  }, [imgRef]);

  const onClickSearchPost = (permalink) => {
    navigate(-1, { replace: true });
    const postFound = postsData.find((post) => post?.post_url === permalink);
    if (postFound) {
      setSearchDone(true);
      setPostFound(true);
    } else {
      setSearchDone(true);
      setPostFound(false);
    }
  };

  const onClickCheckPost = () => {
    triggerGa({
      category: GA_CATEGORIES.CHECK,
      action: GA_ACTION.CREATE_IG_POST,
      label: GA_LABEL.POST_IG,
      page: GA_PAGE.DEBUG,
      screenName: GA_SCREEN_NAME.DEBUG,
    });
    navigate("#post-check", {
      state: {
        report,
        hideEngageHealth,
      },
    });
  };

  const onClickCreateWishlinkPost = () => {
    triggerGa({
      category: GA_CATEGORIES.CREATE,
      action: GA_ACTION.CREATE_IG_POST,
      label: GA_LABEL.POST_IG,
      page: GA_PAGE.DEBUG,
      screenName: GA_SCREEN_NAME.DEBUG,
    });
    dispatch(updateIsDiyAllowed(true));
    navigate("/new-post#instagram");
  };

  return (
    <div>
      <div className="flex">
        <img src={getAssetUrl(statusIcon)} alt="icon" />
        <WishlinkTypography
          fontSize="0.75rem"
          fontWeight={400}
          color="#141414"
          className="ml-2"
        >
          Post Made on Wishlink
        </WishlinkTypography>
        <WishlinkTypography
          fontSize="0.75rem"
          fontWeight={400}
          color={postFound ? "#18A716" : "#E91E63"}
          className="ml-2"
        >
          {searchDone ? (postFound ? ": Yes" : ": No") : ""}
        </WishlinkTypography>
      </div>
      {!searchDone ? (
        <div className="text-left ml-4">
          <WishlinkTypography
            fontSize="0.6875rem"
            fontStyle="italic"
            fontWeight={400}
            color="#7f7f7f"
            className="ml-2"
          >
            Engage not working for specific post?
          </WishlinkTypography>
        </div>
      ) : (
        <></>
      )}
      {searchDone && !postFound ? (
        <WishlinkTypography
          fontSize="0.6875rem"
          fontWeight={400}
          color="#7f7f7f"
          className="text-left"
          fontStyle="italic"
        >
          You need to publish post on Wishlink for Engage to work
        </WishlinkTypography>
      ) : (
        <></>
      )}
      {!searchDone ? (
        <div
          style={{
            alignItems: "flex-start",
            display: "flex",
            flexDirection: "column",
            marginLeft: "1.4rem",
          }}
        >
          <div
            className="p-1 pl-4 pr-4 mt-4"
            style={{
              borderRadius: "0.625rem",
              border: `1px solid #000`,
              cursor: "pointer",
            }}
            onClick={onClickCheckPost}
          >
            <WishlinkTypography
              fontSize="0.75rem"
              fontWeight={400}
              color={"#000"}
            >
              Check Wishlink Post
            </WishlinkTypography>
          </div>
        </div>
      ) : (
        <></>
      )}
      {searchDone && !postFound ? (
        <div
          style={{
            alignItems: "flex-start",
            display: "flex",
            flexDirection: "column",
            marginLeft: "1.4rem",
          }}
        >
          <div
            className="p-1 pl-4 pr-4 mt-4"
            style={{
              borderRadius: "0.625rem",
              border: `1px solid #000`,
              cursor: "pointer",
            }}
            onClick={() => onClickCreateWishlinkPost()}
          >
            <WishlinkTypography
              fontSize="0.75rem"
              fontWeight={400}
              color={"#000"}
            >
              Create Wishlink Post
            </WishlinkTypography>
          </div>
        </div>
      ) : (
        <></>
      )}
      <WishlinkDrawer
        drawerOpen={drawerState}
        onClose={() => navigate(-1, { replace: true })}
        onOpen={() => {}}
      >
        <Grid container spacing={0.5} className="w-100">
          {instaPostsList?.map((post, idx) => {
            return (
              <Grid
                item
                xs={4}
                key={`instagram-post-${idx}`}
                ref={idx === 0 ? imgRef : null}
                sx={{ height: itemHeight }}
              >
                <div
                  className="flex flex-column relative"
                  style={{ height: "100%" }}
                  data-cy={`diy-instagram-post-${idx}`}
                >
                  <img
                    src={post?.thumbnail_url || post?.media_url}
                    style={{
                      width: "100%",
                      height: "100%",
                      objectFit: "cover",
                    }}
                    alt="instagram-post"
                    onClick={() => onClickSearchPost(post?.permalink)}
                  />
                </div>
              </Grid>
            );
          })}
        </Grid>
      </WishlinkDrawer>
    </div>
  );
};

export default EngagePostChecker;
