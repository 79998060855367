import { useDispatch } from "react-redux";
import { useEffect } from "react";
import {
  updateRecommendedCollectionTags,
  updateRecommendedCollectionTriggers,
  updateRecommendedCollections,
  updateRecommendedCollectionTagsLoading,
  updateRecommendedCollectionsLoading,
  updateSingleRecommendedCollection,
  updateSingleRecommendedCollectionLoading,
  updateConvertedRecommendedCollection,
} from "./RecommendedCollectionsRedux";
import {
  useLazyGetRecommendedCollectionsTagsQuery,
  useGetRecommendedCollectionsMutation,
  useLazyGetRecommendedCollectionByIdQuery,
  useGetRecommendedProductWishlinkMutation,
  useConvertRecommendedCollectionMutation,
} from "./RecommendedCollectionsReduxAPI";
import { showToastNotification } from "../redux/redux";
import { getDataPointLoadingValue } from "../utils/common";
// import { getDataPointLoadingValue } from "../utils/common";

const RecommendedCollectionsDataLayer = () => {
  const dispatch = useDispatch();

  const [triggerRecommendedCollectionsTags, resultRecommendedCollectionsTags] =
    useLazyGetRecommendedCollectionsTagsQuery();
  const [triggerRecommendedCollections, resultRecommendedCollections] =
    useGetRecommendedCollectionsMutation();
  const [triggerRecommendedCollectionById, resultRecommendedCollectionById] =
    useLazyGetRecommendedCollectionByIdQuery();
  const [triggerRecommendedProductWishlink] =
    useGetRecommendedProductWishlinkMutation();
  const [
    triggerConvertRecommendedCollection,
    resultConvertRecommendedCollection,
  ] = useConvertRecommendedCollectionMutation();

  const trigger = {
    getRecommendedCollectionsTags: async (apiCallParams) =>
      await triggerRecommendedCollectionsTags(apiCallParams),
    getRecommendedCollections: async (apiCallParams) =>
      await triggerRecommendedCollections(apiCallParams),
    getRecommendedCollectionById: async (apiCallParams) =>
      await triggerRecommendedCollectionById(apiCallParams),
    getRecommendedProductWishlink: async (apiCallParams) =>
      await triggerRecommendedProductWishlink(apiCallParams),
    convertRecommendedCollection: async (apiCallParams) =>
      await triggerConvertRecommendedCollection(apiCallParams),
  };

  useEffect(() => {
    dispatch(updateRecommendedCollectionTriggers(trigger));
  }, []);

  useEffect(() => {
    if (resultRecommendedCollectionsTags?.data?.success === false) {
      showToastNotification({
        variant: "error",
        message: resultRecommendedCollectionsTags?.data?.msg,
      });
      return;
    }
    dispatch(
      updateRecommendedCollectionTagsLoading(
        getDataPointLoadingValue(resultRecommendedCollectionsTags)
      )
    );
    dispatch(updateRecommendedCollectionTags(resultRecommendedCollectionsTags));
  }, [resultRecommendedCollectionsTags]);

  useEffect(() => {
    if (resultRecommendedCollections?.data?.success === false) {
      showToastNotification({
        variant: "error",
        message: resultRecommendedCollections?.data?.msg,
      });
      return;
    }
    dispatch(
      updateRecommendedCollectionsLoading(
        getDataPointLoadingValue(resultRecommendedCollections)
      )
    );
    dispatch(updateRecommendedCollections(resultRecommendedCollections));
  }, [resultRecommendedCollections]);

  useEffect(() => {
    if (resultRecommendedCollectionById?.data?.success === false) {
      showToastNotification({
        variant: "error",
        message: resultRecommendedCollectionById?.data?.msg,
      });
      return;
    }
    dispatch(
      updateSingleRecommendedCollectionLoading(
        getDataPointLoadingValue(resultRecommendedCollectionById)
      )
    );
    dispatch(
      updateSingleRecommendedCollection(resultRecommendedCollectionById)
    );
  }, [resultRecommendedCollectionById]);

  useEffect(() => {
    dispatch(
      updateConvertedRecommendedCollection(resultConvertRecommendedCollection)
    );
  }, [resultConvertRecommendedCollection]);

  return <></>;
};

export default RecommendedCollectionsDataLayer;
