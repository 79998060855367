import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  recommendedCollectionTags: [],
  recommendedCollectionTagsLoading: false,
  recommendedCollectionTriggers: {},
  recommendedCollections: [],
  recommendedCollectionsLoading: false,
  recommendedCollectionsMaxPages: 0,
  isRecommendedCollectionScrollAllowed: true,
  singleRecommendedCollection: {},
  singleRecommendedCollectionLoading: false,
  convertedRecommendedCollectionCopyText: "",
};

export const RecommendedCollectionSlice = createSlice({
  name: "recommendedCollection",
  initialState: initialState,
  reducers: {
    updateRecommendedCollectionTags(state, action) {
      if (
        action?.payload?.data?.data !== undefined &&
        action.payload.status === "fulfilled"
      ) {
        state.recommendedCollectionTags = action.payload.data.data.brand_tags;
      }
    },
    updateRecommendedCollectionTagsLoading(state, action) {
      state.recommendedCollectionTagsLoading = action.payload;
    },
    updateRecommendedCollectionTriggers(state, action) {
      state.recommendedCollectionTriggers = action.payload;
    },
    updateRecommendedCollections(state, action) {
      if (
        action?.payload?.data?.data !== undefined &&
        action.payload.status === "fulfilled"
      ) {
        if (action.payload?.data?.data?.page === 1) {
          state.recommendedCollections =
            action.payload?.data?.data?.recommended_collections;
        } else if (
          action.payload?.data?.data?.recommended_collections.length === 0
        ) {
          state.recommendedCollections = [];
        } else {
          state.recommendedCollections = [
            ...state?.recommendedCollections,
            ...action.payload?.data?.data?.recommended_collections,
          ];
        }
        state.isRecommendedCollectionScrollAllowed = true;
        state.recommendedCollectionsMaxPages =
          action.payload.data.data.max_pages;
      }
    },
    updateRecommendedCollectionsLoading(state, action) {
      state.recommendedCollectionsLoading = action.payload;
    },
    updateIsRecommendedCollectionScrollAllowed(state, action) {
      state.isRecommendedCollectionScrollAllowed = action.payload;
    },
    updateSingleRecommendedCollection(state, action) {
      if (
        action?.payload?.data?.data !== undefined &&
        action.payload.status === "fulfilled"
      )
        state.singleRecommendedCollection = action.payload.data.data;
    },
    removeSingleRecommendedCollection(state) {
      state.singleRecommendedCollection = {};
    },
    updateSingleRecommendedCollectionLoading(state, action) {
      state.singleRecommendedCollectionLoading = action.payload;
    },
    updateConvertedRecommendedCollection(state, action) {
      if (
        action?.payload?.data?.data !== undefined &&
        action.payload.status === "fulfilled"
      )
        state.convertedRecommendedCollectionCopyText =
          action.payload.data.data.copy_text;
    },
    clearConvertedRecommendedCollection(state, action) {
      state.convertedRecommendedCollectionCopyText = "";
    },
  },
});

export const {
  updateRecommendedCollectionTags,
  updateRecommendedCollectionTriggers,
  updateRecommendedCollections,
  updateIsRecommendedCollectionScrollAllowed,
  updateRecommendedCollectionTagsLoading,
  updateRecommendedCollectionsLoading,
  updateSingleRecommendedCollection,
  updateSingleRecommendedCollectionLoading,
  removeSingleRecommendedCollection,
  updateConvertedRecommendedCollection,
  clearConvertedRecommendedCollection,
} = RecommendedCollectionSlice.actions;
