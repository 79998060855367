import { useEffect, useContext, useState } from "react";
import { useSelector } from "react-redux";
import { useTheme } from "@mui/material";

import { tokens } from "../../theme";
import EngageCommentReply from "./EngageCommentReply.jsx";
import WishlinkTypography from "../../WishlinkComponents/WishlinkTypography";
import { APIContext } from "../../apiLayer";
import WishlinkButton from "../../WishlinkComponents/WishlinkButton";
import WishlinkTextField from "../../WishlinkComponents/WishlinkTextField";
import WishlinkModal from "../../WishlinkComponents/WishlinkModal";
import { triggerGa } from "../../utils/gaUtils";
import {
  GA_ACTION,
  GA_CATEGORIES,
  GA_LABEL,
  GA_PAGE,
  GA_SCREEN_NAME,
  IMAGE_PREFIX,
} from "../../utils/constants";
import EngageProTip from "./EngageProTip";

const EngageCommentReplyList = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const apis = useContext(APIContext);

  const { templates } = useSelector((state) => state.engageReducer);
  const { comments } = templates;
  const [modalState, setModalState] = useState(false);
  const [link, setLink] = useState("");
  const [btnLoading, setBtnLoading] = useState(false);

  useEffect(() => {
    apis.getCreatorTemplates();
  }, []);

  function onClickCancel() {
    setLink("");
    setModalState(false);
  }

  async function onClickAddTemplate() {
    triggerGa({
      category: GA_CATEGORIES.USER_INTERACTION,
      page: GA_PAGE.ENGAGE,
      action: GA_ACTION.EDIT_REPLIES,
      label: GA_LABEL.NEW_TEMPLATE_ADD,
      screenName: GA_SCREEN_NAME.ENGAGE_SETTING,
    });
    setBtnLoading(true);
    const body = {
      templateId: 0,
      templateText: link,
      templateType: "comment_new",
    };
    await apis.updateCreatorTemplates(body);
    setLink("");
    setModalState(false);
    setBtnLoading(false);
  }

  return (
    <div className="p-4">
      <WishlinkTypography
        fontSize="1rem"
        fontWeight={500}
        color={colors.grey[700]}
        className="text-left"
      >
        Set custom comment reply
      </WishlinkTypography>
      <WishlinkTypography
        fontSize="0.75rem"
        fontWeight={400}
        color={colors.grey[500]}
        lineHeight="1.125rem"
        className="text-left pt-2"
      >
        Set how Wishlink Engage replies to comment after sending product links
        in DMs
      </WishlinkTypography>
      {comments?.map((comment, idx) => {
        return (
          <div key={idx} className="mt-2">
            <EngageCommentReply
              comment={comment}
              onlyOneTemplateExists={comments?.length === 1}
            />
          </div>
        );
      })}
      <div
        className="flex align-center mt-3"
        onClick={() => {
          triggerGa({
            category: GA_CATEGORIES.USER_INTERACTION,
            page: GA_PAGE.ENGAGE,
            action: GA_ACTION.EDIT_REPLIES,
            label: GA_LABEL.NEW_TEMPLATE_CLICK,
            screenName: GA_SCREEN_NAME.ENGAGE_SETTING,
          });
          setModalState(true);
        }}
      >
        <img src={`${IMAGE_PREFIX}pluscircle.svg`} className="pr-2" alt="" />
        <WishlinkTypography
          fontSize="0.875rem"
          fontWeight={600}
          color={colors.red[500]}
        >
          Add New Reply
        </WishlinkTypography>
      </div>
      <EngageProTip />
      <WishlinkModal
        showModal={modalState}
        setShowModal={setModalState}
        showCloseIcon={false}
      >
        <WishlinkTypography
          fontSize="1.125rem"
          fontWeight={500}
          color={colors.grey[100]}
        >
          Add a new template
        </WishlinkTypography>
        <WishlinkTextField
          value={link}
          fullWidth={true}
          placeholder="Template name"
          style={{ marginTop: "0.5rem" }}
          onInputValueChange={(val) => setLink(val)}
          showIcon={false}
        />
        <div
          className="flex align-center justify-end pt-3"
          style={{ gap: "0.5rem" }}
        >
          <WishlinkButton
            background={colors.red[500]}
            style={{
              ":hover": {
                bgcolor: colors.red[500],
              },
              ":disabled": {
                bgcolor: colors.grey[800],
              },
            }}
            borderRadius="0.5rem"
            height="2.5rem"
            disabled={!link || btnLoading}
            onClick={() => onClickAddTemplate()}
          >
            <WishlinkTypography color={colors.grey[0]}>
              {btnLoading ? "Wait..." : "Add"}
            </WishlinkTypography>
          </WishlinkButton>
          <WishlinkButton
            border={`0.06rem solid ${colors.red[500]}`}
            borderRadius="0.5rem"
            height="2.5rem"
            onClick={() => onClickCancel()}
          >
            <WishlinkTypography color={`${colors.red[500]}`}>
              Cancel
            </WishlinkTypography>
          </WishlinkButton>
        </div>
      </WishlinkModal>
    </div>
  );
};

export default EngageCommentReplyList;
