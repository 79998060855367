import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  sourcingCredits: 0,
  wishlistTriggers: null,
  products: [],
};

export const sourcingWishlistSlice = createSlice({
  name: "Wishlist",
  initialState: initialState,
  reducers: {
    updateSourcingCreatorDetails(state, action) {
      state.collectionDetails = action.payload;
    },
    updateSourcingCreatorWishlistProducts(state, action) {
      state.products = action.payload;
    },
    updateSourcingCreatorWishlistLoading(state, action) {
      state.collectionLoading = action.payload;
    },
  },
});

export const {
  updateSourcingCreatorDetails,
  updateSourcingCreatorWishlistProducts,
  updateSourcingCreatorWishlistLoading,
} = sourcingWishlistSlice.actions;
