import React from "react";
import { Skeleton } from "@mui/material";

export const ValueSkeleton = React.memo(function ValueSkeleton(props) {
  const { isLoading, value, width = "3rem", className, style } = props;

  return (
    <>
      {isLoading ? (
        <Skeleton sx={{ width, ...style }} className={className} />
      ) : (
        value
      )}
    </>
  );
});

export default ValueSkeleton;
