import styled from "@emotion/styled";

export const getMarginFromProps = (props) => {
  const { m, mt, mb, ml, mr } = props;
  return `${mt || m || 0}rem ${mr || m || 0}rem ${mb || m || 0}rem ${
    ml || m || 0
  }rem `;
};
export const getPaddingFromProps = (props) => {
  const { p, pt, pb, pl, pr } = props;
  return `${pt || p || 0}rem ${pr || p || 0}rem ${pb || p || 0}rem ${
    pl || p || 0
  }rem `;
};

export const Card = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  width: ${({ width }) => width || "100%"};
  min-width: ${({ minWidth }) => minWidth || "auto"};
  max-width: 100%;
  margin: ${(props) =>
    !props.isGutter ? getMarginFromProps(props) : "0rem 1rem"};
  padding: ${getPaddingFromProps};
  border-radius: ${({ borderRadius }) => borderRadius || "0.625rem"};
  background: ${({ bgColor }) => bgColor || "#ffffff"};
  box-shadow: ${({ isShadow }) =>
    isShadow ? "0px 4px 4px 0px rgba(0, 0, 0, 0.1)" : "none"};
  overflow: hidden;
  overflow: ${({ overflow }) => overflow || "hidden"};
  border: ${({ border }) => (border ? `1px solid ${border}` : "none")};
  gap: ${({ gap }) => gap || "0"}rem;
`;

export const Column = styled.div`
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  width: ${({ width }) => width || "100%"};
  flex-shrink: 0;
  flex-grow: 0;
  margin: ${getMarginFromProps};
  padding: ${getPaddingFromProps};
  justify-content: ${({ justifyContent }) => justifyContent || "flex-start"};
  align-items: ${({ alignItems }) => alignItems || "flex-start"};
  border-bottom: ${({ divider, borderColor }) =>
    divider ? `1px solid ${borderColor ? borderColor : "#CFD0D4"}` : "none"};
  background-color: ${({ bgColor }) => bgColor || "transparent"};
  gap: ${({ gap }) => gap || "0"}rem;
  flex-wrap: ${({ wrap }) => (wrap ? "wrap" : "nowrap")};
  flex: ${({ flex }) => (flex ? flex : "none")};
  overflow-y: ${({ overflowY }) => overflowY || "visible"};
`;

export const Row = styled(Column)`
  flex-direction: row;
  align-items: ${({ alignItems }) => alignItems || "center"};
  justify-content: ${({ justifyContent }) => justifyContent || "flex-start"};
  margin-top: ${({ mt }) => mt || 0}rem;
`;
