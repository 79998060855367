import WishlinkModal from "../../WishlinkComponents/WishlinkModal";
import WishlinkTypography from "../../WishlinkComponents/WishlinkTypography";
import WishlinkTextField from "../../WishlinkComponents/WishlinkTextField";
import WishlinkButton from "../../WishlinkComponents/WishlinkButton";
import { tokens } from "../../theme";
import { useTheme } from "@mui/material";

const EngageAddKeyWordModal = ({
  keyword,
  setKeyword,
  modalState,
  setModalState,
  onClickAdd,
  onClickCancel,
  btnLoading,
}) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  return (
    <WishlinkModal
      showModal={modalState}
      setShowModal={setModalState}
      showCloseIcon={false}
    >
      <WishlinkTypography
        fontSize="1.125rem"
        fontWeight={500}
        color={colors.grey[100]}
      >
        Add a new keyword
      </WishlinkTypography>
      <WishlinkTextField
        value={keyword}
        fullWidth={true}
        placeholder="Keyword name"
        style={{ marginTop: "0.5rem" }}
        onInputValueChange={(val) => setKeyword(val)}
        showIcon={false}
      />
      <div
        className="flex align-center justify-end pt-3"
        style={{ gap: "0.5rem" }}
      >
        <WishlinkButton
          background={colors.red[500]}
          style={{
            ":hover": {
              bgcolor: colors.red[500],
            },
            ":disabled": {
              bgcolor: colors.grey[800],
            },
          }}
          borderRadius="0.5rem"
          height="2.5rem"
          disabled={!keyword || btnLoading}
          onClick={() => onClickAdd()}
        >
          <WishlinkTypography color={colors.grey[0]}>
            {btnLoading ? "Wait..." : "Add"}
          </WishlinkTypography>
        </WishlinkButton>
        <WishlinkButton
          border={`0.06rem solid ${colors.red[500]}`}
          borderRadius="0.5rem"
          height="2.5rem"
          onClick={() => onClickCancel()}
        >
          <WishlinkTypography color={`${colors.red[500]}`}>
            Cancel
          </WishlinkTypography>
        </WishlinkButton>
      </div>
    </WishlinkModal>
  );
};

export default EngageAddKeyWordModal;
