import React from "react";
import {
  getAssetUrl,
  getResponsiveWidth,
  triggerGoBackGa,
} from "../../utils/common";
import { Grid, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import {
  GA_ACTION,
  GA_CATEGORIES,
  GA_LABEL,
  GA_PAGE,
  GA_SCREEN_NAME,
  IMAGE_PREFIX,
} from "../../utils/constants";
import { triggerGa } from "../../utils/gaUtils";
import { updatePageNameAndScreenName } from "../../redux/redux";
import { useDispatch, useSelector } from "react-redux";
import "../../animation.css";

const checkBackground = "check-grid-background.png";
const playbookAnimation = "playbook-animation.gif";
const swipeVector = "swipe-vector.svg";
const swipeVectorMini = "swipe-vector-mini.svg";
// const maximizeContentIcon = "maximise-content.svg";
// const maximizeMonetizationIcon = "maximise-monetization.svg";
const maximizeContentIcon = "maximize-content-4x.png";
const maximizeMonetizationIcon = "maximize-earning-4x.png";
const rightArrow = "arrow-right-white.svg";
const leftArrow = "arrow-left-light.svg";

const redirectionCards = [
  {
    iconUrl: maximizeContentIcon,
    title: "Maximize Your Wishlink Posts",
    subHeading: "Level up your content game",
    redirectTo: "/playbook-content",
    gaLabel: GA_LABEL.POSTS,
  },
  {
    iconUrl: maximizeMonetizationIcon,
    title: "Maximize Monetization",
    subHeading: "Handcrafted to help you scale",
    redirectTo: "/playbook-monetize",
    gaLabel: GA_LABEL.EARNINGS,
  },
];

const HelloTypography = (props) => {
  return (
    <Typography
      sx={{
        fontWeight: 400,
        fontSize: "1.5rem",
        lineHeight: "2.25rem",
        color: "#FFFFFF",
      }}
      className="right-transition"
    >
      {props.children}
    </Typography>
  );
};

const SubtitleTypography = (props) => {
  return (
    <Typography
      sx={{
        fontWeight: 700,
        fontSize: "1.5rem",
        lineHeight: "2.25rem",
        color: "#FFFFFF",
      }}
    >
      {props.children}
    </Typography>
  );
};

const HeadingTypography = (props) => {
  return (
    <Typography
      sx={{
        fontWeight: 500,
        fontSize: "0.875rem",
        lineHeight: "1.3125rem",
        color: "#F2F2F2",
        marginTop: "0.75rem",
        padding: "0rem 1rem",
      }}
      className="left-transition"
    >
      {props.children}
    </Typography>
  );
};

const RedirectCard = (props) => {
  const redirectCard = props.redirectCard;
  const index = props.index;
  return (
    <Grid
      item
      xs={6}
      className={index % 2 === 0 ? `right-transition` : `left-transition`}
    >
      <div
        onClick={props.onClick}
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          background: "#FFFFFF",
          border: "0.0625rem solid #E5E5E5",
          boxShadow: "0rem 0.25rem 0.75rem rgba(0, 0, 0, 0.24)",
          borderRadius: "0.5rem",
          padding: "0.75rem",
        }}
      >
        <img
          src={getAssetUrl(redirectCard.iconUrl)}
          alt="card-icon"
          style={{ width: "3rem" }}
        />
        <Typography
          sx={{
            fontWeight: 500,
            fontSize: "0.875rem",
            lineHeight: "1.3125rem",
            color: "#333333",
            marginTop: "0.25rem",
            maxWidth: "10rem",
          }}
        >
          {redirectCard.title}
        </Typography>
        <img
          src={getAssetUrl(swipeVectorMini)}
          alt="swipe-vector"
          style={{ marginTop: "0.5rem" }}
        />
        <Typography
          sx={{
            fontWeight: 400,
            fontSize: "0.6875rem",
            lineHeight: "1rem",
            color: "#666666",
            marginTop: "0.5rem",
            maxWidth: "9rem",
          }}
        >
          {redirectCard.subHeading}
        </Typography>
        <div
          style={{
            marginTop: "0.5rem",
            background: "#E79548",
            borderRadius: "0.75rem",
            padding: "0.375rem 0rem",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            height: "1.5rem",
            width: "2.5rem",
            boxSizing: "border-box",
          }}
        >
          <img src={`${IMAGE_PREFIX}${rightArrow}`} alt="" />
        </div>
      </div>
    </Grid>
  );
};

const Playbook = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { pageName, screenName } = useSelector((state) => state.appReducer);

  const [responsiveWidth, setResponsiveWidth] = useState(getResponsiveWidth);

  useEffect(() => {
    const handleScreenResize = () => {
      setResponsiveWidth(getResponsiveWidth());
    };

    window.addEventListener("resize", handleScreenResize);
    return () => window.removeEventListener("resize", handleScreenResize);
  }, []);

  useEffect(() => {
    dispatch(
      updatePageNameAndScreenName({
        pageName: GA_PAGE.SELLING_PLAYBOOK,
        screenName: GA_SCREEN_NAME.SELLING_PLAYBOOK,
      })
    );
  }, []);

  return (
    <div
      style={{
        backgroundColor: "#E79548",
        position: "relative",
        margin: "auto",
      }}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          minHeight: window.innerHeight,
        }}
      >
        <img
          src={getAssetUrl(checkBackground)}
          style={{ position: "absolute", width: responsiveWidth }}
          alt="check-background"
        />
        <div
          style={{
            padding: "1rem",
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-start",
            width: "100%",
            boxSizing: "border-box",
            zIndex: 100,
          }}
        >
          <img
            src={`${IMAGE_PREFIX}${leftArrow}`}
            alt=""
            onClick={() => {
              triggerGoBackGa(pageName, screenName);
              navigate(-1);
            }}
          />
        </div>
        <img
          src={getAssetUrl(playbookAnimation)}
          alt="playbook-animation"
          style={{ height: "6.25rem" }}
        />
        <HelloTypography>Hello creator,</HelloTypography>
        <SubtitleTypography>
          Wishlink has
          <br />
          got your back!
        </SubtitleTypography>
        <img src={getAssetUrl(swipeVector)} alt="swipe" />
        <HeadingTypography>
          Learn the best tips & tricks to optimise & maximize the earnings from
          your content pieces
        </HeadingTypography>
        <Grid
          container
          spacing={"1rem"}
          sx={{
            paddingTop: "1.5rem",
            marginTop: "1.5rem",
            padding: "0rem 1rem",
            width: "100%",
          }}
        >
          {redirectionCards.map((card, idx) => (
            <RedirectCard
              redirectCard={card}
              index={idx}
              onClick={() => {
                triggerGa({
                  category: GA_CATEGORIES.USER_EDUCATION,
                  action: GA_ACTION.CARD_CLICK,
                  label: card.gaLabel,
                  page: GA_PAGE.SELLING_PLAYBOOK,
                  screenName: GA_SCREEN_NAME.SELLING_PLAYBOOK,
                });
                navigate(card.redirectTo);
              }}
            />
          ))}
        </Grid>
      </div>
    </div>
  );
};

export default Playbook;
