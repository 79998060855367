import { useBlitzllama } from "./useBlitzllama";
import { EVENTS } from "./constants";

const useEngageActivation = () => {
  const { triggerEvent } = useBlitzllama();

  const handleEngageToggle = (firstEngageActivation) => {
    triggerEvent(EVENTS.engage_activation, {
      first_time_engage_activation: firstEngageActivation,
    });
  };
  return { handleEngageToggle };
};

export default useEngageActivation;
