export const usePixelEvent = () => {
  const fbqEvent = (eventName, eventProperties) => {
    if (window.fbq) {
      window.fbq("trackCustom", eventName, eventProperties);
    } else {
      if (document.getElementById("facebook-pixel")) return;
      const fbScript = document.createElement("script");
      fbScript.id = "facebook-pixel";
      fbScript.defer = true;
      fbScript.innerHTML = `
      !(function (f, b, e, v, n, t, s) {
        if (f.fbq) return;
        n = f.fbq = function () {
          n.callMethod
            ? n.callMethod.apply(n, arguments)
            : n.queue.push(arguments);
        };
        if (!f._fbq) f._fbq = n;
        n.push = n;
        n.loaded = !0;
        n.version = "2.0";
        n.queue = [];
        t = b.createElement(e);
        t.async = !0;
        t.src = v;
        s = b.getElementsByTagName(e)[0];
        s.parentNode.insertBefore(t, s);
      })(
        window,
        document,
        "script",
        "https://connect.facebook.net/en_US/fbevents.js"
      );
      fbq("init", "496989819025278");
      fbq("track", "PageView");
    `;
      document.head.appendChild(fbScript);
      window.fbq("trackCustom", eventName, eventProperties);
    }
  };

  return { fbqEvent };
};
