import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useTheme } from "@mui/material";

import { tokens } from "../../theme";
import WishlinkTypography from "../../WishlinkComponents/WishlinkTypography";
import WishlinkChip from "../../WishlinkComponents/WishlinkChip";
import WishlinkDrawer from "../../WishlinkComponents/WishlinkDrawer";
import WishlinkButton from "../../WishlinkComponents/WishlinkButton";
import {
  DEFAULT_KEYWORDS_CTK,
  GA_ACTION,
  GA_CATEGORIES,
  GA_LABEL,
  GA_PAGE,
  GA_SCREEN_NAME,
  IMAGE_PREFIX,
} from "../../utils/constants";
import { useLocation, useNavigate } from "react-router-dom";
import { triggerGa } from "../../utils/gaUtils";
import EngageAddKeyWordModal from "./EngageAddKeyWordModal";

const EngageKeywords = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const { profileTriggers } = useSelector(
    (state) => state.socialMediaProfileReducer
  );
  const navigate = useNavigate();
  const location = useLocation();
  const { keywords } = useSelector((state) => state.engageReducer);

  const [keywordsDrawer, setKeywordsDrawer] = useState(false);
  const [allKeywords, setAllKeywords] = useState([]);
  const [btnLoading, setBtnLoading] = useState(false);
  const [modalState, setModalState] = useState(false);
  const [keyword, setKeyword] = useState("");

  useEffect(() => {
    profileTriggers.getCreatorKeywords();
  }, []);

  useEffect(() => {
    if (location.hash === "#keywords") {
      setKeywordsDrawer(true);
    } else setKeywordsDrawer(false);
  });

  useEffect(() => {
    setAllKeywords(keywords);
  }, [keywords]);

  function onClickKeyword(label) {
    triggerGa({
      category: GA_CATEGORIES.USER_INTERACTION,
      page: GA_PAGE.ENGAGE,
      action: GA_ACTION.EDIT_KEYWORDS,
      label: GA_LABEL.KEYWORDS_SELECTION,
      screenName: GA_SCREEN_NAME.ENGAGE_SETTING,
    });
    if (DEFAULT_KEYWORDS_CTK.includes(label)) return;
    const updatedAllKeywords = allKeywords.map((word) => {
      if (word.keyword === label) {
        return { keyword: label, selected: !word.selected };
      }
      return word;
    });
    setAllKeywords(updatedAllKeywords);
  }

  async function onClickSaveKeywords() {
    triggerGa({
      category: GA_CATEGORIES.USER_INTERACTION,
      page: GA_PAGE.ENGAGE,
      action: GA_ACTION.EDIT_KEYWORDS,
      label: GA_LABEL.KEYWORDS_UPDATED,
      screenName: GA_SCREEN_NAME.ENGAGE_SETTING,
    });
    setBtnLoading(true);
    await profileTriggers.updateCreatorKeywords({
      keyword_list: allKeywords,
    });
    setBtnLoading(false);
    navigate(-1, { replace: true });
  }

  async function onClickAddKeyword() {
    triggerGa({
      category: GA_CATEGORIES.USER_INTERACTION,
      page: GA_PAGE.ENGAGE,
      action: GA_ACTION.ADD_KEYWORD,
      label: GA_LABEL.NEW_KEYWORD_ADD,
      screenName: GA_SCREEN_NAME.ENGAGE_SETTING,
    });
    setBtnLoading(true);
    await profileTriggers.addCreatorKeywords({ keyword: keyword });
    setKeyword("");
    setModalState(false);
    setBtnLoading(false);
  }

  function onClickCancel() {
    setKeyword("");
    setModalState(false);
  }

  return (
    <div className="p-4">
      <WishlinkTypography
        fontSize="1rem"
        fontWeight={500}
        color={colors.grey[700]}
        className="text-left"
      >
        Set trigger keywords
      </WishlinkTypography>
      <WishlinkTypography
        fontSize="0.75rem"
        fontWeight={400}
        color={colors.grey[500]}
        lineHeight="1.125rem"
        className="text-left pt-2"
      >
        Keywords on which we will send in DMs to your followers
      </WishlinkTypography>
      <div
        className="border-radius p-2 mt-2 text-left"
        style={{ border: `0.06rem solid ${colors.grey[400]}` }}
        onClick={() => navigate("#keywords")}
      >
        {keywords?.map((word, idx) => {
          return (
            word.selected && (
              <WishlinkChip
                onClick={() => navigate("#keywords")}
                key={idx}
                label={word.keyword}
                variant="outlined"
                style={{
                  margin: "0.2rem",
                  background: colors.red[900],
                }}
              ></WishlinkChip>
            )
          );
        })}
      </div>
      <div
        className="flex align-center mt-3"
        onClick={() => {
          triggerGa({
            category: GA_CATEGORIES.USER_INTERACTION,
            page: GA_PAGE.ENGAGE,
            action: GA_ACTION.ADD_KEYWORD,
            label: GA_LABEL.NEW_KEYWORD_ADD,
            screenName: GA_SCREEN_NAME.ENGAGE_SETTING,
          });
          setModalState(true);
        }}
      >
        <img src={`${IMAGE_PREFIX}pluscircle.svg`} className="pr-2" alt="" />
        <WishlinkTypography
          fontSize="0.875rem"
          fontWeight={600}
          color={colors.red[500]}
        >
          Add New Keyword
        </WishlinkTypography>
      </div>
      <WishlinkDrawer
        drawerOpen={keywordsDrawer}
        onClose={() => navigate(-1, { replace: true })}
        onOpen={() => {}}
      >
        <div className="p-4">
          <WishlinkTypography
            fontSize="1rem"
            fontWeight={500}
            color={colors.grey[100]}
            className="pt-4 pb-2"
          >
            Recommended keywords
          </WishlinkTypography>
          {allKeywords?.map((word, idx) => {
            return (
              <WishlinkChip
                key={idx}
                label={word.keyword}
                variant="filled"
                style={{
                  border: `0.06rem solid ${colors.grey[100]}`,
                  margin: "0.2rem",
                  background: word.selected ? colors.red[900] : colors.grey[0],
                  ":hover": {
                    bgcolor: word.selected ? colors.red[900] : colors.grey[0],
                  },
                }}
                onClick={onClickKeyword}
              ></WishlinkChip>
            );
          })}
          <WishlinkButton
            background={colors.red[500]}
            style={{
              ":hover": {
                bgcolor: colors.red[500],
              },
              ":disabled": {
                bgcolor: colors.grey[800],
              },
            }}
            borderRadius="0.5rem"
            width="100%"
            height="2.5rem"
            className="mt-3"
            onClick={() => onClickSaveKeywords()}
            disabled={btnLoading}
          >
            <WishlinkTypography
              fontSize="1rem"
              fontWeight={400}
              color={colors.grey[0]}
            >
              {btnLoading ? "Please Wait..." : "Save"}
            </WishlinkTypography>
          </WishlinkButton>
        </div>
      </WishlinkDrawer>
      <EngageAddKeyWordModal
        keyword={keyword}
        setKeyword={setKeyword}
        modalState={modalState}
        setModalState={setModalState}
        onClickAdd={onClickAddKeyword}
        onClickCancel={onClickCancel}
      ></EngageAddKeyWordModal>
    </div>
  );
};

export default EngageKeywords;
