import { useEffect, useState, useContext } from "react";
import { useTheme } from "@mui/material";

import { tokens } from "../../theme";
import WishlinkEditableInput from "../../WishlinkComponents/WishlinkEditableInput";
import WishlinkModal from "../../WishlinkComponents/WishlinkModal";
import WishlinkTypography from "../../WishlinkComponents/WishlinkTypography";
import WishlinkButton from "../../WishlinkComponents/WishlinkButton";
import { APIContext } from "../../apiLayer";
import { triggerGa } from "../../utils/gaUtils";
import {
  GA_ACTION,
  GA_CATEGORIES,
  GA_LABEL,
  GA_PAGE,
  GA_SCREEN_NAME,
} from "../../utils/constants";

const EngageCommentReply = (props) => {
  const { comment, onlyOneTemplateExists } = props;
  const apis = useContext(APIContext);
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [editMode, setEditMode] = useState(false);
  const [customComment, setCustomComment] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [btnLoading, setBtnLoading] = useState(false);

  useEffect(() => {
    setCustomComment(comment);
  }, [comment]);

  async function onClickCheckMark() {
    triggerGa({
      category: GA_CATEGORIES.USER_INTERACTION,
      page: GA_PAGE.ENGAGE,
      action: GA_ACTION.EDIT_REPLIES,
      label: GA_LABEL.EDIT_EXISTING_SAVE,
      screenName: GA_SCREEN_NAME.ENGAGE_SETTING,
    });
    const body = {
      templateId: comment.id,
      templateText: customComment.text,
      templateType: "comment_update",
    };
    await apis.updateCreatorTemplates(body);
    setEditMode(false);
  }

  function onClickCross() {
    setCustomComment(comment);
    setEditMode(false);
  }

  function onInputValueChange(val) {
    setCustomComment({
      text: val,
      id: comment.id,
    });
  }

  async function onClickDelete() {
    triggerGa({
      category: GA_CATEGORIES.USER_INTERACTION,
      page: GA_PAGE.ENGAGE,
      action: GA_ACTION.EDIT_REPLIES,
      label: GA_LABEL.DELETE_EXISTING_CONFIRM,
      screenName: GA_SCREEN_NAME.ENGAGE_SETTING,
    });
    setBtnLoading(true);
    const body = {
      templateId: comment.id,
      templateText: "",
      templateType: "comment_delete",
    };
    await apis.updateCreatorTemplates(body);
    setBtnLoading(false);
    setShowModal(false);
  }

  return (
    <>
      <WishlinkEditableInput
        webkitTextFillColor="rgba(0, 0, 0, 0.87)"
        fontSize="0.8125rem"
        value={customComment?.text}
        showDelete={true}
        editMode={editMode}
        setEditMode={setEditMode}
        placeholder="Add a bio to your shop"
        toggleEditModeGa={() =>
          triggerGa({
            category: GA_CATEGORIES.USER_INTERACTION,
            page: GA_PAGE.ENGAGE,
            action: GA_ACTION.EDIT_REPLIES,
            label: GA_LABEL.EDIT_EXISTING_CLICK,
            screenName: GA_SCREEN_NAME.ENGAGE_SETTING,
          })
        }
        onInputValueChange={onInputValueChange}
        onClickCheckMark={onClickCheckMark}
        onClickCross={onClickCross}
        onClickDelete={() => {
          if (onlyOneTemplateExists) {
            return;
          }
          triggerGa({
            category: GA_CATEGORIES.USER_INTERACTION,
            page: GA_PAGE.ENGAGE,
            action: GA_ACTION.EDIT_REPLIES,
            label: GA_LABEL.DELETE_EXISTING_CLICK,
            screenName: GA_SCREEN_NAME.ENGAGE_SETTING,
          });
          setShowModal(true);
        }}
      />
      <WishlinkModal
        showModal={showModal}
        setShowModal={setShowModal}
        showCloseIcon={false}
      >
        <WishlinkTypography
          color={colors.grey[100]}
          fontSize="1.125rem"
          fontWeight={500}
          lineHeight="1.7rem"
        >
          Are you sure you want to remove this template?
        </WishlinkTypography>
        <div className="flex align-center justify-end">
          <WishlinkButton
            border={`0.06rem solid ${colors.red[500]}`}
            background={colors.grey[0]}
            borderRadius="0.5rem"
            style={{
              paddingLeft: "1rem",
              paddingRight: "1rem",
            }}
            onClick={() => setShowModal(false)}
          >
            <WishlinkTypography color={colors.red[500]}>No</WishlinkTypography>
          </WishlinkButton>
          <WishlinkButton
            background={colors.red[500]}
            className="ml-2"
            style={{
              paddingLeft: "1rem",
              paddingRight: "1rem",
              ":hover": {
                bgcolor: colors.red[500],
              },
              ":disabled": {
                bgcolor: colors.grey[800],
              },
            }}
            borderRadius="0.5rem"
            onClick={() => onClickDelete()}
            disabled={btnLoading}
          >
            <WishlinkTypography color={colors.grey[0]}>
              {btnLoading ? `Wait...` : `Yes`}
            </WishlinkTypography>
          </WishlinkButton>
        </div>
      </WishlinkModal>
    </>
  );
};

export default EngageCommentReply;
