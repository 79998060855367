import { useDispatch } from "react-redux";
import { useEffect } from "react";
import {
  useLazyGetProductColorOptionsQuery,
  useLazyGetProductDetailsQuery,
  useLazyGetProductSizeChartQuery,
  useLazyGetProductVariantsQuery,
} from "./productReduxAPI";
import {
  setProductVariantsLoading,
  setSizeChart,
  updateSourcingProduct,
  updateSourcingProductColorOptions,
  updateSourcingProductColorOptionsLoading,
  updateSourcingProductLoading,
  updateSourcingProductTriggers,
  updateSourcingProductVariants,
} from "./productRedux";
import useDataUpdate, {
  useDataUpdateWithLoading,
} from "../../utils/useDataUpdate";

const ProductDataLayer = () => {
  const dispatch = useDispatch();

  const [triggerGetSourcingProductDetails, resultGetSourcingProductDetails] =
    useLazyGetProductDetailsQuery();
  const [triggerGetProductColorOptions, resultGetProductColorOptions] =
    useLazyGetProductColorOptionsQuery();
  const [triggerGetProductSizeChart, resultGetProductSizeChart] =
    useLazyGetProductSizeChartQuery();
  const [triggerGetProductVariants, resultGetProductVariants] =
    useLazyGetProductVariantsQuery();

  const trigger = {
    getSourcingProductDetails: async (apiCallParams) =>
      await triggerGetSourcingProductDetails(apiCallParams),
    getSourcingProductVariants: async (apiCallParams) =>
      await triggerGetProductVariants(apiCallParams),
    getSourcingProductColorOptions: async (apiCallParams) =>
      await triggerGetProductColorOptions(apiCallParams),
    getSourcingProductSizeChart: async (apiCallParams) =>
      await triggerGetProductSizeChart(apiCallParams),
  };

  useEffect(() => {
    dispatch(updateSourcingProductTriggers(trigger));
  }, [trigger]);

  useDataUpdateWithLoading(
    resultGetProductVariants,
    updateSourcingProductVariants,
    setProductVariantsLoading
  );

  useDataUpdateWithLoading(
    resultGetSourcingProductDetails,
    updateSourcingProduct,
    updateSourcingProductLoading
  );

  useDataUpdateWithLoading(
    resultGetProductColorOptions,
    updateSourcingProductColorOptions,
    updateSourcingProductColorOptionsLoading
  );

  useDataUpdate(resultGetProductSizeChart, setSizeChart);

  return <></>;
};

export default ProductDataLayer;
