import React from "react";
import { Skeleton } from "@mui/material";

const ListSkeletonElement = (props) => {
  const { showForValue } = props;
  return (
    <div
      className="flex"
      style={{
        gap: "0.75rem",
        alignItems: "center",
        width: "100%",
      }}
    >
      <div
        className="flex"
        style={{
          height: "5rem",
          width: "3rem",
          alignItems: "center",
          justifyContent: "center",
          marginLeft: "0.5rem",
        }}
      >
        <Skeleton sx={{ height: "3rem", width: "3rem" }} />
      </div>
      <div
        className="flex"
        style={{
          flexDirection: "column",
          alignItems: "flex-start",
          gap: "0.25rem",
          width: `100%`,
        }}
      >
        <Skeleton sx={{ width: "8rem" }} />
        <Skeleton sx={{ width: "5rem" }} />
      </div>
      {showForValue && (
        <div
          className="flex"
          style={{
            alignItems: "center",
            marginLeft: "auto",
            marginRight: "0.5rem",
          }}
        >
          <Skeleton sx={{ width: "3rem" }} />
        </div>
      )}
    </div>
  );
};

export default ListSkeletonElement;
