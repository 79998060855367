import { useTheme } from "@mui/material";

import { tokens } from "../../theme";
import WishlinkButton from "../../WishlinkComponents/WishlinkButton";
import WishlinkTypography from "../../WishlinkComponents/WishlinkTypography";
import {
  GA_ACTION,
  GA_CATEGORIES,
  GA_LABEL,
  IMAGE_PREFIX,
} from "../../utils/constants.js";
import { useSelector } from "react-redux";
import { triggerGa } from "../../utils/gaUtils";
import { openExternalLinkInNewTab } from "../../utils/common.js";

const HowToActivateEngage = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const { pageName, screenName } = useSelector((state) => state.appReducer);

  const handleWatchClick = () => {
    openExternalLinkInNewTab({
      redirectUrl: `${IMAGE_PREFIX}WishlinkEngageConnectDemo.mp4`,
    });

    triggerGa({
      category: GA_CATEGORIES.USER_ENGAGEMENT,
      action: GA_ACTION.WATCH_VIDEO,
      label: GA_LABEL.ENGAGE_ACTIVATION,
      page: pageName,
      screenName: screenName,
    });
  };

  return (
    <>
      <div
        className="border-radius flex align-center justify-between p-3"
        style={{
          border: `0.06rem solid ${colors.red[200]}`,
          background: `linear-gradient(90.31deg, rgba(227, 129, 37, 0.08) 0.25%, rgba(227, 129, 37, 0.048) 113.83%)`,
        }}
      >
        <div className="flex align-center justify-start">
          <img
            src={`${IMAGE_PREFIX}bulb.svg`}
            style={{ borderRadius: "50%" }}
            alt=""
          />
          <WishlinkTypography
            fontSize="0.9rem"
            className="ml-2"
            fontWeight={500}
            color={colors.grey[700]}
          >
            How to activate engage
          </WishlinkTypography>
        </div>
        <WishlinkButton
          background={colors.grey[0]}
          height="2rem"
          className="bs-1 border-radius"
          onClick={() => {
            handleWatchClick();
          }}
        >
          <img src={`${IMAGE_PREFIX}play-circle.svg`} className="pr-1" alt="" />
          <WishlinkTypography
            color={colors.grey[700]}
            fontSize="0.75rem"
            fontWeight={500}
          >
            Watch
          </WishlinkTypography>
        </WishlinkButton>
      </div>
    </>
  );
};

export default HowToActivateEngage;
