import { useContext, useEffect, useState } from "react";
import { getResponsiveWidth } from "../../utils/common";
import {
  DRAWER_ALLOWED_OVERLAYS,
  GA_ACTION,
  IMAGE_PREFIX,
  OVERLAY_ALLOWED_HASHES,
  SCREEN_HALVES,
} from "../../utils/constants";
import WishlinkTypography from "../../WishlinkComponents/WishlinkTypography";
import { APIContext } from "../../apiLayer";
import { useSelector } from "react-redux";
import { triggerGa } from "../../utils/gaUtils";
import { store } from "../../redux/redux";

function getTitleAndSubtitleText(
  stepNumber,
  totalSteps,
  title,
  subtitle,
  label,
  label2,
  shouldRightAlign,
  overlayZIndex,
  onOverlayClick,
  showSteps
) {
  const { pageName, screenName } = store.getState().appReducer;

  const handleClick = () => {
    triggerGa({
      category: pageName,
      action: GA_ACTION.OVERLAY_CLICK,
      label,
      label2,
      page: pageName,
      screenName: screenName,
    });
    onOverlayClick();
  };

  return (
    <div
      className="w-100"
      style={{
        paddingRight: shouldRightAlign ? "10%" : "0%",
        paddingLeft: shouldRightAlign ? "0%" : "10%",
        zIndex: overlayZIndex + 1,
        position: "relative",
      }}
    >
      <div
        className="flex flex-column justify-around"
        style={{
          alignItems: shouldRightAlign ? "flex-end" : "flex-start",
        }}
      >
        {showSteps && (
          <WishlinkTypography
            color={"#FFFFFF"}
            fontSize="0.75rem"
            fontWeight={500}
            textAlign={shouldRightAlign ? "right" : "left"}
          >
            {stepNumber}/{totalSteps}
          </WishlinkTypography>
        )}
        <WishlinkTypography
          color={"#FFFFFF"}
          fontSize="1rem"
          fontWeight={500}
          mt={"0.5rem"}
          textAlign={shouldRightAlign ? "right" : "left"}
        >
          {title}
        </WishlinkTypography>
        <WishlinkTypography
          color={"#FFFFFF"}
          fontSize="0.75rem"
          fontWeight={400}
          lineHeight="1.125rem"
          mt="0.5rem"
          textAlign={shouldRightAlign ? "right" : "left"}
        >
          {subtitle}
        </WishlinkTypography>
        <WishlinkTypography
          color={"#FFFFFF"}
          textAlign={shouldRightAlign ? "right" : "left"}
          mt={"1rem"}
          fontSize="0.875rem"
          fontWeight={500}
          onClick={handleClick}
        >
          OK Got it !
        </WishlinkTypography>
      </div>
    </div>
  );
}

function getArrow(halfToShowDetailsIn, overlayZIndex, shouldRightAlign) {
  return (
    <img
      alt=""
      src={`${IMAGE_PREFIX}ovelay-arrow.png`}
      style={{
        position: "relative",
        scaleX: halfToShowDetailsIn === SCREEN_HALVES.TOP ? -1 : 1,
        zIndex: overlayZIndex + 1,
        paddingRight: shouldRightAlign ? "10%" : "0%",
        paddingLeft: shouldRightAlign ? "0%" : "10%",
      }}
    />
  );
}

function getOverlayDetails(
  stepNumber,
  totalSteps,
  title,
  subtitle,
  label,
  label2,
  shouldRightAlign,
  halfToShowDetailsIn,
  overlayZIndex,
  height,
  left,
  right,
  onOverlayClick,
  showSteps
) {
  let titleAndSubtitleText = getTitleAndSubtitleText(
    stepNumber,
    totalSteps,
    title,
    subtitle,
    label,
    label2,
    shouldRightAlign,
    overlayZIndex,
    onOverlayClick,
    showSteps
  );
  let arrow = getArrow(halfToShowDetailsIn, overlayZIndex, shouldRightAlign);
  let style = {};
  if (halfToShowDetailsIn === SCREEN_HALVES.TOP) {
    style = { bottom: height };
  } else {
    style = { top: height };
  }
  if (shouldRightAlign) {
    style = { ...style, right: window.innerWidth - right };
  } else {
    style = { ...style, left: left };
  }
  return (
    <div style={{ position: "fixed", zIndex: 100000, ...style }}>
      <div
        className="flex flex-column flex-gap-4"
        style={{ alignItems: shouldRightAlign ? "flex-end" : "flex-start" }}
      >
        {halfToShowDetailsIn === SCREEN_HALVES.TOP ? (
          <>
            {titleAndSubtitleText}
            {arrow}
          </>
        ) : (
          <>
            {arrow}
            {titleAndSubtitleText}
          </>
        )}
      </div>
    </div>
  );
}

function isElementCompletelyVisible(className) {
  const isVisible = false;

  const element = document.querySelector("." + className);

  if (element) {
    const rect = element.getBoundingClientRect();
    return (
      rect.top >= 0 &&
      rect.left >= 0 &&
      rect.bottom <= window.innerHeight &&
      rect.right <= window.innerWidth
    );
  }
  return isVisible;
}

const getTotalSteps = (overlays) => {
  let totalSteps = 0;
  overlays.forEach((overlay) => {
    let overlayItems = overlay.overlay_items;
    overlayItems.map((item) => {
      let className = item.classname_to_pick;
      let element = document.querySelector("." + className);
      if (!element) {
        return item;
      }
      totalSteps += 1;
      return item;
    });
  });
  return totalSteps;
};

const ProductTourOverlay = (props) => {
  const {
    isVisible,
    overlays = [],
    onCompletion = () => {},
    showSteps = true,
  } = props;
  const apis = useContext(APIContext);

  const { suppressOverlays } = useSelector((state) => state.apiDataReducer);

  const overlayZIndex = 100000;

  const [responsiveWidth, setResponsiveWidth] = useState(getResponsiveWidth());
  const [top, setTop] = useState(0);
  const [left, setLeft] = useState(0);
  const [right, setRight] = useState(0);
  const [width, setWidth] = useState(0);
  const [height, setHeight] = useState(0);
  const [halfToShowDetailsIn, setHalfToShowDetailsIn] = useState("");
  const [shouldRightAlign, setShouldRightAlign] = useState(false);
  const [highlightClassname, setHighlightClassname] = useState("");
  const [title, setTitle] = useState("");
  const [subtitle, setSubtitle] = useState("");
  const [label, setLabel] = useState("");
  const [label2, setLabel2] = useState("");
  const [stepNumber, setStepNumber] = useState(0);
  const [totalSteps, setTotalSteps] = useState(0);
  const [totalOverlays, setTotalOverlays] = useState(0);
  const [currentOverlayIndex, setCurrentOverlayIndex] = useState(0);
  const [currentOverlay, setCurrentOverlay] = useState([]);
  const [currentOverlayName, setCurrentOverlayName] = useState("");
  const [showOverlayItem, setShowOverlayItem] = useState({});
  const [overlayItemIndex, setOverlayItemIndex] = useState(0);
  const [totalOverlayItems, setTotalOverlayItems] = useState(0);

  useEffect(() => {
    const handleScreenResize = () => {
      setResponsiveWidth(getResponsiveWidth());
    };

    window.addEventListener("resize", handleScreenResize);
    return () => window.removeEventListener("resize", handleScreenResize);
  }, []);

  useEffect(() => {
    if (overlays?.length > 0 && !suppressOverlays) {
      setTotalOverlays(overlays.length);
      setCurrentOverlay(overlays[0]?.overlay_items);
      setCurrentOverlayName(overlays[0]?.overlay_name);
      setCurrentOverlayIndex(0);
      setStepNumber(1);
      setTotalSteps(getTotalSteps(overlays));
    }
  }, [overlays, suppressOverlays]);

  useEffect(() => {
    if (currentOverlay?.length > 0) {
      setShowOverlayItem(currentOverlay[0]);
      setOverlayItemIndex(0);
      setTotalOverlayItems(currentOverlay.length);
    }
  }, [currentOverlay]);

  useEffect(() => {
    if (Object.keys(showOverlayItem)?.length > 0) {
      setHighlightClassname(showOverlayItem.classname_to_pick);
    }
  }, [showOverlayItem]);

  const onOverlayClick = async (markAsRead = true) => {
    if (overlayItemIndex < totalOverlayItems - 1) {
      setShowOverlayItem(currentOverlay[overlayItemIndex + 1]);
      setOverlayItemIndex(overlayItemIndex + 1);
    } else if (currentOverlayIndex <= totalOverlays - 1) {
      let previousOverlayName = currentOverlayName;
      setCurrentOverlayName(overlays[currentOverlayIndex + 1]?.overlay_name);
      setCurrentOverlay(overlays[currentOverlayIndex + 1]?.overlay_items);
      setCurrentOverlayIndex(currentOverlayIndex + 1);
      if (markAsRead && !localStorage.adminToken) {
        await apis.markOverlayAsRead({ overlayName: previousOverlayName });
      }
      if (currentOverlayIndex === totalOverlays - 1) {
        cleanupOverlay();
        onCompletion();
      }
    }
    setStepNumber(stepNumber + 1);
  };

  const cleanupOverlay = () => {
    setTop(0);
    setLeft(0);
    setRight(0);
    setWidth(0);
    setHeight(0);
    setTitle("");
    setSubtitle("");
    setLabel("");
    setLabel2("");
  };

  function getComponentDimensionsAndPosition(className) {
    const component = document.querySelector("." + className);

    if (component) {
      const rect = component.getBoundingClientRect();
      setWidth(rect.width);
      setHeight(rect.height);
      setTop(rect.top);
      setLeft(rect.left);
      setRight(rect.right);
      setTitle(showOverlayItem.title);
      setSubtitle(showOverlayItem.subtitle);
      setLabel(overlays[currentOverlayIndex]?.overlay_name);
      setLabel2(showOverlayItem?.classname_to_pick);
    } else {
      cleanupOverlay();
    }
  }

  useEffect(() => {
    function setupWhereToShowDetails(className) {
      let element = document.querySelector("." + className);
      if (element) {
        let rect = element.getBoundingClientRect();
        let elementMidpoint = rect.top + rect.height / 2;
        let viewportMidpoint = window.innerHeight / 2;
        if (elementMidpoint < viewportMidpoint) {
          //Component is in upper half of screen
          setHalfToShowDetailsIn(SCREEN_HALVES.BOTTOM);
        } else {
          //Component is in lower half of screen
          setHalfToShowDetailsIn(SCREEN_HALVES.TOP);
        }
      }
    }

    function checkCenterToRight(className) {
      const element = document.querySelector("." + className);

      if (element) {
        const rect = element.getBoundingClientRect();
        const elementMidpoint = rect.left + rect.width / 2;
        if (elementMidpoint > window.innerWidth / 2) {
          setShouldRightAlign(true);
        } else {
          setShouldRightAlign(false);
        }
      }
    }

    function scrollConcernedElementIntoView(className) {
      const element = document.querySelector("." + className);

      if (element) {
        // Scroll the element into view, centered in the viewport
        element.scrollIntoView({
          behavior: "smooth",
          block: "center",
          inline: "center",
        });
      }
    }
    if (highlightClassname) {
      const element = document.querySelector("." + highlightClassname);
      const hash = window.location.hash;
      if (
        hash &&
        !DRAWER_ALLOWED_OVERLAYS.includes(currentOverlayName) &&
        !OVERLAY_ALLOWED_HASHES.includes(hash)
      ) {
        onOverlayClick(false);
        cleanupOverlay();
        return;
      }
      if (!element) {
        onOverlayClick(false);
        cleanupOverlay();
        return;
      }
      const isCompletelyVisible =
        isElementCompletelyVisible(highlightClassname);
      if (isCompletelyVisible) {
        getComponentDimensionsAndPosition(highlightClassname);
        checkCenterToRight(highlightClassname);
        setupWhereToShowDetails(highlightClassname);
      } else {
        scrollConcernedElementIntoView(highlightClassname);
        setTimeout(() => {
          getComponentDimensionsAndPosition(highlightClassname);
          checkCenterToRight(highlightClassname);
          setupWhereToShowDetails(highlightClassname);
        }, 2000);
      }
    }
  }, [highlightClassname]);

  useEffect(() => {
    document.body.style.overflow = "hidden";
    return () => {
      document.body.style.overflow = "visible";
    };
  }, []);

  return (
    <>
      {isVisible && halfToShowDetailsIn && title && subtitle ? (
        <div
          className="fixed product-tour-overlay"
          style={{
            zIndex: overlayZIndex,
            height: window.innerHeight,
            width: responsiveWidth,
            top: 0,
          }}
        >
          {halfToShowDetailsIn === SCREEN_HALVES.TOP && (
            <>
              {getOverlayDetails(
                stepNumber,
                totalSteps,
                title,
                subtitle,
                label,
                label2,
                shouldRightAlign,
                halfToShowDetailsIn,
                overlayZIndex,
                window.innerHeight - top + 16,
                left,
                right,
                onOverlayClick,
                showSteps
              )}
            </>
          )}
          <div
            style={{
              position: "fixed",
              height: height,
              width: width,
              left: left,
              top: top,
              borderRadius: "0.125rem",
              boxShadow: "0 0 0 99999px rgba(0, 0, 0, .8)",
            }}
          ></div>
          {halfToShowDetailsIn === SCREEN_HALVES.BOTTOM && (
            <>
              {getOverlayDetails(
                stepNumber,
                totalSteps,
                title,
                subtitle,
                label,
                label2,
                shouldRightAlign,
                halfToShowDetailsIn,
                overlayZIndex,
                top + height + 16,
                left,
                right,
                onOverlayClick,
                showSteps
              )}
            </>
          )}
        </div>
      ) : (
        <></>
      )}
    </>
  );
};

export default ProductTourOverlay;
