import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  creditTriggers: null,
  sourcingCredits: null,
  barterCredits: null,
  showCreditAnimation: false,
  sourcingMonthlyLimit: null,
  barterMonthlyLimit: null,
  sourcingLimitForSelectedBrand: null,
  barterCreditsForCollection: null,
  brandWiseSourcingCreditsList: [],
  collectionWiseBarterCreditsList: [],
  creditExpiryDate: "",
};

export const sourcingCreditsSlice = createSlice({
  name: "sourcingCredits",
  initialState: initialState,
  reducers: {
    updateCreditTriggers(state, action) {
      state.creditTriggers = action.payload;
    },
    updateCreditLimits(state, action) {
      state.sourcingCredits = action.payload.sourcingCredits;
      if (action.payload.showAnimation) state.showCreditAnimation = true;
      state.barterCredits = action.payload.barterCredits;
      state.sourcingMonthlyLimit = action.payload.sourcingMonthlyLimit;
      state.barterMonthlyLimit = action.payload.barterMonthlyLimit;
      state.creditExpiryDate = action.payload.creditExpiryDate;
    },
    stopCreditAnimation(state, action) {
      state.showCreditAnimation = false;
    },
    updateSourcingBrandLimit(state, action) {
      state.sourcingLimitForSelectedBrand = action.payload;
    },
    updateSourcingLimitForAllBrands(state, action) {
      state.brandWiseSourcingCreditsList = action.payload;
    },
    updateBarterLimitForAllCollections(state, action) {
      state.collectionWiseBarterCreditsList = action.payload;
    },
    updateBarterCreditsForCollection(state, action) {
      state.barterCreditsForCollection = action.payload;
    },
    resetAllSourcingLimits(state, action) {
      state.sourcingCredits = null;
      state.barterCredits = null;
      state.sourcingMonthlyLimit = null;
      state.showCreditAnimation = null;
      state.barterMonthlyLimit = null;
      state.sourcingLimitForSelectedBrand = null;
      state.barterCreditsForCollection = null;
    },
  },
});

export const {
  updateCreditTriggers,
  updateCreditLimits,
  updateSourcingBrandLimit,
  updateSourcingLimitForAllBrands,
  updateBarterLimitForAllCollections,
  updateBarterCreditsForCollection,
  resetAllSourcingLimits,
  stopCreditAnimation,
} = sourcingCreditsSlice.actions;
