import React from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

// components
import EducationCard from "./components/EducationCard";
import OrderStatusCard from "./components/OrderStatusCard";
import { BetterHeader } from "../../components/BetterHeader";
import ListSkeletonContainer from "../../components/ListSkeletonContainer";
import WishlinkTypography from "../../WishlinkComponents/WishlinkTypography";

import { useGetMonthYearListSincePayoutV2Query } from "../../redux/reduxAPI";

// utils
import { getAssetUrl } from "../../utils/common";
import usePageExitHandler from "../../hooks/Blitzllama/usePageExitHandler";

const MonthlyOrderStatus = () => {
  const navigate = useNavigate();
  const { milestones } = useSelector((state) => state.appReducer);
  const { handleMoMPageExit } = usePageExitHandler();
  const firstSaleAchived = milestones?.check_first_sale_milestone?.achieved;
  const emptyStateTitle = firstSaleAchived
    ? "No commissions yet"
    : "No commissions since April '24";
  const {
    data: monthYearList,
    isLoading,
    isFetching,
  } = useGetMonthYearListSincePayoutV2Query();

  const handleGoBack = () => {
    handleMoMPageExit();
    navigate(-1);
  };

  return (
    <div style={{ background: "#F4F6F5", minHeight: "100vh" }}>
      <BetterHeader
        title="Monthly Order Confirmation Status"
        onClick={handleGoBack}
        bgColor="#F4F6F5"
      />
      <div style={{ margin: "1rem" }}>
        {monthYearList?.length > 0 && <EducationCard />}

        {isLoading || isFetching ? (
          <ListSkeletonContainer numRows={5} showForValue={false} />
        ) : monthYearList?.length > 0 ? (
          <>
            <WishlinkTypography
              fontSize="0.75rem"
              color={"#000000"}
              fontWeight={500}
              textAlign={"left"}
              mt={"1.5rem"}
            >
              Click any month to see further details
            </WishlinkTypography>
            {monthYearList?.map((item, index) => (
              <OrderStatusCard
                key={item?.label}
                month={item?.label}
                item={item}
                index={index}
              />
            ))}
          </>
        ) : (
          <div
            style={{
              flex: 1,
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              marginTop: "6rem",
              minHeight: "100vh",
            }}
          >
            <img width="125px" src={getAssetUrl("empty-content.png")} alt="" />
            <div
              style={{
                marginLeft: "3rem",
                marginRight: "3rem",
                marginTop: "1rem",
                justifyContent: "center",
                alignItems: "center",
                display: "flex",
                flexDirection: "column",
              }}
            >
              <WishlinkTypography
                className="text-center"
                fontWeight={500}
                fontSize="0.975rem"
                color={"#DF472C"}
                mb={"0.5rem"}
              >
                {emptyStateTitle}
              </WishlinkTypography>
              <WishlinkTypography
                className="text-center"
                fontWeight={400}
                fontSize="0.65rem"
                lineHeight="0.95rem"
                mb={"1.81rem"}
              >
                Once you start receiving orders & commissions from Wishlink, you
                can view the confirmation status for each month right here.
              </WishlinkTypography>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default MonthlyOrderStatus;
