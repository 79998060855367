import React from "react";
import { useTheme } from "@mui/material";

// components
import WishlinkTypography from "../../../WishlinkComponents/WishlinkTypography";

// utils
import { tokens } from "../../../theme";
import { getAssetUrl, triggerEngageLogin } from "../../../utils/common";
import { triggerGa } from "../../../utils/gaUtils";
import {
  GA_ACTION,
  GA_CATEGORIES,
  GA_LABEL,
  GA_PAGE,
  GA_SCREEN_NAME,
} from "../../../utils/constants";

const FacebookConnect = ({ isConnected }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  const statusColor = isConnected ? colors.green[500] : colors.red[500];
  const statusText = isConnected ? ": Yes" : ": No";
  const statusIcon = isConnected
    ? "confirmed-check.svg"
    : "engage-info-alert.svg";

  const handlePdf = () => {
    window.open(
      "https://d3g01po1nkka75.cloudfront.net/creator-master/engage-help.pdf",
      "_blank"
    );
  };

  const handleVideo = () => {
    window.open(
      "https://d3g01po1nkka75.cloudfront.net/creator-master/stk-video.mp4",
      "_blank"
    );
  };
  const handleConnect = () => {
    triggerGa({
      category: GA_CATEGORIES.USER_INTERACTION,
      action: GA_ACTION.BUTTON_CLICK,
      label: GA_LABEL.ENGAGE_DRAWER,
      page: GA_PAGE.DEBUG,
      screenName: GA_SCREEN_NAME.DEBUG,
    });
    triggerEngageLogin();
  };
  return (
    <div>
      <div className="flex">
        <img src={getAssetUrl(statusIcon)} alt="icon" />
        <WishlinkTypography
          fontSize="0.75rem"
          fontWeight={400}
          color={colors.grey[100]}
          ml="0.4rem"
          mr="0.2rem"
        >
          Facebook Connected
        </WishlinkTypography>
        <WishlinkTypography
          fontSize="0.75rem"
          fontWeight={500}
          color={statusColor}
        >
          {statusText}
        </WishlinkTypography>
      </div>
      {!isConnected && (
        <div
          style={{
            alignItems: "flex-start",
            display: "flex",
            flexDirection: "column",
            marginLeft: "1.4rem",
          }}
        >
          <div
            className="p-1 pl-4 pr-4 mt-4"
            style={{
              borderRadius: "0.625rem",
              border: `1px solid #000`,
              cursor: "pointer",
              marginBottom: "1.25rem",
            }}
            onClick={handleConnect}
          >
            <WishlinkTypography
              fontSize="0.75rem"
              fontWeight={400}
              color={"#000"}
            >
              Connect Facebook
            </WishlinkTypography>
          </div>

          <div className="flex" onClick={handleVideo}>
            <img
              src={getAssetUrl("youtube.png")}
              alt="imgyt"
              style={{ width: "4.5rem", height: "4.5rem", cursor: "pointer" }}
            />
            <div className="ml-6">
              <WishlinkTypography
                fontSize="0.75rem"
                fontWeight={500}
                color={"#000"}
                textAlign="left"
              >
                Connect Facebook for Engage
              </WishlinkTypography>
              <WishlinkTypography
                fontSize="0.625rem"
                fontWeight={400}
                color={"#7F7F7F"}
                textAlign="left"
              >
                You need to reconnect your Facebook for Engage to work. Follow
                the steps mentioned in this video
              </WishlinkTypography>
              <div className="flex mt-1" style={{ cursor: "pointer" }}>
                <img src={getAssetUrl("youtube-video-icon.svg")} alt="icon" />
                <WishlinkTypography
                  fontSize="0.625rem"
                  fontWeight={400}
                  color={"#000"}
                  textAlign="left"
                  ml="0.1rem"
                >
                  01:15
                </WishlinkTypography>
              </div>
            </div>
          </div>
          <div className="flex align-center mt-4">
            <WishlinkTypography
              fontSize="0.625rem"
              fontWeight={400}
              fontStyle="italic"
              color={"#7F7F7F"}
              textAlign="left"
            >
              Incase you get error while reconnecting,
            </WishlinkTypography>
            <WishlinkTypography
              fontSize="0.625rem"
              fontWeight={400}
              fontStyle="italic"
              color={"#3F6FD9"}
              textAlign="left"
              ml="0.2rem"
              onClick={handlePdf}
              style={{ cursor: "pointer" }}
            >
              refer to this PDF
            </WishlinkTypography>
          </div>
        </div>
      )}
    </div>
  );
};

export default FacebookConnect;
