import WishlinkTypography from "../../WishlinkComponents/WishlinkTypography";
import EngageCommentShare from "../../components/EngageCommentShare/EngageCommentShare";
import CreatorTestimonialVideo from "../../components/CreatorEarnings/CreatorTestimonialVideo";
import PoweredBy from "../../components/Poweredby";
import { GA_PAGE, IMAGE_PREFIX } from "../../utils/constants";
import HowToConnectSTK from "../../components/EnableEngageSTK/HowToConnectSTK";
import EngageToggle from "../../components/Engage/EngageToggle";
import EngageCommentToggle from "../../components/Engage/EngageCommentToggle";
import EngageKeywords from "../../components/Engage/EngageKeywords";
import EngageCommentReplyList from "../../components/Engage/EngageCommentReplyList";
import ConnectEngageAccount from "../../components/EnableEngageSTK/ConnectEngageAccount";
import { useSelector } from "react-redux";
import { useContext, useEffect } from "react";
import { APIContext } from "../../apiLayer";

const EngageComment = () => {
  const { milestones, milestonesLoading } = useSelector(
    (state) => state.appReducer
  );
  const { creatorData, creatorDataIsLoading } = useSelector(
    (state) => state.apiDataReducer
  );
  const apis = useContext(APIContext);

  const navigateLocked = Boolean(
    !milestones?.check_creator_social_login_complete?.achieved ||
      !milestones?.check_first_post_milestone?.achieved ||
      !creatorData?.data?.data?.is_connected_insta
  );

  const ctkNotConnected = Boolean(
    !navigateLocked && !creatorData?.data?.data?.is_connected_ctk
  );

  useEffect(() => {
    apis.getCreatorData(undefined, false);
    apis.getCreatorDashboardMilestonesStatus();
  }, []);

  const engageHeaderDetails = () => {
    return (
      <div
        className="ml-4 mr-4 border-radius"
        style={{ background: "#FFF9FF", border: "1px solid #000" }}
      >
        <ul className="pr-4">
          <li>
            <WishlinkTypography
              fontSize="0.75rem"
              fontWeight={400}
              color="#000"
              className="text-left"
            >
              Get <b>Engagement</b> and <b>Reach</b> on Instagram
            </WishlinkTypography>
          </li>
          <li>
            <WishlinkTypography
              fontSize="0.75rem"
              fontWeight={400}
              color="#000"
              className="text-left"
            >
              <b>Set Comment Keyword</b> and start sending links on those
              comments.
            </WishlinkTypography>
          </li>
        </ul>
      </div>
    );
  };

  return (
    <>
      <EngageCommentShare
        headerBgColor="#F3C8D0"
        starImage="engage-comment-star.png"
        titleText="Send links to users who comment on your reels."
        engageHeaderDetails={engageHeaderDetails}
        engageHeaderGif="engage-comment.gif"
        page={GA_PAGE.ENGAGE_COMMENT}
      />
      {!creatorDataIsLoading &&
      !milestonesLoading &&
      !ctkNotConnected &&
      !navigateLocked ? (
        <>
          <EngageToggle />
          <EngageCommentToggle />
          <EngageKeywords />
          <EngageCommentReplyList />
        </>
      ) : (
        <></>
      )}
      <HowToConnectSTK />
      <div className="p-4 border-radius flex justify-center mt-5">
        <div
          style={{ border: "0.5px solid #000", width: "80%" }}
          className="text-center block"
        >
          <CreatorTestimonialVideo
            media_url={`${IMAGE_PREFIX}ctk-video.mp4`}
            playing={false}
          />
        </div>
      </div>
      <div style={{ paddingBottom: ctkNotConnected ? "7rem" : "" }}>
        <PoweredBy />
      </div>
      {ctkNotConnected || navigateLocked ? <ConnectEngageAccount /> : <></>}
    </>
  );
};

export default EngageComment;
