import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { updateFreshDeskLoading } from "../../redux/redux";
import { useSubmitCreatorFreshdeskRestoreIdMutation } from "../../redux/reduxAPI";
import { useLocation, useNavigate } from "react-router-dom";
import { useGetCreatorFreshdeskConversationQuery } from "../../redux/miniSourcingAuthAPI";
import { triggerGa } from "../../utils/gaUtils";
import { GA_ACTION, GA_CATEGORIES, GA_LABEL } from "../../utils/constants";
import {
  FRESHDESK_RETRIES,
  FRESHDESK_RETRY_INTERVAL,
} from "../../utils/constants";
const freshDeskConfig = {
  config: {
    fullscreen: true,
    disableNotifications: true,
    headerProperty: {
      hideChatButton: true,
    },
  },
};

const WidgetHeight = "100px";

const checkFreshDeskWidgetOpenFromUrl = () => {
  return window.location.href.indexOf("?support=true") > 0 ? true : false;
};

export const SupportWidget = () => {
  const { creatorData } = useSelector((state) => state.apiDataReducer);
  const { phoneNumber } = useSelector((state) => state.loginOtpReducer);
  const { loadingFreshDesk } = useSelector((state) => state.appReducer);
  const { username = "", restore_id } = creatorData?.data?.data || {};
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const support = queryParams.get("support");
  const dispatch = useDispatch();
  const {
    data: creatorConversationsStatus,
    isFetching,
    refetch,
  } = useGetCreatorFreshdeskConversationQuery();
  const [submitCreatorFreshdesk] = useSubmitCreatorFreshdeskRestoreIdMutation();

  const navigate = useNavigate();
  const openFreshDeskWidget = () => {
    if (!localStorage.getItem("adminToken")) {
      const element = document.getElementById("fc_frame");
      if (element) {
        element.style.display = "block";
      }
      window?.fcWidget?.open();
    }
  };

  useEffect(() => {
    const script = document.createElement("script");
    let intervalToken = null;
    const freshdeskLibElement = document.getElementById("freshdesk_lib");
    if (username !== "" && !freshdeskLibElement) {
      script.setAttribute("src", "//in.fw-cdn.com/31729874/882789.js");
      script.setAttribute("chat", "true");
      script.setAttribute("id", "freshdesk_lib");
      window.fcWidgetMessengerConfig = {
        ...freshDeskConfig,
        externalId: username,
        restoreId: restore_id,
      };
      document.body.appendChild(script);
      let retryCount = 0;
      if (username === "") return;
      intervalToken = setInterval(() => {
        if (retryCount > FRESHDESK_RETRIES) {
          clearInterval(intervalToken);
          return;
        }
        if (
          window?.fcWidget?.isInitialized?.() !== true &&
          loadingFreshDesk !== false
        ) {
          retryCount++;
          return;
        }
        clearInterval(intervalToken);
        const element = document.getElementById("fc_frame");
        if (element) {
          element.style.display = "none";
          element.style.marginBottom = WidgetHeight;
        }
        dispatch(updateFreshDeskLoading(false));
        window.fcWidget.setExternalId(username);
        window.fcWidget.user.setProperties({
          firstName: username,
          phone: phoneNumber,
        });
        window?.fcWidget?.on("widget:loaded", function (resp) {
          window?.fcWidget?.on("user:created", (res) => {
            const restoreId = res?.data?.restoreId;
            if (restoreId) {
              submitCreatorFreshdesk({ restoreId });
            } else {
              triggerGa({
                category: GA_CATEGORIES.HELP,
                action: GA_ACTION.FRESHDESK_USER_CREATED,
                label:
                  GA_LABEL.FRESHDESK_USER_CREATED_BUT_RESTORED_ID_NOT_FOUND,
                page: window.location.pathname,
              });
            }
          });
          window.fcWidget.on("widget:closed", function (resp) {
            refetch();
            const element = document.getElementById("fc_frame");
            if (element) {
              element.style.marginBottom = WidgetHeight;
            }
            if (window.location.href.indexOf("?support=true") > 0) {
              navigate(`${window.location.pathname}${window.location.hash}`, {
                replace: true,
              });
            }
          });
          window.fcWidget.on("widget:opened", function (resp) {
            triggerGa({
              category: GA_CATEGORIES.HELP,
              action: GA_ACTION.FRESHDESK_OPEN,
              page: window.location.pathname,
            });
            const element = document.getElementById("fc_frame");
            if (element) {
              element.style.marginBottom = "0px";
            }
            if (!checkFreshDeskWidgetOpenFromUrl()) {
              navigate(
                `${window.location.pathname}?support=true${window.location.hash}`
              );
            }
          });
          window.fcWidget.on("unreadCount:notify", function (resp) {
            if (resp.count > 0) {
              const element = document.getElementById("fc_frame");
              if (element && window.fcWidget.isOpen() === false) {
                element.style.display = "block";
                element.style.marginBottom = WidgetHeight;
              }
              window?.fcWidget?.show();
            }
          });
        });
      }, FRESHDESK_RETRY_INTERVAL);
    }
    return () => {
      if (!!script) {
        script.remove();
        if (window.fcWidget && window.fcWidget.isInitialized()) {
          window.fcWidget.destroy();
        }
      }
      if (intervalToken) {
        clearInterval(intervalToken);
      }
    };
  }, [username, phoneNumber]);

  useEffect(() => {
    const freshdeskWidget = document.getElementById("fc_frame");
    if (loadingFreshDesk !== false || !freshdeskWidget) return;
    if (support === "true" && window?.fcWidget?.isOpen() === false) {
      openFreshDeskWidget();
    }
    if (support !== "true" && window?.fcWidget?.isOpen()) {
      window?.fcWidget?.close();
      freshdeskWidget.style.marginBottom = WidgetHeight;
    }
  }, [loadingFreshDesk, support]);

  useEffect(() => {
    const freshdeskWidget = document.getElementById("fc_frame");
    if (loadingFreshDesk !== false || !freshdeskWidget) return;
    if (
      isFetching === false &&
      creatorConversationsStatus &&
      window?.fcWidget?.isOpen() === false &&
      checkFreshDeskWidgetOpenFromUrl() === false
    ) {
      if (creatorConversationsStatus?.data?.is_any_conversation_not_resolved) {
        freshdeskWidget.style.display = "block";
        freshdeskWidget.style.marginBottom = WidgetHeight;
      } else if (
        creatorConversationsStatus?.data?.is_any_conversation_not_resolved ===
        false
      ) {
        freshdeskWidget.style.display = "none";
        if (checkFreshDeskWidgetOpenFromUrl()) {
          navigate(`${window.location.pathname}${window.location.hash}`, {
            replace: true,
          });
        }
      }
    }
  }, [loadingFreshDesk, isFetching, creatorConversationsStatus, support]);

  return <></>;
};
