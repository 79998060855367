import { createSlice } from "@reduxjs/toolkit";
import { MyOrdersTimeFilters } from "../../utils/constants";
// All the data related to the Home page as well as miscellaneous data that can be used anywhere will be stored here
const initialState = {
  myOrdersTriggers: null,
  // Products should come sorted
  orders: [],
  ordersLoading: true,
  selectedAddress: null,
  placeOrderRequestLoading: false,
  placeOrderRequestSuccessfulId: null,

  selected_line_item_id_for_return: null,
  statusFilterOptions: [],
  showContentLiveDrawer: false,
  selectedStatusFilterOption: [],
  selectedTimeFilterOption: MyOrdersTimeFilters.ALL_TIME,
  isContentPendingSelected: false,
  isToBeReturnedSelected: false,
};

export const sourcingMyOrdersSlice = createSlice({
  name: "Cart",
  initialState: initialState,
  reducers: {
    updateMyOrdersTriggers(state, action) {
      state.myOrdersTriggers = action.payload;
    },
    updateSourcingMyOrders(state, action) {
      state.orders = action.payload;
    },
    updateSourcingOrdersLoading(state, action) {
      state.ordersLoading = action.payload;
    },
    setStatusFilterOptions(state, action) {
      state.statusFilterOptions = action.payload;
    },
    setSelectedLineItemIdForReturn(state, action) {
      state.selected_line_item_id_for_return = action.payload;
    },
    setShowContentLiveDrawer(state, action) {
      state.showContentLiveDrawer = action.payload;
    },
    setSelectedStatusFilterOption(state, action) {
      state.selectedStatusFilterOption = action.payload;
    },
    setSelectedTimeFilterOption(state, action) {
      state.selectedTimeFilterOption = action.payload;
    },
    setIsContentPendingSelected(state, action) {
      state.isContentPendingSelected = action.payload;
    },
    setIsToBeReturnedSelected(state, action) {
      state.isToBeReturnedSelected = action.payload;
    },
  },
});

export const {
  updateMyOrdersTriggers,
  updateSourcingMyOrders,
  updateSourcingOrdersLoading,
  setStatusFilterOptions,
  setSelectedLineItemIdForReturn,
  setShowContentLiveDrawer,
  setSelectedStatusFilterOption,
  setSelectedTimeFilterOption,
  setIsContentPendingSelected,
  setIsToBeReturnedSelected,
} = sourcingMyOrdersSlice.actions;
