import React from "react";
import {
  GA_ACTION,
  GA_CATEGORIES,
  GA_LABEL,
  GA_LABEL2,
  GA_PAGE,
  GA_SCREEN_NAME,
  IMAGE_PREFIX,
} from "../../utils/constants";
import {
  getAssetUrl,
  getResponsiveWidth,
  triggerGoBackGa,
} from "../../utils/common";
import { useDispatch, useSelector } from "react-redux";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Typography,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { triggerGa } from "../../utils/gaUtils";
import { updatePageNameAndScreenName } from "../../redux/redux";
import "../../animation.css";

const checkBackground = "check-grid-background.png";
const swipeVector = "swipe-vector.svg";
const maximiseContentIcon = "maximize-content-4x.png";
const bulbIcon = "bulb-animation.gif";
const ytShortsIcon = "yt-shorts-icon-white.svg";
const ytVideosIcon = "yt-video-icon-white.svg";
const instaReelsIcon = "insta-reels-icon-white.svg";
const instaPostsIcon = "insta-posts-icon-white.svg";
const infoIcon = "info-icon-white.svg";
const pbContent1 = "playbook-content-1.png";
const pbContent2 = "playbook-content-2.png";
const pbContent3 = "playbook-content-3.png";
const pbContent4 = "playbook-content-4.png";
const leftArrow = "arrow-left-light.svg";

const infoItems = [
  {
    iconUrl: instaPostsIcon,
    accordionBg: "#F6F3FF",
    iconBgColor: "#5132A9",
    titleText: "Instagram Posts",
    headingText: "Boost your reach with carousel posts",
    subHeadingText:
      "Share your outfits effortlessly with carousel and static posts.",
    imageUrl: pbContent1,
    backgroundColor: "#7349EB",
    gaLabel2: GA_LABEL2.IG_POST,
  },
  {
    iconUrl: instaReelsIcon,
    accordionBg: "#FFF0E0",
    iconBgColor: "#BF7335",
    titleText: "Instagram Reels",
    headingText: "Gain more followers with reels on Instagram",
    subHeadingText:
      "Also, monetize non-partner brands' reels by featuring similar products",
    imageUrl: pbContent3,
    backgroundColor: "#FF9C4B",
    gaLabel2: GA_LABEL2.IG_REEL,
  },
  {
    iconUrl: ytShortsIcon,
    accordionBg: "#FFE9E9",
    iconBgColor: "#A50000",
    titleText: "YouTube Shorts",
    headingText: "Leverage YouTube Shorts for incredible views",
    subHeadingText:
      "Maximize your YouTube growth with least effort by posting IG reels and snippets from your full-length videos as YT Shorts",
    imageUrl: pbContent2,
    backgroundColor: "#C4302B",
    gaLabel2: GA_LABEL2.YT_SHORTS,
  },
  {
    iconUrl: ytVideosIcon,
    accordionBg: "#FFE6E6",
    iconBgColor: "#CC0000",
    titleText: "YouTube Videos",
    headingText:
      "The best avenue to build engaging and informative content is through videos",
    subHeadingText:
      'Showcase products from multiple partner brands in one video as "Wishlink Finds"',
    imageUrl: pbContent4,
    backgroundColor: "#FF0000",
    gaLabel2: GA_LABEL2.YT_VIDEO,
  },
];

const HeadingTypography = (props) => {
  return (
    <Typography
      sx={{
        color: "#FFFFFF",
        fontWeight: 500,
        fontSize: "1rem",
        lineHeight: "1.5rem",
        marginBottom: "-0.5rem",
      }}
      className="right-transition"
    >
      {props.children}
    </Typography>
  );
};

const Banner = () => {
  return (
    <div
      style={{
        background: "#000000",
      }}
    >
      <div
        style={{
          display: "flex",
          alignItems: "center",
          marginTop: "2rem",
          background:
            "linear-gradient(148.05deg, rgba(255, 255, 255, 0.4) 0%, rgba(255, 255, 255, 0) 100%, rgba(255, 255, 255, 0) 100%)",
          filter: "drop-shadow(0px 2px 4px rgba(0, 0, 0, 0.2))",
          backdropFilter: "blur(20px)",
          borderRadius: "0.75rem",
          padding: "0.75rem",
          height: "4.125rem",
          boxSizing: "border-box",
          zIndex: 100,
        }}
      >
        <img
          src={getAssetUrl(bulbIcon)}
          alt="bulb"
          style={{ width: "2rem", borderRadius: "1rem", zIndex: 100 }}
        />
        <Typography
          sx={{
            color: "#F2F2F2",
            fontWeight: 500,
            fontSize: "0.8125rem",
            lineHeight: "1.25rem",
            textAlign: "left",
            marginLeft: "0.75rem",
            zIndex: 100,
          }}
        >
          Reach a wider audience by sharing content across all social media
          platforms
        </Typography>
      </div>
    </div>
  );
};

const InfoItem = (props) => {
  const item = props.item;
  return (
    <Box sx={{ "& .MuiPaper-root": { borderRadius: "0.5rem !important" } }}>
      <Accordion
        onChange={(event, expanded) => {
          triggerGa({
            category: GA_CATEGORIES.USER_EDUCATION,
            action: GA_ACTION.ACCORDION_CLICK,
            label: expanded ? GA_LABEL.OPEN : GA_LABEL.CLOSE,
            label2: item.gaLabel2,
            page: GA_PAGE.SELLING_PLAYBOOK,
            screenName: GA_SCREEN_NAME.SELLING_PLAYBOOK_POSTS,
          });
        }}
        sx={{
          width: "100%",
          "& .MuiPaper-root": { borderRadius: "0.5rem" },
          borderRadius: "0.5rem",
        }}
      >
        <AccordionSummary
          sx={{ background: item.backgroundColor, borderRadius: "0.5rem" }}
          expandIcon={
            <img
              src={`${IMAGE_PREFIX}up-arrow-white.svg`}
              style={{ transform: "rotate(180deg)" }}
              alt=""
            />
          }
        >
          <div
            style={{
              display: "flex",
              alignItems: "center",
              borderRadius: "0.5rem",
            }}
          >
            <div
              style={{
                background: item.iconBgColor,
                height: "3rem",
                width: "3rem",
                boxShadow: "0rem 0.0625rem 0.25rem rgba(0, 0, 0, 0.12)",
                borderRadius: "1.5rem",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <img src={getAssetUrl(item.iconUrl)} alt="item-icon" />
            </div>
            <Typography
              sx={{
                color: "#FFFFFF",
                fontWeight: 500,
                fontSize: "1rem",
                lineHeight: "1.5rem",
                marginLeft: "1rem",
              }}
            >
              {item.titleText}
            </Typography>
          </div>
        </AccordionSummary>
        <AccordionDetails
          sx={{ borderRadius: "0.5rem", background: item.accordionBg }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              borderRadius: "0.5rem",
            }}
          >
            <Typography
              sx={{
                color: "#333333",
                fontWeight: 500,
                fontSize: "0.875rem",
                lineHeight: "1.3125rem",
                textAlign: "left",
                marginTop: "0.5rem",
              }}
            >
              {item.headingText}
            </Typography>
            <Typography
              sx={{
                color: "#666666",
                fontWeight: 400,
                fontSize: "0.75rem",
                lineHeight: "1.125rem",
                textAlign: "left",
                marginTop: "0.25rem",
              }}
            >
              {item.subHeadingText}
            </Typography>
            <img
              src={getAssetUrl(item.imageUrl)}
              alt="detail"
              style={{
                borderRadius: "0.5rem",
                width: "100%",
                marginTop: "0.75rem",
              }}
            />
          </div>
        </AccordionDetails>
      </Accordion>
    </Box>
  );
};

const AttentionTypography = (props) => {
  return (
    <Typography
      sx={{
        fontWeight: 400,
        fontSize: "0.8125rem",
        lineHeight: "1.25rem",
        color: "#F2F2F2",
        textAlign: "left",
        marginLeft: "0.5rem",
      }}
    >
      {props.children}
    </Typography>
  );
};

const PlaybookContent = React.memo(function PlaybookContent(props) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { pageName, screenName } = useSelector((state) => state.appReducer);

  const [responsiveWidth, setResponsiveWidth] = useState(getResponsiveWidth);

  useEffect(() => {
    const handleScreenResize = () => {
      setResponsiveWidth(getResponsiveWidth());
    };

    window.addEventListener("resize", handleScreenResize);
    return () => window.removeEventListener("resize", handleScreenResize);
  }, []);

  useEffect(() => {
    dispatch(
      updatePageNameAndScreenName({
        pageName: GA_PAGE.SELLING_PLAYBOOK,
        screenName: GA_SCREEN_NAME.SELLING_PLAYBOOK_POSTS,
      })
    );
  }, []);

  return (
    <div
      style={{
        backgroundColor: "#000000",
        position: "relative",
        margin: "auto",
      }}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          minHeight: window.innerHeight,
        }}
      >
        <img
          src={getAssetUrl(checkBackground)}
          style={{ position: "absolute", width: responsiveWidth, zIndex: 0 }}
          alt="check-background"
        />
        <div
          style={{
            padding: "1rem",
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-start",
            width: "100%",
            boxSizing: "border-box",
            zIndex: 100,
          }}
        >
          <img
            src={getAssetUrl(leftArrow)}
            alt="left"
            style={{ zIndex: 100 }}
            onClick={() => {
              triggerGoBackGa(pageName, screenName);
              navigate(-1);
            }}
          />
        </div>
        <div style={{ padding: "1.5rem", zIndex: 100 }}>
          <img
            src={getAssetUrl(maximiseContentIcon)}
            alt="icon"
            style={{ width: "4rem", marginBottom: "0.5rem", zIndex: 100 }}
          />
          <HeadingTypography>Maximize your Wishlink Posts</HeadingTypography>
          <img src={getAssetUrl(swipeVector)} alt="vector" />
          <Banner />
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              gap: "1rem",
              marginTop: "2rem",
            }}
          >
            {infoItems.map((item, idx) => (
              <InfoItem item={item} />
            ))}
          </div>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              marginTop: "1rem",
            }}
          >
            <img src={getAssetUrl(infoIcon)} alt="info-icon" />
            <AttentionTypography>
              Don’t forget to create separate Wishlink Posts for each channel
              and share their product Wishlinks
            </AttentionTypography>
          </div>
        </div>
      </div>
    </div>
  );
});

export default PlaybookContent;
