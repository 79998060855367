import React, { useEffect, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import { usePostHog } from "posthog-js/react";
import CONFIG from "../utils/config";
import {
  useLazyGetCreatorDataQuery,
  useLazyGetStorePostsDataQuery,
  useLazyGetStoreCollectionsDataQuery,
  useLazyGetStoreProductsDataQuery,
  useLazyGetStoreDraftsDataQuery,
  useLazyGetCreatorShopCountsQuery,
  useLazyGetPostProductsDetailsQuery,
  useLazyGetCreatorTagsQuery,
  usePostNewPostDataMutation,
  useLazyGetCreatorPostDataQuery,
  useLazyGetProductLinksDataQuery,
  usePostShopProductsDataMutation,
  useUpdatePostOrCollectionStatusMutation,
  usePostNewCollectionDataMutation,
  useUpdateProductStatusMutation,
  useLazyGetPostOrCollectionProductsDataQuery,
  usePostYoutubeLoginMutation,
  usePostSendOTPMutation,
  usePostVerifyOTPMutation,
  usePostLogOTPAuthStatusMutation,
  usePostFirebaseLoginMutation,
  useLazyCheckAuthStatusDataQuery,
  usePostSocialManualLoginMutation,
  usePostConfirmCreatorUsernameMutation,
  usePostInstagramLoginMutation,
  usePostPinterestLoginMutation,
  useLazyGetInstaPostsListQuery,
  useLazyGetYoutubePostsListQuery,
  useLazyGetCreatorSocialLoginStatusQuery,
  usePostFilteredBrandsDataMutation,
  useLazyFetchBrandTagsDataQuery,
  useLazyGetFiltersAndSortDataQuery,
  useLazyFetchAllBrandBannersDataQuery,
  usePostSingleProductLinkMutation,
  useLazyCheckTaskStatusQuery,
  useLazyGetPostRedirectsQuery,
  useLazyGetCollectionRedirectsQuery,
  useLazyGetProductRedirectsQuery,
  useUpdateCreatorProfileMutation,
  useLazyFetchCreatorBankDetailsQuery,
  useSubmitCreatorBankDetailsMutation,
  useLazyGetCTKDetailsQuery,
  useLazyGetCreatorTemplatesQuery,
  useUpdateCreatorTemplatesMutation,
  useLoginMutation,
  useLazyGetAmazonDataQuery,
  useLazyGetBrandWiseEarningsSplitDataQuery,
  useLazyGetCreatorAnalyticsChartDataQuery,
  useLazyGetChannelWiseEarningsSplitDataQuery,
  useLazyGetCreatorAnalyticsCollectionsDataQuery,
  useLazyGetCreatorAnalyticsPostsDataQuery,
  useLazyGetCreatorAnalyticsProductsDataQuery,
  useLazyGetCreatorSalesDataQuery,
  useLazyGetCreatorAnalyticsRedirectionsDataQuery,
  useLazyGetCreatorLifetimeEarningsQuery,
  useLazyGetCreatorEarningsThisPayoutQuery,
  useLazyGetCreatorPastPayoutsQuery,
  useDisconnectFacebookMutation,
  useConnectFacebookMutation,
  useLazyGetCreatorDashboardMilestonesStatusQuery,
  useUpdateCreatorDashboardMilestonesStatusMutation,
  useLazyGetMarketingMediaReelsQuery,
  useLazyGetCreatorsUsernamesQuery,
  useLazyGetCalendarPostsReleaseDatesQuery,
  useLazyGetCreatorRewardsAnalyticsQuery,
  useLazyGetPostOrCollectionLifetimeFunnelQuery,
  useLazyGetPostOrCollectionEarningsQuery,
  useLazyGetPostOrCollectionProductEarningsQuery,
  useLazyGetSortAndFiltersForAnalyticsQuery,
  useUpdateCTKsettingsMutation,
  useLazyGetInstaBasicProfileDataQuery,
  useLazyGetProductAnalyticsQuery,
  useLazyGetRewardsLeaderboardQuery,
  useLazyGetCampaignDetailsQuery,
  useLazyGetBrandRewardsQuery,
  useLazyGetNykaaAprilRewardsQuery,
  useLazyGetRewardsSummaryQuery,
  useCollectCreatorFeedbackMutation,
  useLazyGetMeeshoCatalogQuery,
  useLazyGetDateWiseOrderSummaryQuery,
  useLazyGetOrderHistoryForDateQuery,
  useLazyFetchCreatorAddressQuery,
  useUpdateCreatorAddressMutation,
  useLazyFetchAddressByIdQuery,
  useCreateNewAddressMutation,
  useLazyGetProductFindsQuery,
  useUpdateProductFindsFlagMutation,
  useLazyGetPostOrCollectionLifetimeLinkClicksQuery,
  useLazyDeleteWishlinkAccountQuery,
  useLazyDeactivateWishlinkAccountQuery,
  useLazyGetNotificationsQuery,
  useLazyGetEarlierNotificationsQuery,
  useLazyGetBellIconStatusQuery,
  useMarkNotificationAsReadMutation,
  useLazyGetCreatorReferralEarningsQuery,
  useLazyGetRefererDetailsQuery,
  useLazyGetCreatorReferralLedgerQuery,
  useLazyGetCreatorReferralDataQuery,
  useUpdateRewardsFocusMutation,
  useLazyGetCreatorDemoDataQuery,
  useUpdateCreatorDemoDetailsMutation,
  useLazyGetScrapingFailureDomainsQuery,
  useLazyGetCreatorWinterContentDataQuery,
  useUpdateCreatorWinterMeetDetailsMutation,
  useUpdateCreatorBPCMeetDetailsMutation,
  useLazyGetMarqueeDetailsQuery,
  useLazyGetExploreBannerDetailsQuery,
  useLazyFetchCreatorTypeQuery,
  useLazyGetOverlaysQuery,
  useUpdateFirstLinkSkippedMutation,
  useUpdateOverlayStatusAsReadMutation,
  useUpdateEngageGtmFlagMutation,
  useLazyGetOauthClientNameQuery,
  useLazyGetCrossPostingConfigQuery,
  usePostCrossPostingConfigMutation,
} from "../redux/reduxAPI";
import { APIContext } from "../apiLayer";
import { useDispatch } from "react-redux";
import {
  updateCreatorData,
  updatePostsData,
  updateCollectionsData,
  updateProductsData,
  updateDraftsData,
  updateShopCountsData,
  updatePostProductsData,
  updateCreatorTagsData,
  fetchCreatorPost,
  updateCreatorDataIsLoading,
  updateProductDataIsLoading,
  updateCollectionsDataIsLoading,
  updatePostsDataIsLoading,
  updateDraftsDataIsLoading,
  updatePostProductsDataIsLoading,
  updateProductLinksData,
  updatePostOrCollectionProductsData,
  showToastNotification,
  hideToastNotification,
  updateAuthCheckStatus,
  updateInstaPostsList,
  updateYoutubePostsList,
  updateCreatorSocialLoginStatus,
  updateFilteredBrandsData,
  updateBrandTagsData,
  updateFiltersAndSort,
  updateBrandBanners,
  updatePostOrCollectionStatusIsLoading,
  updateNewPostDataIsLoading,
  updatePostRedirects,
  updateCollectionRedirects,
  updateProductRedirects,
  updateBankDetails,
  updateCtkDetails,
  updateCtkDetailsLoading,
  updateTemplates,
  updateAmazonAnalytics,
  updateBrandWiseEarningsSplit,
  updateAnalyticsChart,
  updateChannelWiseEarningsSplit,
  updateCollectionAnalytics,
  updatePostAnalytics,
  updateProductAnalytics,
  updateSalesAnalytics,
  updateRedirectionsAnalytics,
  updateCreatorLifetimeEarnings,
  updateCreatorEarningsThisPayout,
  updatePastPayouts,
  updateMilestones,
  updateMediaReels,
  updateBrandListIsLoading,
  updateAllCreatorUsernames,
  updateAnalyticsPostsReleaseDates,
  updateRewardsAnalytics,
  updatePostOrCollectionLifetimeFunnel,
  updatePostOrCollectionEarnings,
  updatePostOrCollectionProductEarnings,
  updateSortAndFiltersForAnalytics,
  updateInstaBasicProfileData,
  updateInstaBasicProfileLoading,
  updateAffiliateProductAnalytics,
  updateCampaignDetails,
  updateRewardsLeaderboard,
  updateRewardsSummary,
  updateMeeshoCatalogDetails,
  updateOrderSummary,
  updateOrderHistoryForDate,
  updateOrderSummaryLoading,
  updateOrderHistoryForDateLoading,
  updateCreatorAddressByIdLoading,
  updateCreatorAddress,
  updateAddressById,
  updateProductFinds,
  updatePostOrCollectionLifetimeLinkClicks,
  updateMilestonesLoading,
  updateNotificationsList,
  updateEarlierNotificationsList,
  updateBellIconStatus,
  appendNewSocketNotification,
  updateNotificatonsListLoading,
  updateBellIconLoading,
  updateCreatorReferrals,
  updateReferrerDetails,
  updateCreatorRefererLedger,
  updateCreatorRefererLedgerLoading,
  updateCreatorReferalData,
  updateCreatorDemoDataLoading,
  updateCreatorDemoData,
  updateScrapingFailureDomains,
  updateScrapingFailureDomainsIsLoading,
  updateCreatorWinterContentMeetData,
  updateCreatorWinterContentMeetDataLoading,
  updatePostOrCollectionLifetimeClicksLoading,
  updateMarqueeDetails,
  updateExploreBanners,
  updateIsMyntraCreator,
  updateOverlays,
  updateOauthClientNameIsLoading,
  updateOauthClientName,
  updateBrandRewards,
  updateNykaaBrandRewards,
  updateOverlaysIsLoading,
  updateCrossPostingConfig,
} from "../redux/redux";
import { getDataPointLoadingValue } from "../utils/common";
import {
  ADD_CREATOR_TO_GROUP,
  NOTIF_TYPE,
  PAGE_URLS,
} from "../utils/constants";
import { useBlitzllama } from "../hooks/Blitzllama/useBlitzllama";
import { useSingular } from "../hooks/useSingular/useSingular";

export const DataLayer = React.memo(function DataLayer(props) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const posthog = usePostHog();
  const { setBlitzllamaUserId } = useBlitzllama();
  const { setUserIdInSingular } = useSingular();

  useEffect(() => {
    let websocket = new WebSocket(CONFIG.API.wss);

    websocket.onopen = (e) => {
      let token = localStorage.firebaseToken;
      let token_data = {
        message_type: ADD_CREATOR_TO_GROUP,
        access_token: token,
      };
      websocket.send(JSON.stringify(token_data));
    };

    websocket.onmessage = (e) => {
      let newNotification = JSON.parse(e.data)?.data;
      if (newNotification.update_type === NOTIF_TYPE.NOTIFICATION_LEDGER) {
        dispatch(appendNewSocketNotification(newNotification?.data));
      } else if (newNotification.update_type === NOTIF_TYPE.BELL_ICON) {
        let bellIconStatus = {
          data: {
            data: newNotification?.data,
          },
        };
        dispatch(updateBellIconStatus(bellIconStatus));
      }
    };
  }, []);

  const [triggerCreatorData, resultCreatorData] = useLazyGetCreatorDataQuery();
  const [triggerPostsData, resultPostsData] = useLazyGetStorePostsDataQuery();
  const [triggerUpdateEngageGtmFlag] = useUpdateEngageGtmFlagMutation();
  const [triggerCollectionsData, resultCollectionsData] =
    useLazyGetStoreCollectionsDataQuery();
  const [triggerProductsData, resultProductsData] =
    useLazyGetStoreProductsDataQuery();
  const [triggerDraftsData, resultDraftsData] =
    useLazyGetStoreDraftsDataQuery();
  const [triggerShopCountsData, resultShopCountsData] =
    useLazyGetCreatorShopCountsQuery();
  const [triggerGetPostProductData, resultPostProductData] =
    useLazyGetPostProductsDetailsQuery();
  const [triggerCheckAuthStatusData, resultCheckAuthStatusData] =
    useLazyCheckAuthStatusDataQuery();
  const [triggerGetInstaPostsListQuery, resultInstaPostsListQuery] =
    useLazyGetInstaPostsListQuery();
  const [triggerGetYoutubePostsListQuery, resultYoutubePostsListQuery] =
    useLazyGetYoutubePostsListQuery();
  const [
    triggerGetCreatorSocialLoginStatusData,
    resultCreatorSocialLoginStatus,
  ] = useLazyGetCreatorSocialLoginStatusQuery();
  const [triggerCalendarPostsReleaseDates, resultCalendarPostsReleaseDates] =
    useLazyGetCalendarPostsReleaseDatesQuery();
  const [
    triggerPostOrCollectionLifetimeFunnel,
    resultPostOrCollectionLifetimeFunnel,
  ] = useLazyGetPostOrCollectionLifetimeFunnelQuery();
  const [triggerPostOrCollectionEarnings, resultPostOrCollectionEarnings] =
    useLazyGetPostOrCollectionEarningsQuery();
  const [
    triggerPostOrCollectionProductEarnings,
    resultPostOrCollectionProductEarnings,
  ] = useLazyGetPostOrCollectionProductEarningsQuery();
  const [triggerCampaignDetails, resultCampaignDetails] =
    useLazyGetCampaignDetailsQuery();
  const [triggerGetBrandRewards, resultGetBrandRewards] =
    useLazyGetBrandRewardsQuery();
  const [triggerGetNykaaBrandRewards, resultGetNykaaBrandRewards] =
    useLazyGetNykaaAprilRewardsQuery();

  const [triggerRewardsLeaderboard, resultRewardsLeaderboard] =
    useLazyGetRewardsLeaderboardQuery();
  const [triggerRewardsSummary, resultRewardsSummary] =
    useLazyGetRewardsSummaryQuery();
  const [triggerScrapingFailureDomains, resultScrapingFailureDomains] =
    useLazyGetScrapingFailureDomainsQuery();
  const [triggerOverlays, resultOverlays] = useLazyGetOverlaysQuery();

  const [triggerGetCreatorTags, resultTagsData] = useLazyGetCreatorTagsQuery();
  const [postNewPostDataTrigger, resultNewPostData] =
    usePostNewPostDataMutation();
  const [postNewCollectionDataTrigger, resultNewCollectionData] =
    usePostNewCollectionDataMutation();
  const [triggerCreatorPostData, resultCreatorPostData] =
    useLazyGetCreatorPostDataQuery();
  const [triggerProductLinksData, resultProductLinksData] =
    useLazyGetProductLinksDataQuery();
  const [postShopProductsDataTrigger] = usePostShopProductsDataMutation();
  const [confirmCreatorUsernameMutationTrigger] =
    usePostConfirmCreatorUsernameMutation();
  const [postYoutubeLoginTrigger] = usePostYoutubeLoginMutation();
  const [postSendOTPtrigger] = usePostSendOTPMutation();
  const [postVerifyOTPTrigger] = usePostVerifyOTPMutation();
  const [postLogOTPAuthStatusTrigger] = usePostLogOTPAuthStatusMutation();
  const [postFirebaseLoginTrigger, resultFirebaseLogin] =
    usePostFirebaseLoginMutation();
  const [postSocialManualLoginTrigger, resultPostSocialManualLogin] =
    usePostSocialManualLoginMutation();
  const [postInstagramLoginTrigger] = usePostInstagramLoginMutation();
  const [postPinterestLoginTrigger] = usePostPinterestLoginMutation();
  const [triggerUpdateCrossPostingConfigData] =
    usePostCrossPostingConfigMutation();
  const [triggerGetCrossPostingConfigData, resultGetCrossPostingConfig] =
    useLazyGetCrossPostingConfigQuery();
  const [
    updatePostOrCollectionStatusTrigger,
    resultUpdatePostOrCollectionStatus,
  ] = useUpdatePostOrCollectionStatusMutation();
  const [updateProductStatusTrigger, resultUpdateProductStatus] =
    useUpdateProductStatusMutation();
  const [triggerAmazonData, resultAmazonData] = useLazyGetAmazonDataQuery();
  const [triggerBrandWiseEarningsSplitData, resultBrandWiseEarningsSplitData] =
    useLazyGetBrandWiseEarningsSplitDataQuery();
  const [triggerCreatorAnalyticsChartData, resultCreatorAnalyticsChartData] =
    useLazyGetCreatorAnalyticsChartDataQuery();
  const [
    triggerChannelWiseEarningsSplitData,
    resultChannelWiseEarningsSplitData,
  ] = useLazyGetChannelWiseEarningsSplitDataQuery();
  const [
    triggerCreatorAnalyticsCollectionsDataQuery,
    resultCreatorAnalyticsCollectionsDataQuery,
  ] = useLazyGetCreatorAnalyticsCollectionsDataQuery();
  const [triggerCreatorAnalyticsPostsData, resultCreatorAnalyticsPostsData] =
    useLazyGetCreatorAnalyticsPostsDataQuery();
  const [
    triggerCreatorAnalyticsProductsData,
    resultCreatorAnalyticsProductsData,
  ] = useLazyGetCreatorAnalyticsProductsDataQuery();
  const [triggerCreatorRedirectionsData, resultCreatorRedirectionsData] =
    useLazyGetCreatorAnalyticsRedirectionsDataQuery();
  const [triggerCreatorSalesData, resultCreatorSalesData] =
    useLazyGetCreatorSalesDataQuery();
  const [
    triggerPostOrCollectionProductsData,
    resultPostOrCollectionProductsData,
  ] = useLazyGetPostOrCollectionProductsDataQuery();
  const [triggerCreatorRewardsAnalytics, resultCreatorRewardsAnalytics] =
    useLazyGetCreatorRewardsAnalyticsQuery();
  const [triggerSortAndFiltersForAnalytics, resultSortAndFiltersForAnalytics] =
    useLazyGetSortAndFiltersForAnalyticsQuery();
  const [triggerAffiliateProductAnalytics, resultAffiliateProductAnalytics] =
    useLazyGetProductAnalyticsQuery();

  const [triggerFilteredBrands, resultFilteredBrands] =
    usePostFilteredBrandsDataMutation();
  const [triggerBrandTags, resultBrandTags] = useLazyFetchBrandTagsDataQuery();
  const [triggerFiltersAndSort, resultFiltersAndSort] =
    useLazyGetFiltersAndSortDataQuery();
  const [triggerBrandBanners, resultBrandBanners] =
    useLazyFetchAllBrandBannersDataQuery();
  const [triggerPostRedirects, resultPostRedirects] =
    useLazyGetPostRedirectsQuery();
  const [triggerCollectionRedirects, resultCollectionRedirects] =
    useLazyGetCollectionRedirectsQuery();
  const [triggerProductRedirects, resultProductRedirects] =
    useLazyGetProductRedirectsQuery();
  const [triggerPostSingleProductLink, resultPostSingleProductLink] =
    usePostSingleProductLinkMutation();
  const [triggerCheckTaskStatus] = useLazyCheckTaskStatusQuery();
  const [triggerCreatorProfile, resultCreatorProfile] =
    useUpdateCreatorProfileMutation();
  const [triggerCreatorBankDetails, resultCreatorBankDetails] =
    useLazyFetchCreatorBankDetailsQuery();
  const [triggerSubmitCreatorBankDetails, resultSubmitCreatorBankDetails] =
    useSubmitCreatorBankDetailsMutation();
  const [triggerCTKDetails, resultCTKDetails] = useLazyGetCTKDetailsQuery();
  const [triggerGetCreatorTemplates, resultGetCreatorTemplates] =
    useLazyGetCreatorTemplatesQuery();
  const [triggerUpdateCreatorTemplates, resuleUpdateCreatorTemplates] =
    useUpdateCreatorTemplatesMutation();
  const [triggerLogin] = useLoginMutation();
  const [triggerCreatorLifetimeEarnings, resultCreatorLifetimeEarnings] =
    useLazyGetCreatorLifetimeEarningsQuery();
  const [triggerCreatorEarningsThisPayout, resultCreatorEarningsThisPayout] =
    useLazyGetCreatorEarningsThisPayoutQuery();
  const [triggerCreatorPastPayouts, resultCreatorPastPayouts] =
    useLazyGetCreatorPastPayoutsQuery();
  const [triggerDisconnectFacebook, resultDisconnectFacebook] =
    useDisconnectFacebookMutation();
  const [triggerConnectFacebook] = useConnectFacebookMutation();
  const [
    triggerCreatorDashboardMilestonesStatus,
    resultCreatorDashboardMilestonesStatus,
  ] = useLazyGetCreatorDashboardMilestonesStatusQuery();
  const [triggerMeeshoCatalogDetails, resultMeeshoCatalogDetails] =
    useLazyGetMeeshoCatalogQuery();
  const [
    triggerUpdateCreatorDashboardMilestonesStatus,
    resultUpdateCreatorDashboardMilestonesStatus,
  ] = useUpdateCreatorDashboardMilestonesStatusMutation();
  const [triggerMediaReels, resultMediaReels] =
    useLazyGetMarketingMediaReelsQuery();
  const [triggerAllCreatorUsernames, resultAllCreatorUsernames] =
    useLazyGetCreatorsUsernamesQuery();
  const [triggerUpdateCTKsettings] = useUpdateCTKsettingsMutation();
  const [triggerGetInstaBasicProfileData, resultGetInstaBasicProfileData] =
    useLazyGetInstaBasicProfileDataQuery();
  const [triggerCollectCreatorFeedback, resultCollectCreatorFeedback] =
    useCollectCreatorFeedbackMutation();
  const [triggerGetDateWiseOrderSummary, resultGetDateWiseOrderSummary] =
    useLazyGetDateWiseOrderSummaryQuery();
  const [triggerGetOrderHistoryForDate, resultGetOrderHistoryForDate] =
    useLazyGetOrderHistoryForDateQuery();
  const [triggerCreatorAddress, resultCreatorAddress] =
    useLazyFetchCreatorAddressQuery();
  const [triggerUpdateCreatorAddress, resultUpdateCreatorAddress] =
    useUpdateCreatorAddressMutation();
  const [triggerFetchAddressById, resultFetchAddressById] =
    useLazyFetchAddressByIdQuery();
  const [triggerCreateNewAddress, resultCreateNewAddress] =
    useCreateNewAddressMutation();
  const [triggerGetProductFinds, resultGetProductFinds] =
    useLazyGetProductFindsQuery();
  const [triggerUpdateProductFindsFlag] = useUpdateProductFindsFlagMutation();
  const [
    triggerGetPostOrCollectionLifetimeLinkClicks,
    resultGetPostOrCollectionLifetimeLinkClicks,
  ] = useLazyGetPostOrCollectionLifetimeLinkClicksQuery();
  const [triggerDeleteWishlinkAccountQuery, resultDeleteWishlinkAccountQuery] =
    useLazyDeleteWishlinkAccountQuery();
  const [
    triggerDeactivateWishlinkAccountQuery,
    resultDeactivateWishlinkAccountQuery,
  ] = useLazyDeactivateWishlinkAccountQuery();
  const [triggerGetNotifications, resultGetNotifications] =
    useLazyGetNotificationsQuery();
  const [triggerGetEarlierNotifications, resultGetEarlierNotifications] =
    useLazyGetEarlierNotificationsQuery();
  const [triggerGetBellIconStatus, resultGetBellIconStatus] =
    useLazyGetBellIconStatusQuery();
  const [triggerMarkNotificationAsRead] = useMarkNotificationAsReadMutation();
  const [triggerCreatorReferralEarnings, resultCreatorReferralEarnings] =
    useLazyGetCreatorReferralEarningsQuery();
  const [triggerRefererDetails, resultRefererDetails] =
    useLazyGetRefererDetailsQuery();
  const [triggerCreatorReferralLedger, resultCreatorReferralLedger] =
    useLazyGetCreatorReferralLedgerQuery();
  const [triggerCreatorReferralData, resultCreatorReferralData] =
    useLazyGetCreatorReferralDataQuery();
  const [triggerCreatorDemoData, resultCreatorDemoData] =
    useLazyGetCreatorDemoDataQuery();
  const [triggerWinterContentData, resultWinterContentData] =
    useLazyGetCreatorWinterContentDataQuery();
  const [triggerUpdateRewardsFocus] = useUpdateRewardsFocusMutation();
  const [triggerUpdateDemoDetails, resultUpdateDemoDetails] =
    useUpdateCreatorDemoDetailsMutation();
  const [
    triggerUpdateWinterContentMeetDetails,
    resultUpdateWinterContentMeetDetails,
  ] = useUpdateCreatorWinterMeetDetailsMutation();
  const [triggerFirstLinkSkipped] = useUpdateFirstLinkSkippedMutation();
  const [
    triggerUpdateBPCContentMeetDetails,
    resultUpdateBPCContentMeetDetails,
  ] = useUpdateCreatorBPCMeetDetailsMutation();
  const [triggerMarqueeDetails, resultMarqueeDetails] =
    useLazyGetMarqueeDetailsQuery();
  const [triggerExploreBannerDetails, resultExploreBannerDetails] =
    useLazyGetExploreBannerDetailsQuery();
  const [triggerFetchCreatorTypeQuery, resultFetchCreatorTypeQuery] =
    useLazyFetchCreatorTypeQuery();
  const [triggerUpdateOverlayStatusAsRead] =
    useUpdateOverlayStatusAsReadMutation();
  const [triggerGetOauthClientName, resultOauthClientName] =
    useLazyGetOauthClientNameQuery();

  let apiTriggers = {
    showToast({ variant, message }) {
      dispatch(showToastNotification({ variant, message }));
      setTimeout(() => {
        dispatch(hideToastNotification());
      }, 3000);
    },
    getCreatorData: async (apiCallParams, cache = true) =>
      await triggerCreatorData(apiCallParams, cache),
    getStorePostsData: async (apiCallParams, cache = true) =>
      await triggerPostsData(apiCallParams, cache),
    getStoreCollectionsData: async (apiCallParams, cache = true) =>
      await triggerCollectionsData(apiCallParams, cache),
    getStoreProductsData: async (apiCallParams, cache = true) =>
      await triggerProductsData(apiCallParams, cache),
    getStoreDraftsData: async (apiCallParams, cache = false) =>
      await triggerDraftsData(apiCallParams, cache),
    getShopCountsData: async (apiCallParams, cache = true) =>
      await triggerShopCountsData(apiCallParams, cache),
    getPostProductsData: async (apiCallParams) =>
      await triggerGetPostProductData(apiCallParams),
    getCreatorTags: async (apiCallParams) =>
      await triggerGetCreatorTags(apiCallParams, true),
    postNewPostData: async (apiCallParams) =>
      await postNewPostDataTrigger(apiCallParams),
    postEngageGtmFlagUpdate: async (apiCallParams) =>
      await triggerUpdateEngageGtmFlag(apiCallParams),
    getCreatorPost: async (apiCallParams) =>
      await triggerCreatorPostData(apiCallParams, true),
    getProductLinks: async (apiCallParams) =>
      await triggerProductLinksData(apiCallParams),
    postShopProductsData: async (apiCallParams) =>
      await postShopProductsDataTrigger(apiCallParams),
    updatePostOrCollectionStatus: async (apiCallParams) =>
      await updatePostOrCollectionStatusTrigger(apiCallParams),
    postNewCollectionData: async (apiCallParams) =>
      await postNewCollectionDataTrigger(apiCallParams),
    updateProductStatus: async (apiCallParams) =>
      await updateProductStatusTrigger(apiCallParams),
    getPostOrCollectionProductsData: async (apiCallParams) =>
      await triggerPostOrCollectionProductsData(apiCallParams),
    getYoutubePostsListData: async (apiCallParams) =>
      await triggerGetYoutubePostsListQuery(apiCallParams, true),
    getInstaPostsListData: async (apiCallParams) =>
      await triggerGetInstaPostsListQuery(apiCallParams, true),
    postYoutubeLogin: async (apiCallParams) =>
      await postYoutubeLoginTrigger(apiCallParams),
    postSendOTP: async (apiCallParams) =>
      await postSendOTPtrigger(apiCallParams),
    postVerifyOTP: async (apiCallParams) =>
      await postVerifyOTPTrigger(apiCallParams),
    postLogOTPAuthStatus: async (apiCallParams) =>
      await postLogOTPAuthStatusTrigger(apiCallParams),
    getOauthClientNameData: async (apiCallParams) =>
      await triggerGetOauthClientName(apiCallParams, true),
    postFirebaseLogin: async (apiCallParams) =>
      await postFirebaseLoginTrigger(apiCallParams),
    postSocialManualLogin: async (apiCallParams) =>
      await postSocialManualLoginTrigger(apiCallParams),
    postInstagramLogin: async (apiCallParams) =>
      await postInstagramLoginTrigger(apiCallParams),
    confirmCreatorUsername: async (apiCallParams) =>
      await confirmCreatorUsernameMutationTrigger(apiCallParams),
    getCheckAuthStatusData: async (apiCallParams) =>
      await triggerCheckAuthStatusData(apiCallParams, false),
    getCreatorSocialLoginStatusData: async (apiCallParams) =>
      await triggerGetCreatorSocialLoginStatusData(apiCallParams, false),
    postPinterestLogin: async (apiCallParams) =>
      await postPinterestLoginTrigger(apiCallParams),
    getCrossPostingConfig: async (apiCallParams) =>
      await triggerGetCrossPostingConfigData(apiCallParams, false),
    updateCrossPostingConfig: async (apiCallParams) =>
      await triggerUpdateCrossPostingConfigData(apiCallParams),
    fetchFilteredBrands: async (apiCallParams) =>
      await triggerFilteredBrands(apiCallParams, true),
    fetchBrandTags: async (apiCallParams) =>
      await triggerBrandTags(apiCallParams, true),
    markFirstLinkSkipped: async (apiCallParams) =>
      await triggerFirstLinkSkipped(apiCallParams),
    fetchFiltersAndSort: async (apiCallParams) =>
      await triggerFiltersAndSort(apiCallParams, true),
    fetchBrandBanners: async (apiCallParams) =>
      await triggerBrandBanners(apiCallParams, true),
    getPostRedirects: async (apiCallParams, cache = true) =>
      await triggerPostRedirects(apiCallParams, cache),
    getCollectionRedirects: async (apiCallParams, cache = true) =>
      await triggerCollectionRedirects(apiCallParams, cache),
    getProductRedirects: async (apiCallParams, cache = true) =>
      await triggerProductRedirects(apiCallParams, cache),
    postSingleProductLink: async (apiCallParams) =>
      await triggerPostSingleProductLink(apiCallParams),
    checkTaskStatus: async (apiCallParams) =>
      await triggerCheckTaskStatus(apiCallParams),
    updateCreatorProfile: async (apiCallParams) =>
      await triggerCreatorProfile(apiCallParams),
    fetchCreatorBankDetails: async (apiCallParams) =>
      await triggerCreatorBankDetails(apiCallParams, true),
    submitCreatorBankDetails: async (apiCallParams) =>
      await triggerSubmitCreatorBankDetails(apiCallParams),
    getCTKDetails: async (apiCallParams) =>
      await triggerCTKDetails(apiCallParams, true),
    getCreatorTemplates: async (apiCallParams) =>
      await triggerGetCreatorTemplates(apiCallParams, true),
    updateCreatorTemplates: async (apiCallParams) =>
      await triggerUpdateCreatorTemplates(apiCallParams),
    login: async (apiCallParams) => await triggerLogin(apiCallParams),
    getAmazonAnalytics: async (apiCallParams) =>
      await triggerAmazonData(apiCallParams, true),
    getBrandEarningsSplit: async (apiCallParams) =>
      await triggerBrandWiseEarningsSplitData(apiCallParams, true),
    getChartAnalytics: async (apiCallParams) =>
      await triggerCreatorAnalyticsChartData(apiCallParams, true),
    getChannelEarningsSplit: async (apiCallParams) =>
      await triggerChannelWiseEarningsSplitData(apiCallParams, true),
    getCollectionAnalytics: async (apiCallParams) =>
      await triggerCreatorAnalyticsCollectionsDataQuery(apiCallParams, true),
    getPostAnalytics: async (apiCallParams) =>
      await triggerCreatorAnalyticsPostsData(apiCallParams, true),
    getProductAnalytics: async (apiCallParams) =>
      await triggerCreatorAnalyticsProductsData(apiCallParams, true),
    getSalesAnalytics: async (apiCallParams) =>
      await triggerCreatorSalesData(apiCallParams, true),
    getRedirectionsAnalytics: async (apiCallParams) =>
      await triggerCreatorRedirectionsData(apiCallParams, true),
    getOverlayData: async (apiCallParams) =>
      await triggerOverlays(apiCallParams, false),
    markOverlayAsRead: async (apiCallParams) =>
      await triggerUpdateOverlayStatusAsRead(apiCallParams),
    getCreatorLifetimeEarnings: async (apiCallParams) =>
      await triggerCreatorLifetimeEarnings(apiCallParams, true),
    getCreatorEarningsThisPayout: async (apiCallParams) =>
      await triggerCreatorEarningsThisPayout(apiCallParams, true),
    getCreatorPastPayouts: async (apiCallParams) =>
      await triggerCreatorPastPayouts(apiCallParams, true),
    disconnectFacebook: async (apiCallParams) =>
      await triggerDisconnectFacebook(apiCallParams),
    connectFacebook: async (apiCallParams) =>
      await triggerConnectFacebook(apiCallParams),
    getCreatorDashboardMilestonesStatus: async (apiCallParams, cache = false) =>
      await triggerCreatorDashboardMilestonesStatus(apiCallParams, cache),
    updateCreatorDashboardMilestonesStatus: async (apiCallParams) =>
      await triggerUpdateCreatorDashboardMilestonesStatus(apiCallParams),
    getMarketingMediaReels: async (apiCallParams) =>
      await triggerMediaReels(apiCallParams, true),
    getCreatorsUsernamesData: async (apiCallParams) =>
      await triggerAllCreatorUsernames(apiCallParams, true),
    getPostsReleaseDates: async (apiCallParams) =>
      await triggerCalendarPostsReleaseDates(apiCallParams, true),
    getRewardsAnalytics: async (apiCallParams) =>
      await triggerCreatorRewardsAnalytics(apiCallParams, true),
    getPostOrCollectionLifetimeFunnel: async (apiCallParams) =>
      await triggerPostOrCollectionLifetimeFunnel(apiCallParams, true),
    getPostOrCollectionEarnings: async (apiCallParams) =>
      await triggerPostOrCollectionEarnings(apiCallParams, true),
    getPostOrCollectionProductEarnings: async (apiCallParams) =>
      await triggerPostOrCollectionProductEarnings(apiCallParams, true),
    getSortAndFiltersForAnalytics: async (apiCallParams) =>
      await triggerSortAndFiltersForAnalytics(apiCallParams, true),
    updateCTKsettings: async (apiCallParams) =>
      await triggerUpdateCTKsettings(apiCallParams),
    getInstaBasicProfileData: async (apiCallParams) =>
      await triggerGetInstaBasicProfileData(apiCallParams, false),
    getAffiliateProductAnalytics: async (apiCallParams) =>
      await triggerAffiliateProductAnalytics(apiCallParams, true),
    getRewardsCampaignData: async (apiCallParams) =>
      await triggerCampaignDetails(apiCallParams, true),
    getBrandRewards: async (apiCallParams) =>
      await triggerGetBrandRewards(apiCallParams, true),
    getNykaaBrandRewards: async (apiCallParams) =>
      await triggerGetNykaaBrandRewards(apiCallParams, false),

    getRewardsLeaderboardData: async (apiCallParams) =>
      await triggerRewardsLeaderboard(apiCallParams, true),
    getRewardsSummary: async (apiCallParams) =>
      await triggerRewardsSummary(apiCallParams, false),
    collectCreatorFeedback: async (apiCallParams) =>
      await triggerCollectCreatorFeedback(apiCallParams),
    getMeeshoCatalogDetails: async (apiCallParams) =>
      await triggerMeeshoCatalogDetails(apiCallParams),
    getDateWiseOrderSummary: async (apiCallParams) =>
      await triggerGetDateWiseOrderSummary(apiCallParams, false),
    getOrderHistoryForDate: async (apiCallParams) =>
      await triggerGetOrderHistoryForDate(apiCallParams, true),
    fetchCreatorAddress: async (apiCallParams) =>
      await triggerCreatorAddress(apiCallParams),
    updateCreatorAddress: async (apiCallParams) =>
      await triggerUpdateCreatorAddress(apiCallParams),
    fetchAddressById: async (apiCallParams) =>
      await triggerFetchAddressById(apiCallParams),
    createNewAddress: async (apiCallParams) =>
      await triggerCreateNewAddress(apiCallParams),
    getProductFinds: async (apiCallParams) =>
      await triggerGetProductFinds(apiCallParams),
    updateProductFindsFlag: async (apiCallParams) =>
      await triggerUpdateProductFindsFlag(apiCallParams),
    getPostOrCollectionLifetimeLinkClicks: async (apiCallParams) =>
      await triggerGetPostOrCollectionLifetimeLinkClicks(apiCallParams),
    deleteWishlinkAccount: async (apiCallParams) =>
      await triggerDeleteWishlinkAccountQuery(apiCallParams),
    deactivateWishlinkAccount: async (apiCallParams) =>
      await triggerDeactivateWishlinkAccountQuery(apiCallParams),
    getNotifications: async (apiCallParams) =>
      await triggerGetNotifications(apiCallParams, false),
    getEarlierNotifications: async (apiCallParams) =>
      await triggerGetEarlierNotifications(apiCallParams, false),
    getBellIconStatus: async (apiCallParams) =>
      await triggerGetBellIconStatus(apiCallParams, false),
    markNotificationAsRead: async (apiCallParams) =>
      await triggerMarkNotificationAsRead(apiCallParams),
    getCreatorReferralEarnings: async (apiCallParams) =>
      await triggerCreatorReferralEarnings(apiCallParams),
    getRefererDetails: async (apiCallParams) =>
      await triggerRefererDetails(apiCallParams),
    getCreatorReferralLedger: async (apiCallParams) =>
      await triggerCreatorReferralLedger(apiCallParams),
    getCreatorReferralData: async (apiCallParams) =>
      await triggerCreatorReferralData(apiCallParams),
    getCreatorDemoDetails: async (apiCallParams) =>
      await triggerCreatorDemoData(apiCallParams),
    getCreatorWinterContentDetails: async (apiCallParams) =>
      await triggerWinterContentData(apiCallParams),
    updateRewardsFocus: async (apiCallParams) =>
      await triggerUpdateRewardsFocus(apiCallParams),
    updateCreatorDemoDetails: async (apiCallParams) =>
      await triggerUpdateDemoDetails(apiCallParams),
    updateCreatorWinterContentMeetDetails: async (apiCallParams) =>
      await triggerUpdateWinterContentMeetDetails(apiCallParams),
    updateCreatorBPCContentMeetDetails: async (apiCallParams) =>
      await triggerUpdateBPCContentMeetDetails(apiCallParams),
    getScrapingFailureDomainsData: async (apiCallParams) =>
      await triggerScrapingFailureDomains(apiCallParams),
    getMarqueeDetails: async (apiCallParams) =>
      await triggerMarqueeDetails(apiCallParams),
    getExploreBannerDetails: async (apiCallParams) =>
      await triggerExploreBannerDetails(apiCallParams),
    fetchCreatorType: async (apiCallParams) =>
      await triggerFetchCreatorTypeQuery(apiCallParams),
  };

  const memoizedApiTriggers = useMemo(() => apiTriggers, []);
  function showToast({ variant, message }) {
    dispatch(showToastNotification({ variant, message }));
    setTimeout(() => {
      dispatch(hideToastNotification());
    }, 3000);
  }

  useEffect(() => {
    if (resultFirebaseLogin?.data?.success === false) {
      if (resultFirebaseLogin?.data?.error?.code === 423) {
        navigate(PAGE_URLS.USER_BLOCKED);
      } else {
        showToast({
          variant: "error",
          message: resultFirebaseLogin?.data?.msg,
        });
      }
    }
  }, [resultFirebaseLogin]);

  useEffect(() => {
    if (resultDeleteWishlinkAccountQuery?.data?.success === false) {
      showToast({
        variant: "error",
        message: resultDeleteWishlinkAccountQuery?.data.msg,
      });
    }
  }, [resultDeleteWishlinkAccountQuery]);

  useEffect(() => {
    if (resultUpdateDemoDetails?.data?.success === false) {
      showToast({
        variant: "error",
        message: resultUpdateDemoDetails?.data.msg,
      });
    } else if (resultUpdateDemoDetails?.data?.success === true) {
      showToast({
        variant: "success",
        message: "Demo call booked!",
      });
    }
  }, [resultUpdateDemoDetails]);

  useEffect(() => {
    if (resultUpdateWinterContentMeetDetails?.data?.success === false) {
      showToast({
        variant: "error",
        message: resultUpdateWinterContentMeetDetails?.data.msg,
      });
    } else if (resultUpdateWinterContentMeetDetails?.data?.success === true) {
      showToast({
        variant: "success",
        message: "Call scheduled!",
      });
    }
  }, [resultUpdateWinterContentMeetDetails]);

  useEffect(() => {
    if (resultUpdateBPCContentMeetDetails?.data?.success === false) {
      showToast({
        variant: "error",
        message: resultUpdateBPCContentMeetDetails?.data.msg,
      });
    } else if (resultUpdateBPCContentMeetDetails?.data?.success === true) {
      showToast({
        variant: "success",
        message: "Call scheduled!",
      });
    }
  }, [resultUpdateBPCContentMeetDetails]);

  useEffect(() => {
    if (resultDeactivateWishlinkAccountQuery?.data?.success === false) {
      showToast({
        variant: "error",
        message: resultDeactivateWishlinkAccountQuery?.data.msg,
      });
    }
  }, [resultDeactivateWishlinkAccountQuery]);

  useEffect(() => {
    if (resultCreatorData?.data?.success === false) {
      showToast({
        variant: "error",
        message: resultCreatorData.data.msg,
      });
      return;
    }
    if (resultCreatorData?.data?.data?.is_creator_blocked) {
      localStorage.clear();
      navigate(PAGE_URLS.USER_BLOCKED);
      return;
    }
    if (Boolean(resultCreatorData?.data?.data?.id)) {
      setBlitzllamaUserId(String(resultCreatorData?.data?.data?.id));
      setUserIdInSingular(String(resultCreatorData?.data?.data?.id));
    }
    dispatch(
      updateCreatorDataIsLoading(getDataPointLoadingValue(resultCreatorData))
    );
    dispatch(updateCreatorData(resultCreatorData));
    if (resultCreatorData?.data?.data && !localStorage.adminToken) {
      posthog?.identify(resultCreatorData?.data?.data?.username);
      posthog.people.set_once({
        username: resultCreatorData?.data?.data?.username,
      });
      if (localStorage.firebaseToken && !localStorage.adminToken)
        posthog.capture("$pageview");
    }
  }, [resultCreatorData]);

  useEffect(() => {
    if (resultMeeshoCatalogDetails?.data?.success === false) {
      showToast({
        variant: "error",
        message: resultMeeshoCatalogDetails.data.msg,
      });
      return;
    }
    dispatch(updateMeeshoCatalogDetails(resultMeeshoCatalogDetails));
  }, [resultMeeshoCatalogDetails]);

  useEffect(() => {
    if (resultOverlays?.data?.success === false) {
      showToast({
        variant: "error",
        message: resultOverlays?.data?.msg,
      });
      return;
    }
    dispatch(updateOverlaysIsLoading(getDataPointLoadingValue(resultOverlays)));
    dispatch(updateOverlays(resultOverlays));
  }, [resultOverlays]);

  useEffect(() => {
    if (resultPostsData?.data?.success === false) {
      showToast({
        variant: "error",
        message: resultPostsData?.data?.msg,
      });
      updatePostsDataIsLoading(false);
      return;
    }
    dispatch(
      updatePostsDataIsLoading(getDataPointLoadingValue(resultPostsData))
    );
    dispatch(updatePostsData(resultPostsData));
  }, [resultPostsData]);

  useEffect(() => {
    if (resultCollectionsData?.data?.success === false) {
      showToast({
        variant: "error",
        message: resultCollectionsData?.data?.msg,
      });
      updateCollectionsDataIsLoading(false);
      return;
    }
    dispatch(
      updateCollectionsDataIsLoading(
        resultCollectionsData?.status === "pending"
      )
    );
    dispatch(updateCollectionsData(resultCollectionsData));
  }, [resultCollectionsData]);

  useEffect(() => {
    if (resultProductsData?.data?.success === false) {
      showToast({
        variant: "error",
        message: resultProductsData?.data?.msg,
      });
      updateProductDataIsLoading(false);
      return;
    }
    dispatch(
      updateProductDataIsLoading(resultProductsData?.status === "pending")
    );
    dispatch(updateProductsData(resultProductsData));
  }, [resultProductsData]);

  useEffect(() => {
    if (resultDraftsData?.data?.success === false) {
      showToast({
        variant: "error",
        message: resultDraftsData?.data?.msg,
      });
      return;
    }
    updateDraftsDataIsLoading(false);
    dispatch(
      updateDraftsDataIsLoading(getDataPointLoadingValue(resultDraftsData))
    );
    dispatch(updateDraftsData(resultDraftsData));
  }, [resultDraftsData]);

  useEffect(() => {
    if (resultShopCountsData?.data?.success === false) {
      showToast({
        variant: "error",
        message: resultShopCountsData?.data?.msg,
      });
      return;
    }
    dispatch(updateShopCountsData(resultShopCountsData));
  }, [resultShopCountsData]);

  useEffect(() => {
    if (resultPostProductData?.data?.success === false) {
      showToast({
        variant: "error",
        message: resultPostProductData?.data?.msg,
      });
      return;
    }
    dispatch(
      updatePostProductsDataIsLoading(
        getDataPointLoadingValue(resultPostProductData)
      )
    );
    dispatch(updatePostProductsData(resultPostProductData?.data?.data));
  }, [resultPostProductData]);

  useEffect(() => {
    if (resultTagsData?.data?.success === false) {
      showToast({
        variant: "error",
        message: resultTagsData?.data?.msg,
      });
    }
    dispatch(updateCreatorTagsData(resultTagsData));
    return;
  }, [resultTagsData]);

  useEffect(() => {
    if (resultCreatorPostData?.data?.success === false) {
      showToast({
        variant: "error",
        message: resultCreatorPostData?.data?.msg,
      });
    }
    dispatch(fetchCreatorPost(resultCreatorPostData));
    return;
  }, [resultCreatorPostData]);

  useEffect(() => {
    if (resultProductLinksData?.data?.success === false) {
      showToast({
        variant: "error",
        message: resultProductLinksData?.data?.msg,
      });
      return;
    }
    dispatch(updateProductLinksData(resultProductLinksData));
  }, [resultProductLinksData]);

  useEffect(() => {
    if (resultPostOrCollectionProductsData?.data?.success === false) {
      showToast({
        variant: "error",
        message: resultPostOrCollectionProductsData?.data?.msg,
      });
      return;
    }
    dispatch(
      updatePostOrCollectionProductsData(resultPostOrCollectionProductsData)
    );
  }, [resultPostOrCollectionProductsData]);

  useEffect(() => {
    dispatch(updateAuthCheckStatus(resultCheckAuthStatusData));
  }, [resultCheckAuthStatusData]);

  useEffect(() => {
    if (resultInstaPostsListQuery?.data?.success === false) {
      showToast({
        variant: "error",
        message: resultInstaPostsListQuery?.data?.msg,
      });
      return;
    }
    dispatch(updateInstaPostsList(resultInstaPostsListQuery));
  }, [resultInstaPostsListQuery]);

  useEffect(() => {
    if (resultYoutubePostsListQuery?.data?.success === false) {
      showToast({
        variant: "error",
        message: resultYoutubePostsListQuery?.data?.msg,
      });
      return;
    }
    dispatch(updateYoutubePostsList(resultYoutubePostsListQuery));
  }, [resultYoutubePostsListQuery]);

  useEffect(() => {
    if (resultCreatorSocialLoginStatus?.data?.success === false) {
      showToast({
        variant: "error",
        message: resultCreatorSocialLoginStatus?.data?.msg,
      });
      return;
    }
    dispatch(updateCreatorSocialLoginStatus(resultCreatorSocialLoginStatus));
  }, [resultCreatorSocialLoginStatus]);

  useEffect(() => {
    if (resultGetCrossPostingConfig?.data?.success === false) {
      showToast({
        variant: "error",
        message: resultGetCrossPostingConfig?.data?.msg,
      });
      return;
    }
    dispatch(updateCrossPostingConfig(resultGetCrossPostingConfig));
  }, [resultGetCrossPostingConfig]);

  useEffect(() => {
    if (resultFilteredBrands?.data?.success === false) {
      showToast({
        variant: "error",
        message: resultFilteredBrands?.data?.msg,
      });
      return;
    }
    dispatch(
      updateBrandListIsLoading(resultFilteredBrands?.status === "pending")
    );
    dispatch(updateFilteredBrandsData(resultFilteredBrands));
  }, [resultFilteredBrands]);

  useEffect(() => {
    if (resultBrandTags?.data?.success === false) {
      showToast({
        variant: "error",
        message: resultBrandTags?.data?.msg,
      });
      return;
    }
    dispatch(updateBrandTagsData(resultBrandTags));
  }, [resultBrandTags]);

  useEffect(() => {
    if (resultFiltersAndSort?.data?.success === false) {
      showToast({
        variant: "error",
        message: resultFiltersAndSort?.data?.msg,
      });
      return;
    }
    dispatch(updateFiltersAndSort(resultFiltersAndSort));
  }, [resultFiltersAndSort]);

  useEffect(() => {
    if (resultBrandBanners?.data?.success === false) {
      showToast({
        variant: "error",
        message: resultBrandBanners?.data?.msg,
      });
      return;
    }
    dispatch(updateBrandBanners(resultBrandBanners));
  }, [resultBrandBanners]);

  useEffect(() => {
    if (resultUpdatePostOrCollectionStatus?.data?.success === false) {
      showToast({
        variant: "error",
        message: resultUpdatePostOrCollectionStatus?.data?.msg,
      });
      return;
    }
    dispatch(
      updatePostOrCollectionStatusIsLoading(
        getDataPointLoadingValue(
          resultUpdatePostOrCollectionStatus.status === "pending"
        )
      )
    );
  }, [resultUpdatePostOrCollectionStatus]);

  useEffect(() => {
    if (resultNewPostData?.data?.success === false) {
      showToast({
        variant: "error",
        message: resultNewPostData?.data?.msg,
      });
      return;
    }
    dispatch(
      updateNewPostDataIsLoading(resultNewPostData.status === "pending")
    );
  }, [resultNewPostData]);

  useEffect(() => {
    if (resultNewCollectionData?.data?.success === false) {
      showToast({
        variant: "error",
        message: resultNewCollectionData?.data?.msg,
      });
      return;
    }
  }, [resultNewCollectionData]);

  useEffect(() => {
    if (resultPostRedirects?.data?.success === false) {
      showToast({
        variant: "error",
        message: resultPostRedirects?.data?.msg,
      });
      return;
    }
    dispatch(updatePostRedirects(resultPostRedirects));
  }, [resultPostRedirects]);

  useEffect(() => {
    if (resultCollectionRedirects?.data?.success === false) {
      showToast({
        variant: "error",
        message: resultCollectionRedirects?.data?.msg,
      });
      return;
    }
    dispatch(updateCollectionRedirects(resultCollectionRedirects));
  }, [resultCollectionRedirects]);

  useEffect(() => {
    if (resultProductRedirects?.data?.success === false) {
      showToast({
        variant: "error",
        message: resultProductRedirects?.data?.msg,
      });
      return;
    }
    dispatch(updateProductRedirects(resultProductRedirects));
  }, [resultProductRedirects]);

  useEffect(() => {
    if (resultCreatorBankDetails?.data?.success === false) {
      // showToast({
      //   variant: "error",
      //   message: resultCreatorBankDetails?.data?.msg,
      // });
    }
    dispatch(updateBankDetails(resultCreatorBankDetails));
  }, [resultCreatorBankDetails]);

  useEffect(() => {
    if (resultCTKDetails?.data?.success === false) {
      showToast({
        variant: "error",
        message: resultCTKDetails?.data?.msg,
      });
      return;
    }
    dispatch(
      updateCtkDetailsLoading(getDataPointLoadingValue(resultCTKDetails))
    );
    dispatch(updateCtkDetails(resultCTKDetails));
  }, [resultCTKDetails]);

  useEffect(() => {
    if (resultOauthClientName?.data?.success === false) {
      showToast({
        variant: "error",
        message: resultOauthClientName?.data?.msg,
      });
      return;
    }
    dispatch(
      updateOauthClientNameIsLoading(
        getDataPointLoadingValue(resultOauthClientName)
      )
    );
    dispatch(updateOauthClientName(resultOauthClientName));
  }, [resultOauthClientName]);

  useEffect(() => {
    if (resultGetCreatorTemplates?.data?.success === false) {
      showToast({
        variant: "error",
        message: resultGetCreatorTemplates?.data?.msg,
      });
      return;
    }
    dispatch(updateTemplates(resultGetCreatorTemplates));
  }, [resultGetCreatorTemplates]);

  useEffect(() => {
    if (resultAmazonData?.data?.success === false) {
      showToast({
        variant: "error",
        message: resultAmazonData?.data?.msg,
      });
      return;
    }
    dispatch(updateAmazonAnalytics(resultAmazonData));
  }, [resultAmazonData]);

  useEffect(() => {
    if (resultBrandWiseEarningsSplitData?.data?.success === false) {
      showToast({
        variant: "error",
        message: resultBrandWiseEarningsSplitData?.data?.msg,
      });
      return;
    }
    dispatch(updateBrandWiseEarningsSplit(resultBrandWiseEarningsSplitData));
  }, [resultBrandWiseEarningsSplitData]);

  useEffect(() => {
    if (resultCreatorAnalyticsChartData?.data?.success === false) {
      showToast({
        variant: "error",
        message: resultCreatorAnalyticsChartData?.data?.msg,
      });
      return;
    }
    dispatch(updateAnalyticsChart(resultCreatorAnalyticsChartData));
  }, [resultCreatorAnalyticsChartData]);

  useEffect(() => {
    if (resultChannelWiseEarningsSplitData?.data?.success === false) {
      showToast({
        variant: "error",
        message: resultChannelWiseEarningsSplitData?.data?.msg,
      });
      return;
    }
    dispatch(
      updateChannelWiseEarningsSplit(resultChannelWiseEarningsSplitData)
    );
  }, [resultChannelWiseEarningsSplitData]);

  useEffect(() => {
    if (resultCreatorAnalyticsCollectionsDataQuery?.data?.success === false) {
      showToast({
        variant: "error",
        message: resultCreatorAnalyticsCollectionsDataQuery?.data?.msg,
      });
      return;
    }
    dispatch(
      updateCollectionAnalytics(resultCreatorAnalyticsCollectionsDataQuery)
    );
  }, [resultCreatorAnalyticsCollectionsDataQuery]);

  useEffect(() => {
    if (resultCreatorAnalyticsPostsData?.data?.success === false) {
      showToast({
        variant: "error",
        message: resultCreatorAnalyticsPostsData?.data?.msg,
      });
      return;
    }
    dispatch(updatePostAnalytics(resultCreatorAnalyticsPostsData));
  }, [resultCreatorAnalyticsPostsData]);

  useEffect(() => {
    if (resultCreatorAnalyticsProductsData?.data?.success === false) {
      showToast({
        variant: "error",
        message: resultCreatorAnalyticsProductsData?.data?.msg,
      });
      return;
    }
    dispatch(updateProductAnalytics(resultCreatorAnalyticsProductsData));
  }, [resultCreatorAnalyticsProductsData]);

  useEffect(() => {
    if (resultCreatorSalesData?.data?.success === false) {
      showToast({
        variant: "error",
        message: resultCreatorSalesData?.data?.msg,
      });
      return;
    }
    dispatch(updateSalesAnalytics(resultCreatorSalesData));
  }, [resultCreatorSalesData]);

  useEffect(() => {
    if (resultPostSingleProductLink?.data?.success === false) {
      showToast({
        variant: "error",
        message: resultPostSingleProductLink?.data?.msg,
      });
      return;
    }
  }, [resultPostSingleProductLink]);

  useEffect(() => {
    if (resultCreatorRedirectionsData?.data?.success === false) {
      showToast({
        variant: "error",
        message: resultCreatorRedirectionsData?.data?.msg,
      });
      return;
    }
    dispatch(updateRedirectionsAnalytics(resultCreatorRedirectionsData));
  }, [resultCreatorRedirectionsData]);

  useEffect(() => {
    if (resultCreatorLifetimeEarnings?.data?.success === false) {
      showToast({
        variant: "error",
        message: resultCreatorLifetimeEarnings?.data?.msg,
      });
      return;
    }
    dispatch(updateCreatorLifetimeEarnings(resultCreatorLifetimeEarnings));
  }, [resultCreatorLifetimeEarnings]);

  useEffect(() => {
    if (resultCreatorEarningsThisPayout?.data?.success === false) {
      showToast({
        variant: "error",
        message: resultCreatorEarningsThisPayout?.data?.msg,
      });
      return;
    }
    dispatch(updateCreatorEarningsThisPayout(resultCreatorEarningsThisPayout));
  }, [resultCreatorEarningsThisPayout]);

  useEffect(() => {
    if (resultCreatorPastPayouts?.data?.success === false) {
      showToast({
        variant: "error",
        message: resultCreatorPastPayouts?.data?.msg,
      });
      return;
    }
    dispatch(updatePastPayouts(resultCreatorPastPayouts));
  }, [resultCreatorPastPayouts]);

  useEffect(() => {
    if (resultCreatorDashboardMilestonesStatus?.data?.success === false) {
      showToast({
        variant: "error",
        message: resultCreatorDashboardMilestonesStatus?.data?.msg,
      });
      return;
    }
    dispatch(
      updateMilestonesLoading(
        getDataPointLoadingValue(resultCreatorDashboardMilestonesStatus)
      )
    );
    dispatch(updateMilestones(resultCreatorDashboardMilestonesStatus));
  }, [resultCreatorDashboardMilestonesStatus]);

  useEffect(() => {
    if (resultMediaReels?.data?.success === false) {
      showToast({
        variant: "error",
        message: resultMediaReels?.data?.msg,
      });
      return;
    }
    dispatch(updateMediaReels(resultMediaReels));
  }, [resultMediaReels]);

  useEffect(() => {
    if (resultAllCreatorUsernames?.data?.success === false) {
      showToast({
        variant: "error",
        message: resultAllCreatorUsernames?.data?.msg,
      });
      return;
    }
    dispatch(updateAllCreatorUsernames(resultAllCreatorUsernames));
  }, [resultAllCreatorUsernames]);

  useEffect(() => {
    dispatch(updateAnalyticsPostsReleaseDates(resultCalendarPostsReleaseDates));
  }, [resultCalendarPostsReleaseDates]);

  useEffect(() => {
    dispatch(updateRewardsAnalytics(resultCreatorRewardsAnalytics));
  }, [resultCreatorRewardsAnalytics]);

  useEffect(() => {
    dispatch(
      updatePostOrCollectionLifetimeFunnel(resultPostOrCollectionLifetimeFunnel)
    );
  }, [resultPostOrCollectionLifetimeFunnel]);

  useEffect(() => {
    dispatch(updatePostOrCollectionEarnings(resultPostOrCollectionEarnings));
  }, [resultPostOrCollectionEarnings]);

  useEffect(() => {
    dispatch(
      updatePostOrCollectionProductEarnings(
        resultPostOrCollectionProductEarnings
      )
    );
  }, [resultPostOrCollectionProductEarnings]);

  useEffect(() => {
    dispatch(
      updateSortAndFiltersForAnalytics(resultSortAndFiltersForAnalytics)
    );
  }, [resultSortAndFiltersForAnalytics]);

  useEffect(() => {
    dispatch(updateAffiliateProductAnalytics(resultAffiliateProductAnalytics));
  }, [resultAffiliateProductAnalytics]);

  useEffect(() => {
    dispatch(updateCampaignDetails(resultCampaignDetails));
  }, [resultCampaignDetails]);

  useEffect(() => {
    dispatch(updateBrandRewards(resultGetBrandRewards));
  }, [resultGetBrandRewards]);

  useEffect(() => {
    dispatch(updateNykaaBrandRewards(resultGetNykaaBrandRewards));
  }, [resultGetNykaaBrandRewards]);

  useEffect(() => {
    dispatch(updateRewardsLeaderboard(resultRewardsLeaderboard));
  }, [resultRewardsLeaderboard]);

  useEffect(() => {
    dispatch(updateRewardsSummary(resultRewardsSummary));
  }, [resultRewardsSummary]);

  useEffect(() => {
    if (resuleUpdateCreatorTemplates?.data?.success === false) {
      showToast({
        variant: "error",
        message: resuleUpdateCreatorTemplates?.data?.msg,
      });
      return;
    }
  }, [resuleUpdateCreatorTemplates]);

  useEffect(() => {
    if (resultDisconnectFacebook?.data?.success === false) {
      showToast({
        variant: "error",
        message: resultDisconnectFacebook?.data?.msg,
      });
      return;
    }
  }, [resultDisconnectFacebook]);

  useEffect(() => {
    if (resultCreatorProfile?.data?.success === false) {
      showToast({
        variant: "error",
        message: resultCreatorProfile?.data?.msg,
      });
      return;
    }
  }, [resultCreatorProfile]);

  useEffect(() => {
    if (resultUpdateCreatorDashboardMilestonesStatus?.data?.success === false) {
      showToast({
        variant: "error",
        message: resultUpdateCreatorDashboardMilestonesStatus?.data?.msg,
      });
      return;
    }
  }, [resultUpdateCreatorDashboardMilestonesStatus]);

  useEffect(() => {
    if (resultSubmitCreatorBankDetails?.data?.success === false) {
      showToast({
        variant: "error",
        message: resultSubmitCreatorBankDetails?.data?.msg,
      });
      return;
    } else if (resultSubmitCreatorBankDetails?.data?.success === true) {
      navigate(`/profile#thanks`, { replace: true });
    }
  }, [resultSubmitCreatorBankDetails]);

  useEffect(() => {
    if (resultUpdateProductStatus?.data?.success === false) {
      showToast({
        variant: "error",
        message: resultUpdateProductStatus?.data?.msg,
      });
      return;
    }
  }, [resultUpdateProductStatus]);

  useEffect(() => {
    if (resultPostSocialManualLogin?.data?.success === false) {
      showToast({
        variant: "error",
        message: resultPostSocialManualLogin?.data?.msg,
      });
      return;
    }
  }, [resultPostSocialManualLogin]);

  useEffect(() => {
    dispatch(
      updateInstaBasicProfileLoading(
        getDataPointLoadingValue(resultGetInstaBasicProfileData)
      )
    );
    dispatch(updateInstaBasicProfileData(resultGetInstaBasicProfileData));
  }, [resultGetInstaBasicProfileData]);

  useEffect(() => {
    if (resultCollectCreatorFeedback?.data?.success === false) {
      showToast({
        variant: "error",
        message: resultCollectCreatorFeedback?.data?.msg,
      });
      return;
    }
    if (resultCollectCreatorFeedback?.data?.success === true) {
      showToast({
        variant: "success",
        message: "Feedback submitted successfully!",
      });
      return;
    }
  }, [resultCollectCreatorFeedback]);

  useEffect(() => {
    dispatch(
      updateOrderSummaryLoading(
        getDataPointLoadingValue(resultGetDateWiseOrderSummary)
      )
    );
    dispatch(updateOrderSummary(resultGetDateWiseOrderSummary));
  }, [resultGetDateWiseOrderSummary]);

  useEffect(() => {
    dispatch(
      updateOrderHistoryForDateLoading(
        getDataPointLoadingValue(resultGetOrderHistoryForDate)
      )
    );
    dispatch(updateOrderHistoryForDate(resultGetOrderHistoryForDate));
  }, [resultGetOrderHistoryForDate]);

  useEffect(() => {
    dispatch(updateCreatorAddress(resultCreatorAddress));
  }, [resultCreatorAddress]);

  useEffect(() => {
    if (resultUpdateCreatorAddress?.data?.success === false) {
      showToast({
        variant: "error",
        message: resultUpdateCreatorAddress?.data?.msg,
      });
      return;
    }
    if (resultUpdateCreatorAddress?.data?.success === true) {
      showToast({
        variant: "success",
        message: "Address Updated Successfully!",
      });
    }
  }, [resultUpdateCreatorAddress]);

  useEffect(() => {
    if (resultFetchAddressById?.data?.success === false) {
      showToast({
        variant: "error",
        message: resultFetchAddressById?.data?.msg,
      });
      return;
    }
    dispatch(
      updateCreatorAddressByIdLoading(
        getDataPointLoadingValue(resultFetchAddressById)
      )
    );
    dispatch(updateAddressById(resultFetchAddressById));
  }, [resultFetchAddressById]);

  useEffect(() => {
    if (resultCreateNewAddress?.data?.success === false) {
      showToast({
        variant: "error",
        message: resultCreateNewAddress?.data?.msg,
      });
      return;
    }
    if (resultCreateNewAddress?.data?.success === true) {
      showToast({
        variant: "success",
        message: "Address Created Successfully!",
      });
    }
  }, [resultCreateNewAddress]);

  useEffect(() => {
    dispatch(updateProductFinds(resultGetProductFinds));
  }, [resultGetProductFinds]);

  useEffect(() => {
    dispatch(
      updatePostOrCollectionLifetimeClicksLoading(
        getDataPointLoadingValue(resultGetPostOrCollectionLifetimeLinkClicks)
      )
    );
    dispatch(
      updatePostOrCollectionLifetimeLinkClicks(
        resultGetPostOrCollectionLifetimeLinkClicks
      )
    );
  }, [resultGetPostOrCollectionLifetimeLinkClicks]);

  useEffect(() => {
    dispatch(
      updateNotificatonsListLoading(
        getDataPointLoadingValue(resultGetNotifications)
      )
    );
    dispatch(updateNotificationsList(resultGetNotifications));
  }, [resultGetNotifications]);

  useEffect(() => {
    dispatch(updateEarlierNotificationsList(resultGetEarlierNotifications));
  }, [resultGetEarlierNotifications]);

  useEffect(() => {
    dispatch(
      updateBellIconLoading(getDataPointLoadingValue(resultGetBellIconStatus))
    );
    dispatch(updateBellIconStatus(resultGetBellIconStatus));
  }, [resultGetBellIconStatus]);

  useEffect(() => {
    dispatch(
      updateCreatorReferrals(
        getDataPointLoadingValue(resultCreatorReferralEarnings)
      )
    );
    dispatch(updateCreatorReferrals(resultCreatorReferralEarnings));
  }, [resultCreatorReferralEarnings]);

  useEffect(() => {
    dispatch(
      updateReferrerDetails(getDataPointLoadingValue(resultRefererDetails))
    );
    dispatch(updateReferrerDetails(resultRefererDetails));
  }, [resultRefererDetails]);

  useEffect(() => {
    dispatch(
      updateCreatorRefererLedgerLoading(
        getDataPointLoadingValue(resultCreatorReferralLedger)
      )
    );
    dispatch(updateCreatorRefererLedger(resultCreatorReferralLedger));
  }, [resultCreatorReferralLedger]);

  useEffect(() => {
    dispatch(
      updateCreatorReferalData(
        getDataPointLoadingValue(resultCreatorReferralData)
      )
    );
    dispatch(updateCreatorReferalData(resultCreatorReferralData));
  }, [resultCreatorReferralData]);

  useEffect(() => {
    dispatch(
      updateCreatorDemoDataLoading(
        getDataPointLoadingValue(resultCreatorDemoData)
      )
    );
    dispatch(updateCreatorDemoData(resultCreatorDemoData));
  }, [resultCreatorDemoData]);

  useEffect(() => {
    dispatch(
      updateCreatorWinterContentMeetDataLoading(
        getDataPointLoadingValue(resultWinterContentData)
      )
    );
    dispatch(updateCreatorWinterContentMeetData(resultWinterContentData));
  }, [resultWinterContentData]);

  useEffect(() => {
    dispatch(
      updateScrapingFailureDomainsIsLoading(
        getDataPointLoadingValue(resultScrapingFailureDomains)
      )
    );
    dispatch(updateScrapingFailureDomains(resultScrapingFailureDomains));
  }, [resultScrapingFailureDomains]);

  useEffect(() => {
    dispatch(
      updateMarqueeDetails(getDataPointLoadingValue(resultMarqueeDetails))
    );
    dispatch(updateMarqueeDetails(resultMarqueeDetails));
  }, [resultMarqueeDetails]);

  useEffect(() => {
    dispatch(updateExploreBanners(resultExploreBannerDetails));
  }, [resultExploreBannerDetails]);

  useEffect(() => {
    dispatch(updateIsMyntraCreator(resultFetchCreatorTypeQuery));
  }, [resultFetchCreatorTypeQuery]);

  return (
    <APIContext.Provider value={memoizedApiTriggers}>
      {props.children}
    </APIContext.Provider>
  );
});

export default DataLayer;
