import { useRef } from "react";

import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./index.css";

const WishlinkSlider = (props) => {
  const {
    slidesToShow = 1,
    showDots = true,
    slidesToScroll = 1,
    autoplay = false,
    autoplaySpeed = 5000,
    focusOnSelect = false,
    centerPadding,
    centerMode = false,
    beforeChange,
    afterChange,
    responsive = [],
    infinite = true,
    appendDots = false,
    variableWidth = false,
    arrows = false,
    onSwipe,
  } = props;

  const slideRef = useRef(null);

  var settings = {
    beforeChange,
    afterChange,
    centerMode,
    centerPadding,
    focusOnSelect,
    dots: showDots,
    infinite,
    speed: 500,
    slidesToShow: slidesToShow,
    slidesToScroll: slidesToScroll,
    arrows,
    responsive,
    autoplay,
    autoplaySpeed,
    variableWidth,
    onSwipe,

    customPaging: (i) => (
      <div
        style={{
          width: "4px",
          height: "4px",
          padding: "4px",
          color: `${appendDots ? "white" : "black"}`,
          opacity: "0.5",
        }}
      >
        &#x2022;
      </div>
    ),
  };
  return (
    <>
      <Slider {...settings} ref={slideRef}>
        {props.children}
      </Slider>
    </>
  );
};

export default WishlinkSlider;
