import { fetchBaseQuery, retry } from "@reduxjs/toolkit/dist/query/react";
import CONFIG from "./config";
import jwt_decode from "jwt-decode";
import { auth } from "../utils/firebase";
import { triggerGa } from "./gaUtils";
import { GA_ACTION, GA_CATEGORIES, GA_LABEL } from "./constants";

export const baseQuery = retry(
  fetchBaseQuery({
    baseUrl: CONFIG.API.url,
    prepareHeaders: async (headers, { getState }) => {
      const authToken =
        localStorage?.adminToken?.replaceAll('"', "") ||
        localStorage?.firebaseToken;
      authToken && headers.set("Authorization", `Token ${authToken}`);
      return headers;
    },
  }),
  { maxRetries: 5 }
);

export const getQueryStringWithCreator = (query) => {
  const creator = localStorage.username?.replaceAll('"', "");
  if (creator) {
    let encodedCreator = encodeURIComponent(creator);
    if (query.includes("?")) query += `&creator=${encodedCreator}`;
    else query += `?creator=${encodedCreator}`;
  }
  return query;
};

export const baseQueryWithArgs = async (args, api, extraOptions) => {
  const tokenExpiryTime = parseInt(localStorage?.firebaseTokenExpiry);
  let result = null;
  triggerGa({
    category: GA_CATEGORIES.WEB_VITALS,
    action: GA_ACTION.API_CALL_STARTED,
    label: `${GA_LABEL.API_CALL}_${api.endpoint}`,
    eventValue: Date.now(),
  });
  if (new Date().getTime() / 1000 < tokenExpiryTime - 100) {
    result = await baseQuery(args, api, extraOptions);
    if (
      result?.error &&
      (result?.error?.status === 403 || result?.error?.status === 401)
    ) {
      await firebaseUserStateChange();
      result = await baseQuery(args, api, extraOptions);
    }
  } else {
    try {
      await firebaseUserStateChange();
    } catch (e) {
      // console.log("Firebase refresh errored", e);
    }
    result = await baseQuery(args, api, extraOptions);
  }
  triggerGa({
    category: GA_CATEGORIES.WEB_VITALS,
    action: GA_ACTION.API_CALL_END,
    label: `${GA_LABEL.API_CALL}_${api.endpoint}`,
    eventValue: Date.now(),
    label2: result?.status,
  });
  return result;
};

const firebaseUserStateChange = async () => {
  // return new Promise((resolve, reject) => {
  const unsunscribeFn = auth.onAuthStateChanged(
    async (user) => {
      if (user) {
        const token = await user.getIdToken(true);
        if (token && localStorage?.firebaseToken) {
          localStorage.setItem("firebaseToken", token);
          let decodedToken = jwt_decode(token);
          const tokenExpiry = decodedToken?.exp;
          localStorage.setItem("firebaseTokenExpiry", tokenExpiry);
        }
      } else {
        if (!localStorage.adminToken) {
          localStorage.removeItem("firebaseToken");
          localStorage.removeItem("firebaseTokenExpiry");
          localStorage.removeItem("adminToken");
        }
      }
      // resolve(user);
      unsunscribeFn();
    },
    (error) => {
      // reject(error);
    }
  );
  // reject("firebase refresh could not exit");
  // });
};

export const postQueryBodyWithCreator = (body) => {
  const creator = localStorage.username?.replaceAll('"', "");
  if (creator) {
    if (!body.creator) body.creator = creator;
  }
  return body;
};
