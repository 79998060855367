import { createApi } from "@reduxjs/toolkit/query/react";
import {
  baseQueryWithArgs,
  getQueryStringWithCreator,
} from "../../../utils/reduxHelper";

export const sourcingCreditsReduxAPI = createApi({
  reducerPath: "sourcingCreditsReduxAPI",
  baseQuery: baseQueryWithArgs,

  tagTypes: [
    "creditLimits",
    "brandLimit",
    "allBrandLimits",
    "allBarterLimits",
    "collectionBarterLimit",
  ],

  endpoints: (builder) => ({
    //GET Queries
    getCreditLimits: builder.query({
      query: () => getQueryStringWithCreator(`s/getCreditLimits`),
      providesTags: ["creditLimits"],
      keepUnusedDataFor: 0,
    }),
    getBrandCreditLimit: builder.query({
      query: ({ brandId }) =>
        getQueryStringWithCreator(`s/getBrandCreditLimit?brandId=${brandId}`),
      providesTags: ["brandLimit"],
      keepUnusedDataFor: 0,
    }),
    getCreditLimitsForAllBrands: builder.query({
      query: () => getQueryStringWithCreator(`s/getCreditLimitsForAllBrands`),
      providesTags: ["allBrandLimits"],
      keepUnusedDataFor: 0,
    }),
    getBarterCreditLimitsForAllCollections: builder.query({
      query: () =>
        getQueryStringWithCreator(`s/getBarterCreditLimitsForAllCollections`),
      providesTags: ["allBarterLimits"],
      keepUnusedDataFor: 0,
    }),
    getBarterCreditsForCollection: builder.query({
      query: ({ collectionId }) =>
        getQueryStringWithCreator(
          `s/getBarterCreditsForCollection?collectionId=${collectionId}`
        ),
      providesTags: ["collectionBarterLimit"],
      keepUnusedDataFor: 0,
    }),
  }),
});

export const {
  useLazyGetCreditLimitsQuery,
  useGetCreditLimitsQuery,
  useLazyGetBrandCreditLimitQuery,
  useLazyGetCreditLimitsForAllBrandsQuery,
  useLazyGetBarterCreditLimitsForAllCollectionsQuery,
  useLazyGetBarterCreditsForCollectionQuery,
} = sourcingCreditsReduxAPI;
