// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=Poppins&display=swap);"]);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `body {
  margin: 0;
  font-family: "Poppins", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

::-webkit-scrollbar {
  display: none;
}

::-webkit-scrollbar-track {
  background-color: #e0e0e0;
}

::-webkit-scrollbar-thumb {
  background-color: #888;
}

::-webkit-scrollbar-track:hover {
  background-color: #555;
}
a:link {
  color: #2355f5;
  background-color: transparent;
  text-decoration: none;
}

a:visited {
  color: #2355f5;
  background-color: transparent;
  text-decoration: none;
}

a:hover {
  color: #2355f5;
  background-color: transparent;
  text-decoration: underline;
}

a:active {
  color: #2355f5;
  background-color: transparent;
  text-decoration: underline;
}
`, "",{"version":3,"sources":["webpack://./src/index.css"],"names":[],"mappings":"AAEA;EACE,SAAS;EACT,kCAAkC;EAClC,mCAAmC;EACnC,kCAAkC;AACpC;;AAEA;EACE;aACW;AACb;;AAEA;EACE,aAAa;AACf;;AAEA;EACE,yBAAyB;AAC3B;;AAEA;EACE,sBAAsB;AACxB;;AAEA;EACE,sBAAsB;AACxB;AACA;EACE,cAAc;EACd,6BAA6B;EAC7B,qBAAqB;AACvB;;AAEA;EACE,cAAc;EACd,6BAA6B;EAC7B,qBAAqB;AACvB;;AAEA;EACE,cAAc;EACd,6BAA6B;EAC7B,0BAA0B;AAC5B;;AAEA;EACE,cAAc;EACd,6BAA6B;EAC7B,0BAA0B;AAC5B","sourcesContent":["@import url(\"https://fonts.googleapis.com/css2?family=Poppins&display=swap\");\n\nbody {\n  margin: 0;\n  font-family: \"Poppins\", sans-serif;\n  -webkit-font-smoothing: antialiased;\n  -moz-osx-font-smoothing: grayscale;\n}\n\ncode {\n  font-family: source-code-pro, Menlo, Monaco, Consolas, \"Courier New\",\n    monospace;\n}\n\n::-webkit-scrollbar {\n  display: none;\n}\n\n::-webkit-scrollbar-track {\n  background-color: #e0e0e0;\n}\n\n::-webkit-scrollbar-thumb {\n  background-color: #888;\n}\n\n::-webkit-scrollbar-track:hover {\n  background-color: #555;\n}\na:link {\n  color: #2355f5;\n  background-color: transparent;\n  text-decoration: none;\n}\n\na:visited {\n  color: #2355f5;\n  background-color: transparent;\n  text-decoration: none;\n}\n\na:hover {\n  color: #2355f5;\n  background-color: transparent;\n  text-decoration: underline;\n}\n\na:active {\n  color: #2355f5;\n  background-color: transparent;\n  text-decoration: underline;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
