import { useTheme } from "@mui/material";
import { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { APIContext } from "../../apiLayer";

import { tokens } from "../../theme";
import { getOpacityForDisabledValue } from "../../utils/common";
import {
  GA_ACTION,
  GA_CATEGORIES,
  GA_LABEL,
  GA_PAGE,
  GA_SCREEN_NAME,
  IMAGE_PREFIX,
  PAGE_URLS,
} from "../../utils/constants";
import { triggerGa } from "../../utils/gaUtils";
import WishlinkButton from "../../WishlinkComponents/WishlinkButton";
import WishlinkTextField from "../../WishlinkComponents/WishlinkTextField";
import WishlinkTypography from "../../WishlinkComponents/WishlinkTypography";

const ReferralInputComponent = (props) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const navigate = useNavigate();
  const apis = useContext(APIContext);
  const [referralCode, setReferralCode] = useState("");
  const [referralSuccess, setReferralSuccess] = useState("");
  const [height, setHeight] = useState(window.innerHeight);

  useEffect(() => {
    if (referralCode === "") {
      setReferralSuccess(null);
    }
  }, [referralCode]);

  useEffect(() => {
    const handleResize = (e) => {
      setHeight(e.target.height);
    };
    window.visualViewport.addEventListener("resize", handleResize);
    return () =>
      window.visualViewport.removeEventListener("resize", handleResize);
  }, []);

  const onClickSubmitCode = async () => {
    triggerGa({
      category: GA_CATEGORIES.LOGIN,
      action: GA_ACTION.BUTTON_CLICK,
      label: GA_LABEL.SUBMIT_REFERRAL,
      page: GA_PAGE.LOGIN,
      screenName: GA_SCREEN_NAME.REFERRAL,
    });
    triggerGa({
      category: GA_CATEGORIES.NAVIGATION,
      action: GA_ACTION.BUTTON_CLICK,
      label: GA_LABEL.SIGNUP,
      page: GA_PAGE.LOGIN,
      screenName: GA_SCREEN_NAME.REFERRAL,
    });
    let result = await apis.getRefererDetails({ referee: referralCode });
    if (result?.data?.success) {
      setReferralSuccess(true);
      localStorage.setItem("referee", referralCode);
      navigate(PAGE_URLS.SOCIALMEDIALOGIN);
    } else {
      setReferralSuccess(false);
    }
  };

  const onClickNoRefCode = () => {
    triggerGa({
      category: GA_CATEGORIES.LOGIN,
      action: GA_ACTION.BUTTON_CLICK,
      label: GA_LABEL.SKIP_REFERRAL,
      page: GA_PAGE.LOGIN,
      screenName: GA_SCREEN_NAME.REFERRAL,
    });
    triggerGa({
      category: GA_CATEGORIES.NAVIGATION,
      action: GA_ACTION.BUTTON_CLICK,
      label: GA_LABEL.HOME,
      page: GA_PAGE.LOGIN,
      screenName: GA_SCREEN_NAME.REFERRAL,
    });
    navigate(PAGE_URLS.HOME);
  };

  return (
    <div
      className="text-left relative page-click-to-left-transition"
      style={{
        background: colors.grey[0],
      }}
    >
      <div
        className="flex flex-column justify-between"
        style={{ height: height + "px" }}
      >
        <div>
          <img
            src={`${IMAGE_PREFIX}wishlink-orange.svg`}
            className="ml-4 mt-4"
            style={{ width: "7.375rem" }}
            alt=""
          />
          <div className="p-4">
            <WishlinkTypography
              fontSize="1.375rem"
              fontWeight={600}
              color={colors.grey[700]}
            >
              Got an invite code
            </WishlinkTypography>
            <WishlinkTypography
              fontSize="0.875rem"
              fontWeight={400}
              color={colors.grey[500]}
              className="pt-3"
            >
              Who should we thank for bringing you here?
            </WishlinkTypography>
            <div className="mt-7 border-radius">
              <WishlinkTextField
                value={referralCode}
                fullWidth={true}
                placeholder="Enter referral code here"
                onInputValueChange={(val) => setReferralCode(val)}
                showIcon={false}
                helperText=""
                error={referralSuccess === false}
                autoFocus={true}
              />
            </div>
            {referralSuccess === false && (
              <WishlinkTypography color={colors.red[500]} fontSize="0.75rem">
                Please enter a valid referral code
              </WishlinkTypography>
            )}
          </div>
        </div>
        <div className="w-100">
          <WishlinkButton
            background={colors.red[500]}
            disabled={!referralCode?.length}
            opacity={getOpacityForDisabledValue(!referralCode?.length)}
            style={{
              ":hover": {
                bgcolor: colors.red[500],
              },
            }}
            borderRadius="2.5rem"
            width="100%"
            height="3rem"
            className="pt-3 mt-3 mb-3 pl-4 pr-4"
            onClick={() => onClickSubmitCode()}
          >
            <WishlinkTypography
              fontSize="1rem"
              fontWeight={600}
              color={colors.grey[0]}
              className="mr-2"
            >
              Ok
            </WishlinkTypography>
            <img src={`${IMAGE_PREFIX}arrow-right.svg`} alt="lock" />
          </WishlinkButton>
          <WishlinkTypography
            color={colors.red[500]}
            fontSize="0.875rem"
            fontWeight={600}
            className="text-center pt-3 pb-3"
            onClick={() => onClickNoRefCode()}
          >
            Maybe Later!
          </WishlinkTypography>
        </div>
      </div>
    </div>
  );
};

export default ReferralInputComponent;
