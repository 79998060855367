const configDev = {
  API: {
    url: "http://localhost:8000/api/",
    // url: "https://api-c.wishllink.com/api/",
    // url: "http://localhost:8000/api/",
    baseFrontend: "http://localhost:3000/",
    editFrontend: "http://localhost:3001/",
    postHogKey: "test",
    postHogHost: "test",
    wss: "ws://localhost:8000",
  },
};

const configProd = {
  API: {
    url: "https://api.wishlink.com/api/",
    baseFrontend: "https://insights.wishlink.com/",
    editFrontend: "https://wishlink.com/",
    postHogKey: "phc_XrBEJtuhTDL9RqKBKU7VrtSuDXcqnCL1wdBfeeRmhZj",
    postHogHost: "https://app.posthog.com",
    wss: "wss://api-sockets.wishlink.com/ws/",
  },
};

const configStage = {
  API: {
    url: "https://api-c.wishllink.com/api/",
    postHogKey: "test",
    postHogHost: "test",
    wss: "ws://localhost:8000",
  },
};
let CONFIG = configProd;
if (process.env.NODE_ENV === "development") {
  CONFIG = configDev;
} else if (process.env.NODE_ENV === "staging") {
  CONFIG = configStage;
} else if (process.env.NODE_ENV === "production") {
  CONFIG = configProd;
} else {
  CONFIG = configStage;
}

export default CONFIG;

let appBuild = null;
export const getAppBuildVersion = () => {
  if (appBuild !== null) return appBuild;
  if (window.cordova) {
    appBuild = 0;
  }
  if (window.ReactNativeWebView) {
    let injectedObject = {};
    try {
      injectedObject = JSON.parse(
        window?.ReactNativeWebView?.injectedObjectJson()
      );
    } catch (e) {
      // eslint-disable-next-line no-console
      console.log("Error in parsing injectedObjectJson");
    }
    appBuild = Number(injectedObject.appBuild) || null;
  }
  /// This is a fallback value for web
  return appBuild;
};

let platform = null;
export const getPlatform = () => {
  if (platform !== null) return platform;
  if (window.cordova) {
    platform = window.cordova.platformId;
    return platform;
  }
  platform = "web";
  if (window.ReactNativeWebView) {
    let injectedObject = {};
    try {
      injectedObject = JSON.parse(
        window?.ReactNativeWebView?.injectedObjectJson()
      );
    } catch (e) {
      // eslint-disable-next-line no-console
      console.log("Error in parsing injectedObjectJson");
    }
    platform = injectedObject.platform;
  }
  return platform;
};

let appSessionUUID = null;
export const getAppSessionUuid = () => {
  if (appSessionUUID !== null) return appSessionUUID;
  if (window.ReactNativeWebView) {
    let injectedObject = {};
    try {
      injectedObject = JSON.parse(
        window?.ReactNativeWebView?.injectedObjectJson()
      );
    } catch (e) {
      // eslint-disable-next-line no-console
      console.log("Error in parsing injectedObjectJson");
    }
    appSessionUUID = injectedObject.appSessionUUID;
  }
  return appSessionUUID;
};
