import { createSlice } from "@reduxjs/toolkit";

// All the data related to the Home page as well as miscellaneous data that can be used anywhere will be stored here
const initialState = {
  isResetPageOnOpen: true,
  homeLoading: false,
  homeMoreLoading: false,
  homeTriggers: null,
  categories: [],
  selectedCategory: "",
  selectedBrandId: 0,
  subcategoriesDrawerList: [],
  subcategoriesDrawerListLoading: false,

  sourceByPriceCollections: [],
  recommendedCollections: [],
  brandsListMain: [],

  brandsListEndless: [],
  brandsListEndlessPage: 1,
  brandsListMaxPages: 1,
  brandListWithSubcategories: [],

  hottestDealsList: [],
  showSearch: false,
  searchText: "",

  homeSearchProducts: [],
  homeSearchProductPage: 1,
  homeSearchMaxProductPages: 1,

  homeSearchBrands: [],
  activeOrders: 0,
};
// its not is use for sourcing home category and brands sub categories
export const sourcingHomeSlice = createSlice({
  name: "Home",
  initialState: initialState,
  reducers: {
    setResetEnabledHomePage(state, action) {
      state.isResetPageOnOpen = action.payload;
    },
    setHomeLoading: (state, action) => {
      state.homeLoading = action.payload;
    },
    setHomeMoreLoading: (state, action) => {
      state.homeMoreLoading = action.payload;
    },
    updateHomeTriggers: (state, action) => {
      state.homeTriggers = action.payload;
    },
    setSelectedCategory: (state, action) => {
      state.selectedCategory = action.payload;
    },
    setSelectedBrandId: (state, action) => {
      state.selectedBrandId = action.payload;
    },
    setCategories: (state, action) => {
      state.categories = action.payload;
    },
    setSourceByPriceCollections: (state, action) => {
      state.sourceByPriceCollections = action.payload;
    },
    setRecommendedCollections: (state, action) => {
      state.recommendedCollections = action.payload;
    },
    setSubcategoriesDrawerList: (state, action) => {
      state.subcategoriesDrawerList = action.payload;
    },
    setSubcategoriesDrawerListLoading: (state, action) => {
      state.subcategoriesDrawerListLoading = action.payload;
    },
    setBrandsListMain: (state, action) => {
      state.brandsListMain = action.payload;
    },

    setBrandsListEndless: (state, action) => {
      if (action.payload.page === 1) {
        state.brandsListEndless = action.payload?.data;
      } else {
        state.brandsListEndless = [
          ...state?.brandsListEndless,
          ...action.payload?.data,
        ];
      }
      state.brandsListMaxPages = action.payload?.num_pages;
    },
    setBrandListWithSubcategories: (state, action) => {
      state.brandsList = action?.payload?.data?.brands;
      state.subcategoriesByBrands =
        action?.payload?.data?.subcategories_by_brands;
    },
    setBrandListForHome: (state, action) => {
      state.brandList = action?.payload?.data?.brands;
    },

    setSubcategoryListInHomeDrawer: (state, action) => {
      state.subcategoryListInHomeDrawer = action?.payload;
    },

    updateBrandsListEndlessPage: (state, action) => {
      state.brandsListEndlessPage = state.brandsListEndlessPage + 1;
    },
    resetBrandsListEndless(state, action) {
      state.brandsListEndless = [];
      state.brandsListEndlessPage = 1;
      state.brandsListMaxPages = 1;
    },

    setHottestDealsList: (state, action) => {
      state.hottestDealsList = action.payload;
    },
    setShowSearch: (state, action) => {
      state.showSearch = action.payload;
    },
    setSearchText: (state, action) => {
      state.searchText = action.payload;
    },

    setHomeSearchBrands: (state, action) => {
      state.homeSearchBrands = action.payload;
    },
    setHomeSearchProducts: (state, action) => {
      if (action.payload.page === 1) {
        state.homeSearchProducts = action.payload?.data;
      } else {
        if (action.payload.page !== state.homeSearchProductPage) {
          state.homeSearchProducts = [
            ...state?.homeSearchProducts,
            ...action.payload?.data,
          ];
        }
      }
      state.homeSearchMaxProductPages = action.payload?.num_pages;
    },
    updateHomeSearchProductPage(state, action) {
      state.homeSearchProductPage = state.homeSearchProductPage + 1;
    },
    resetHomeSearch(state, action) {
      state.homeSearchBrands = [];
      state.homeSearchProducts = [];
      state.homeSearchProductPage = 1;
      state.searchText = "";
      state.homeSearchMaxProductPages = 1;
    },
    resetHomeProducts(state, action) {
      state.homeSearchBrands = [];
      state.homeSearchProducts = [];
      state.homeSearchProductPage = 1;
      state.homeSearchMaxProductPages = 1;
    },
    setActiveOrders(state, action) {
      state.activeOrders = action.payload;
    },
  },
});

export const {
  setResetEnabledHomePage,
  setHomeLoading,
  setHomeMoreLoading,
  updateHomeTriggers,
  setSelectedCategory,
  setCategories,
  setSelectedBrandId,
  setSourceByPriceCollections,
  setRecommendedCollections,

  setSubcategoriesDrawerList,
  setSubcategoriesDrawerListLoading,

  setBrandsListMain,
  setBrandsListEndless,
  resetBrandsListEndless,
  updateBrandsListEndlessPage,
  setBrandListWithSubcategories,
  setSubcategoryListInHomeDrawer,

  setHottestDealsList,
  setShowSearch,
  setSearchText,

  setHomeSearchBrands,
  setHomeSearchProducts,
  updateHomeSearchProductPage,
  resetHomeSearch,
  resetHomeProducts,
  setActiveOrders,
} = sourcingHomeSlice.actions;
