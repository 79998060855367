import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

// components
import WishlinkTypography from "../../WishlinkComponents/WishlinkTypography";

// utils
import { getAssetUrl } from "../../utils/common";
import {
  GA_ACTION,
  GA_CATEGORIES,
  GA_LABEL,
  GA_PAGE,
  GA_SCREEN_NAME,
  PAGE_URLS,
} from "../../utils/constants";
import { useEngageDebuggerReportMutation } from "../../redux/reduxAPI";
import { triggerGa } from "../../utils/gaUtils";
import { useSelector } from "react-redux";

export const EngageHealth = () => {
  const navigate = useNavigate();
  const [engageHealthState, setEngageHealthState] = useState("");
  const [getCreatorEngageDebuggerResult, { isLoading }] =
    useEngageDebuggerReportMutation();

  const { milestones } = useSelector((state) => state.appReducer);
  const { creatorData } = useSelector((state) => state.apiDataReducer);

  useEffect(() => {
    getCreatorEngageDebuggerResult({ postUrl: null })
      .unwrap()
      .then((response) => {
        const report = response?.debug_report;
        const isAllStateGood =
          report?.is_engage_enabled &&
          !report?.is_message_access_disabled &&
          report?.is_facebook_connected &&
          report?.connected_accounts_insta?.length <= 1;
        setEngageHealthState(isAllStateGood ? "Good" : "Low");
      });
  }, []);

  const navigateLocked = Boolean(
    !milestones?.check_creator_social_login_complete?.achieved ||
      !milestones?.check_first_post_milestone?.achieved ||
      !creatorData?.data?.data?.is_connected_insta
  );
  const ctkNotConnected = Boolean(
    !navigateLocked && !creatorData?.data?.data?.is_connected_ctk
  );

  const handleClick = () => {
    triggerGa({
      category: GA_CATEGORIES.NAVIGATION,
      action: GA_ACTION.CARD_CLICK,
      label: GA_LABEL.ENGAGE_DEBUG,
      page: GA_PAGE.ENGAGE,
      screenName: GA_SCREEN_NAME.ENGAGE,
    });
    if (navigateLocked) {
      navigate("#accesslocked");
    } else if (ctkNotConnected) {
      navigate("#connect-engage");
    } else {
      navigate(PAGE_URLS.ENGAGE_DIAGONISIS_REPORT);
    }
  };

  if (isLoading) return null;

  return (
    <div
      className="flex"
      style={{
        marginLeft: "1.25rem",
        marginRight: "1.25rem",
        alignItems: "flex-start",
        marginTop: "1.25rem",
      }}
    >
      <img src={getAssetUrl("engage-meter-status.svg")} alt="icon" />
      <div
        style={{
          marginLeft: "1rem",
          display: "flex",
          flexDirection: "column",
          alignItems: "flex-start",
        }}
      >
        <WishlinkTypography
          fontSize="0.875rem"
          color="#000000"
          fontWeight={500}
          textAlign="left"
          mb="0.5rem"
        >
          Your Engage health is {engageHealthState}
        </WishlinkTypography>
        {engageHealthState === "Low" ? (
          <WishlinkTypography
            fontSize="0.625rem"
            color="#8A8A8A"
            fontWeight={400}
            textAlign="left"
            mb="0.5rem"
          >
            check the issue to fix them for engage to work
          </WishlinkTypography>
        ) : (
          <></>
        )}
        <WishlinkTypography
          fontSize="0.625rem"
          color="#144CC7"
          fontWeight={500}
          textAlign="left"
          style={{ borderBottom: `1px solid #144CC7`, cursor: "pointer" }}
          onClick={handleClick}
        >
          View details
        </WishlinkTypography>
      </div>
    </div>
  );
};
