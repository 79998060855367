import { useEffect, useState } from "react";
import { useTheme } from "@mui/material";

import WishlinkTypography from "../../WishlinkComponents/WishlinkTypography";
import { tokens } from "../../theme";
import { getAssetUrl } from "../../utils/common";
import { pollingAnimations } from "../../utils/constants";

const PollingAnimation = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [currentAnimationKey, setCurrentAnimationKey] = useState();

  useEffect(() => {
    setCurrentAnimationKey(Math.floor(Math.random() * 5));
  }, []);

  const getPollingAnimationUrl = (currentAnimationKey) => {
    return getAssetUrl(pollingAnimations[currentAnimationKey]?.animationUrl);
  };

  const getPollingAnimationText = (currentAnimationKey) => {
    return pollingAnimations[currentAnimationKey]?.title;
  };

  return (
    <>
      <div
        className="flex align-center justify-center flex-column"
        style={{ minHeight: window.innerHeight }}
      >
        <div style={{ paddingLeft: "2rem", paddingRight: "2rem" }}>
          <WishlinkTypography
            fontWeight={400}
            fontSize="0.875rem"
            color={colors.grey[700]}
          >
            Please don't press back or refresh this page
          </WishlinkTypography>
          <WishlinkTypography
            fontWeight={400}
            fontSize="0.875rem"
            color={colors.grey[700]}
            className="pb-5"
          >
            We are creating your Wishlinks
          </WishlinkTypography>
          <img
            src={getPollingAnimationUrl(currentAnimationKey)}
            style={{ maxWidth: "100%" }}
            alt=""
          />
          <WishlinkTypography
            fontWeight={400}
            fontSize="0.75rem"
            color={colors.grey[700]}
            style={{ paddingLeft: "2rem", paddingRight: "2rem" }}
          >
            {getPollingAnimationText(currentAnimationKey)}
          </WishlinkTypography>
        </div>
      </div>
    </>
  );
};

export default PollingAnimation;
