import WishlinkTypography from "../../WishlinkComponents/WishlinkTypography";
import { Card, Column, Row } from "../UtilityComponents";
import { IMAGE_PREFIX } from "../../utils/constants";
import ValueSkeleton from "../ValueSkeleton";
import styled from "@emotion/styled";
import { useEffect, useRef } from "react";

const StyledCard = styled(Card)`
  top: 0;
  background: ${({ bgColor }) => bgColor || "#ffffff"};
  border-radius: 0;
  z-index: 1000;
`;

export const BetterHeader = ({
  title,
  subtitle,
  onClick,
  rightComponents,
  isLoading = false,
  fixed = true,
  bgColor,
}) => {
  const headerRef = useRef(null);
  useEffect(() => {
    const handleScroll = () => {
      if (!headerRef.current) return;
      if (window.scrollY > 0 && headerRef.current) {
        headerRef.current.style.boxShadow = "0px 2px 4px rgba(0, 0, 0, 0.1)";
        headerRef.current.style.transition = "box-shadow 0.5s";
        headerRef.current.style.position = "sticky";
      } else {
        headerRef.current.style.boxShadow = "none";
        headerRef.current.style.position = "relative";
      }
    };
    if (fixed) window.addEventListener("scroll", handleScroll);
    return () => {
      if (fixed) window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <>
      <StyledCard bgColor={bgColor} ref={headerRef} fixed={fixed} mb={1}>
        <Row justifyContent="space-between" alignItems="center" p="1">
          <Row width="auto">
            <Card bgColor="transparent" onClick={onClick}>
              <img
                src={IMAGE_PREFIX + "arrow-left_header.svg"}
                alt="back_icon"
                width="24px"
              />
            </Card>
            <Column width="auto" ml="1">
              <WishlinkTypography
                className="text-left"
                fontSize="1rem"
                fontWeight={500}
              >
                <ValueSkeleton
                  value={title}
                  isLoading={isLoading}
                  width="9rem"
                />
              </WishlinkTypography>
              {subtitle && (
                <WishlinkTypography
                  className="text-left"
                  fontSize="0.8125rem"
                  fontWeight={400}
                >
                  <ValueSkeleton
                    value={subtitle}
                    isLoading={isLoading}
                    width="10rem"
                  />
                </WishlinkTypography>
              )}
            </Column>
          </Row>
          <Row width="auto">{rightComponents}</Row>
        </Row>
      </StyledCard>
    </>
  );
};
