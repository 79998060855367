import React from "react";
import { useTheme } from "@mui/material";

import { tokens } from "../../../theme";
// components
import WishlinkTypography from "../../../WishlinkComponents/WishlinkTypography";
import { getAssetUrl } from "../../../utils/common";

const ReportCardItem = ({ status, title, subtitle, statusText }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  const statusColor = status ? colors.red[500] : "#18A716";
  const statusIcon = status ? "engage-info-alert.svg" : "confirmed-check.svg";

  return (
    <div>
      <div className="flex">
        <img src={getAssetUrl(statusIcon)} alt="icon" />
        <WishlinkTypography
          fontSize="0.75rem"
          fontWeight={400}
          color={colors.grey[100]}
          ml="0.4rem"
          mr="0.2rem"
        >
          {title}
        </WishlinkTypography>
        <WishlinkTypography
          fontSize="0.75rem"
          fontWeight={500}
          color={statusColor}
        >
          : {statusText}
        </WishlinkTypography>
      </div>
      {status ? (
        <WishlinkTypography
          fontSize="0.625rem"
          fontWeight={400}
          fontStyle="italic"
          color={"#7F7F7F"}
          textAlign="left"
          ml="1.4rem"
        >
          {subtitle}
        </WishlinkTypography>
      ) : (
        <></>
      )}
    </div>
  );
};

export default ReportCardItem;
