import { useSelector } from "react-redux";
import CreatorTestimonialCard from "./CreatorTestimonialCard";
import { TESTIMONIAL_SUBTITLES } from "../../utils/constants";

const CreatorTestimonialsList = (props) => {
  const { setShowModal, setTestimonialLink } = props;
  const { testimonials } = useSelector((state) => state.homePageReducer);
  return (
    <div className="p-4 pt-0">
      {testimonials?.map((testimonial, idx) => {
        return (
          <>
            <CreatorTestimonialCard
              testimonial={testimonial}
              setShowModal={setShowModal}
              setTestimonialLink={setTestimonialLink}
              testimonialSubtitle={TESTIMONIAL_SUBTITLES[idx]}
            />
          </>
        );
      })}
    </div>
  );
};

export default CreatorTestimonialsList;
