import { useEffect } from "react";
import { getAppBuildVersion } from "../../utils/config";

const WebEngageScript = () => {
  useEffect(() => {
    let licenceCode = "in~~134106246";
    if (process.env.NODE_ENV === "production") {
      licenceCode = "in~d3a49b5a";
    }
    const script = document.createElement("script");
    script.id = "_webengage_script_tag";
    script.type = "text/javascript";
    script.async = true;
    const buildId = getAppBuildVersion();
    script.innerHTML = `
      var webengage;
      !(function (w, e, b, n, g) {
        function o(e, t) {
          e[t[t.length - 1]] = function () {
            r.__queue.push([t.join("."), arguments]);
          };
        }
        var i,
          s,
          r = w[b],
          z = " ",
          l = "init options track screen onReady".split(z),
          a = "feedback survey notification".split(z),
          c = "options render clear abort".split(z),
          p = "Open Close Submit Complete View Click".split(z),
          u = "identify login logout setAttribute".split(z);
        if (!r || !r.__v) {
          for (
            w[b] = r =
              {
                __queue: [],
                __v: "6.0",
                user: {},
              },
              i = 0;
            i < l.length;
            i++
          )
            o(r, [l[i]]);
          for (i = 0; i < a.length; i++) {
            for (r[a[i]] = {}, s = 0; s < c.length; s++)
              o(r[a[i]], [a[i], c[s]]);
            for (s = 0; s < p.length; s++) o(r[a[i]], [a[i], "on" + p[s]]);
          }
          for (i = 0; i < u.length; i++) o(r.user, ["user", u[i]]);
        }
      })(window, document, "webengage");
      if (${buildId > 7}) {
        (function () {
          var type = Object.prototype.toString;

          webengage.user.login = webengage.user.identify = function (id) {
            window?.ReactNativeWebView?.postMessage(
              JSON.stringify({ type: "webengage_login", payload: id })
            );
          };
          webengage.user.logout = function () {
            window?.ReactNativeWebView?.postMessage(
              JSON.stringify({ type: "webengage_logout", payload: id })
            );
          };

          webengage.user.setAttribute = function (name, value) {
            var attr = null;

            if (type.call(name) === "[object Object]") {
              attr = name;
            } else {
              attr = {};
              attr[name] = value;
            }

            if (type.call(attr) === "[object Object]") {
              window?.ReactNativeWebView?.postMessage(
                JSON.stringify({
                  type: "webengage_set_attribute",
                  payload: attr,
                })
              );
            }
          };

          webengage.screen = function (name, data) {
            if (
              arguments.length === 1 &&
              type.call(name) === "[object Object]"
            ) {
              data = name;
              name = null;
            }
            window.ReactNativeWebView.postMessage(
              JSON.stringify({
                type: "webengage_track_screen",
                payload: {
                  name: name || null,
                  data: data,
                },
              })
            );
          };

          webengage.track = function (name, data) {
            window?.ReactNativeWebView?.postMessage(
              JSON.stringify({
                type: "webengage_track_event",
                payload: data,
              })
            );
          };
        })();
      } else {
        setTimeout(function () {
          var f = document.createElement("script"),
            d = document.getElementById("_webengage_script_tag");
          (f.type = "text/javascript"),
            (f.async = !0),
            (f.src =
              ("https:" == window.location.protocol
                ? "https://widgets.in.webengage.com"
                : "http://widgets.in.webengage.com") +
              "/js/webengage-min-v-6.0.js"),
            d.parentNode.insertBefore(f, d);
        });
      }

      webengage.init("${licenceCode}");
    `;
    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);

  return null;
};

export default WebEngageScript;
