import { useEffect } from "react";

export const useClickOutSide = (ref, callback) => {
  useEffect(() => {
    const handleClickOutSide = (e) => {
      if (!ref?.current?.contains(e?.target) && callback) {
        callback(e);
      }
    };
    document.addEventListener("click", handleClickOutSide);
    return () => {
      document.removeEventListener("click", handleClickOutSide);
    };
  }, [ref, callback]);
};
