import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  phoneNumber: "",
  otpVerificationId: null,
  usingCustomAuth: false,
  otpResult: null,
  countryCode: "+91",
};

export const loginOtpSlice = createSlice({
  name: "loginOtp",
  initialState: initialState,
  reducers: {
    updatePhoneNumber(state, action) {
      state.phoneNumber = action.payload;
    },
    updateOtpVerificationId(state, action) {
      state.otpVerificationId = action.payload;
    },
    updateUsingCustomAuth(state, action) {
      state.usingCustomAuth = action.payload;
    },
    updateOtpResult(state, action) {
      state.otpResult = action.payload;
    },
    updateCountryCode(state, action) {
      state.countryCode = action.payload;
    },
  },
});

export const {
  updatePhoneNumber,
  updateOtpVerificationId,
  updateUsingCustomAuth,
  updateOtpResult,
  updateCountryCode,
} = loginOtpSlice.actions;
