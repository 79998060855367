import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  brandsPageTriggers: {},
  onboardingBrands: null,
  amazonEarningsCard: null,
  amazonEarningsLoading: false,
};

export const BrandsPageSlice = createSlice({
  name: "BrandsPage",
  initialState: initialState,
  reducers: {
    updateOnboardingBrands(state, action) {
      if (
        action?.payload?.data?.data !== undefined &&
        action.payload.status === "fulfilled"
      ) {
        state.onboardingBrands =
          action.payload.data.data.onboarding_brands_list;
      }
    },
    updateBrandsPageTriggers(state, action) {
      state.brandsPageTriggers = action.payload;
    },
    updateAmazonEarningsCard(state, action) {
      state.amazonEarningsCard = action.payload;
    },
    updateAmazonEarningsLoading(state, action) {
      state.amazonEarningsLoading = action.payload;
    },
  },
});

export const {
  updateBrandsPageTriggers,
  updateOnboardingBrands,
  updateAmazonEarningsCard,
  updateAmazonEarningsLoading,
} = BrandsPageSlice.actions;
