import { useTheme } from "@mui/material";
import { useSelector } from "react-redux";

import { tokens } from "../../theme";
import WishlinkButton from "../../WishlinkComponents/WishlinkButton";
import WishlinkTypography from "../../WishlinkComponents/WishlinkTypography";
import { openExternalLinkInNewTab } from "../../utils/common";
import {
  GA_ACTION,
  GA_CATEGORIES,
  GA_LABEL,
  GA_LABEL2,
  GA_PAGE,
  GA_SCREEN_NAME,
  IMAGE_PREFIX,
  PAGE_URLS,
} from "../../utils/constants";
import { useLocation, useNavigate } from "react-router-dom";
import { useContext, useEffect, useState } from "react";
import { triggerGa } from "../../utils/gaUtils";
import { APIContext } from "../../apiLayer";
import { usePixelEvent } from "../../hooks/usePixelEvent";

const ErrorPage = (props) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const navigate = useNavigate();
  const location = useLocation();
  const apis = useContext(APIContext);

  const { errorPage } = props;
  const [errorCode, setErrorCode] = useState(null);
  const [failureLabel2, setFailureLabel2] = useState("");
  const [loginType, setLoginType] = useState("");
  const [existingAccountHandle, setExistingAccountHandle] = useState("");
  const [errorCopies, setErrorCopies] = useState({});
  const [multipleAttempts, setMultipleAttempts] = useState(false);
  const { creatorData } = useSelector((state) => state.apiDataReducer);
  const { isMyntraCreator, milestones } = useSelector(
    (state) => state.appReducer
  );
  const { fbqEvent } = usePixelEvent();

  const LEARN_HOW = "Learn How";
  const CONTACT_SUPPORT = "Contact Support";

  function onClickLogout() {
    triggerGa({
      category: GA_CATEGORIES.LOGIN,
      action: GA_ACTION.CONTACT_US,
      label: GA_LABEL.SIGNUP_FAIL,
      label2: failureLabel2,
      page: GA_PAGE.LOGIN,
      screenName: GA_SCREEN_NAME.SIGNUP_FAIL,
    });
    const fallbackUrl = localStorage.getItem("instaFallbackUrl");
    localStorage.removeItem("instaFallbackUrl");
    if (fallbackUrl) {
      navigate(fallbackUrl);
    } else if (
      milestones.milestones_found &&
      milestones.check_first_sale_milestone?.achieved
    ) {
      navigate(PAGE_URLS.PROFILE);
    } else {
      navigate(PAGE_URLS.HOME);
    }
  }

  useEffect(() => {
    setErrorCode(location.state?.errorCode);
    setLoginType(location.state?.loginType);
    setExistingAccountHandle(location.state?.existingAccountHandle);
    setMultipleAttempts(location.state?.multipleAttempts);
    apis.fetchCreatorType();
  }, []);

  useEffect(() => {
    setErrorCopies(getErrorCopies());
    try {
      fbqEvent("WCSoialLoginFail", {
        loginType: loginType,
        errorCode: errorCode,
      });
    } catch {
      fetch("https://api.wishlink.com/api/frontEndLogger", {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          log: "Wishlink Creator Social Login Error Fb Pixel Failed",
        }),
      })
        .then()
        .catch();
    }
  }, [errorCode, loginType, creatorData]);

  const getErrorCopies = () => {
    if (errorPage) {
      return {
        title: `Sorry, something seems like an invalid path`,
        subTitle: `Please check the link in address bar and try again`,
        ctaText: `Go back`,
        footerText: multipleAttempts ? CONTACT_SUPPORT : "",
        whatsappMessageText: isMyntraCreator
          ? "Hey there, I need help logging in to Wishlink Creator. I am a part of Myntra Creator Squad."
          : "Hey there, I need help logging in to Wishlink Creator",
      };
    }
    if (loginType === "instagram") {
      if (errorCode === 401) {
        setFailureLabel2(GA_LABEL2.ACCOUNT_TYPE);
        return {
          title: `Oops, creator account not found`,
          subTitle: `Change you Instagram account type to ‘Creator’ to continue`,
          ctaText: `Login again`,
          footerText: multipleAttempts ? CONTACT_SUPPORT : LEARN_HOW,
          whatsappMessageText: isMyntraCreator
            ? `Hey there, I need help logging in to Wishlink Creator. It says the product is not for me. I am a part of Myntra Creator Squad.`
            : `Hey there, I need help logging in to Wishlink Creator. It says the product is not for me.`,
        };
      } else if (errorCode === 402) {
        setFailureLabel2(GA_LABEL2.FOLLOWERS);
        return {
          title: `You've been waitlisted. You can use the platform once you have`,
          subTitle: ``,
          ctaText: `Login with different handle`,
          footerText: multipleAttempts ? CONTACT_SUPPORT : "",
          showImage: true,
          img_url: IMAGE_PREFIX + "social-media-requirements.svg",
          whatsappMessageText: isMyntraCreator
            ? `Hey there, I need help logging in to Wishlink Creator. I have less than 2000 followers. I am a part of Myntra Creator Squad.`
            : `Hey there, I need help logging in to Wishlink Creator. I have less than 2000 followers.`,
        };
      } else if (errorCode === 403) {
        setFailureLabel2(GA_LABEL2.MEDIA_PERMISSIONS);
        return {
          title: `Please provide us the media permission to continue`,
          subTitle: `This will allow you to seamlessly link your content with products`,
          ctaText: `Login again`,
          footerText: multipleAttempts ? CONTACT_SUPPORT : "",
          showImage: false,
          whatsappMessageText: isMyntraCreator
            ? `Hey there, I need help logging in to Wishlink Creator. I haven't given media permissions. I am a part of Myntra Creator Squad.`
            : `Hey there, I need help logging in to Wishlink Creator. I haven't given media permissions.`,
        };
      } else if (errorCode === 400) {
        setFailureLabel2(GA_LABEL2.ACCOUNT_MAPPING);
        return {
          title: `Sorry, this account doesn’t match with Insta account connected to your Wishlink Shop`,
          subTitle: `Please switch to @${existingAccountHandle} on your Instagram app to continue`,
          ctaText: `Login with different handle`,
          footerText: CONTACT_SUPPORT,
          showImage: false,
          whatsappMessageText: isMyntraCreator
            ? `Hey there, I need help logging in to Wishlink Creator. I already have Wishlink Engage setup with @${existingAccountHandle}.  I am a part of Myntra Creator Squad.`
            : `Hey there, I need help logging in to Wishlink Creator. I already have Wishlink Engage setup with @${existingAccountHandle}`,
        };
      } else if (errorCode === 404) {
        setFailureLabel2(GA_LABEL2.PAGE_NOT_CONNECTED);
        return {
          title: `Oops, there seems to be an issue with your Facebook page connection`,
          subTitle: (
            <>
              {"Please refer to "}
              <div
                onClick={() => {
                  openExternalLinkInNewTab({
                    redirectUrl: `${IMAGE_PREFIX}engage-help.pdf`,
                  });
                }}
                style={{
                  textDecoration: "underline",
                  fontWeight: "700",
                  color: "#FFFFFF",
                }}
              >
                this guide
              </div>
              {" to resolve the issue"}
            </>
          ),
          ctaText: `Go back`,
          footerText: CONTACT_SUPPORT,
          showImage: false,
          whatsappMessageText: isMyntraCreator
            ? `Hey there, I need help with Wishlink Engage. My username is ${creatorData?.data?.data?.username}.  I am a part of Myntra Creator Squad.`
            : `Hey there, I need help with Wishlink Engage. My username is ${creatorData?.data?.data?.username}`,
        };
      } else if (errorCode === 423) {
        setFailureLabel2(GA_LABEL2.SOCIALMEDIABLOCKED);
        navigate(PAGE_URLS.USER_BLOCKED);
      }
    } else if (loginType === "youtube") {
      if (errorCode === 402) {
        setFailureLabel2(GA_LABEL2.FOLLOWERS);
        return {
          title: `You've been waitlisted. You can use the platform once you have`,
          subTitle: ``,
          ctaText: `Login with different handle`,
          footerText: multipleAttempts ? CONTACT_SUPPORT : "",
          showImage: true,
          img_url: IMAGE_PREFIX + "social-media-requirements.svg",
          whatsappMessageText: isMyntraCreator
            ? `Hey there, I need help logging in to Wishlink Creator. I have less than 500 subscribers. I am a part of Myntra Creator Squad.`
            : `Hey there, I need help logging in to Wishlink Creator. I have less than 500 subscribers.`,
        };
      } else if (errorCode === 403) {
        setFailureLabel2(GA_LABEL2.MEDIA_PERMISSIONS);
        return {
          title: `Please provide us the media permission to continue`,
          subTitle: `This will allow you to seamlessly link your content with products`,
          ctaText: `Login again`,
          footerText: multipleAttempts ? CONTACT_SUPPORT : "",
          showImage: false,
          whatsappMessageText: isMyntraCreator
            ? `Hey there, I need help logging in to Wishlink Creator. I haven't given media permissions. I am a part of Myntra Creator Squad.`
            : `Hey there, I need help logging in to Wishlink Creator. I haven't given media permissions.`,
        };
      } else if (errorCode === 423) {
        setFailureLabel2(GA_LABEL2.SOCIALMEDIABLOCKED);
        return {
          title: `Sorry, this account is blocked`,
          subTitle: `Malpractises Detected`,
          ctaText: `Go back`,
          footerText: CONTACT_SUPPORT,
          showImage: false,
          whatsappMessageText: isMyntraCreator
            ? `Hey there, I need help logging in to Wishlink Creator. I already have Wishlink Engage setup with @${existingAccountHandle}.  I am a part of Myntra Creator Squad.`
            : `Hey there, I need help logging in to Wishlink Creator. I already have Wishlink Engage setup with @${existingAccountHandle}`,
        };
      }
    }
    return {
      title: `Sorry, something failed while trying to login`,
      subTitle: `Please try again in some time`,
      ctaText: `Login again`,
      footerText: CONTACT_SUPPORT,
      showImage: false,
      whatsappMessageText: isMyntraCreator
        ? "Hey there, I need help logging in to Wishlink Creator. I am a part of Myntra Creator Squad."
        : "Hey there, I need help logging in to Wishlink Creator",
    };
  };
  const onClickContactSupport = () => {
    triggerGa({
      category: GA_CATEGORIES.LOGIN,
      action: GA_ACTION.CONTACT_US,
      label: GA_LABEL.SIGNUP_FAIL,
      label2: failureLabel2,
      page: GA_PAGE.LOGIN,
      screenName: GA_SCREEN_NAME.SIGNUP_FAIL,
    });
    navigate(`${location.pathname}?support=true`);
  };

  return (
    <div
      style={{
        minHeight: window.innerHeight,
        background: colors.red[200],
      }}
      className="relative"
    >
      <img
        src={`${IMAGE_PREFIX}coin.svg`}
        className="absolute"
        style={{ top: "1.875rem", right: 0 }}
        alt=""
      />
      <img
        src={`${IMAGE_PREFIX}dollar-coin.svg`}
        className="absolute"
        style={{ bottom: "8rem", left: "-2rem" }}
        alt=""
      />
      <img
        src={`${IMAGE_PREFIX}error-graphic-sorry.png`}
        className="absolute w-100"
        style={{ top: "20%", left: "50%", transform: "translateX(-50%)" }}
        alt=""
      />
      <div className="absolute w-100" style={{ top: "50%" }}>
        <WishlinkTypography
          className="p-5 text-center w-100"
          fontSize="1rem"
          color={colors.grey[0]}
          fontWeight={700}
        >
          {errorCopies?.title}
        </WishlinkTypography>
        {errorCopies?.subTitle ? (
          <WishlinkTypography
            className="p-5 w-100"
            fontSize="1.25rem"
            fontWeight={800}
            color={colors.grey[0]}
          >
            {errorCopies?.subTitle}
          </WishlinkTypography>
        ) : (
          <></>
        )}
        {errorCopies?.showImage ? (
          <img src={errorCopies.img_url} style={{ width: "90%" }} alt="" />
        ) : (
          <></>
        )}
      </div>
      <div className="absolute w-100" style={{ bottom: "5%" }}>
        <WishlinkButton
          borderRadius="2.5rem"
          height="2.5rem"
          background={colors.grey[0]}
          style={{
            ":hover": {
              bgcolor: colors.grey[0],
            },
            width: "80%",
          }}
          buttonClassName="p-4"
          onClick={() => onClickLogout()}
        >
          <WishlinkTypography
            fontWeight={600}
            fontSize="0.875rem"
            color={colors.red[400]}
            className="text-left"
          >
            {errorCopies?.ctaText}
          </WishlinkTypography>
        </WishlinkButton>
        {errorCopies?.footerText ? (
          <div className="flex justify-center align-center pt-5">
            <img src={`${IMAGE_PREFIX}learn-how.svg`} alt="" />
            <WishlinkTypography
              fontWeight={600}
              fontSize="0.875rem"
              color={colors.grey[0]}
              className="text-center pl-3"
              onClick={() => onClickContactSupport()}
            >
              {errorCopies?.footerText}
            </WishlinkTypography>
          </div>
        ) : (
          <></>
        )}
      </div>
    </div>
  );
};

export default ErrorPage;
