import { useEffect, useState, useContext } from "react";
import { useSelector } from "react-redux";
import { useTheme } from "@mui/material";

import { tokens } from "../../theme";
import { APIContext } from "../../apiLayer";
import WishlinkSwitch from "../../WishlinkComponents/WishlinkSwitch";
import WishlinkTypography from "../../WishlinkComponents/WishlinkTypography";
import { triggerGa } from "../../utils/gaUtils";
import {
  ENGAGE_UPDATE_TYPES,
  GA_ACTION,
  GA_CATEGORIES,
  GA_LABEL,
  GA_LABEL2,
  GA_PAGE,
  GA_SCREEN_NAME,
} from "../../utils/constants";
import { openExternalLinkInNewTab } from "../../utils/common";

const EngageToggle = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const apis = useContext(APIContext);
  const [checked, setChecked] = useState(false);
  const [toggleDisabledByThreshold, setToggleDisabledByThreshold] =
    useState(true);
  const [disabledForTime, setDisabledForTime] = useState("");
  const [disabledForReason, setDisabledForReason] = useState("");
  const [allowAccessToMessagesDisabled, setAllowAccessToMessagesDisabled] =
    useState(false);

  const { instaBasicProfileData, instaBasicProfileLoading } = useSelector(
    (state) => state.engageReducer
  );

  useEffect(() => {
    apis.getInstaBasicProfileData(undefined, false);
  }, []);

  useEffect(() => {
    setChecked(instaBasicProfileData.ctkStatus);
    setToggleDisabledByThreshold(!instaBasicProfileData.allowCtkToggle);
    setDisabledForTime(instaBasicProfileData.dm_paused_for);
    setDisabledForReason(instaBasicProfileData.dm_paused_reason);
    setAllowAccessToMessagesDisabled(
      instaBasicProfileData.allow_access_to_messages_disabled
    );
  }, [instaBasicProfileData]);

  async function onClickUpdateCTKsettings() {
    triggerGa({
      category: GA_CATEGORIES.USER_INTERACTION,
      action: GA_ACTION.TOGGLE,
      label: GA_LABEL.ENGAGE_STATUS,
      label2: checked ? GA_LABEL2.OFF : GA_LABEL2.ON,
      page: GA_PAGE.ENGAGE,
      screenName: GA_SCREEN_NAME.ENGAGE_SETTING,
    });
    const body = {
      updateType: ENGAGE_UPDATE_TYPES.DM,
      status: !checked,
    };
    await apis.updateCTKsettings(body);
  }

  return (
    <div className="p-4">
      <div className="flex align-center justify-between">
        <WishlinkTypography
          fontSize="1rem"
          fontWeight={500}
          color={colors.grey[100]}
          lineHeight="1.5rem"
        >
          Engage Comment
        </WishlinkTypography>
        <WishlinkSwitch
          checked={checked}
          disabled={instaBasicProfileLoading || toggleDisabledByThreshold}
          onChange={() => onClickUpdateCTKsettings()}
        />
      </div>
      <WishlinkTypography
        fontSize="0.75rem"
        fontWeight={400}
        color={colors.grey[500]}
        lineHeight="1.125rem"
        className="text-left"
      >
        Send automatic DMs with exact product links to everyone who asks for
        link in your comments
      </WishlinkTypography>
      {!instaBasicProfileLoading && (
        <>
          {!checked && allowAccessToMessagesDisabled ? (
            <div className="flex align-center justify-between">
              <WishlinkTypography
                mt="0.75rem"
                fontSize="0.75rem"
                fontWeight={500}
                color={colors.red[500]}
                lineHeight="1.25rem"
                className="text-left"
              >
                Allow access to messages on Instagram and then enable Engage
              </WishlinkTypography>

              <WishlinkTypography
                mt="0.75rem"
                fontSize="0.75rem"
                fontWeight={500}
                color={colors.red[500]}
                lineHeight="1.25rem"
                className="text-left"
                style={{ textDecoration: "underline" }}
                onClick={() => {
                  openExternalLinkInNewTab({
                    redirectUrl:
                      "https://www.facebook.com/help/instagram/791161338412168",
                  });
                }}
              >
                Allow now
              </WishlinkTypography>
            </div>
          ) : (
            toggleDisabledByThreshold &&
            disabledForReason &&
            disabledForTime && (
              <>
                <WishlinkTypography
                  mt="0.75rem"
                  fontSize="0.75rem"
                  fontWeight={500}
                  color={colors.red[500]}
                  lineHeight="1.25rem"
                  className="text-left"
                >
                  Engage will resume sending DMs in: {disabledForTime}
                </WishlinkTypography>
                <WishlinkTypography
                  mt="0.25rem"
                  fontSize="0.625rem"
                  fontWeight={400}
                  color={"rgba(233, 30, 99, 0.60)"}
                  lineHeight="0.925rem"
                  className="text-left"
                >
                  Reason : {disabledForReason}
                </WishlinkTypography>
              </>
            )
          )}
        </>
      )}
    </div>
  );
};

export default EngageToggle;
