// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyDLL6Yr4GtLRq7dc8h4uho9izw9ChoQ9Z8",
  authDomain: "authentication-f3c92.firebaseapp.com",
  projectId: "authentication-f3c92",
  storageBucket: "authentication-f3c92.appspot.com",
  messagingSenderId: "595955571752",
  appId: "1:595955571752:web:b0ba43385870c7e813291d",
  measurementId: "G-91SLWGKHM1",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const auth = getAuth(app);

export { auth, app };
