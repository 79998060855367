import React from "react";
import WishlinkDrawer from "../../WishlinkComponents/WishlinkDrawer";
import { useTheme } from "@emotion/react";
import { tokens } from "../../theme";
import WishlinkTypography from "../../WishlinkComponents/WishlinkTypography";
import WishlinkButton from "../../WishlinkComponents/WishlinkButton";
import { getPlatform } from "../../utils/config";
import { ANDRIOD_URL, IOS_URL } from "../../utils/constants";
import { openExternalLinkInNewTab } from "../../utils/common";

export const AppForceUpdate = ({ isForceUpdateAvailable = false }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const platform = getPlatform();
  const handleNavigation = () => {
    const redirectUrl = platform === "ios" ? IOS_URL : ANDRIOD_URL;
    openExternalLinkInNewTab({ redirectUrl });
  };

  if (isForceUpdateAvailable === false || platform === "web") return <></>;
  return (
    <>
      <WishlinkDrawer drawerOpen={isForceUpdateAvailable}>
        <div className="p-4">
          <WishlinkTypography
            fontSize="1rem"
            fontWeight={400}
            color={colors.grey[100]}
            className="text-center pt-2 pb-2"
          >
            Attention Creators!!
          </WishlinkTypography>
          <WishlinkTypography
            fontSize="1rem"
            fontWeight={400}
            color={colors.grey[500]}
            className="text-center pt-2 pb-2"
          >
            To ensure seamless performance and continued access to all features,
            update your app now!
          </WishlinkTypography>
          <WishlinkButton
            background={colors.red[500]}
            className="pt-5 mb-3"
            height="3rem"
            width="100%"
            borderRadius="0.5rem"
            buttonClassName="bs-2"
            style={{
              ":hover": {
                bgcolor: colors.red[500],
              },
            }}
            onClick={handleNavigation}
          >
            <WishlinkTypography color={colors.grey[0]}>
              Update Now
            </WishlinkTypography>
          </WishlinkButton>
        </div>
      </WishlinkDrawer>
    </>
  );
};
