import { useDispatch } from "react-redux";
import { useEffect } from "react";
import {
  useAddCreatorKeywordsMutation,
  useUnlinkSocialMediaHandleMutation,
  useLazyGetCreatorKeywordsQuery,
  useUpdateCreatorKeywordsMutation,
} from "./ProfileReduxAPI";
import {
  hideToastNotification,
  showToastNotification,
  updateKeywords,
} from "../redux/redux";
import { updateProfileTriggers } from "./ProfileRedux";

const ProfileDataLayer = () => {
  const dispatch = useDispatch();

  const [triggerUnlinkSocialMediaHandle, resultUnlinkSocialMediaHandle] =
    useUnlinkSocialMediaHandleMutation();
  const [triggerUpdateCreatorKeywords, resultUpdateCreatorKeywords] =
    useUpdateCreatorKeywordsMutation();
  const [triggerAddCreatorKeywords, resultAddCreatorKeywords] =
    useAddCreatorKeywordsMutation();
  const [triggerCreatorKeywords, resultCreatorKeywords] =
    useLazyGetCreatorKeywordsQuery();

  const trigger = {
    updateUnlinkSocialMediaHandle: async (apiCallParams) =>
      await triggerUnlinkSocialMediaHandle(apiCallParams),
    updateCreatorKeywords: async (apiCallParams) =>
      await triggerUpdateCreatorKeywords(apiCallParams),
    addCreatorKeywords: async (apiCallParams) =>
      await triggerAddCreatorKeywords(apiCallParams),
    getCreatorKeywords: async (apiCallParams) =>
      await triggerCreatorKeywords(apiCallParams, true),
  };

  function showToast({ variant, message }) {
    dispatch(showToastNotification({ variant, message }));
    setTimeout(() => {
      dispatch(hideToastNotification());
    }, 3000);
  }

  useEffect(() => {
    dispatch(updateProfileTriggers(trigger));
  }, []);

  useEffect(() => {
    if (resultUnlinkSocialMediaHandle?.data?.success === false) {
      showToast({
        variant: "error",
        message: resultUnlinkSocialMediaHandle?.data?.msg,
      });
    } else if (resultUnlinkSocialMediaHandle?.data?.success === true) {
      showToast({
        variant: "success",
        message: "Social Media Unlinked Successfully",
      });
    }
  }, [resultUnlinkSocialMediaHandle]);

  useEffect(() => {
    if (resultUpdateCreatorKeywords?.data?.success === false) {
      showToast({
        variant: "error",
        message: resultUpdateCreatorKeywords?.data?.msg,
      });
      return;
    }
  }, [resultUpdateCreatorKeywords]);

  useEffect(() => {
    if (resultAddCreatorKeywords?.data?.success === false) {
      showToast({
        variant: "error",
        message: resultAddCreatorKeywords?.data?.msg,
      });
      return;
    }
  }, [resultAddCreatorKeywords]);

  useEffect(() => {
    if (resultCreatorKeywords?.data?.success === false) {
      showToast({
        variant: "error",
        message: resultCreatorKeywords?.data?.msg,
      });
      return;
    }
    dispatch(updateKeywords(resultCreatorKeywords));
  }, [resultCreatorKeywords]);

  return <></>;
};

export default ProfileDataLayer;
