import { isToday } from "date-fns";
import { useSelector } from "react-redux";
import ConnectEngageAccount from "../../components/EnableEngageSTK/ConnectEngageAccount";
import EnableEngageSTKHeader from "../../components/EnableEngageSTK/EnableEngageSTKHeader";
import ExploreEngageSTK from "../../components/EnableEngageSTK/ExploreEngageSTK";
import HowToConnectSTK from "../../components/EnableEngageSTK/HowToConnectSTK";
import WhyConnectEngageSTK from "../../components/EnableEngageSTK/WhyConnectEngageSTK";
import { IMAGE_PREFIX } from "../../utils/constants";
import Poweredby from "../../components/Poweredby";
import { useContext, useEffect } from "react";
import { APIContext } from "../../apiLayer";
import { EngageHealth } from "../../components/EnableEngageSTK/EngageHealth";
import useEngageActivation from "../../hooks/Blitzllama/useEngageActivation";

const EnableEngageSTK = () => {
  const { milestones } = useSelector((state) => state.appReducer);
  const { creatorData } = useSelector((state) => state.apiDataReducer);
  const apis = useContext(APIContext);
  const { handleEngageToggle } = useEngageActivation();

  const navigateLocked = Boolean(
    !milestones?.check_creator_social_login_complete?.achieved ||
      !milestones?.check_first_post_milestone?.achieved ||
      !creatorData?.data?.data?.is_connected_insta
  );
  const ctkNotConnected = Boolean(
    !navigateLocked && !creatorData?.data?.data?.is_connected_ctk
  );

  const first_time_engage_activation_date =
    creatorData?.data?.data?.first_time_engage_activation_date;

  const firstTimeActivationDate =
    creatorData?.data?.data?.first_time_engage_activation_date;

  useEffect(() => {
    if (first_time_engage_activation_date) {
      const first_time_engage_activation = isToday(
        new Date(first_time_engage_activation_date)
      );
      handleEngageToggle(first_time_engage_activation);
    }
  }, []);

  useEffect(() => {
    apis.getCreatorData(undefined, false);
    apis.getCreatorDashboardMilestonesStatus();
  }, []);

  return (
    <>
      <EnableEngageSTKHeader />
      {firstTimeActivationDate && <EngageHealth />}
      <img
        src={`${IMAGE_PREFIX}engage-link-dms.png`}
        className="w-100 mt-5"
        alt="Engage CTK"
      />
      <ExploreEngageSTK />
      <WhyConnectEngageSTK />
      <HowToConnectSTK />
      <div
        className="mt-4"
        style={{ paddingBottom: ctkNotConnected ? "7rem" : "" }}
      >
        <Poweredby />
      </div>
      {ctkNotConnected || navigateLocked ? <ConnectEngageAccount /> : <></>}
    </>
  );
};

export default EnableEngageSTK;
