import { useDispatch } from "react-redux";
import { useEffect, useState } from "react";
import { getDataPointLoadingValue } from "../../../utils/common";
import {
  setFilterOptionsLoading,
  updateCollectionTriggers,
  updateSourcingCollectionDetails,
  updateSourcingCollectionLoading,
  updateSourcingCollectionMoreProductsLoading,
  updateSourcingCollectionProducts,
  updateSourcingCollectionProductsLoading,
  updateSourcingFilterBrandOptions,
  updateSourcingFilterCategoriesOptions,
  updateSourcingFilterColorOptions,
  updateSourcingFilterDiscountOptions,
  updateSourcingFilterSizeOptions,
} from "./collectionsRedux";
import {
  useLazyGetCollectionDetailsQuery,
  useLazyGetCollectionProductsQuery,
  useLazyGetFilterBrandOptionsQuery,
  useLazyGetFilterCategoriesOptionsQuery,
  useLazyGetFilterColorOptionsQuery,
  useLazyGetFilterDiscountOptionsQuery,
  useLazyGetFilterSizeOptionsQuery,
} from "./collectionsReduxAPI";
import { useDataUpdateWithLoading } from "../../utils/useDataUpdate";

const CollectionsDataLayer = () => {
  const dispatch = useDispatch();

  const [
    triggerGetSourcingCollectionDetails,
    resultGetSourcingCollectionDetails,
  ] = useLazyGetCollectionDetailsQuery();
  const [
    triggerGetSourcingCollectionProducts,
    resultGetSourcingCollectionProducts,
  ] = useLazyGetCollectionProductsQuery();
  const [triggerGetFilterSizeOptions, resultGetFilterSizeOptions] =
    useLazyGetFilterSizeOptionsQuery();
  const [triggerGetFilterColorOptions, resultGetFilterColorOptions] =
    useLazyGetFilterColorOptionsQuery();
  const [triggerGetFilterBrandOptions, resultGetFilterBrandOptions] =
    useLazyGetFilterBrandOptionsQuery();
  const [triggerGetFilterCategoriesOptions, resultGetFilterCategoriesOptions] =
    useLazyGetFilterCategoriesOptionsQuery();
  const [triggerGetFilterDiscountOptions, resultGetFilterDiscountOptions] =
    useLazyGetFilterDiscountOptionsQuery();
  const [lastApiCallParams, setLastApiCallParams] = useState(null);

  const trigger = {
    getSourcingCollectionDetails: async (apiCallParams) =>
      await triggerGetSourcingCollectionDetails(apiCallParams),
    getSourcingCollectionProducts: async (apiCallParams) => {
      setLastApiCallParams(apiCallParams);
      await triggerGetSourcingCollectionProducts(apiCallParams);
    },
    getFilterSizeOptions: async (apiCallParams) =>
      await triggerGetFilterSizeOptions(apiCallParams),
    getFilterColorOptions: async (apiCallParams) =>
      await triggerGetFilterColorOptions(apiCallParams),
    getFilterBrandOptions: async (apiCallParams) =>
      await triggerGetFilterBrandOptions(apiCallParams),
    getFilterCategoriesOptions: async (apiCallParams) =>
      await triggerGetFilterCategoriesOptions(apiCallParams),
    getFilterDiscountOptions: async (apiCallParams) =>
      await triggerGetFilterDiscountOptions(apiCallParams),
  };

  useEffect(() => {
    dispatch(updateCollectionTriggers(trigger));
  }, [trigger]);

  useDataUpdateWithLoading(
    resultGetSourcingCollectionDetails,
    updateSourcingCollectionDetails,
    updateSourcingCollectionLoading
  );

  useEffect(() => {
    const page = lastApiCallParams?.page || 1;
    const data = resultGetSourcingCollectionProducts.data;
    if (data) {
      if (data?.success) {
        dispatch(updateSourcingCollectionProducts(data));
      }
    }
    if (page === 1) {
      dispatch(
        updateSourcingCollectionProductsLoading(
          getDataPointLoadingValue(resultGetSourcingCollectionProducts)
        )
      );
    }
    dispatch(
      updateSourcingCollectionMoreProductsLoading(
        getDataPointLoadingValue(resultGetSourcingCollectionProducts)
      )
    );
  }, [resultGetSourcingCollectionProducts]);

  // Filter Options
  useDataUpdateWithLoading(
    resultGetFilterSizeOptions,
    updateSourcingFilterSizeOptions,
    setFilterOptionsLoading
  );
  useDataUpdateWithLoading(
    resultGetFilterColorOptions,
    updateSourcingFilterColorOptions,
    setFilterOptionsLoading
  );
  useDataUpdateWithLoading(
    resultGetFilterBrandOptions,
    updateSourcingFilterBrandOptions,
    setFilterOptionsLoading
  );
  useDataUpdateWithLoading(
    resultGetFilterCategoriesOptions,
    updateSourcingFilterCategoriesOptions,
    setFilterOptionsLoading
  );
  useDataUpdateWithLoading(
    resultGetFilterDiscountOptions,
    updateSourcingFilterDiscountOptions,
    setFilterOptionsLoading
  );

  return <></>;
};

export default CollectionsDataLayer;
