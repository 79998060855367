import { useContext, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useTheme } from "@mui/material";

import { tokens } from "../../theme";
import WishlinkDrawer from "../../WishlinkComponents/WishlinkDrawer";
import WishlinkButton from "../../WishlinkComponents/WishlinkButton";
import WishlinkTypography from "../../WishlinkComponents/WishlinkTypography";
import {
  GA_ACTION,
  GA_CATEGORIES,
  GA_LABEL,
  GA_LABEL2,
  IMAGE_PREFIX,
} from "../../utils/constants";
import HowToActivateEngage from "../EnableEngage/HowToActivateEngage";
import { triggerGa } from "../../utils/gaUtils";
import { useSelector } from "react-redux";
import {
  openExternalLinkInNewTab,
  triggerEngageLogin,
} from "../../utils/common";
import { APIContext } from "../../apiLayer";
import EngageLockedDrawer from "./EngageLockedDrawer";

const SocialMediaDrawer = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const apis = useContext(APIContext);

  const [drawerState, setDrawerState] = useState(false);
  const [lockedDrawerState, setLockedDrawerState] = useState(false);
  const { pageName, screenName } = useSelector((state) => state.appReducer);

  const { creatorData } = useSelector((state) => state.apiDataReducer);

  useEffect(() => {
    if (location.hash === "#connect-engage") {
      setDrawerState(true);
    } else if (location.hash === "#accesslocked") {
      triggerGa({
        category: GA_CATEGORIES.ERROR,
        action: GA_ACTION.BUTTON_CLICK,
        label: GA_LABEL.ENGAGE,
        label2: GA_LABEL2.LOCKED_MILESTONE,
        page: pageName,
        screenName: screenName,
      });
      setLockedDrawerState(true);
      setDrawerState(false);
    } else {
      setLockedDrawerState(false);
      setDrawerState(false);
    }
  }, [location]);

  useEffect(() => {
    apis.getCreatorSocialLoginStatusData();
  }, []);

  async function onClickFacebookButton() {
    localStorage.setItem("engageFallbackUrl", location.pathname);
    triggerGa({
      category: GA_CATEGORIES.USER_INTERACTION,
      action: GA_ACTION.BUTTON_CLICK,
      label: GA_LABEL.ENGAGE_ACTIVATION,
      page: pageName,
      screenName: screenName,
    });
    if (window.cordova) {
      triggerEngageLogin();
    } else {
      triggerEngageLogin();
    }

    // const client_id = "665365291834413";
    // const redirect_uri = window.location.origin + "/auth/success";
    // const response_type = "token";
    // const scope = [
    //   "email",
    //   "public_profile",
    //   "pages_show_list",
    //   "instagram_basic",
    //   "instagram_manage_insights",
    //   "instagram_manage_comments",
    //   "instagram_manage_messages",
    //   "pages_manage_metadata",
    //   "pages_messaging",
    //   "pages_read_engagement",
    //   "pages_show_list",
    // ];
    // let url = `https://www.facebook.com/v15.0/dialog/oauth?client_id=${client_id}
    // 	&redirect_uri=${redirect_uri}
    // 	&response_type=${response_type}
    // 	&scope=${scope}`;
    // window.location.href = url;
  }

  return (
    <>
      <EngageLockedDrawer lockedDrawerState={lockedDrawerState} />
      <WishlinkDrawer
        drawerOpen={drawerState}
        onClose={() => navigate(-1, { replace: true })}
        onOpen={() => {}}
      >
        <div className="p-4 m-auto text-center">
          <img
            src={`${IMAGE_PREFIX}wishlink-empty-state-default-graphic.png`}
            style={{ width: "30%" }}
            className="pt-5"
            alt=""
          />
          <WishlinkTypography
            fontSize="1rem"
            fontWeight={500}
            lineHeight="1.5rem"
            color={colors.grey[100]}
            className="pt-5"
          >
            How to activate Wishlink Engage
          </WishlinkTypography>
          <div className="flex align-center justify-start mt-5">
            <div
              className="mr-3 flex justify-center align-center"
              style={{
                background: "#FFDCBC",
                borderRadius: "50%",
                width: "2rem",
                height: "2rem",
                flexGrow: 0,
                flexShrink: 0,
              }}
            >
              <WishlinkTypography fontSize="0.812rem" fontWeight={600}>
                1
              </WishlinkTypography>
            </div>
            <WishlinkTypography
              fontSize="0.75rem"
              fontWeight={400}
              color={colors.grey[700]}
              className="text-left pr-4"
            >
              Make sure your Instagram account is connected to a Facebook page.{" "}
              <a href="https://help.instagram.com/570895513091465/">
                Learn How
              </a>
            </WishlinkTypography>
          </div>
          <WishlinkTypography
            fontSize="0.75rem"
            fontWeight={400}
            color={colors.grey[700]}
            lineHeight="1rem"
            className="text-left pt-5"
            style={{ paddingLeft: "2.8rem" }}
          >
            Having troubles in this step?{" "}
            <div
              onClick={() =>
                openExternalLinkInNewTab({
                  redirectUrl: `${IMAGE_PREFIX}engage-help.pdf`,
                })
              }
            >
              Learn More
            </div>
          </WishlinkTypography>
          <div className="flex align-center justify-start mt-5">
            <div
              className="mr-3 flex justify-center align-center"
              style={{
                background: "#FFDCBC",
                borderRadius: "50%",
                width: "2rem",
                height: "2rem",
                flexGrow: 0,
                flexShrink: 0,
              }}
            >
              <WishlinkTypography fontSize="0.812rem" fontWeight={600}>
                2
              </WishlinkTypography>
            </div>
            <WishlinkTypography
              fontSize="0.75rem"
              fontWeight={400}
              color={colors.grey[700]}
              className="text-left pr-4"
            >
              In your Instagram app, go to Privacy {">"} Messages and allow
              access to messages
            </WishlinkTypography>
          </div>
          <div className="flex align-center justify-start mt-5">
            <div
              className="mr-3 flex justify-center align-center"
              style={{
                background: "#FFDCBC",
                borderRadius: "50%",
                width: "2rem",
                height: "2rem",
                flexGrow: 0,
                flexShrink: 0,
              }}
            >
              <WishlinkTypography fontSize="0.812rem" fontWeight={600}>
                3
              </WishlinkTypography>
            </div>
            <WishlinkTypography
              fontSize="0.75rem"
              fontWeight={400}
              color={colors.grey[700]}
              className="text-left pr-4"
            >
              Click on Enable Wishlink Engage and provide us the required
              permissions
            </WishlinkTypography>
          </div>
          <div className="flex align-center justify-start mt-5">
            <div
              className="mr-3 flex justify-center align-center"
              style={{
                background: "#FFDCBC",
                borderRadius: "50%",
                width: "2rem",
                height: "2rem",
                flexGrow: 0,
                flexShrink: 0,
              }}
            >
              <WishlinkTypography fontSize="0.812rem" fontWeight={600}>
                4
              </WishlinkTypography>
            </div>
            <WishlinkTypography
              fontSize="0.75rem"
              fontWeight={400}
              color={colors.grey[700]}
              className="text-left pr-4"
            >
              Set up multiple reply templates and keywords on which the DMs are
              sent
            </WishlinkTypography>
          </div>
          <WishlinkTypography
            fontSize="0.75rem"
            fontWeight={400}
            color={colors.grey[700]}
            lineHeight="1rem"
            className="text-left pt-5"
            style={{ paddingLeft: "2.8rem" }}
          >
            Don't forget to add #wishlinked to every post
          </WishlinkTypography>
          <div style={{ marginTop: "2rem", marginBottom: "2rem" }}>
            <HowToActivateEngage />
          </div>
          {creatorData?.data?.data?.username === "wishlinksrishti" ? (
            <WishlinkButton
              // borderRadius="1.75rem"
              height="2.75rem"
              className="mt-3 mb-3"
            >
              <img
                alt=""
                src={`${IMAGE_PREFIX}facebook-button-background.png`}
                style={{ maxWidth: "100%", maxHeight: "5rem" }}
                onClick={() => onClickFacebookButton()}
              />
            </WishlinkButton>
          ) : (
            <WishlinkButton
              background={colors.red[200]}
              borderRadius="1.75rem"
              height="2.75rem"
              className="mt-3"
              style={{
                ":hover": {
                  bgcolor: colors.red[200],
                },
              }}
              onClick={() => onClickFacebookButton()}
            >
              <div className="flex align-center justify-between w-100 pl-4 pr-4">
                <div className="flex align-center justify-start">
                  <img
                    src={`${IMAGE_PREFIX}wl-logo-white.svg`}
                    className="mr-2"
                    alt=""
                  />
                  <WishlinkTypography
                    fontSize="0.875rem"
                    fontWeight={500}
                    color={colors.grey[0]}
                  >
                    Enable Wishlink Engage
                  </WishlinkTypography>
                </div>
                <img
                  src={`${IMAGE_PREFIX}arrow-right.svg`}
                  className="pl-4"
                  alt="lock"
                />
              </div>
            </WishlinkButton>
          )}
        </div>
      </WishlinkDrawer>
    </>
  );
};

export default SocialMediaDrawer;
