import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  product: null,
  productLoading: false,
  productTriggers: null,
  productVariants: [],
  productVariantsLoading: false,
  selectedVariant: null,
  productColorOptions: [],
  productColorOptionsLoading: false,
  sizeChart: "",
  isBarter: false,
  collectionIdOfCurrentProduct: null,
};

export const sourcingProductSlice = createSlice({
  name: "Product",
  initialState: initialState,
  reducers: {
    updateSourcingProduct(state, action) {
      state.product = action.payload;
    },
    updateSourcingProductVariants(state, action) {
      state.productVariants = action.payload;
    },
    setProductVariantsLoading(state, action) {
      state.productVariantsLoading = action.payload;
    },
    updateSourcingProductLoading(state, action) {
      state.productLoading = action.payload;
    },
    updateSourcingProductTriggers(state, action) {
      state.productTriggers = action.payload;
    },
    updateSourcingProductColorOptions(state, action) {
      state.productColorOptions = action.payload;
    },
    updateSourcingProductColorOptionsLoading(state, action) {
      state.productColorOptionsLoading = action.payload;
    },
    setSelectedVariant(state, action) {
      state.selectedVariant = action.payload;
    },
    setSizeChart(state, action) {
      state.sizeChart = action.payload;
    },
    resetProductPage(state, action) {
      state.product = null;
      state.productVariants = [];
      state.selectedVariant = null;
      state.productColorOptions = [];
      state.sizeChart = "";
    },
    setProductBarter(state, action) {
      state.isBarter = action.payload;
    },
    setCollectionIdOfCurrentProduct(state, action) {
      state.collectionIdOfCurrentProduct = action.payload;
    },
  },
});

export const {
  updateSourcingProduct,
  updateSourcingProductVariants,
  setProductVariantsLoading,
  updateSourcingProductLoading,
  updateSourcingProductTriggers,
  updateSourcingProductColorOptions,
  updateSourcingProductColorOptionsLoading,
  setSelectedVariant,
  setSizeChart,
  resetProductPage,
  setProductBarter,
  setCollectionIdOfCurrentProduct,
} = sourcingProductSlice.actions;
