import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";

import { useContext, useEffect, useState } from "react";
import { APIContext } from "../../apiLayer";
import ProductTourOverlay from "../ProductTourOverlay/ProductTourOverlay";
import { triggerGa } from "../../utils/gaUtils";
import {
  GA_ACTION,
  GA_CATEGORIES,
  GA_PAGE,
  GA_SCREEN_NAME,
  IMAGE_PREFIX,
  OVERLAY_NAMES,
  PAGE_URLS,
} from "../../utils/constants";

const EnableEngageSTKHeader = () => {
  const { milestones } = useSelector((state) => state.appReducer);
  const { creatorData } = useSelector((state) => state.apiDataReducer);
  const navigate = useNavigate();
  const apis = useContext(APIContext);
  const { overlays } = useSelector((state) => state.apiDataReducer);

  const [showOverlays, setShowOverlays] = useState(false);

  const navigateLocked = Boolean(
    !milestones?.check_creator_social_login_complete?.achieved ||
      !milestones?.check_first_post_milestone?.achieved ||
      !creatorData?.data?.data?.is_connected_insta
  );
  const ctkNotConnected = Boolean(
    navigateLocked || !creatorData?.data?.data?.is_connected_ctk
  );

  const handleBackButton = ({ isNavigating }) => {
    const fallbackUrl = localStorage.getItem("engageFallbackUrl");
    if (fallbackUrl) {
      localStorage.removeItem("engageFallbackUrl");
      navigate(PAGE_URLS.HOME, { replace: true });
    } else if (!isNavigating) navigate(-1);
  };

  const onClickBackButton = () => {
    handleBackButton({ isNavigating: false });
  };

  const onClickSettings = () => {
    triggerGa({
      category: GA_CATEGORIES.NAVIGATION,
      action: GA_ACTION.BUTTON_CLICK,
      label: "",
      page: GA_PAGE.ENGAGE,
      screenName: GA_SCREEN_NAME.ENGAGE,
    });
    navigate(PAGE_URLS.ENGAGE);
  };

  useEffect(() => {
    apis.getOverlayData({
      overlays: [OVERLAY_NAMES.ENGAGE_OVERLAY],
    });
  }, []);

  useEffect(() => {
    if (overlays?.length) {
      setTimeout(() => setShowOverlays(true), 1000);
    } else {
      setShowOverlays(false);
    }
  }, [overlays]);

  return (
    <div className="flex align-center justify-between p-4">
      <div className="flex align-center justify-between">
        <img
          src={`${IMAGE_PREFIX}leftIcon.svg`}
          onClick={() => onClickBackButton()}
          className="pr-5"
          alt=""
        />
        <img
          src={`${IMAGE_PREFIX}engage-ctk-header-text.png`}
          className="pr-2 mt-2"
          style={{ height: "1.5rem" }}
          alt=""
        />
      </div>
      {ctkNotConnected ? (
        <img
          src={`${IMAGE_PREFIX}settings.svg`}
          className="pr-2 mt-2"
          style={{ height: "1.5rem" }}
          alt=""
        />
      ) : (
        <img
          src={`${IMAGE_PREFIX}settings-enabled.svg`}
          className="mr-2 mt-2 overlay-engage-settings-highlight"
          onClick={() => onClickSettings()}
          style={{ height: "1.5rem" }}
          alt=""
        />
      )}
      {showOverlays && (
        <ProductTourOverlay
          isVisible={showOverlays}
          overlays={overlays}
          onCompletion={() => {
            setShowOverlays(false);
          }}
          showSteps={false}
        />
      )}
    </div>
  );
};

export default EnableEngageSTKHeader;
