// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.main-content {
  padding: 20px;
  width: inherit;
}

.overlay-content {
  border-radius: 5px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  position: fixed;
  bottom: 0%;
  left: 50%;
  transform: translateX(-50%);
  padding: 20px;
  text-align: center;
  width: inherit;
  background: linear-gradient(rgba(179, 179, 179, 0.02) 0%, rgba(20, 20, 20, 0.48) 46.88%, rgb(0, 0, 0) 100%);
}

.rectangle-wrapper {
  background: linear-gradient(180deg, rgb(227, 129, 37) 0%, rgba(227, 129, 37, 0.6) 100%);
  border-radius: 10px;
  height: 1.25rem;
  position: relative;
  width: 1.25rem;
}

.close-btn {
  position: absolute;
  top: 0.2rem;
  right: 0.2rem;
  padding: 2px 7px;
  cursor: pointer;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 50%;
}`, "",{"version":3,"sources":["webpack://./src/components/Brands/Overlay.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,cAAc;AAChB;;AAEA;EACE,kBAAkB;EAClB,0CAA0C;EAC1C,eAAe;EACf,UAAU;EACV,SAAS;EACT,2BAA2B;EAC3B,aAAa;EACb,kBAAkB;EAClB,cAAc;EACd,2GAA2G;AAC7G;;AAEA;EACE,uFAAuF;EACvF,mBAAmB;EACnB,eAAe;EACf,kBAAkB;EAClB,cAAc;AAChB;;AAEA;EACE,kBAAkB;EAClB,WAAW;EACX,aAAa;EACb,gBAAgB;EAChB,eAAe;EACf,oCAAoC;EACpC,kBAAkB;AACpB","sourcesContent":[".main-content {\n  padding: 20px;\n  width: inherit;\n}\n\n.overlay-content {\n  border-radius: 5px;\n  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);\n  position: fixed;\n  bottom: 0%;\n  left: 50%;\n  transform: translateX(-50%);\n  padding: 20px;\n  text-align: center;\n  width: inherit;\n  background: linear-gradient(rgba(179, 179, 179, 0.02) 0%, rgba(20, 20, 20, 0.48) 46.88%, rgb(0, 0, 0) 100%);\n}\n\n.rectangle-wrapper {\n  background: linear-gradient(180deg, rgb(227, 129, 37) 0%, rgba(227, 129, 37, 0.6) 100%);\n  border-radius: 10px;\n  height: 1.25rem;\n  position: relative;\n  width: 1.25rem;\n}\n\n.close-btn {\n  position: absolute;\n  top: 0.2rem;\n  right: 0.2rem;\n  padding: 2px 7px;\n  cursor: pointer;\n  border: 1px solid rgba(0, 0, 0, 0.2);\n  border-radius: 50%;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
