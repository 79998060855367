import { useState, useRef } from "react";
import { ToolTipRightWarper, ToolTipLeftWarper } from "./ToolTip.styles";
import { useClickOutSide } from "../../hooks/useClickOutSide";

export const ToolTip = ({
  component,
  children,
  onClick,
  placement = "right",
}) => {
  const toolTipRef = useRef(null);
  const [visible, setVisible] = useState(false);
  const handleClick = ({ visible }) => {
    setVisible(visible);
    if (onClick && visible) {
      onClick();
    }
  };
  useClickOutSide(toolTipRef, () => handleClick({ visible: false }));

  return (
    <>
      {placement === "left" ? (
        <ToolTipLeftWarper ref={toolTipRef}>
          {visible && <div className="tooltipContainer">{component}</div>}
          <div onClick={() => handleClick({ visible: true })}>{children}</div>
        </ToolTipLeftWarper>
      ) : (
        <ToolTipRightWarper ref={toolTipRef}>
          {visible && <div className="tooltipContainer">{component}</div>}
          <div onClick={() => handleClick({ visible: true })}>{children}</div>
        </ToolTipRightWarper>
      )}
    </>
  );
};
