import { createSlice } from "@reduxjs/toolkit";
import {
  filterOptionsInitialState,
  filterOptionsSelectedInitialState,
  initialParameters,
  SORT_BY_CATEGORIES,
  sortByOptionsInitialState,
} from "../../utils/constants";

const initialState = {
  isResetPageOnOpen: true,
  collectionDetails: null,
  productsLoading: true,
  moreProductsLoading: true,
  page: 1,
  maxProductPages: 1,
  collectionLoading: true,
  searchText: "",
  collectionTriggers: null,
  parameters: initialParameters,
  sortByOptions: sortByOptionsInitialState,
  sortByOptionSelected: SORT_BY_CATEGORIES.WHATS_NEW,
  filterOptionsLoading: true,
  filterOptions: filterOptionsInitialState,
  filterOptionsSelected: filterOptionsSelectedInitialState,
};

export const collectionsSlice = createSlice({
  name: "sourcingCollections",
  initialState: initialState,
  reducers: {
    setResetPageOnCollectionOpen(state, action) {
      state.isResetPageOnOpen = action.payload;
    },
    updateSourcingCollectionDetails(state, action) {
      state.collectionDetails = action.payload;
    },
    updateSourcingCollectionHeader(state, action) {
      state.collectionHeader = action.payload;
    },
    updateSourcingCollectionProducts(state, action) {
      state.maxPrice = action.payload?.max_price;
      state.minPrice = action.payload?.min_price;
      state.maxProductPages = action.payload?.num_pages;
    },
    increaseSourcingCollectionProductPage(state, action) {
      state.page = state.page + 1;
    },
    updateSourcingCollectionLoading(state, action) {
      state.collectionLoading = action.payload;
    },
    updateSourcingCollectionProductsLoading(state, action) {
      state.productsLoading = action.payload;
    },
    updateSourcingCollectionMoreProductsLoading(state, action) {
      state.moreProductsLoading = action.payload;
    },
    setSearchText(state, action) {
      state.searchText = action.payload;
      state.page = 1;
    },
    updateCollectionTriggers(state, action) {
      state.collectionTriggers = action.payload;
    },
    setSortByOptionSelected(state, action) {
      state.page = 1;
      state.sortByOptionSelected = action.payload;
    },
    resetCollectionPage(state, action) {
      state.productsLoading = true;
      state.isResetPageOnOpen = true;
      state.collectionDetails = null;
      state.products = [];
      state.productPage = 1;
      state.maxProductPages = 1;
      state.searchText = "";
      state.page = 1;

      state.parameters = initialParameters;
      state.filterOptions = filterOptionsInitialState;
      state.filterOptionsSelected = filterOptionsSelectedInitialState;
      state.sortByOption = sortByOptionsInitialState;
      state.sortByOptionSelected = SORT_BY_CATEGORIES.WHATS_NEW;
    },
    resetCollectionProducts(state, action) {
      state.products = [];
      state.productPage = 1;
      state.maxProductPages = 1;
    },
    setParameters(state, action) {
      state.parameters = action.payload;
    },

    // FILTER OPTIONS
    setFilterOptionsLoading(state, action) {
      state.filterOptionsLoading = action.payload;
    },
    updateSourcingFilterSizeOptions(state, action) {
      state.filterOptions[0].options = action.payload;
    },
    updateSourcingFilterColorOptions(state, action) {
      state.filterOptions[1].options = action.payload;
    },
    updateSourcingFilterBrandOptions(state, action) {
      state.filterOptions[2].options = action.payload;
    },
    updateSourcingFilterCategoriesOptions(state, action) {
      state.filterOptions[3].options = action.payload;
    },
    updateSourcingFilterDiscountOptions(state, action) {
      state.filterOptions[4].options = action.payload;
    },
    setFilterOptionsSelected(state, action) {
      state.filterOptionsSelected = action.payload;
      state.page = 1;
    },
    setPriceRangeFilter(state, action) {
      state.filterOptionsSelected[4] = {
        value: "price",
        options: action.payload,
      };
      state.page = 1;
    },
  },
});

export const {
  setResetPageOnCollectionOpen,
  updateSourcingCollectionDetails,
  updateSourcingCollectionProducts,
  increaseSourcingCollectionProductPage,
  updateSourcingCollectionLoading,
  updateSourcingCollectionProductsLoading,
  updateSourcingCollectionMoreProductsLoading,
  setSearchText,
  updateCollectionTriggers,
  setSortByOptionSelected,
  resetCollectionPage,
  resetCollectionProducts,
  setParameters,
  setPriceRangeFilter,
  setFilterOptionsLoading,
  updateSourcingFilterSizeOptions,
  updateSourcingFilterColorOptions,
  updateSourcingFilterBrandOptions,
  updateSourcingFilterCategoriesOptions,
  updateSourcingFilterDiscountOptions,
  setFilterOptionsSelected,
} = collectionsSlice.actions;
