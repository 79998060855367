import { useNavigate } from "react-router-dom";
import { useTheme } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";

import { tokens } from "../../theme";

import WishlinkButton from "../../WishlinkComponents/WishlinkButton";
import WishlinkDrawer from "../../WishlinkComponents/WishlinkDrawer";
import WishlinkLottie from "../../WishlinkComponents/WishlinkLottieLazyLoad";
import WishlinkTypography from "../../WishlinkComponents/WishlinkTypography";
import EngageActivationMilestones from "./EngageActivationMilestones";

import { IMAGE_PREFIX, PAGE_URLS } from "../../utils/constants";
import { updateIsDiyAllowed } from "../../redux/redux";

const EngageLockedDrawer = (props) => {
  const { lockedDrawerState } = props;
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  const { creatorSocialLoginStatus } = useSelector(
    (state) => state.apiDataReducer
  );
  const { creatorData } = useSelector((state) => state.apiDataReducer);
  const redirect = creatorData?.data?.data?.is_connected_insta
    ? "#instagram"
    : "#youtube";

  const isInstagramLoggedIn =
    creatorSocialLoginStatus?.data?.data?.instagram_logged_in;
  const buttonlabel = isInstagramLoggedIn
    ? "Create Post Wishlink"
    : "Connect Instagram";

  const handleNavigation = () => {
    dispatch(updateIsDiyAllowed(true));
    window.open(PAGE_URLS.NEWPOST + redirect, "_self");
  };

  return (
    <>
      <WishlinkDrawer
        drawerOpen={lockedDrawerState}
        onClose={() => navigate(-1, { replace: true })}
        onOpen={() => {}}
      >
        <div className="p-4">
          <WishlinkLottie
            play={true}
            loop={true}
            path={`${IMAGE_PREFIX}locked-icon.json`}
            style={{
              width: "20%",
              position: "relative",
              left: "50%",
              transform: `translateX(-50%)`,
              marginTop: "1rem",
            }}
          />
          <WishlinkTypography
            fontSize="1rem"
            fontWeight={600}
            color={colors.grey[100]}
            className="text-center"
          >
            ENGAGE is locked
          </WishlinkTypography>
          <EngageActivationMilestones />
          <WishlinkButton
            background={colors.red[500]}
            className="pt-5 mb-3"
            height="3rem"
            width="100%"
            borderRadius="0.5rem"
            buttonClassName="bs-2"
            style={{
              ":hover": {
                bgcolor: colors.red[500],
              },
            }}
            onClick={handleNavigation}
          >
            <WishlinkTypography color={colors.grey[0]}>
              {buttonlabel}
            </WishlinkTypography>
          </WishlinkButton>
        </div>
      </WishlinkDrawer>
    </>
  );
};

export default EngageLockedDrawer;
