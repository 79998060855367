import React from "react";
import { useTheme } from "@mui/material";

import { tokens } from "../../../theme";
// components
import WishlinkSwitch from "../../../WishlinkComponents/WishlinkSwitch";
import WishlinkTypography from "../../../WishlinkComponents/WishlinkTypography";
import { getAssetUrl } from "../../../utils/common";

const EngageComments = ({ checked, onChange, title, subtitle, disabled }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  const statusColor = checked ? colors.green[500] : colors.red[500];
  const statusText = checked ? ": ON" : ": OFF";
  const statusIcon = checked ? "confirmed-check.svg" : "engage-info-alert.svg";

  return (
    <div>
      <div className="flex align-center justify-between">
        <div className="flex">
          <img src={getAssetUrl(statusIcon)} alt="icon" />
          <WishlinkTypography
            fontSize="0.75rem"
            fontWeight={400}
            color={colors.grey[100]}
            ml="0.4rem"
            mr="0.2rem"
          >
            {title}
          </WishlinkTypography>
          <WishlinkTypography
            fontSize="0.75rem"
            fontWeight={500}
            color={statusColor}
          >
            {statusText}
          </WishlinkTypography>
        </div>
        <WishlinkSwitch
          disabled={disabled}
          checked={checked}
          onChange={onChange}
        />
      </div>
      {!checked && (
        <WishlinkTypography
          fontSize="0.625rem"
          fontWeight={400}
          fontStyle="italic"
          color={"#7F7F7F"}
          textAlign="left"
          ml="1.4rem"
        >
          {subtitle}
        </WishlinkTypography>
      )}
    </div>
  );
};

export default EngageComments;
