import React, { useState } from "react";

// components
import WishlinkTypography from "../../../WishlinkComponents/WishlinkTypography";
// utils
import { getAssetUrl } from "../../../utils/common";
import {
  GA_ACTION,
  GA_CATEGORIES,
  GA_PAGE,
  GA_SCREEN_NAME,
} from "../../../utils/constants";
import { triggerGa } from "../../../utils/gaUtils";

const EducationCard = () => {
  const [expand, setExpanded] = useState(false);

  const handleClick = () => {
    triggerGa({
      category: GA_CATEGORIES.USER_EDUCATION,
      action: GA_ACTION.BUTTON_CLICK,
      label: expand ? "readless" : "readmore",
      page: GA_PAGE.ANALYTICS,
      screenName: GA_SCREEN_NAME.MONTHLY_ORDER_STATUS,
    });
    setExpanded((prev) => !prev);
  };
  return (
    <div
      style={{
        border: `1px solid #144CC7`,
        background: "#C2D0F2",
        borderRadius: "0.5rem",
        padding: "0.75rem",
      }}
    >
      <div
        className="flex "
        style={{
          gap: "0.4rem",
          marginBottom: "0.5rem",
        }}
      >
        <img src={getAssetUrl("question-icon-blue.svg")} alt="icon" />
        <WishlinkTypography
          fontSize="0.75rem"
          color={"#000000"}
          fontWeight={500}
          textAlign={"left"}
        >
          When will my pending commissions be confirmed?
        </WishlinkTypography>
      </div>
      {expand && (
        <div>
          <WishlinkTypography
            fontSize="0.625rem"
            color={"#000000"}
            fontWeight={400}
            textAlign={"left"}
            mb={"0.5rem"}
          >
            Brands usually take 1-2 months to confirm the status of your orders,
            including{" "}
            <i>
              {" "}
              <strong>Returns, Cancellations, or Confirmations</strong>
            </i>
          </WishlinkTypography>
          <WishlinkTypography
            fontSize="0.625rem"
            color={"#000000"}
            fontWeight={400}
            textAlign={"left"}
            mb={"0.5rem"}
          >
            Orders are placed throughout the month, and different brands &
            orders have different timelines for delivery and returns. This
            causes delays in reporting status by the brand. You can check
            brand-wise pending commissions under Payouts section
          </WishlinkTypography>
        </div>
      )}
      <WishlinkTypography
        fontWeight="500"
        fontSize="0.625rem"
        color={"#2C5ECD"}
        textAlign={"left"}
        style={{
          textDecoration: "underline",
          textDecorationColor: "#2C5ECD",
        }}
        onClick={handleClick}
        dataCy={"education-expand-button"}
      >
        {expand ? "Read less..." : "Read more..."}
      </WishlinkTypography>
    </div>
  );
};

export default EducationCard;
