import { useDispatch } from "react-redux";
import { useEffect } from "react";
import {
  updateYoutubeShoppingData,
  updateBrandWiseYoutubeEarningsData,
  updateYoutubeShoppingDataLoading,
  updateBrandWiseYoutubeEarningsDataLoading,
  updateYtEarningsStatus,
  updateYtEarningsStatusLoading,
  updateYoutubeShoppingTriggers,
} from "./YoutubeShoppingRedux";
import {
  useLazyGetYoutubeEarningsDataQuery,
  useLazyGetBrandWiseYoutubeEarningsDataQuery,
  useUpdateShowYtEarningsForCreatorMutation,
  useLazyFetchShowYtEarningsStatusQuery,
} from "./YoutubeShoppingReduxAPI";
import { showToastNotification } from "../redux/redux";
import { getDataPointLoadingValue } from "../utils/common";

const YoutubeShoppingDataLayer = () => {
  const dispatch = useDispatch();

  const [triggerYoutubeShoppingData, resultYoutubeShoppingData] =
    useLazyGetYoutubeEarningsDataQuery();
  const [
    triggerBrandWiseYoutubeShoppingData,
    resultBrandWiseYoutubeShoppingData,
  ] = useLazyGetBrandWiseYoutubeEarningsDataQuery();
  const [triggerShowYtEarningsForCreator] =
    useUpdateShowYtEarningsForCreatorMutation();
  const [triggerFetchShowYtEarningsStatus, resultFetchShowYtEarningsStatus] =
    useLazyFetchShowYtEarningsStatusQuery();

  const trigger = {
    getYoutubeEarningsData: async (apiCallParams, cache = true) =>
      await triggerYoutubeShoppingData(apiCallParams, cache),
    getBrandWiseYoutubeEarningsData: async (apiCallParams, cache = true) =>
      await triggerBrandWiseYoutubeShoppingData(apiCallParams, cache),
    updateShowYtEarningsForCreator: async (apiCallParams) =>
      await triggerShowYtEarningsForCreator(apiCallParams),
    fetchShowYtEarningsStatus: async (apiCallParams) =>
      await triggerFetchShowYtEarningsStatus(apiCallParams),
  };

  useEffect(() => {
    dispatch(updateYoutubeShoppingTriggers(trigger));
  }, []);

  useEffect(() => {
    if (resultYoutubeShoppingData?.data?.success === false) {
      showToastNotification({
        variant: "error",
        message: resultYoutubeShoppingData?.data?.msg,
      });
      return;
    }
    dispatch(
      updateYoutubeShoppingDataLoading(
        getDataPointLoadingValue(resultYoutubeShoppingData)
      )
    );
    dispatch(updateYoutubeShoppingData(resultYoutubeShoppingData));
  }, [resultYoutubeShoppingData]);

  useEffect(() => {
    if (resultBrandWiseYoutubeShoppingData?.data?.success === false) {
      showToastNotification({
        variant: "error",
        message: resultBrandWiseYoutubeShoppingData?.data?.msg,
      });
      return;
    }
    dispatch(
      updateBrandWiseYoutubeEarningsDataLoading(
        getDataPointLoadingValue(resultBrandWiseYoutubeShoppingData)
      )
    );
    dispatch(
      updateBrandWiseYoutubeEarningsData(resultBrandWiseYoutubeShoppingData)
    );
  }, [resultBrandWiseYoutubeShoppingData]);

  useEffect(() => {
    if (resultFetchShowYtEarningsStatus?.data?.success === false) {
      showToastNotification({
        variant: "error",
        message: resultFetchShowYtEarningsStatus?.data?.msg,
      });
      return;
    }
    dispatch(
      updateYtEarningsStatusLoading(
        getDataPointLoadingValue(resultFetchShowYtEarningsStatus)
      )
    );
    dispatch(updateYtEarningsStatus(resultFetchShowYtEarningsStatus));
  }, [resultFetchShowYtEarningsStatus]);

  return <></>;
};

export default YoutubeShoppingDataLayer;
