import { useNavigate } from "react-router-dom";
import {
  GA_ACTION,
  GA_CATEGORIES,
  IMAGE_PREFIX,
  PAGE_URLS,
} from "../../utils/constants";
import WishlinkTypography from "../../WishlinkComponents/WishlinkTypography";
import { useSelector } from "react-redux";
import { triggerGa } from "../../utils/gaUtils";

const EngageCommentShareHeader = (props) => {
  const {
    headerBgColor,
    starImage,
    titleText,
    engageHeaderDetails,
    engageHeaderGif,
    page,
  } = props;
  const { milestones } = useSelector((state) => state.appReducer);
  const { creatorData } = useSelector((state) => state.apiDataReducer);
  const navigate = useNavigate();
  const navigateLocked = Boolean(
    !milestones?.check_creator_social_login_complete?.achieved ||
      !milestones?.check_first_post_milestone?.achieved ||
      !creatorData?.data?.data?.is_connected_insta
  );
  const ctkNotConnected = Boolean(
    !navigateLocked && !creatorData?.data?.data?.is_connected_ctk
  );

  const onClickEngageSettings = () => {
    triggerGa({
      category: GA_CATEGORIES.NAVIGATION,
      action: GA_ACTION.BUTTON_CLICK,
      label: "",
      page: page,
      screenName: page,
    });
    navigate(PAGE_URLS.ENGAGE);
  };

  return (
    <div className="pb-4" style={{ background: headerBgColor }}>
      <div className="flex align-center justify-between p-4">
        <img
          src={`${IMAGE_PREFIX}leftIcon.svg`}
          onClick={() => {
            navigate(-1);
          }}
          alt=""
        />
        {ctkNotConnected || navigateLocked ? (
          <img src={`${IMAGE_PREFIX}settings.svg`} alt="" />
        ) : (
          <img
            src={`${IMAGE_PREFIX}settings-enabled.svg`}
            onClick={() => onClickEngageSettings()}
            style={{ height: "1.5rem" }}
            alt=""
          />
        )}
      </div>
      <div className="text-left">
        <img
          src={`${IMAGE_PREFIX}${starImage}`}
          style={{ height: "3.75rem" }}
          alt=""
        />
      </div>
      <img src={`${IMAGE_PREFIX}${engageHeaderGif}`} className="w-100" alt="" />
      <WishlinkTypography
        className="p-4 ml-6 mr-6"
        fontSize="1.375rem"
        fontWeight={600}
        color="#000"
      >
        {titleText}
      </WishlinkTypography>
      {engageHeaderDetails()}
    </div>
  );
};

export default EngageCommentShareHeader;
