import { configureStore } from "@reduxjs/toolkit";
import { createSlice } from "@reduxjs/toolkit";
import { setupListeners } from "@reduxjs/toolkit/query";
import { DATE_PRESETS } from "../utils/constants";
import { reduxAPI } from "./reduxAPI";
import { miniSourcingAuthAPI } from "./miniSourcingAuthAPI";
import { LOGIN_DRAWER_STAGES, SOCIAL_LOGIN_STATES } from "../utils/constants";
import {
  formatDate,
  getStartAndEndDateFromPreset,
  triggerGAUserId,
  triggerGAPlatform,
} from "../utils/common";
import { setUserPropertiesGa } from "../utils/gaUtils";
import { getDataPointLoadingValue } from "../utils/common";
import { collectionsSlice } from "../sourcing/views/Collections/collectionsRedux";
import { sourcingCollectionsReduxAPI } from "../sourcing/views/Collections/collectionsReduxAPI";
import { sourcingHomeReduxAPI } from "../sourcing/views/Home/homeReduxAPI";
import { sourcingHomeSlice } from "../sourcing/views/Home/homeRedux";
import { sourcingCartSlice } from "../sourcing/views/Cart/cartRedux";
import { sourcingCartReduxAPI } from "../sourcing/views/Cart/cartReduxAPI";
import { sourcingWishlistReduxAPI } from "../sourcing/views/Wishlist/wishlistReduxAPI";
import { sourcingWishlistSlice } from "../sourcing/views/Wishlist/wishlistRedux";
import { sourcingMyOrdersSlice } from "../sourcing/views/MyOrders/myOrdersRedux";
import { sourcingMyOrdersReduxAPI } from "../sourcing/views/MyOrders/myOrdersReduxAPI";
import { sourcingProductSlice } from "../sourcing/views/Product/productRedux";
import { sourcingProductReduxAPI } from "../sourcing/views/Product/productReduxAPI";
import { RecommendedCollectionSlice } from "../RecommendedCollectionsStore/RecommendedCollectionsRedux";
import { recommendedCollectionsReduxAPI } from "../RecommendedCollectionsStore/RecommendedCollectionsReduxAPI";
import { sourcingCreditsReduxAPI } from "../sourcing/views/Credits/creditsReduxAPI";
import { sourcingCreditsSlice } from "../sourcing/views/Credits/creditsRedux";
import { YoutubeShoppingSlice } from "../YoutubeShoppingStore/YoutubeShoppingRedux";
import { youtubeShoppingReduxAPI } from "../YoutubeShoppingStore/YoutubeShoppingReduxAPI";
import { socialMediaProfileSlice } from "../ProfileDataLayer/ProfileRedux";
import { loginOtpSlice } from "../LoginDataLayer/LoginRedux";
import { profileReduxAPI } from "../ProfileDataLayer/ProfileReduxAPI";
import { homePageSlice } from "../HomePageDataLayer/HomePageRedux";
import { homePageReduxAPI } from "../HomePageDataLayer/HomePageReduxAPI";
import { BrandsPageSlice } from "../BrandsPageDataLayer/BrandsPageRedux";
import { BrandsPageReduxAPI } from "../BrandsPageDataLayer/BrandsPageReduxAPI";

const initialState = {
  hello: "hello",
  showPopup: false,
  showCongratulationsPopup: false,
  milestones: [],
  milestonesLoading: false,
  isUserFromDiy: false,
  mediaReels: [],
  isDiyAllowed: true,
  usernameConfirmDrawerState: false,
  sharePostText: "",
  pageName: "",
  screenName: "",
  meeshoBannerToggled: false,
  productFinds: {},
  isMyntraCreator: false,
  oauthResponseType: "",
  oauthRedirectUri: "",
  oauthClientId: "",
  oauthCodeChallenge: "",
  onGoingOauth: false,
  oauthClientName: "",
  oauthClientNameIsLoading: false,
  loadingFreshDesk: null,
};

const initialApiDataStates = {
  meeshoCatalogPdfUrl: "",
  meeshoCatalogRedirectUrl: "",
  creatorData: {},
  creatorDataIsLoading: true,
  postsData: [],
  postsDataIsLoading: true,
  maxPosts: 0,
  collectionsData: [],
  collectionsDataIsLoading: true,
  maxCollections: 0,
  productsData: [],
  productsDataIsLoading: true,
  maxProducts: 0,
  draftsData: {},
  draftsDataIsLoading: true,
  createPostDrawer: false,
  postDrawerDetailsAndRedirections: {},
  postProductsData: {},
  postProductsDataIsLoading: true,
  shopCountsData: {},
  drawerDetailsType: "",
  newPostDrawer: false,
  instagramPostsDataIsLoading: true,
  instaPostsList: [],
  instaPostsNextPageExists: false,
  instaPostsNextPageCursor: "",
  youtubePostsDataIsLoading: true,
  youtubePostsList: [],
  youtubePostsNextPageExists: false,
  youtubePostsNextPageToken: "",
  creatorSocialLoginStatus: {},
  crossPostingConfig: {},
  filtersAndSort: {},
  postOrCollectionStatusIsLoading: false,
  newPostDataIsLoading: false,
  postRedirects: {},
  collectionRedirects: {},
  productRedirects: {},
  isScrollAllowed: true,
  postOrCollectionProductRedirects: {},
  selectedShopPagePill: "",
  postsPage: 1,
  collectionsPage: 1,
  productsPage: 1,
  onScrollYPos: 0,
  sortBy: "-added_on",
  selectedBrands: [],
  selectedChannel: [],
  filterBy: {},
  showCreatorDemoBanner: false,
  creatorDemoDataIsLoading: true,
  showCreatorWinterMeetBanner: false,
  showCreatorWinterMeetBannerIsLoading: true,
  overlays: [],
  overlaysIsLoading: true,
  suppressOverlays: false,
};

const initialDiyDataStates = {
  tags: [],
  creatorPost: {},
  productLinks: [],
  productsData: [],
  youtubeSelectedPostUrl: "",
  selectedPostObj: {},
  instagramSelectedPostUrl: "",
  creatorPostIsLoading: true,
  productLinksIsLoading: true,
  productDataIsLoading: true,
  scrapingFailureDomains: [],
  scrapingFailureDomainsIsLoading: true,
};

const initialLoginStates = {
  drawerStage: LOGIN_DRAWER_STAGES.ENTER_NUMBER,
  socialLoginState: SOCIAL_LOGIN_STATES.CONNECT_ACCOUNT,
  authCheckStatus: {},
  allowWhatsappChat: true,
};

const initialNotificationStates = {
  showToast: false,
  variant: "",
  message: "",
};

const initialBrandStates = {
  brandListIsLoading: false,
  brandList: [],
  defaultBrandsList: [],
  favoriteBrands: [],
  tags: [],
  maxBrands: 0,
  searchText: "",
  sourcingAccess: false,
  brandBanners: [],
  isBrandScrollAllowed: true,
  brandTagsIsLoading: true,
  showSpotlightBrands: false,
};

const initialHamburgerState = {
  showHamburger: false,
  usernames: [],
  isUsernamesLoading: false,
};

const initialProfileState = {
  bankDetails: {},
  bankDetailsIsLoading: true,
  creatorAddress: [],
  creatorAddressById: {},
  creatorAddressByIdLoading: false,
};

const initialEngageState = {
  ctkdetails: {},
  ctkdetailsLoading: false,
  keywords: [],
  templates: {},
  instaBasicProfileData: {},
  instaBasicProfileLoading: false,
};

const initialPayoutState = {
  lifetimeEarnings: "",
  earningsThisPayout: {},
  pastPayouts: [],
  earningsThisPayoutIsLoading: true,
  lifetimeEarningsIsLoading: true,
  pastPayoutsIsLoading: true,
};

const initialHelperState = {
  showTooltip: false,
  tooltipState: 1,
  tooltipText: `This is an individual product Wishlink. Copy & share with your audience to start earning`,
};

const initialRewardsState = {
  campaignDetails: {},
  rewardsLeaderboard: {},
  rewardsSummary: {},
};

const initialBrandRewardsState = {
  brandRewards: {},
};

const initialNykaaBrandRewardsState = {
  nykaaBrandRewards: {},
};

const initialReferralState = {
  creatorReferrals: {},
  referrerDetails: {},
  creatorRefererLedger: [],
  creatorRefererLedgerLoading: false,
  creatorReferalData: {},
};

const marqueeInitialState = {
  marqueeDetails: [],
};

const marqueeSlice = createSlice({
  name: "marquee",
  initialState: marqueeInitialState,
  reducers: {
    updateMarqueeDetails(state, action) {
      if (
        action?.payload?.data?.data !== undefined &&
        action.payload.status === "fulfilled"
      ) {
        state.marqueeDetails = action.payload.data.data;
      }
    },
  },
});

const helperSlice = createSlice({
  name: "helper",
  initialState: initialHelperState,
  reducers: {
    updateShowTooltip(state, action) {
      state.showTooltip = action.payload;
    },
    updateTooltipState(state, action) {
      state.tooltipState = action.payload;
      if (state.tooltipState === 1) {
        state.tooltipText = `This is an individual product Wishlink. Copy & share with your audience to start earning`;
      } else if (state.tooltipState === 2) {
        state.tooltipText = `This is not a Wishlink Partner Brand. You won't earn any commissions on sharing this link`;
      } else if (state.tooltipState === 3) {
        state.tooltipText = `You can track your Amazon earnings in Analytics. Product wise sales are not available for Amazon`;
      } else {
        state.tooltipText = `Find your Post Wishlink here`;
      }
    },
  },
});

const rewardsSlice = createSlice({
  name: "rewards",
  initialState: initialRewardsState,
  reducers: {
    updateCampaignDetails(state, action) {
      state.campaignDetails = action.payload;
    },
    updateRewardsLeaderboard(state, action) {
      state.rewardsLeaderboard = action.payload;
    },
    updateRewardsSummary(state, action) {
      state.rewardsSummary = action.payload;
    },
  },
});

const brandRewardsSlice = createSlice({
  name: "brandRewards",
  initialState: initialBrandRewardsState,
  reducers: {
    updateBrandRewards(state, action) {
      state.brandRewards = action.payload;
    },
  },
});

const nykaaBrandRewardsSlice = createSlice({
  name: "nykaaBrandRewards",
  initialState: initialNykaaBrandRewardsState,
  reducers: {
    updateNykaaBrandRewards(state, action) {
      state.nykaaBrandRewards = action.payload;
    },
  },
});

const payoutSlice = createSlice({
  name: "payout",
  initialState: initialPayoutState,
  reducers: {
    updateCreatorLifetimeEarnings(state, action) {
      if (
        action?.payload?.data?.data !== undefined &&
        action.payload.status === "fulfilled"
      ) {
        state.lifetimeEarnings = action.payload.data.data;
      }
      state.lifetimeEarningsIsLoading = getDataPointLoadingValue(
        action.payload
      );
    },
    updateCreatorEarningsThisPayout(state, action) {
      if (
        action?.payload?.data?.data !== undefined &&
        action.payload.status === "fulfilled"
      ) {
        state.earningsThisPayout = action.payload.data.data;
      }
      state.earningsThisPayoutIsLoading = getDataPointLoadingValue(
        action.payload
      );
    },
    updatePastPayouts(state, action) {
      if (
        action?.payload?.data?.data !== undefined &&
        action.payload.status === "fulfilled"
      ) {
        state.pastPayouts = action.payload.data.data;
      }
      state.pastPayoutsIsLoading = getDataPointLoadingValue(action.payload);
    },
  },
});

const initialAnalyticsState = {
  amazonAnalytics: {},
  brandEarningsSplit: {},
  chartAnalyticsEarnings: {},
  grossChartAnalyticsEarnings: {},
  returnsChartAnalyticsEarnings: {},
  ordersChartAnalyticsEarnings: {},
  chartAnalyticsSales: {},
  chartAnalyticsRedirections: {},
  channelEarningsSplit: {},
  collectionAnalytics: [],
  postAnalytics: [],
  productAnalytics: [],
  salesAnalytics: {},
  analyticsChartType: "day",
  donutChartType: "brand",
  analyticsChartCurrentStartDate: "",
  analyticsChartCurrentEndDate: "",
  analyticsChartPreviousPageExists: false,
  analyticsChartNextPageExists: false,
  redirectionsAnalytics: {},
  analyticsStartDate: formatDate(
    getStartAndEndDateFromPreset(DATE_PRESETS.THIS_MONTH)[0]
  ),
  analyticsEndDate: formatDate(
    getStartAndEndDateFromPreset(DATE_PRESETS.THIS_MONTH)[1]
  ),
  analyticsPreset: DATE_PRESETS.THIS_MONTH,
  maxAnalyticsPosts: 0,
  maxAnalyticsCollections: 0,
  maxAnalyticsProducts: 0,
  analyticsFilterAndSort: {},
  analyticsPostsDataIsLoading: false,
  analyticsCollectionsDataIsLoading: false,
  analyticsProductsDataIsLoading: false,
  analyticsPostsReleaseDates: [],
  analyticsFirstPostDate: formatDate(
    getStartAndEndDateFromPreset(DATE_PRESETS.LAST_3_MONTHS)[0]
  ),
  rewardsAnalytics: {},
  postOrCollectionLifetimeFunnel: {},
  postOrCollectionEarnings: {},
  postOrCollectionProductEarnings: {},
  analyticsSortAndFilters: {},
  analyticsChartLoading: true,
  affiliateProductAnalytics: {},
  allowNextPageAPIAnalytics: true,
  creatorHasNoRedirections: false,
  analyticsSortBy: "-added_on",
  analyticsSelectedBrand: [],
  analyticsSelectedChannel: [],
  selectedAnalyticsPagePill: "",
  analyticsPostsPage: 1,
  analyticsCollectionsPage: 1,
  analyticsProductsPage: 1,
  analyticsOnScrollYPos: 0,
  analyticsFilterBy: {},
  orderSummary: [],
  maxOrderPages: 0,
  isOrderScrollAllowed: true,
  orderHistoryForDate: [],
  orderSummaryLoading: false,
  orderHistoryForDateLoading: false,
  minScaleEarnings: 0,
  maxScaleEarnings: 1000,
  minScaleRedirections: 0,
  maxScaleRedirections: 1000,
  minScaleOrders: 0,
  maxScaleOrders: 1000,
  postOrCollectionLifetimeLinkClicks: {},
  postOrCollectionLifetimeClicksLoading: false,
  pastPayoutsList: [],
};

const initialNotificationListStates = {
  notificationsList: [],
  notificationsListLoading: false,
  bellIconLoading: false,
  earlierNotificationsList: [],
  notifPage: 0,
  maxNotifPage: 0,
  isNotificationScrollAllowed: true,
  bellIconStatus: {},
};

const initialHomePageUtilitiesState = {
  exploreBanners: [],
};

const notificationsListSlice = createSlice({
  name: "notifications",
  initialState: initialNotificationListStates,
  reducers: {
    updateNotificatonsListLoading(state, action) {
      state.notificationsListLoading = action.payload;
    },
    updateBellIconLoading(state, action) {
      state.bellIconLoading = action.payload;
    },
    updateNotificationsList(state, action) {
      if (
        action?.payload?.data?.data !== undefined &&
        action?.payload?.data?.data?.success === true
      ) {
        const { notifications } = action.payload.data.data;
        state.notificationsList = notifications;
      }
    },
    updateEarlierNotificationsList(state, action) {
      if (
        action?.payload?.data?.data !== undefined &&
        action?.payload?.data?.data?.success === true
      ) {
        const { notifications, num_pages, page } = action.payload.data.data;
        state.maxNotifPage = num_pages;
        state.notifPage = page;
        if (page === 1) state.earlierNotificationsList = notifications;
        else
          state.earlierNotificationsList = [
            ...state?.earlierNotificationsList,
            ...notifications,
          ];
      }
    },
    updateIsNotificationScrollAllowed(state, action) {
      state.isNotificationScrollAllowed = action.payload;
    },
    updateBellIconStatus(state, action) {
      if (
        action?.payload?.data?.data !== undefined &&
        action?.payload?.data?.data?.success === true
      ) {
        state.bellIconStatus = action?.payload?.data?.data;
      }
    },
    appendNewSocketNotification(state, action) {
      state.notificationsList = [action.payload, ...state.notificationsList];
    },
    updateSingleNotification(state, action) {
      let index;
      index = state.notificationsList.findIndex((notif) => {
        return notif.id === action.payload;
      });
      if (index !== -1) state.notificationsList[index].is_read = true;
      else {
        index = state.earlierNotificationsList.findIndex((notif) => {
          return notif.id === action.payload;
        });
        state.earlierNotificationsList[index].is_read = true;
      }
    },
  },
});

const analyticsSlice = createSlice({
  name: "analytics",
  initialState: initialAnalyticsState,
  reducers: {
    updateAmazonAnalytics(state, action) {
      state.amazonAnalytics = action.payload;
    },
    updateBrandWiseEarningsSplit(state, action) {
      state.brandEarningsSplit = action.payload;
    },
    updateAnalyticsChart(state, action) {
      state.chartAnalyticsEarnings = action.payload?.data?.data?.earnings_dict;
      state.grossChartAnalyticsEarnings =
        action.payload?.data?.data?.gross_earnings_dict;
      state.returnsChartAnalyticsEarnings =
        action.payload?.data?.data?.returns_earnings_dict;
      state.ordersChartAnalyticsEarnings =
        action.payload?.data?.data?.num_orders_dict;
      state.chartAnalyticsRedirections =
        action.payload?.data?.data?.redirections_dict;
      state.chartAnalyticsSales = action.payload?.data?.data?.sales_dict;
      state.analyticsChartNextPageExists =
        action.payload?.data?.data?.next_page_exists;
      state.analyticsChartPreviousPageExists =
        action.payload?.data?.data?.previous_page_exists;
      state.analyticsChartCurrentStartDate =
        action.payload?.data?.data?.start_date;
      state.analyticsChartCurrentEndDate = action.payload?.data?.data?.end_date;
      state.creatorHasNoRedirections =
        action.payload?.data?.data?.creator_has_no_redirections;
      state.minScaleEarnings = action.payload?.data?.data?.min_scale_earnings;
      state.maxScaleEarnings = action.payload?.data?.data?.max_scale_earnings;
      state.minScaleRedirections =
        action.payload?.data?.data?.min_scale_redirects;
      state.maxScaleRedirections =
        action.payload?.data?.data?.max_scale_redirects;
      state.minScaleOrders = action.payload?.data?.data?.min_scale_orders;
      state.maxScaleOrders = action.payload?.data?.data?.max_scale_orders;
      if (action.payload?.status === "fulfilled") {
        state.analyticsChartLoading = false;
      } else {
        state.analyticsChartLoading = true;
      }
    },
    updateChannelWiseEarningsSplit(state, action) {
      state.channelEarningsSplit = action.payload;
    },
    updateCollectionAnalytics(state, action) {
      if (action?.payload?.type === "manual") {
        state.collectionAnalytics = action?.payload?.updatedCollectionsList;
      }
      if (
        action?.payload?.data?.data !== undefined &&
        action.payload.status === "fulfilled"
      ) {
        if (action.payload.data.page === 1)
          state.collectionAnalytics = action.payload?.data?.data;
        else {
          state.collectionAnalytics = [
            ...state?.collectionAnalytics,
            ...action.payload?.data?.data,
          ];
        }
        state.maxAnalyticsCollections = action.payload.data.num_pages;
      }
      state.analyticsCollectionsDataIsLoading =
        action.payload?.status === "pending";
    },
    updatePostAnalytics(state, action) {
      if (action?.payload?.updateType === "manual") {
        state.postAnalytics = action?.payload?.updatedPostsList;
      }
      if (
        action?.payload?.data?.data !== undefined &&
        action.payload.status === "fulfilled"
      ) {
        if (action.payload.data.page === 1)
          state.postAnalytics = action.payload?.data?.data;
        else {
          state.postAnalytics = [
            ...state?.postAnalytics,
            ...action.payload?.data?.data,
          ];
        }
        state.maxAnalyticsPosts = action.payload.data.num_pages;
      }
      state.analyticsPostsDataIsLoading = action.payload?.status === "pending";
    },
    updateProductAnalytics(state, action) {
      if (
        action?.payload?.data?.data !== undefined &&
        action.payload.status === "fulfilled"
      ) {
        if (action.payload.data.page === 1)
          state.productAnalytics = action.payload?.data?.data;
        else {
          state.productAnalytics = [
            ...state?.productAnalytics,
            ...action.payload?.data?.data,
          ];
        }
        state.maxAnalyticsProducts = action.payload.data.num_pages;
      }
      state.analyticsProductsDataIsLoading =
        action.payload?.status === "pending";
    },
    updateSalesAnalytics(state, action) {
      state.salesAnalytics = action.payload;
    },
    updateAffiliateProductAnalytics(state, action) {
      state.affiliateProductAnalytics = action.payload;
    },
    updateRedirectionsAnalytics(state, action) {
      state.redirectionsAnalytics = action.payload;
    },
    updateAnalyticsChartType(state, action) {
      state.analyticsChartType = action.payload;
    },
    updateDonutChartType(state, action) {
      state.donutChartType = action.payload;
    },
    updateAnalyticsFiltersAndSort(state, action) {
      if (
        action?.payload?.data?.data !== undefined &&
        action?.payload?.data?.success === true
      )
        state.analyticsFilterAndSort = action.payload.data.data;
    },
    updateAnalyticsDates(state, action) {
      state.analyticsStartDate = action.payload?.startDateNew;
      state.analyticsEndDate = action.payload?.endDateNew;
      state.analyticsPreset = action.payload?.presetNew;
    },
    updateAnalyticsPostsReleaseDates(state, action) {
      state.analyticsPostsReleaseDates =
        action.payload?.data?.data?.posts_release_dates;
      state.analyticsFirstPostDate =
        action.payload?.data?.data?.first_post_date;
    },
    updateRewardsAnalytics(state, action) {
      state.rewardsAnalytics = action.payload;
    },
    updatePostOrCollectionLifetimeFunnel(state, action) {
      state.postOrCollectionLifetimeFunnel = action.payload;
    },
    updatePostOrCollectionEarnings(state, action) {
      state.postOrCollectionEarnings = action.payload;
    },
    updatePostOrCollectionProductEarnings(state, action) {
      state.postOrCollectionProductEarnings = action.payload;
    },
    updateSortAndFiltersForAnalytics(state, action) {
      if (
        action?.payload?.data?.data !== undefined &&
        action?.payload?.data?.success === true
      )
        state.analyticsSortAndFilters = action.payload.data.data;
    },
    updateAllowNextPageAPIAnalytics(state, action) {
      state.allowNextPageAPIAnalytics = action.payload;
    },
    updateSelectedAnalyticsPill(state, action) {
      state.selectedAnalyticsPagePill = action.payload;
    },
    updateAnalyticsPostsPage(state, action) {
      state.analyticsPostsPage = action.payload;
    },
    updateAnalyticsCollectionsPage(state, action) {
      state.analyticsCollectionsPage = action.payload;
    },
    updateAnalyticsProductsPage(state, action) {
      state.analyticsProductsPage = action.payload;
    },
    updateAnalyticsOnScrollYPos(state, action) {
      state.analyticsOnScrollYPos = action.payload;
    },
    updateAnalyticsSortBy(state, action) {
      state.analyticsSortBy = action.payload;
    },
    updateAnalyticsSelectedBrands(state, action) {
      state.analyticsSelectedBrand = action.payload || [];
    },
    updateAnalyticsSelectedChannel(state, action) {
      state.analyticsSelectedChannel = action.payload || [];
    },
    updateAnalyticsFilterBy(state, action) {
      state.analyticsFilterBy = action.payload || {};
    },
    updateOrderSummary(state, action) {
      if (
        action?.payload?.data?.data !== undefined &&
        action?.payload?.data?.success === true &&
        action?.payload.status === "fulfilled"
      ) {
        if (action.payload.data.data.page === 1) {
          state.orderSummary = action.payload.data.data.order_summary_list;
        } else {
          state.orderSummary = [
            ...state?.orderSummary,
            ...action.payload?.data?.data.order_summary_list,
          ];
        }
        state.maxOrderPages = action.payload.data.data.num_pages;
        state.isOrderScrollAllowed = true;
      }
    },
    clearOrderSummary(state) {
      state.orderSummary = [];
    },
    updateIsOrderScrollAllowed(state, action) {
      state.isOrderScrollAllowed = action.payload;
    },
    updateOrderHistoryForDate(state, action) {
      if (
        action?.payload?.data?.data !== undefined &&
        action?.payload?.data?.data?.success === true
      ) {
        state.orderHistoryForDate = action.payload.data.data;
      }
    },
    updateOrderSummaryLoading(state, action) {
      state.orderSummaryLoading = action.payload;
    },
    updateOrderHistoryForDateLoading(state, action) {
      state.orderHistoryForDateLoading = action.payload;
    },
    updatePostOrCollectionLifetimeLinkClicks(state, action) {
      if (
        action?.payload?.data?.data !== undefined &&
        action?.payload?.data?.data?.success === true
      ) {
        state.postOrCollectionLifetimeLinkClicks = action.payload.data.data;
      }
    },
    updatePostOrCollectionLifetimeClicksLoading(state, action) {
      state.postOrCollectionLifetimeClicksLoading = action.payload;
    },
    updatePastPayoutsList(state, action) {
      state.pastPayoutsList[action.payload.page] = action.payload.data;
    },
  },
});

const engageSlice = createSlice({
  name: "engage",
  initialState: initialEngageState,
  reducers: {
    updateCtkDetails(state, action) {
      if (
        action?.payload?.data?.data !== undefined &&
        action.payload.status === "fulfilled"
      )
        state.ctkdetails = action.payload.data.data;
    },
    updateCtkDetailsLoading(state, action) {
      state.ctkdetailsLoading = action.payload;
    },
    updateKeywords(state, action) {
      if (
        action?.payload?.data?.data?.keyword_list !== undefined &&
        action.payload.status === "fulfilled"
      )
        state.keywords = action.payload.data.data?.keyword_list;
    },
    updateTemplates(state, action) {
      if (
        action?.payload?.data?.data !== undefined &&
        action.payload.status === "fulfilled"
      )
        state.templates = action.payload.data.data;
    },
    updateInstaBasicProfileData(state, action) {
      if (
        action?.payload?.data?.data !== undefined &&
        action.payload.status === "fulfilled"
      )
        state.instaBasicProfileData = action.payload.data.data;
    },
    updateInstaBasicProfileLoading(state, action) {
      state.instaBasicProfileLoading = action.payload;
    },
  },
});

const profileSlice = createSlice({
  name: "profile",
  initialState: initialProfileState,
  reducers: {
    updateBankDetails(state, action) {
      if (
        action?.payload?.data?.bank_details !== undefined &&
        action.payload.status === "fulfilled"
      )
        state.bankDetails = action.payload.data.bank_details;
      state.bankDetailsIsLoading = getDataPointLoadingValue(action.payload);
    },
    updateCreatorAddress(state, action) {
      if (
        action?.payload?.data?.data !== undefined &&
        action.payload.status === "fulfilled"
      )
        state.creatorAddress = action.payload.data.data;
    },
    updateAddressById(state, action) {
      if (
        action?.payload?.data?.data !== undefined &&
        action.payload.status === "fulfilled"
      )
        state.creatorAddressById = action.payload.data.data;
    },
    updateCreatorAddressByIdLoading(state, action) {
      state.creatorAddressByIdLoading = action.payload;
    },
    clearAddressById(state) {
      state.creatorAddressById = {};
    },
  },
});

const appSlice = createSlice({
  name: "app",
  initialState: initialState,
  reducers: {
    initReceived(state, action) {
      state.hello = action.payload;
    },
    meeshoBannerClicked(state, action) {
      state.meeshoBannerToggled = action.payload;
    },
    updateShowPopup(state, action) {
      state.showPopup = action.payload;
    },
    updateShowCongratulationsPopup(state, action) {
      state.showCongratulationsPopup = action.payload;
    },
    updateMilestones(state, action) {
      if (
        action?.payload?.data?.data?.milestones_found === true &&
        action.payload.status === "fulfilled"
      ) {
        state.milestones = action.payload.data.data;
        let all_milestones = action?.payload?.data?.data;
        let first_post = false;
        let social_login_complete = false;
        let first_collection = false;
        let creator_profile = false;
        let first_sale = false;
        let earnings_cross_2k = false;
        let engage_connected = false;
        let first_link_created = false;

        if (all_milestones?.check_first_post_milestone?.achieved) {
          first_post = true;
        }
        if (all_milestones?.check_creator_social_login_complete?.achieved) {
          social_login_complete = true;
        }
        if (all_milestones?.check_first_collection_milestone?.achieved) {
          first_collection = true;
        }
        if (all_milestones?.check_creator_profile_milestone?.achieved) {
          creator_profile = true;
        }
        if (all_milestones?.check_first_sale_milestone?.achieved) {
          first_sale = true;
        }
        if (all_milestones?.check_earnings_cross_2k_milestone?.achieved) {
          earnings_cross_2k = true;
        }
        if (all_milestones?.check_engage_connected?.achieved) {
          engage_connected = true;
        }
        if (all_milestones?.check_first_link_created?.achieved) {
          first_link_created = true;
        }
        setUserPropertiesGa({
          first_post: first_post,
          social_login_complete: social_login_complete,
          first_collection: first_collection,
          creator_profile: creator_profile,
          first_sale: first_sale,
          earnings_cross_2k: earnings_cross_2k,
          engage_connected: engage_connected,
          first_link_created: first_link_created,
        });
        triggerGAPlatform();
      }
    },
    updateMilestonesLoading(state, action) {
      state.milestonesLoading = action.payload;
    },
    updateIsUserFromDiy(state, action) {
      state.isUserFromDiy = action.payload;
    },
    updateMediaReels(state, action) {
      if (
        action?.payload?.data?.data !== undefined &&
        action.payload.status === "fulfilled"
      ) {
        state.mediaReels = action?.payload?.data?.data;
      }
    },
    updateIsDiyAllowed(state, action) {
      state.isDiyAllowed = action.payload;
    },
    updateUsernameConfirmDrawerState(state, action) {
      state.usernameConfirmDrawerState = action.payload;
    },
    updateSharePostText(state, action) {
      state.sharePostText = action.payload;
    },
    updatePageNameAndScreenName(state, action) {
      state.pageName = action.payload?.pageName;
      state.screenName = action.payload?.screenName;
    },
    updateProductFinds(state, action) {
      if (
        action?.payload?.data?.data !== undefined &&
        action.payload.status === "fulfilled"
      ) {
        state.productFinds = action?.payload?.data?.data;
      }
    },
    updateIsMyntraCreator(state, action) {
      if (
        action?.payload?.data?.data !== undefined &&
        action.payload.status === "fulfilled"
      ) {
        state.isMyntraCreator = action.payload.data.data.is_myntra_creator;
      }
    },
    updateOauthParameters(state, action) {
      state.oauthResponseType = action.payload?.oauthResponseType;
      state.oauthRedirectUri = action.payload?.oauthRedirectUri;
      state.oauthClientId = action.payload?.oauthClientId;
      state.oauthCodeChallenge = action.payload?.oauthCodeChallenge;
      state.onGoingOauth = action.payload?.onGoingOauth;
    },
    updateOauthClientName(state, action) {
      state.oauthClientName = action.payload?.data?.data?.client_name;
    },
    updateOauthClientNameIsLoading(state, action) {
      state.oauthClientNameIsLoading = action.payload;
    },
    updateFreshDeskLoading(state, action) {
      state.loadingFreshDesk = action.payload;
    },
  },
});

const diySlice = createSlice({
  name: "diy",
  initialState: initialDiyDataStates,
  reducers: {
    updateCreatorTagsData(state, action) {
      state.tags = action?.payload?.data?.data;
    },
    updateScrapingFailureDomains(state, action) {
      state.scrapingFailureDomains = action?.payload?.data?.data?.domain_list;
    },
    updateScrapingFailureDomainsIsLoading(state, action) {
      state.scrapingFailureDomainsIsLoading = action.payload;
    },
    fetchCreatorPost(state, action) {
      if (action?.payload?.updateType === "manual") {
        state.creatorPost = action?.payload?.updatedPost;
      } else {
        state.creatorPost = action?.payload?.data?.data;
        state.creatorPostIsLoading = getDataPointLoadingValue(action.payload);
      }
    },
    updateProductLinksData(state, action) {
      state.productLinks = action?.payload?.data?.data;
      state.productLinksIsLoading = getDataPointLoadingValue(action.payload);
    },
    updatePostOrCollectionProductsData(state, action) {
      state.productsData = action?.payload?.data?.data;
      state.productDataIsLoading = getDataPointLoadingValue(action.payload);
    },
    updateInstagramSelectedPost(state, action) {
      state.instagramSelectedPostUrl = action.payload;
    },
    updateYoutubeSelectedPost(state, action) {
      state.youtubeSelectedPostUrl = action.payload;
    },
    updateSelectedPostObject(state, action) {
      state.selectedPostObj = action.payload;
    },
  },
});

const loginSlice = createSlice({
  name: "login",
  initialState: initialLoginStates,
  reducers: {
    updateLoginDrawerStage(state, action) {
      state.drawerStage = action.payload;
    },
    updateSocialLoginState(state, action) {
      state.socialLoginState = action.payload;
    },
    updateAuthCheckStatus(state, action) {
      state.authCheckStatus = action.payload;
    },
    updateAllowWhatsappChat(state, action) {
      state.allowWhatsappChat = action.payload;
    },
  },
});

const apiSlice = createSlice({
  name: "api",
  initialState: initialApiDataStates,
  reducers: {
    updateIsScrollAllowed(state, action) {
      state.isScrollAllowed = action.payload;
    },
    updateCreatorData(state, action) {
      state.creatorData = action.payload;
      triggerGAUserId(action.payload?.data?.data?.id);
      triggerGAPlatform();
    },
    updateMeeshoCatalogDetails(state, action) {
      state.meeshoCatalogPdfUrl = action?.payload?.data?.data?.catalog_url;
      state.meeshoCatalogRedirectUrl =
        action?.payload?.data?.data?.meesho_collection_redirect_url;
    },
    updateCreatorDataIsLoading(state, action) {
      state.creatorDataIsLoading = action.payload;
    },
    updateOverlays(state, action) {
      state.overlays = action.payload?.data?.data?.overlays;
    },
    updateOverlaysIsLoading(state, action) {
      state.overlaysIsLoading = action.payload;
    },
    updateSuppressOverlays(state, action) {
      state.suppressOverlays = action.payload;
    },
    updatePostsData(state, action) {
      if (action?.payload?.updateType === "manual") {
        state.postsData = action.payload?.updatedPostsList;
      } else {
        if (
          action?.payload?.data?.data !== undefined &&
          action.payload.status === "fulfilled"
        ) {
          if (action.payload.data.page === 1)
            state.postsData = action.payload?.data?.data;
          else {
            state.postsData = [
              ...state?.postsData,
              ...action.payload?.data?.data,
            ];
          }
          state.maxPosts = action.payload.data.num_pages;
        }
      }
    },
    updatePostsDataIsLoading(state, action) {
      state.postsDataIsLoading = action.payload;
    },
    updateCollectionsData(state, action) {
      if (action.payload.updateType === "manual") {
        state.collectionsData = action.payload?.updatedCollectionsList;
      } else {
        if (
          action?.payload?.data?.data !== undefined &&
          action.payload.status === "fulfilled"
        ) {
          if (action.payload.data.page === 1)
            state.collectionsData = action.payload?.data?.data;
          else {
            state.collectionsData = [
              ...state?.collectionsData,
              ...action.payload?.data?.data,
            ];
          }
          state.maxCollections = action.payload.data.num_pages;
        }
      }
    },
    updateCollectionsDataIsLoading(state, action) {
      state.collectionsDataIsLoading = action.payload;
    },
    updateProductsData(state, action) {
      if (
        action?.payload?.data?.data !== undefined &&
        action.payload.status === "fulfilled"
      ) {
        if (action.payload.data.page === 1)
          state.productsData = action.payload?.data?.data;
        else {
          state.productsData = [
            ...state?.productsData,
            ...action.payload?.data?.data,
          ];
        }
        state.maxProducts = action.payload.data.num_pages;
      }
    },
    updateProductDataIsLoading(state, action) {
      state.productsDataIsLoading = action.payload;
    },
    updateDraftsData(state, action) {
      state.draftsData = action.payload?.data?.data;
    },
    updateDraftsDataIsLoading(state, action) {
      state.draftsDataIsLoading = action.payload;
    },
    updateCreatePostDrawer(state, action) {
      state.createPostDrawer = action.payload;
    },
    updatePostDrawerDetailsAndRedirections(state, action) {
      state.postDrawerDetailsAndRedirections = action.payload;
    },
    updateShopCountsData(state, action) {
      state.shopCountsData = action.payload;
    },
    updatePostProductsData(state, action) {
      state.postProductsData = action.payload;
    },
    updatePostProductsDataIsLoading(state, action) {
      state.postProductsDataIsLoading = action.payload;
    },
    updateDrawerDetailsType(state, action) {
      state.drawerDetailsType = action.payload;
    },
    showNewPostDrawer(state, action) {
      state.newPostDrawer = action.payload;
    },
    updateFiltersAndSort(state, action) {
      if (
        action?.payload?.data?.data !== undefined &&
        action?.payload?.data?.success === true
      )
        state.filtersAndSort = action.payload.data.data;
    },
    updatePostOrCollectionStatusIsLoading(state, action) {
      state.postOrCollectionStatusIsLoading = action.payload;
    },
    updateNewPostDataIsLoading(state, action) {
      state.newPostDataIsLoading = action.payload;
    },
    updatePostRedirects(state, action) {
      if (
        action?.payload?.data?.data !== undefined &&
        action?.payload?.data?.success === true
      ) {
        if (action.payload?.data?.page === 1) {
          state.postRedirects = action.payload.data.data;
        } else {
          state.postRedirects = {
            ...state?.postRedirects,
            ...action.payload?.data?.data,
          };
        }
      }
    },
    updateCollectionRedirects(state, action) {
      if (
        action?.payload?.data?.data !== undefined &&
        action?.payload?.data?.success === true
      ) {
        if (action.payload?.data?.page === 1) {
          state.collectionRedirects = action.payload.data.data;
        } else {
          state.collectionRedirects = {
            ...state?.collectionRedirects,
            ...action.payload?.data?.data,
          };
        }
      }
    },
    updateProductRedirects(state, action) {
      if (
        action?.payload?.data?.data !== undefined &&
        action?.payload?.data?.success === true
      ) {
        if (action.payload?.data?.page === 1) {
          state.productRedirects = action.payload.data.data;
        } else {
          state.productRedirects = {
            ...state?.productRedirects,
            ...action.payload?.data?.data,
          };
        }
      }
    },
    updateInstaPostsList(state, action) {
      if (
        action?.payload?.data?.data !== undefined &&
        action.payload.status === "fulfilled"
      ) {
        if (action?.payload?.data?.data?.first_page_of_lists) {
          state.instaPostsList = action.payload?.data?.data?.posts;
        } else {
          state.instaPostsList = [
            ...state?.instaPostsList,
            ...action.payload?.data?.data?.posts,
          ];
        }
        state.instaPostsNextPageExists =
          action?.payload?.data?.data?.next_page_exists;
        state.instaPostsNextPageCursor =
          action?.payload?.data?.data?.next_page_cursor;
      }
      state.instagramPostsDataIsLoading = getDataPointLoadingValue(
        action.payload
      );
    },
    updateYoutubePostsList(state, action) {
      if (
        action?.payload?.data?.data !== undefined &&
        action.payload.status === "fulfilled"
      ) {
        if (action?.payload?.data?.data?.first_page_of_lists) {
          state.youtubePostsList = action.payload?.data?.data?.videos;
        } else {
          state.youtubePostsList = [
            ...state?.youtubePostsList,
            ...action.payload?.data?.data?.videos,
          ];
        }
        state.youtubePostsNextPageExists =
          action?.payload?.data?.data?.next_page_exists;
        state.youtubePostsNextPageToken =
          action?.payload?.data?.data?.next_page_token;
      }
      state.youtubePostsDataIsLoading = getDataPointLoadingValue(
        action.payload
      );
    },
    updateCreatorSocialLoginStatus(state, action) {
      state.creatorSocialLoginStatus = action.payload;
    },
    updateCrossPostingConfig(state, action) {
      state.crossPostingConfig = action.payload;
    },
    updateSelectedShopPill(state, action) {
      state.selectedShopPagePill = action.payload;
    },
    updatePostsPage(state, action) {
      state.postsPage = action.payload;
    },
    updateCollectionsPage(state, action) {
      state.collectionsPage = action.payload;
    },
    updateProductsPage(state, action) {
      state.productsPage = action.payload;
    },
    updateOnScrollYPos(state, action) {
      state.onScrollYPos = action.payload;
    },
    updateSortBy(state, action) {
      state.sortBy = action.payload;
    },
    updateSelectedBrands(state, action) {
      state.selectedBrands = action.payload || [];
    },
    updateSelectedChannel(state, action) {
      state.selectedChannel = action.payload || [];
    },
    updateFilterBy(state, action) {
      state.filterBy = action.payload || {};
    },
    updateCreatorDemoDataLoading(state, action) {
      state.creatorDemoDataIsLoading = action.payload;
    },
    updateCreatorDemoData(state, action) {
      state.showCreatorDemoBanner =
        action.payload?.data?.data?.show_demo_banner;
    },
    updateCreatorWinterContentMeetDataLoading(state, action) {
      state.showCreatorWinterMeetBannerIsLoading = action.payload;
    },
    updateCreatorWinterContentMeetData(state, action) {
      state.showCreatorWinterMeetBanner =
        action.payload?.data?.data?.show_meet_banner;
    },
  },
});

const notificationSlice = createSlice({
  name: "notification",
  initialState: initialNotificationStates,
  reducers: {
    showToastNotification(state, action) {
      if (action.payload.message) {
        state.variant = action.payload.variant;
        state.message = action.payload.message;
        state.showToast = true;
      }
    },
    hideToastNotification(state) {
      state.showToast = false;
    },
  },
});

const brandSlice = createSlice({
  name: "brand",
  initialState: initialBrandStates,
  reducers: {
    updateIsBrandScrollAllowed(state, action) {
      state.isBrandScrollAllowed = action.payload;
    },
    updateBrandListIsLoading(state, action) {
      state.brandListIsLoading = action.payload;
    },
    updateFilteredBrandsData(state, action) {
      if (
        action?.payload?.data?.data !== undefined &&
        action?.payload?.data?.success === true
      ) {
        if (state.defaultBrandsList.length === 0) {
          state.defaultBrandsList = action.payload?.data?.data?.brand_list;
        }
        let listToManipulate = [];
        if (action.payload?.data?.data?.fetch_only_favorite_brands) {
          listToManipulate = state.favoriteBrands;
        } else {
          listToManipulate = state.brandList;
        }
        if (action.payload?.data?.data?.page === 1) {
          listToManipulate = action.payload?.data?.data?.brand_list;
        } else if (action.payload?.data?.data?.brand_list.length === 0) {
          listToManipulate = [];
        } else {
          listToManipulate = [
            ...listToManipulate,
            ...action.payload?.data?.data?.brand_list,
          ];
        }
        if (action.payload?.data?.data?.fetch_only_favorite_brands) {
          state.favoriteBrands = listToManipulate;
        } else {
          state.brandList = listToManipulate;
        }
        state.maxBrands = action.payload.data.data.total_pages;
        state.isBrandScrollAllowed = true;
        state.showSpotlightBrands =
          action.payload.data.data.show_spotlight_brands;
      }
    },
    updateBrandTagsData(state, action) {
      if (
        action?.payload?.data?.data !== undefined &&
        action?.payload?.data?.success === true
      ) {
        state.sourcingAccess =
          action.payload?.data?.data?.creator_has_sourcing_access;
        state.tags = action?.payload?.data?.data?.brand_tags;
        state.brandTagsIsLoading = getDataPointLoadingValue(action.payload);
      }
    },
    setSearchText(state, action) {
      state.searchText = action.payload;
    },
    updateBrandBanners(state, action) {
      if (
        action?.payload?.data?.data !== undefined &&
        action?.payload?.data?.success === true
      ) {
        state.brandBanners = action.payload.data.data;
      }
    },
  },
});

const HamburgerSlice = createSlice({
  name: "hamburger",
  initialState: initialHamburgerState,
  reducers: {
    toggleHamburger(state, action) {
      state.showHamburger = action.payload;
    },
    updateAllCreatorUsernames(state, action) {
      state.isUsernamesLoading = action?.payload?.isFetching;
      if (
        action?.payload?.data?.data !== undefined &&
        action?.payload?.data?.success === true
      ) {
        state.usernames = action.payload.data.data;
      }
    },
  },
});

const ReferralSlice = createSlice({
  name: "referral",
  initialState: initialReferralState,
  reducers: {
    updateCreatorReferrals(state, action) {
      if (
        action?.payload?.data?.data !== undefined &&
        action?.payload?.data?.success === true
      )
        state.creatorReferrals = action.payload.data.data;
    },
    updateReferrerDetails(state, action) {
      if (action?.payload?.data?.data !== undefined) {
        if (action?.payload?.data?.success === true) {
          state.referrerDetails = {
            ...action.payload.data.data,
            success: true,
          };
        } else {
          state.referrerDetails = { success: false };
        }
      }
    },
    updateCreatorRefererLedger(state, action) {
      if (
        action?.payload?.data?.data !== undefined &&
        action?.payload?.data?.success === true
      )
        state.creatorRefererLedger = action.payload.data.data;
    },
    updateCreatorRefererLedgerLoading(state, action) {
      state.creatorRefererLedgerLoading = action.payload;
    },
    updateCreatorReferalData(state, action) {
      if (
        action?.payload?.data?.data !== undefined &&
        action?.payload?.data?.success === true
      )
        state.creatorReferalData = action.payload.data.data;
    },
  },
});

const HomePageUtilitiesSlice = createSlice({
  name: "homePageUtilities",
  initialState: initialHomePageUtilitiesState,
  reducers: {
    updateExploreBanners(state, action) {
      if (
        action?.payload?.data?.data !== undefined &&
        action?.payload?.data?.success === true
      ) {
        state.exploreBanners = action.payload.data.data;
      }
    },
  },
});

export const {
  initReceived,
  updateShowPopup,
  updateShowCongratulationsPopup,
  updateMilestones,
  updateMilestonesLoading,
  updateIsUserFromDiy,
  updateMediaReels,
  updateIsDiyAllowed,
  updateUsernameConfirmDrawerState,
  updateSharePostText,
  updatePageNameAndScreenName,
  meeshoBannerClicked,
  updateProductFinds,
  updateIsMyntraCreator,
  updateOauthParameters,
  updateOauthClientName,
  updateOauthClientNameIsLoading,
  updateFreshDeskLoading,
} = appSlice.actions;
export const {
  updateCreatorData,
  updatePostsData,
  updateCollectionsData,
  updateProductsData,
  updateDraftsData,
  updateCreatePostDrawer,
  updateShopCountsData,
  updatePostProductsData,
  updatePostDrawerDetailsAndRedirections,
  updateDrawerDetailsType,
  updatePostProductsDataIsLoading,
  updateCollectionsDataIsLoading,
  updateProductDataIsLoading,
  updateCreatorDataIsLoading,
  updateOverlays,
  updateOverlaysIsLoading,
  updateSuppressOverlays,
  updatePostsDataIsLoading,
  updateDraftsDataIsLoading,
  showNewPostDrawer,
  updateInstaPostsList,
  updateYoutubePostsList,
  updateCreatorSocialLoginStatus,
  updateCrossPostingConfig,
  updateFiltersAndSort,
  updatePostOrCollectionStatusIsLoading,
  updateNewPostDataIsLoading,
  updatePostRedirects,
  updateCollectionRedirects,
  updateProductRedirects,
  updateIsScrollAllowed,
  updateIsListLoading,
  updateSelectedShopPill,
  updatePostsPage,
  updateCollectionsPage,
  updateProductsPage,
  updateOnScrollYPos,
  updateSortBy,
  updateSelectedBrands,
  updateSelectedChannel,
  updateFilterBy,
  updateMeeshoCatalogDetails,
  updateCreatorDemoDataLoading,
  updateCreatorDemoData,
  updateCreatorWinterContentMeetDataLoading,
  updateCreatorWinterContentMeetData,
} = apiSlice.actions;

export const {
  updateCreatorTagsData,
  fetchCreatorPost,
  updateProductLinksData,
  updatePostOrCollectionProductsData,
  updateInstagramSelectedPost,
  updateYoutubeSelectedPost,
  updateSelectedPostObject,
  updateScrapingFailureDomains,
  updateScrapingFailureDomainsIsLoading,
} = diySlice.actions;

export const { showToastNotification, hideToastNotification } =
  notificationSlice.actions;

export const {
  updateLoginDrawerStage,
  updateSocialLoginState,
  updateAuthCheckStatus,
  updateAllowWhatsappChat,
} = loginSlice.actions;

export const {
  updateFilteredBrandsData,
  updateBrandTagsData,
  setSearchText,
  updateBrandBanners,
  updateBrandListIsLoading,
  updateIsBrandScrollAllowed,
} = brandSlice.actions;

export const {
  updateCtkDetails,
  updateKeywords,
  updateTemplates,
  updateCtkDetailsLoading,
  updateInstaBasicProfileData,
  updateInstaBasicProfileLoading,
} = engageSlice.actions;

export const {
  updateAmazonAnalytics,
  updateAnalyticsChart,
  updateBrandWiseEarningsSplit,
  updateChannelWiseEarningsSplit,
  updateCollectionAnalytics,
  updatePostAnalytics,
  updateProductAnalytics,
  updateSalesAnalytics,
  updateRedirectionsAnalytics,
  updateAnalyticsChartType,
  updateDonutChartType,
  updateAnalyticsFiltersAndSort,
  updateAnalyticsPostsReleaseDates,
  updateAnalyticsDates,
  updateRewardsAnalytics,
  updatePostOrCollectionLifetimeFunnel,
  updatePostOrCollectionEarnings,
  updatePostOrCollectionProductEarnings,
  updateSortAndFiltersForAnalytics,
  updateAffiliateProductAnalytics,
  updateAllowNextPageAPIAnalytics,
  updateAnalyticsSelectedBrands,
  updateAnalyticsSelectedChannel,
  updateAnalyticsSortBy,
  updateAnalyticsFilterBy,
  updateAnalyticsOnScrollYPos,
  updateAnalyticsCollectionsPage,
  updateSelectedAnalyticsPill,
  updateAnalyticsProductsPage,
  updateAnalyticsPostsPage,
  updateOrderSummary,
  updateIsOrderScrollAllowed,
  updateOrderHistoryForDate,
  updateOrderSummaryLoading,
  updateOrderHistoryForDateLoading,
  clearOrderSummary,
  updatePostOrCollectionLifetimeLinkClicks,
  updatePostOrCollectionLifetimeClicksLoading,
  updatePastPayoutsList,
} = analyticsSlice.actions;

export const {
  updateCampaignDetails,
  updateRewardsLeaderboard,
  updateRewardsSummary,
} = rewardsSlice.actions;

export const { updateBrandRewards } = brandRewardsSlice.actions;

export const { updateNykaaBrandRewards } = nykaaBrandRewardsSlice.actions;

export const { toggleHamburger, updateAllCreatorUsernames } =
  HamburgerSlice.actions;
export const {
  updateBankDetails,
  updateCreatorAddress,
  updateAddressById,
  clearAddressById,
  updateCreatorAddressByIdLoading,
} = profileSlice.actions;
export const {
  updateCreatorLifetimeEarnings,
  updateCreatorEarningsThisPayout,
  updatePastPayouts,
} = payoutSlice.actions;
export const { updateShowTooltip, updateTooltipState } = helperSlice.actions;
export const {
  updateNotificationsList,
  updateEarlierNotificationsList,
  updateIsNotificationScrollAllowed,
  updateBellIconStatus,
  appendNewSocketNotification,
  updateNotificatonsListLoading,
  updateBellIconLoading,
  updateSingleNotification,
} = notificationsListSlice.actions;
export const {
  updateCreatorReferrals,
  updateReferrerDetails,
  updateCreatorRefererLedger,
  updateCreatorRefererLedgerLoading,
  updateCreatorReferalData,
} = ReferralSlice.actions;
export const { updateExploreBanners } = HomePageUtilitiesSlice.actions;

export const { updateMarqueeDetails } = marqueeSlice.actions;

export const store = configureStore({
  reducer: {
    appReducer: appSlice.reducer,
    apiDataReducer: apiSlice.reducer,
    diyReducer: diySlice.reducer,
    notificationReducer: notificationSlice.reducer,
    loginReducer: loginSlice.reducer,
    brandReducer: brandSlice.reducer,
    hamburgerReducer: HamburgerSlice.reducer,
    profileReducer: profileSlice.reducer,
    engageReducer: engageSlice.reducer,
    analyticsReducer: analyticsSlice.reducer,
    payoutReducer: payoutSlice.reducer,
    helperReducer: helperSlice.reducer,
    rewardsReducer: rewardsSlice.reducer,
    brandRewardsReducer: brandRewardsSlice.reducer,
    nykaaBrandRewardsReducer: nykaaBrandRewardsSlice.reducer,
    notificationsListReducer: notificationsListSlice.reducer,
    referralReducer: ReferralSlice.reducer,
    recommendedCollectionsReducer: RecommendedCollectionSlice.reducer,
    youtubeShoppingReducer: YoutubeShoppingSlice.reducer,
    socialMediaProfileReducer: socialMediaProfileSlice.reducer,
    loginOtpReducer: loginOtpSlice.reducer,

    marqueeReducer: marqueeSlice.reducer,
    HomePageUtilitiesReducer: HomePageUtilitiesSlice.reducer,
    brandsPageReducer: BrandsPageSlice.reducer,
    [BrandsPageReduxAPI.reducerPath]: BrandsPageReduxAPI.reducer,
    [reduxAPI.reducerPath]: reduxAPI.reducer,

    //sourcing
    sourcingCollectionsReducer: collectionsSlice.reducer,
    sourcingHomeReducer: sourcingHomeSlice.reducer,
    sourcingCartReducer: sourcingCartSlice.reducer,
    sourcingWishlistReducer: sourcingWishlistSlice.reducer,
    sourcingMyOrdersReducer: sourcingMyOrdersSlice.reducer,
    sourcingProductReducer: sourcingProductSlice.reducer,
    sourcingCreditsReducer: sourcingCreditsSlice.reducer,
    homePageReducer: homePageSlice.reducer,

    [sourcingCollectionsReduxAPI.reducerPath]:
      sourcingCollectionsReduxAPI.reducer,
    [sourcingHomeReduxAPI.reducerPath]: sourcingHomeReduxAPI.reducer,
    [sourcingCartReduxAPI.reducerPath]: sourcingCartReduxAPI.reducer,
    [sourcingWishlistReduxAPI.reducerPath]: sourcingWishlistReduxAPI.reducer,
    [sourcingMyOrdersReduxAPI.reducerPath]: sourcingMyOrdersReduxAPI.reducer,
    [sourcingProductReduxAPI.reducerPath]: sourcingProductReduxAPI.reducer,
    [sourcingCreditsReduxAPI.reducerPath]: sourcingCreditsReduxAPI.reducer,

    [recommendedCollectionsReduxAPI.reducerPath]:
      recommendedCollectionsReduxAPI.reducer,
    [youtubeShoppingReduxAPI.reducerPath]: youtubeShoppingReduxAPI.reducer,
    [profileReduxAPI.reducerPath]: profileReduxAPI.reducer,
    [homePageReduxAPI.reducerPath]: homePageReduxAPI.reducer,
    [miniSourcingAuthAPI.reducerPath]: miniSourcingAuthAPI.reducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }).concat(
      reduxAPI.middleware,
      sourcingCollectionsReduxAPI.middleware,
      sourcingHomeReduxAPI.middleware,
      sourcingCartReduxAPI.middleware,
      sourcingWishlistReduxAPI.middleware,
      sourcingMyOrdersReduxAPI.middleware,
      sourcingProductReduxAPI.middleware,
      sourcingCreditsReduxAPI.middleware,
      recommendedCollectionsReduxAPI.middleware,
      recommendedCollectionsReduxAPI.middleware,
      youtubeShoppingReduxAPI.middleware,
      profileReduxAPI.middleware,
      homePageReduxAPI.middleware,
      BrandsPageReduxAPI.middleware,
      miniSourcingAuthAPI.middleware
    ),
});

setupListeners(store.dispatch);
