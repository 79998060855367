// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@keyframes toastAnimation {
    0% {
        bottom: 0;
    }

    3.3% {
        bottom: 5rem;
    }

    96.7% {
        bottom: 5rem;
    }

    100% {
        bottom: 0;
    }
}`, "",{"version":3,"sources":["webpack://./src/WishlinkComponents/WishlinkNotification/index.css"],"names":[],"mappings":"AAAA;IACI;QACI,SAAS;IACb;;IAEA;QACI,YAAY;IAChB;;IAEA;QACI,YAAY;IAChB;;IAEA;QACI,SAAS;IACb;AACJ","sourcesContent":["@keyframes toastAnimation {\n    0% {\n        bottom: 0;\n    }\n\n    3.3% {\n        bottom: 5rem;\n    }\n\n    96.7% {\n        bottom: 5rem;\n    }\n\n    100% {\n        bottom: 0;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
