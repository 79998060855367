import { getAnalytics, logEvent, setUserProperties } from "firebase/analytics";
import { getAppBuildVersion, getAppSessionUuid, getPlatform } from "./config";

export const triggerGa = ({
  category = "",
  action = "",
  label = "",
  label2 = "",
  label3 = "",
  page = "",
  screenName = "",
  eventValue = "",
  custom = "",
  post_id = undefined,
  collection_id = undefined,
  product_id = undefined,
}) => {
  if (!localStorage.adminToken) {
    const eventDetails = {
      event_category: category,
      event_label: label,
      event_location: window.location.pathname,
      event_label2: label2,
      event_label3: label3,
      page_title: page,
      screen_name: screenName,
      value: eventValue,
      custom_parameter: custom,
      post_id: post_id,
      collection_id: collection_id,
      product_id: product_id,
      platform: getPlatform(),
      app_build: getAppBuildVersion(),
      app_open_id: getAppSessionUuid(),
    };
    if (window.cordova) {
      window.FirebasePlugin?.logEvent(action, eventDetails);
      window.ga?.trackEvent(category, action, label);
    } else {
      // window.gtag("event", action, eventDetails);  // removed for setting up GA with firebase analytics for web as well
      const analytics = getAnalytics();
      logEvent(analytics, action, eventDetails);
    }
  }
};

export const setUserPropertiesGa = (property_params = {}) => {
  const analytics = getAnalytics();
  setUserProperties(analytics, property_params);
};
